import { FC, useState, useEffect } from "react";
import { useTheme } from "@mui/material";
import {
  Input,
  Icon,
  Select,
  Button,
  PersistentDrawer,
  Accordion,
  MultiSelect,
} from "@ntpkunity/controls";
import { ConfirmationDialog, SmallAccordionWrap } from "shared/components";
import { INewStationeryPopUp } from "./addNewTemplate.type.js";
import { IDealer, ISelectItemList, IStates, IStationery } from "Interface";
import { Controller, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { usePrompt } from "utilities/usePrompt";
import { logout } from "@ntpkunity/controls-ums";
import {
  UseMutation_AddStationery,
  UseMutation_UpdateStationery,
} from "services";

import {
  ActionOptions,
  ValidationMessages,
  QueryKeys,
  DialogMessages,
  StType,
  StationeryRole,
  StationeryType,
  StationeryAction,
  StationeryEvent,
} from "Enums";
import { useNavigate } from "react-router-dom";
import { useStoreContext } from "Stores/SetupStore/Store";

const AddNewStationery: FC<INewStationeryPopUp> = ({
  openPopUp,
  setPopUpState,
  title,
  actionType,
  stationeryData,
  enabledPin,
}) => {
  const theme = useTheme();

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    setError,
    formState: { errors, isDirty },
    watch,
  } = useForm<IStationery>();
  const navigate = useNavigate();

  const [navigationState, setNavigationState] = useState<any>();
  const [leavePagePopupState, setLeavePageState] = useState<boolean>(false);
  const [states, setStates] = useState([]);
  const [dealerDropdownItems, setDealerDropdownItems] = useState<Array<any>>(
    []
  );
  const [signingOrderItems, setSigningOrderItems] = useState<any>([]);

  const queryClient = useQueryClient();
  const { actions } = useStoreContext();
  const items = [
    { text: "Yes", value: true },
    { text: "No", value: false },
  ];

  const usaStates: Array<IStates> = queryClient.getQueryData(
    QueryKeys.GET_ALL_STATES
  );
  const tenant_templates: any = queryClient.getQueryData(
    QueryKeys.GET_ALL_TENANT_DOCUMENTS
  );

  const allDealers: Array<IDealer> = queryClient.getQueryData(
    QueryKeys.GET_ALL_DEALERS
  );
    const activeDealers: Array<IDealer> = queryClient.getQueryData(
    QueryKeys.GET_ACTIVE_DEALERS
  );

  const { mutate: AddStationery } = UseMutation_AddStationery();
  const { mutate: UpdateStationery } = UseMutation_UpdateStationery();

  const updateDealerDropdownItems = () => {
    const allDealersSelectedIds: any = stationeryData?.dealers?.map(
      (dealer) => dealer
    );
    const selectedDealers = allDealersSelectedIds?.map((id) => {
      let dealer = allDealers?.find((dealer) => dealer.id === id);
      return dealer;
    });
    const combinedDealers = [...selectedDealers, ...activeDealers];

    const uniqueDealersSet = new Set(
      combinedDealers.map((dealer) => dealer.id)
    );
    const dropdownDealers = Array.from(uniqueDealersSet).map((id) =>
      combinedDealers.find((dealer) => dealer.id === id)
    );
    setDealerDropdownItems(
      dropdownDealers?.map((dealer) => ({
        selected: allDealersSelectedIds.includes(dealer.id),
        text: dealer.dealer_name,
        value: dealer.id,
      }))
    );
  };

  useEffect(() => {
    if (actionType === ActionOptions.ADD) {
      setDealerDropdownItems(
        activeDealers?.map((dealer) => ({
          text: dealer.dealer_name,
          value: dealer.id,
        }))
      );
    }

    if (actionType === ActionOptions.EDIT) {
      stationeryData = setFieldsData(stationeryData);
      setSigningOrderItems(
        formArray(stationeryData?.required_signatories?.length)
      );
      reset({
        ...stationeryData,
        is_signature_required:
          stationeryData?.is_signature_required?.toString(),
      });
      updateDealerDropdownItems();
    }
  }, [actionType, stationeryData]);

  const setFieldsData = (obj) => {
    obj?.signatories?.forEach((element) => {
      if (element.role == StationeryRole.APPLICANT) {
        obj.applicant_signing_order = element.signing_order;
        obj.applicant_action = element.action;
        obj.applicant_id = element.id;
      } else if (element.role == StationeryRole.CO_APPLICANT) {
        obj.co_applicant_signing_order = element.signing_order;
        obj.co_applicant_action = element.action;
        obj.co_applicant_id = element.id;
      } else {
        obj.dealer_signing_order = element.signing_order;
        obj.dealer_action = element.action;
        obj.dealer_signatory_id = element.id;
      }
    });
    obj.templates = obj?.templates ? obj?.templates?.map((x) => x?.id) : [];
    return obj;
  };
  const updateSignatureType = (co_applicant: boolean) => {
    if (co_applicant == true) {
      setSignatureTypeItems(
        signatureTypeItems?.filter((x) => x.value !== StationeryType.ON_SCREEN)
      );
    } else {
      if (
        signatureTypeItems.find((x) => x.value !== StationeryType.ON_SCREEN)
      ) {
        setSignatureTypeItems([
          ...signatureTypeItems,
          {
            text: StationeryType.ON_SCREEN,
            value: StationeryType.ON_SCREEN,
          },
        ]);
      }
    }
  };
  function onSubmit(data: any) {
    let signatories: any = [];
    if (data?.applicant_signing_order)
      signatories.push({
        id: data?.applicant_id ?? 0,
        role: StationeryRole.APPLICANT,
        signing_order: data?.applicant_signing_order,
        action: data?.applicant_action,
      });
    if (data?.co_applicant_signing_order)
      signatories.push({
        id: data?.co_applicant_id ?? 0,
        role: StationeryRole.CO_APPLICANT,
        signing_order: data?.co_applicant_signing_order,
        action: data?.co_applicant_action,
      });
    if (data?.dealer_signing_order)
      signatories.push({
        id: data?.dealer_signatory_id ?? 0,
        role: StationeryRole.DEALER,
        signing_order: data?.dealer_signing_order,
        action: data?.dealer_action,
      });
    data.signatories = signatories;
    data.templates = JSON.stringify(mapTamplates(data?.templates));
    if (actionType === ActionOptions.ADD) {
      AddStationery(data, {
        onSuccess: (response) => {
          reset();
          onClose();
        },
        onError: (error) => {
          if (
            error?.response?.data?.detail?.message === "Code should be unique"
          ) {
            setError("code", { message: "Code should be unique" });
            return;
          } else {
            onClose();
            actions.setToast({
              toastMessage: error?.response?.data?.detail?.message,
              toastState: true,
              variant: "error",
            });
          }
        },
      });
    } else if (actionType === ActionOptions.EDIT) {
      UpdateStationery(data, {
        onSuccess: () => {
          reset();
          onClose();
        },
        onError: (error) => {
          if (
            error?.response?.data?.detail?.message === "Code should be unique"
          ) {
            setError("code", { message: "Code should be unique" });
            return;
          } else {
            onClose();
            actions.setToast({
              toastMessage: error?.response?.data?.detail?.message,
              toastState: true,
              variant: "error",
            });
          }
        },
      });
    }
  }
  const mapTamplates = (templateIds) => {
    let templates = tenant_templates?.filter((item) =>
      templateIds.includes(item.id)
    );
    return templates.map((item) => {
      return {
        id: item.id,
        template_name: item.template_name,
        template_url: item.template_url,
      };
    });
  };
  const onSaveConfirm = (e: any) => {
    handleSubmit(onSubmit)();
    setLeavePageState(false);
  };
  const onCancelConfirm = (e: any) => {
    navigate(".." + navigationState.location.pathname);
    if (navigationState.location.pathname == "/market-place/signup") {
      logout();
      localStorage.clear();
    }
    localStorage.setItem("DirtyState", "false");
  };
  usePrompt(
    "Leave screen?",
    isDirty,
    (dirty) => {
      setLeavePageState(dirty);
    },
    (tx) => {
      reset(getValues());
      setNavigationState(tx);
    }
  );
  const [signatureTypeItems, setSignatureTypeItems] = useState<
    Array<ISelectItemList>
  >(
    Object.values(StationeryType).map((value) => {
      return { text: value, value: value };
    })
  );

  useEffect(() => {
    if (usaStates) {
      setStates(
        usaStates?.map((x) => {
          return {
            text: x.name,
            value: x.id,
          };
        })
      );
    }
  }, [usaStates]);
  function formArray(number) {
    return Array.from(Array(number), (_, index) => ({
      text: index + 1,
      value: index + 1,
    }));
  }
  const onClose = () => {
    let children = document.querySelectorAll(".drawer-header .pinned");
    if (children.length == 0) {
      setPopUpState(false);
    }
  };
  return (
    <>
      <ConfirmationDialog
        openPopUp={leavePagePopupState}
        onConfirm={onSaveConfirm}
        onCancel={onCancelConfirm}
        setPopUpState={setLeavePageState}
        confirmationTitle={DialogMessages.saveTitle}
        confirmationText={DialogMessages.saveText}
        primaryBtnText={DialogMessages.saveBtnText}
        icon={<Icon name="DialogSaveIcon" />}
      />
      <PersistentDrawer
        title={title}
        openPopUp={openPopUp}
        setPopUpState={setPopUpState}
        theme={theme}
        enabledPin={true}
        children={
          <>
            <Controller
              name="code"
              control={control}
              rules={{
                maxLength: {
                  value: 5,
                  message: ValidationMessages.DOCUMENT_CODE_MAX_LENGTH,
                },
                required: ValidationMessages.CODE_REQUIRED,
              }}
              render={({ field: { onChange, value } }) => (
                <Input
                  id="drawerCode"
                  theme={theme}
                  fullWidth={true}
                  placeholder={"Type Code..."}
                  label={"Code"}
                  type="text"
                  value={value?.trimStart()}
                  onChange={onChange}
                  error={errors?.code?.message}
                />
              )}
            />
            <Controller
              name="name"
              control={control}
              rules={{
                required: ValidationMessages.STATIONERY_NAME,
              }}
              render={({ field: { onChange, value } }) => (
                <Input
                  id="drawerCode"
                  theme={theme}
                  fullWidth={true}
                  placeholder={"Type Code..."}
                  label={"Name"}
                  type="text"
                  value={value?.trimStart()}
                  onChange={onChange}
                  error={errors?.name?.message}
                />
              )}
            />
            <Controller
              name="is_active"
              control={control}
              defaultValue={true}
              render={({ field: { value, onChange } }) => (
                <Select
                  id="drawerIsActive"
                  theme={theme}
                  label={"Status"}
                  placeholder={"Select"}
                  items={[
                    { text: "Enabled", value: true },
                    { text: "Disabled", value: false },
                  ]}
                  value={value}
                  onChange={onChange}
                />
              )}
            />

            <Controller
              name="applicable_finance_types"
              control={control}
              defaultValue={[]}
              render={({ field }) => (
                <MultiSelect
                  id="applicationFinanceType"
                  name="applicable_finance_type"
                  theme={theme}
                  label={"Applicable Finance Type(s)"}
                  items={[
                    { text: "Lease", value: "Lease" },
                    { text: "Finance", value: "Finance" },
                  ]}
                  {...field}
                  value={field.value}
                />
              )}
            />
            <Controller
              name="dealers"
              control={control}
              defaultValue={[]}
              render={({ field }) => (
                <MultiSelect
                  id="dealers"
                  name="dealers"
                  theme={theme}
                  label={"Dealer"}
                  items={dealerDropdownItems}
                  {...field}
                  value={field.value}
                />
              )}
            />
            <Controller
              name={"states"}
              control={control}
              defaultValue={[]}
              render={({ field }) => (
                <MultiSelect
                  id="states"
                  name="states"
                  theme={theme}
                  label={"State"}
                  items={states}
                  {...field}
                />
              )}
            />
            <Controller
              name="co_applicant"
              control={control}
              defaultValue={false}
              render={({ field: { value, onChange } }) => (
                <Select
                  id="CoapplicantIsActive"
                  theme={theme}
                  label={"Co-Applicant"}
                  placeholder={"Select"}
                  items={items}
                  value={value ?? false}
                  onChange={(e) => {
                    onChange(e);
                    // On screen not to be available as an option if Co-applicant dealerached = Yes
                    updateSignatureType(e.target.value as boolean);
                  }}
                />
              )}
            />

            <Controller
              name="event"
              control={control}
              defaultValue={[]}
              rules={{
                required: ValidationMessages.STATIONERY_EVENT,
              }}
              render={({ field }) => (
                <MultiSelect
                  id="events"
                  name="event"
                  theme={theme}
                  label={"Event"}
                  items={Object.values(StationeryEvent).map((value) => {
                    return { text: value, value: value };
                  })}
                  {...field}
                  value={field.value}
                  selectError={errors?.event?.message}
                />
              )}
            />

            <Controller
              name="type"
              control={control}
              defaultValue={""}
              rules={{
                required: ValidationMessages.STATIONERY_TYPE,
              }}
              render={({ field: { value, onChange } }) => (
                <Select
                  id="drawerIsActive"
                  theme={theme}
                  label={"Type"}
                  placeholder={"Select"}
                  items={Object.values(StType).map((value) => {
                    return { text: value, value: value };
                  })}
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    setValue("required_signatories", []);
                    setValue("signature_type", "");
                    setValue("is_signature_required", null);
                    setValue("applicant_signing_order", null);
                    setValue("applicant_action", "");
                    setValue("co_applicant_signing_order", null);
                    setValue("co_applicant_action", "");
                    setValue("dealer_signing_order", null);
                    setValue("dealer_action", "");
                  }}
                  selectError={errors?.type?.message}
                />
              )}
            />
            <Controller
              name="recipient"
              control={control}
              defaultValue={[]}
              rules={{
                required: ValidationMessages.STATIONERY_RECIPIENT,
              }}
              render={({ field }) => (
                <MultiSelect
                  id="Recipient"
                  name="recipient"
                  theme={theme}
                  label={"Recipient"}
                  items={Object.values(StationeryRole)
                    .map((value) => {
                      if (
                        value == "Co-applicant" &&
                        watch("co_applicant") === false
                      ) {
                        return null;
                      }
                      return { text: String(value), value: String(value) };
                    })
                    .filter((item) => item !== null)}
                  onChange={(e: any) => {
                    field.onChange(e);
                    if (!e.target.value?.includes(StationeryRole.APPLICANT)) {
                      if (
                        getValues("required_signatories").includes(
                          StationeryRole.APPLICANT
                        )
                      ) {
                        setValue(
                          "required_signatories",
                          getValues("required_signatories").filter(
                            (x) => x !== StationeryRole.APPLICANT
                          )
                        );
                      }
                      setValue("applicant_signing_order", null);
                      setValue("applicant_action", "");
                    }
                    if (
                      !e.target.value?.includes(StationeryRole.CO_APPLICANT)
                    ) {
                      if (
                        getValues("required_signatories").includes(
                          StationeryRole.CO_APPLICANT
                        )
                      ) {
                        setValue(
                          "required_signatories",
                          getValues("required_signatories").filter(
                            (x) => x !== StationeryRole.CO_APPLICANT
                          )
                        );
                      }
                      setValue("co_applicant_signing_order", null);
                      setValue("co_applicant_action", "");
                    }
                    if (!e.target.value?.includes(StationeryRole.DEALER)) {
                      if (
                        getValues("required_signatories").includes(
                          StationeryRole.DEALER
                        )
                      ) {
                        setValue(
                          "required_signatories",
                          getValues("required_signatories").filter(
                            (x) => x !== StationeryRole.DEALER
                          )
                        );
                      }
                      setValue("dealer_signing_order", null);
                      setValue("dealer_action", "");
                    }
                  }}
                  value={field.value}
                  selectError={errors?.recipient?.message}
                />
              )}
            />
            {watch("type") == StType.DOCUMENT && (
              <Controller
                name="is_signature_required"
                control={control}
                rules={{
                  required: {
                    value: watch("type") == StType.DOCUMENT,
                    message: ValidationMessages.SIGNATURE_REQUIRED,
                  },
                }}
                render={({ field: { value, onChange } }) => (
                  <Select
                    id="Signatures Required"
                    theme={theme}
                    label={"Signatures Required"}
                    placeholder={"Select"}
                    items={[
                      { text: "Yes", value: "true" },
                      { text: "No", value: "false" },
                    ]}
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                      setValue("required_signatories", []);
                      setValue("signature_type", "");
                      setValue("applicant_signing_order", null);
                      setValue("applicant_action", "");
                      setValue("co_applicant_signing_order", null);
                      setValue("co_applicant_action", "");
                      setValue("dealer_signing_order", null);
                      setValue("dealer_action", "");
                    }}
                    selectError={errors?.is_signature_required?.message}
                  />
                )}
              />
            )}
            {watch("is_signature_required") == "true" && (
              <>
                <Controller
                  name="required_signatories"
                  control={control}
                  defaultValue={[]}
                  rules={{
                    required: {
                      value: watch("is_signature_required") == "true",
                      message: ValidationMessages.REQUIRED_SIGNATORIES,
                    },
                  }}
                  render={({ field }) => (
                    <MultiSelect
                      id="Required Signatories"
                      name="Required Signatories"
                      theme={theme}
                      label={"Required Signatories"}
                      items={watch("recipient")?.map((value) => {
                        return { text: value, value: value };
                      })}
                      onChange={(e: any) => {
                        field.onChange(e);
                        if (
                          !e.target.value?.includes(StationeryRole.APPLICANT)
                        ) {
                          setValue("applicant_signing_order", null);
                          setValue("applicant_action", "");
                        }
                        if (
                          !e.target.value?.includes(StationeryRole.CO_APPLICANT)
                        ) {
                          setValue("co_applicant_signing_order", null);
                          setValue("co_applicant_action", "");
                        }
                        if (!e.target.value?.includes(StationeryRole.DEALER)) {
                          setValue("dealer_signing_order", null);
                          setValue("dealer_action", "");
                        }
                        setSigningOrderItems(formArray(e.target.value?.length));
                      }}
                      value={field.value}
                      selectError={errors?.required_signatories?.message}
                    />
                  )}
                />

                <Controller
                  name="signature_type"
                  control={control}
                  defaultValue={""}
                  rules={{
                    required: {
                      value: watch("is_signature_required") == "true",
                      message: ValidationMessages.SIGNATURE_TYPE,
                    },
                  }}
                  render={({ field: { value, onChange } }) => (
                    <Select
                      id="Signature Type"
                      theme={theme}
                      label={"Signature Type"}
                      placeholder={"Select"}
                      items={signatureTypeItems}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        setValue("applicant_signing_order", null);
                        setValue("applicant_action", "");
                        setValue("co_applicant_signing_order", null);
                        setValue("co_applicant_action", "");
                        setValue("dealer_signing_order", null);
                        setValue("dealer_action", "");
                      }}
                      selectError={errors?.signature_type?.message}
                    />
                  )}
                />
              </>
            )}
            {watch("signature_type") == StationeryType.ELECTRONIC && (
              <SmallAccordionWrap>
                {watch("required_signatories")?.find(
                  (x) => x == StationeryRole.APPLICANT
                ) && (
                  <Accordion
                    theme={theme}
                    items={[
                      {
                        title: "APPLICANT",
                        isExpanded: true,
                        key: "string",
                        content: (
                          <>
                            <Controller
                              name="applicant_signing_order"
                              control={control}
                              //   defaultValue={""}
                              rules={{
                                required: {
                                  value: !!watch("required_signatories")?.find(
                                    (x) => x == StationeryRole.APPLICANT
                                  ),
                                  message: ValidationMessages.SIGNING_ORDER,
                                },
                              }}
                              render={({ field: { value, onChange } }) => (
                                <Select
                                  id="signing Order"
                                  theme={theme}
                                  label={"Signing Order"}
                                  placeholder={"Select"}
                                  items={signingOrderItems}
                                  value={value}
                                  onChange={onChange}
                                  selectError={
                                    errors?.applicant_signing_order?.message
                                  }
                                />
                              )}
                            />
                            <Controller
                              name="applicant_action"
                              control={control}
                              defaultValue={""}
                              rules={{
                                required: {
                                  value: !!watch("required_signatories")?.find(
                                    (x) => x == StationeryRole.APPLICANT
                                  ),
                                  message: ValidationMessages.SIGNING_ACTION,
                                },
                              }}
                              render={({ field: { value, onChange } }) => (
                                <Select
                                  id="Action"
                                  theme={theme}
                                  label={"Action"}
                                  placeholder={"Select"}
                                  items={Object.values(StationeryAction).map(
                                    (value) => {
                                      return { text: value, value: value };
                                    }
                                  )}
                                  value={value}
                                  onChange={onChange}
                                  selectError={
                                    errors?.applicant_action?.message
                                  }
                                />
                              )}
                            />
                          </>
                        ),
                        actionBtn: (
                          <>
                            <Button
                              theme={theme}
                              defaultBtn
                              className="btn-delete"
                              iconText={<Icon name="DeleteIcon" />}
                            />
                          </>
                        ),
                      },
                    ]}
                  />
                )}
                {watch("required_signatories")?.find(
                  (x) => x == StationeryRole.CO_APPLICANT
                ) && (
                  <Accordion
                    theme={theme}
                    items={[
                      {
                        title: "CO-APPLICANT",
                        isExpanded: true,
                        key: "string",
                        content: (
                          <>
                            <Controller
                              name="co_applicant_signing_order"
                              control={control}
                              rules={{
                                required: {
                                  value: !!watch("required_signatories")?.find(
                                    (x) => x == StationeryRole.CO_APPLICANT
                                  ),
                                  message: ValidationMessages.SIGNING_ORDER,
                                },
                              }}
                              //   defaultValue={""}
                              render={({ field: { value, onChange } }) => (
                                <Select
                                  id="signing Order"
                                  theme={theme}
                                  label={"Signing Order"}
                                  placeholder={"Select"}
                                  items={signingOrderItems}
                                  value={value}
                                  onChange={onChange}
                                  selectError={
                                    errors?.co_applicant_signing_order?.message
                                  }
                                />
                              )}
                            />
                            <Controller
                              name="co_applicant_action"
                              control={control}
                              defaultValue={""}
                              rules={{
                                required: {
                                  value: !!watch("required_signatories")?.find(
                                    (x) => x == StationeryRole.CO_APPLICANT
                                  ),
                                  message: ValidationMessages.SIGNING_ACTION,
                                },
                              }}
                              render={({ field: { value, onChange } }) => (
                                <Select
                                  id="Action"
                                  theme={theme}
                                  label={"Action"}
                                  placeholder={"Select"}
                                  items={Object.values(StationeryAction).map(
                                    (value) => {
                                      return { text: value, value: value };
                                    }
                                  )}
                                  value={value}
                                  onChange={onChange}
                                  selectError={
                                    errors?.co_applicant_action?.message
                                  }
                                />
                              )}
                            />
                          </>
                        ),
                        actionBtn: (
                          <>
                            <Button
                              theme={theme}
                              defaultBtn
                              className="btn-delete"
                              iconText={<Icon name="DeleteIcon" />}
                            />
                          </>
                        ),
                      },
                    ]}
                  />
                )}
                {watch("required_signatories")?.find(
                  (x) => x == StationeryRole.DEALER
                ) && (
                  <Accordion
                    theme={theme}
                    items={[
                      {
                        title: "DEALER",
                        isExpanded: true,
                        key: "string",
                        content: (
                          <>
                            <Controller
                              name="dealer_signing_order"
                              control={control}
                              rules={{
                                required: {
                                  value: !!watch("required_signatories")?.find(
                                    (x) => x == StationeryRole.DEALER
                                  ),
                                  message: ValidationMessages.SIGNING_ORDER,
                                },
                              }}
                              //   defaultValue={""}
                              render={({ field: { value, onChange } }) => (
                                <Select
                                  id="signing Order"
                                  theme={theme}
                                  label={"Signing Order"}
                                  placeholder={"Select"}
                                  items={signingOrderItems}
                                  value={value}
                                  onChange={onChange}
                                  selectError={
                                    errors?.dealer_signing_order?.message
                                  }
                                />
                              )}
                            />
                            <Controller
                              name="dealer_action"
                              control={control}
                              defaultValue={""}
                              rules={{
                                required: {
                                  value: !!watch("required_signatories")?.find(
                                    (x) => x == StationeryRole.DEALER
                                  ),
                                  message: ValidationMessages.SIGNING_ACTION,
                                },
                              }}
                              render={({ field: { value, onChange } }) => (
                                <Select
                                  id="Action"
                                  theme={theme}
                                  label={"Action"}
                                  placeholder={"Select"}
                                  items={Object.values(StationeryAction).map(
                                    (value) => {
                                      return { text: value, value: value };
                                    }
                                  )}
                                  value={value}
                                  onChange={onChange}
                                  selectError={errors?.dealer_action?.message}
                                />
                              )}
                            />
                          </>
                        ),
                        actionBtn: (
                          <>
                            <Button
                              theme={theme}
                              defaultBtn
                              className="btn-delete"
                              iconText={<Icon name="DeleteIcon" />}
                            />
                          </>
                        ),
                      },
                    ]}
                  />
                )}
              </SmallAccordionWrap>
            )}
            <Controller
              name="templates"
              control={control}
              defaultValue={[]}
              rules={{
                required: {
                  value: true,
                  message: ValidationMessages.TEMPLATE_REQUIRED,
                },
              }}
              render={({ field }) => (
                <MultiSelect
                  id="templates"
                  theme={theme}
                  label={"Template"}
                  items={tenant_templates?.map((item) => {
                    return {
                      text: item.template_name,
                      value: item.id,
                    };
                  })}
                  {...field}
                  value={field.value}
                  selectError={errors?.templates?.message as any}
                />
              )}
            />
          </>
        }
        customFooter={
          <Button
            theme={theme}
            primary
            type="submit"
            text="Save Template"
            fullWidth
            onClick={handleSubmit(onSubmit)}
          />
        }
      />
    </>
  );
};

export default AddNewStationery;

import React, { FC, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { IconButton, useTheme } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { setOpenDrawer } from "../../../Stores/SetupStore/Actions";
import { useSetupsStore } from "../../../Stores/SetupStore/Store";
import EditProvider from "../../../components/EditDealerIntegrations/EditProviderIntegration";
import {
  Icon,
  Menu,
  Button,
  DataTable,
  Tooltip,
  Box,
  Input,
  Switch,
} from "@ntpkunity/controls";
import { DataTableWrap } from "shared/components";
import { useGetStripeAccountById } from "services/integration-api.service";
import { ActionOptions, QueryKeys } from "Enums";
import {
  useUpdateLenderIntegration,
  UseQuery_getAllLenderIntegration_filter,
  useUpdateLenderIntegrationParentStatus,
} from "services";
import {
  UseQuery_getAllDealerIntergrations,
  UseQuery_getAllDealerIntegration_byParams,
} from "services/dealer-integration.service";
import { useGetDealerProfileByDealerId } from "services/dealer-configurations.service";

const TbodyRow: FC<{
  onEdit: (data: any) => unknown;
  integrationsRow: any;
  dealerProfileData: any;
  dealer_profile_data: any;
  penDealerId?: any;
  detailedSubmited?: any;
}> = ({
  integrationsRow,
  onEdit,
  dealerProfileData,
  dealer_profile_data,
  penDealerId,
  detailedSubmited,
}) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isExistingCustomerCheck =
    integrationsRow.integration_type === "Existing Customer Check";
  return (
    <>
      <TableRow key={`parent${integrationsRow.id}`} className="child-tr">
        <TableCell>
          <Box theme={theme} display="flex" alignItems="center">
            {open ? (
              <Icon
                name="ChevronDown"
                className="toggle-btn ch-right"
                onClick={() => setOpen(!open)}
              />
            ) : (
              <Icon
                name="ChevronDown"
                className="toggle-btn"
                onClick={() => setOpen(!open)}
              />
            )}
            <Box theme={theme} display="flex" alignItems="center">
              {integrationsRow.integration_type}
              <Tooltip
                theme={theme}
                title={
                  isExistingCustomerCheck
                    ? ""
                    : integrationsRow.integration_type === "Inventory Upload"
                    ? dealerProfileData &&
                      dealerProfileData?.shift_digital_dealer_code
                      ? ""
                      : "Configuration is missing"
                    : integrationsRow.integration_type === "F&I Products"
                    ? dealer_profile_data && dealer_profile_data.pen_dealer_id
                      ? ""
                      : "Configuration is missing"
                    : "Configuration is missing"
                }
              >
                {isExistingCustomerCheck ? null : integrationsRow.integration_type ===
                  "Inventory Upload" ? (
                  dealerProfileData &&
                  dealerProfileData?.shift_digital_dealer_code ? (
                    <></>
                  ) : (
                    <Button
                      defaultBtn
                      className="pt-0 pb-0"
                      iconText={<Icon name="InfoIcon" />}
                    />
                  )
                ) : integrationsRow.integration_type === "F&I Products" ? (
                  penDealerId ? (
                    <></>
                  ) : (
                    <Button
                      defaultBtn
                      className="pt-0 pb-0"
                      iconText={<Icon name="InfoIcon" />}
                    />
                  )
                ) : (
                  !detailedSubmited && (
                    <Button
                      defaultBtn
                      className="pt-0 pb-0"
                      iconText={<Icon name="InfoIcon" />}
                    />
                  )
                )}
              </Tooltip>
            </Box>
          </Box>
        </TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell className="action-cell fixed-cell"></TableCell>
      </TableRow>
      {open ? (
        <Results
          integrationsRow={integrationsRow}
          onEdit={(data) => onEdit(data)}
          dealerProfileData={dealerProfileData}
          dealer_profile_data={dealer_profile_data}
          penDealerId={penDealerId}
          detailedSubmited={detailedSubmited}
        />
      ) : null}
    </>
  );
};

const Results: FC<{
  onEdit: (data: any) => unknown;
  integrationsRow;
  dealerProfileData;
  dealer_profile_data;
  penDealerId: any;
  detailedSubmited;
}> = ({
  onEdit,
  integrationsRow,
  dealerProfileData,
  dealer_profile_data,
  penDealerId,
  detailedSubmited,
}) => {
  const { mutate: updateLenderIntegration } = useUpdateLenderIntegration();
  const theme = useTheme();
  const isCDK = integrationsRow.provider_name === "Cdk";
  const isExistingCustomerCheck =
    integrationsRow.integration_type === "Existing Customer Check";
  return (
    <>
      <TableRow key={`child${integrationsRow.id}`} className="child-tr">
        <TableCell>
          <Box theme={theme} display="flex" alignItems="center" sx={{ pl: 5 }}>
            {integrationsRow.provider_name}
            {integrationsRow.provider_name === "Provider Exchange Network" && (
              <Tooltip
                theme={theme}
                title={
                  dealer_profile_data && penDealerId ? "" : "Register with PEN"
                }
              >
                {penDealerId ? (
                  <></>
                ) : (
                  <Button
                    defaultBtn
                    className="pt-0 pb-0"
                    iconText={<Icon name="InfoIcon" />}
                  />
                )}
              </Tooltip>
            )}
            <Tooltip
              theme={theme}
              title={
                isExistingCustomerCheck
                  ? ""
                  : isCDK
                  ? ""
                  : integrationsRow.provider_name === "Shift Digital"
                  ? dealerProfileData &&
                    dealerProfileData?.shift_digital_dealer_code
                    ? ""
                    : "Enter Shift Digital Dealer Code"
                  : "Connect with Stripe"
              }
            >
              {isExistingCustomerCheck ||
              isCDK ? null : integrationsRow.provider_name ===
                "Shift Digital" ? (
                dealerProfileData &&
                dealerProfileData?.shift_digital_dealer_code ? (
                  <></>
                ) : (
                  <Button
                    defaultBtn
                    className="pt-0 pb-0"
                    iconText={<Icon name="InfoIcon" />}
                  />
                )
              ) : (
                integrationsRow.provider_name === "Shift Digital" &&
                !detailedSubmited && (
                  <Button
                    defaultBtn
                    className="pt-0 pb-0"
                    iconText={<Icon name="InfoIcon" />}
                  />
                )
              )}
            </Tooltip>
          </Box>
        </TableCell>
        <TableCell></TableCell>
        <TableCell>
          <Switch
            id={integrationsRow.id}
            theme={theme}
            varient={"basic"}
            switchEnabled={integrationsRow?.dealer_is_active}
            onChange={(event) => {
              updateLenderIntegration({
                ...integrationsRow,
                dealer_is_active: event.target.checked,
              });
            }}
            label={integrationsRow.dealer_is_active ? "Enabled" : "Disabled"}
          />
        </TableCell>
        <TableCell className="action-cell fixed-cell">
          {integrationsRow?.lender_integration_user_inputs &&
            integrationsRow.integration_type !== "Existing Customer Check" &&
            integrationsRow.provider_name !== "Cdk" && (
              <Menu
                theme={theme}
                disablePortal
                options={[
                  {
                    optionText: (
                      <>
                        <Icon className="menu-icon" name="EditIcon" />
                        {ActionOptions.EDIT}
                      </>
                    ),
                    optionValue: integrationsRow,
                    optionkey: ActionOptions.EDIT,
                  },
                ]}
                handleOptionClick={(_event, _key, value) => {
                  switch (_key) {
                    case ActionOptions.EDIT:
                      onEdit(value);
                      break;
                  }
                }}
                render={(onMenuSelection) => (
                  <Button
                    defaultBtn
                    iconText={<Icon name="MoreIcon" />}
                    onClick={onMenuSelection}
                  ></Button>
                )}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              />
            )}
        </TableCell>
      </TableRow>
    </>
  );
};

const Intergrations: FC<{
  dealer_profile_data?: any;
  dealer_code?: string;
  isProfileDirty?: any;
  form: any;
  onPopupStateChange: (popUpState: any) => unknown;
}> = ({
  dealer_profile_data,
  dealer_code,
  isProfileDirty,
  onPopupStateChange,
  form,
}) => {
  const controlTypes = {
    BUTTON: "button",
  };
  const { data: intergrationData } =
    UseQuery_getAllDealerIntergrations(dealer_code);
  const [dataState, setDataState] = useState(null);
  const [actionType, setActionType] = useState<ActionOptions>(
    ActionOptions.EDIT
  );
  const [penDealerId, setPenDealerId] = useState(
    dealer_profile_data?.pen_dealer_id
  );
  const [actionTitle, setActionTitle] = useState<string>("Edit Provider");
  const { mutate: GetFilter } = UseQuery_getAllDealerIntegration_byParams();
  const [providerTypeState, setProviderTypeState] = useState<boolean>(false);
  const [actionData, setActionData] = useState<any>();
  const [integrationFilter, setIntegrationFilter] = useState("");
  const [state, dispatch] = useSetupsStore();
  const [detailedSubmited, setDetailSubmitted] = useState<boolean>(false);
  const { mutate: getDealerProfileByDealerId, data: dealerProfileData } =
    useGetDealerProfileByDealerId();
  const { mutate: getStripeAccountById } = useGetStripeAccountById();
  useEffect(() => {
    const lenderIntegration = intergrationData?.find(
      (item) => item?.LenderIntegration?.provider_name === "Stripe Connect"
    );
    if (lenderIntegration) {
      getDealerProfileByDealerId(
        { dealer_id: dealer_code },
        {
          onSuccess(response) {
            setPenDealerId(response?.pen_dealer_id);
            const stripeConnectKey =
              lenderIntegration?.LenderIntegration?.lender_integration_user_inputs?.find(
                (item) => item?.user_input_name === "api_secret_key"
              );
            if (stripeConnectKey && response?.stripe_dealer_code) {
              const request = {
                account_id: response?.stripe_dealer_code,
                stripeApiKey: stripeConnectKey?.user_input_value,
              };
              getStripeAccountById(request, {
                onSuccess(response: any) {
                  setDetailSubmitted(response.details_submitted);
                },
              });
            } else {
              setDetailSubmitted(false);
            }
          },
        }
      );
    }
  }, [dealer_code, intergrationData]);

  useEffect(() => {
    setDataState(intergrationData);
  }, [intergrationData]);

  useEffect(() => {
    if (providerTypeState === false) {
      dispatch(setOpenDrawer(false));
    }
  }, [providerTypeState]);
  useEffect(() => {}, []);
  const onEdit = (formData: any) => {
    setActionType(ActionOptions.EDIT);
    setActionTitle("Edit Provider Type");
    setActionData(formData);
    setProviderTypeState(true);
    dispatch(setOpenDrawer(true));
  };

  onPopupStateChange(providerTypeState);

  const theme = useTheme();
  const handleFilter = () => {
    const params = {
      integration: integrationFilter,
    };
    GetFilter(params);
  };
  return (
    <>
      <DataTableWrap theme={theme} className="table-tabs">
        <Box theme={theme} className="scroll">
          <Box theme={theme} className="scroll-hide spr-border" />
        </Box>
        <DataTable
          theme={theme}
          variant="basic"
          theadChildren={
            <>
              <TableRow>
                <TableCell colSpan={2}>Integrations</TableCell>
                <TableCell className="indent-cell">Status</TableCell>
                <TableCell className="action-cell fixed-cell" />
              </TableRow>
              <TableRow className="filters-row">
                <TableCell colSpan={2}>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={"Search..."}
                      type="text"
                      startAdornment={undefined}
                      endAdornment={undefined}
                      id={""}
                      onChange={(value) => {
                        setIntegrationFilter(value);
                      }}
                      onBlur={() => {
                        handleFilter();
                      }}
                      value={integrationFilter?.trimStart()}
                    />
                    <Button
                      defaultBtn
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={"Search..."}
                      type="text"
                      onChange={(value) => {
                        // setStatusFilter(value);
                      }}
                      // value={statusFilter?.trimStart()}
                      onBlur={() => {
                        handleFilter();
                      }}
                    />
                    <Button
                      defaultBtn
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell className="action-cell fixed-cell" />
              </TableRow>
            </>
          }
          tbodyChildren={
            <>
              {dataState?.map((integrationsRow, index) => {
                return (
                  <React.Fragment key={index}>
                    <TbodyRow
                      integrationsRow={integrationsRow?.LenderIntegration}
                      onEdit={(data) => onEdit(data)}
                      dealerProfileData={dealerProfileData}
                      dealer_profile_data={dealer_profile_data}
                      penDealerId={penDealerId}
                      detailedSubmited={detailedSubmited}
                    />
                  </React.Fragment>
                );
              })}
            </>
          }
        />
      </DataTableWrap>
      {providerTypeState && (
        <EditProvider
          form={form}
          openPopUp={providerTypeState}
          setPopUpState={setProviderTypeState}
          title={actionTitle}
          actionType={actionType}
          data={actionData}
          dealer_profile_data={dealer_profile_data}
          dealer_code={dealer_code}
          isProfileDirty={isProfileDirty}
          penDealerId={penDealerId}
          setPenDealerId={setPenDealerId}
          setDetailSubmitted={setDetailSubmitted}
          detailedSubmited={detailedSubmited}
        />
      )}
    </>
  );
};

export default Intergrations;

import { INewSubsidyChartsPopUp } from "./AddNewSubsidyChart.type";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTheme } from "@mui/material";
import {
  PersistentDrawer,
  Autocomplete,
  Icon,
  MultiSelect,
  Box,
  Button,
  Input,
  Select,
  Grid,
} from "@ntpkunity/controls";
import { ConfirmationDialog, MultiSelectWrap } from "shared/components";
import {
  IAssetCondition,
  IAssetMake,
  IAssetModel,
  IAssetTrim,
  IChartCriteria,
  ICharts,
  ICreditRating,
  ICriteria,
  ISelectItemList,
  ITerms,
} from "Interface";
import {
  useMutation_AddNewSubsidyCharts,
  useMutation_AddNewSubsidyCriteria,
  useMutation_UpdateSubsidyCharts,
  UseQuery_GetAllAssetMakes,
  UseQuery_GetAllAssetModels,
  UseQuery_GetAllAssetTrims,
  UseMutation_AddCharts,
  UseMutation_UpdateCharts,
  UseMutation_CheckDuplicateChartName,
  UseQuery_GetAllAssetConditions,
  UseQuery_getAllCreditRatings,
  UseQuery_GetAllTerms,
  UseMutation_CheckCriteriaAttached,
  ConfigurationAPI,
} from "services";
import { useSetupsStore, useStoreContext } from "Stores/SetupStore/Store";
import { logout } from "@ntpkunity/controls-ums";
import {
  ActionOptions,
  CalculationMethod,
  ChartTypes,
  DialogMessages,
  QueryKeys,
  ToastMessages,
  ValidationMessages,
} from "Enums";
import { setOpenDrawer } from "Stores/SetupStore/Actions";
import { useQueryClient } from "react-query";
import { usePrompt } from "utilities/usePrompt";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SetupContext } from "context";
const schema = Yup.object().shape({
  chart: Yup.object().shape({
    chart_name: Yup.string().required("Chart name is required"),
    output_parameter_type: Yup.string().required("Subsidy Type is required"),
    is_active: Yup.boolean().required("Chart status is required"),
  }),
  criteria: Yup.object().shape({
    manufacturer_rate: Yup.number()
      .nullable()
      .test({
        name: "manufacturer_rate",
        exclusive: false,
        params: {},
        message: "Manufacturer Rate is required",
        test: function (value, context: any) {
          const output_parameter_type =
            context?.from[1]?.value?.chart?.output_parameter_type;
          if (
            output_parameter_type ==
              CalculationMethod.PERCENTAGE_FROM_DEALER_AND_MANUFACTURER &&
            !value &&
            // (this.parent.asset_classification ||
            (this.parent.asset_make_name ||
              this.parent.asset_model_name ||
              this.parent.asset_trim_name ||
              this.parent.asset_condition ||
              this.parent.term ||
              this.parent.credit_rating ||
              this.parent.asset_usages ||
              this.parent.dealer_rate)
          )
            return false;
          return true;
        },
      })
      .typeError("Manufacturer Rate is required"),
    dealer_rate: Yup.number()
      .nullable()
      .test({
        name: "dealer_rate",
        exclusive: false,
        params: {},
        message: "Dealer Rate is required",
        test: function (value, context: any) {
          const output_parameter_type =
            context?.from[1]?.value?.chart?.output_parameter_type;
          if (
            output_parameter_type ==
              CalculationMethod.PERCENTAGE_FROM_DEALER_AND_MANUFACTURER &&
            !value &&
            // (this.parent.asset_classification ||
            (this.parent.asset_make_name ||
              this.parent.asset_model_name ||
              this.parent.asset_trim_name ||
              this.parent.asset_condition ||
              this.parent.term ||
              this.parent.credit_rating ||
              this.parent.asset_usages)
          )
            return false;
          return true;
        },
      })
      .typeError("Dealer Rate is required"),
    financier_rate: Yup.number()
      .nullable()
      .test({
        name: "financier_rate",
        exclusive: false,
        params: {},
        message: "Financier Rate is required",
        test: function (value, context: any) {
          const output_parameter_type =
            context?.from[1]?.value?.chart?.output_parameter_type;
          if (
            output_parameter_type == CalculationMethod.TARGET_BASE_SUBSIDY &&
            !value &&
            // (this.parent.asset_classification ||
            (this.parent.asset_make_name ||
              this.parent.asset_model_name ||
              this.parent.asset_trim_name ||
              this.parent.asset_condition ||
              this.parent.term ||
              this.parent.credit_rating ||
              this.parent.asset_usages)
          )
            return false;
          return true;
        },
      })
      .typeError("Financier Rate is required"),
    rate: Yup.number()
      .nullable()
      .test({
        name: "rate",
        exclusive: false,
        params: {},
        message: "Rate is required",
        test: function (value, context: any) {
          const output_parameter_type =
            context?.from[1]?.value?.chart?.output_parameter_type;
          if (
            output_parameter_type == CalculationMethod.ADJUSTER_CUSTOMER_RATE &&
            !value &&
            // (this.parent.asset_classification ||
            (this.parent.asset_make_name ||
              this.parent.asset_model_name ||
              this.parent.asset_trim_name ||
              this.parent.asset_condition ||
              this.parent.term ||
              this.parent.credit_rating ||
              this.parent.asset_usages)
          )
            return false;
          return true;
        },
      })
      .typeError("Rate is required"),
  }),
});

const AddNewSubsidyCharts: FC<INewSubsidyChartsPopUp> = ({
  openPopUp,
  setPopUpState,
  title,
  actionType,
  subsidyChartData,
}) => {
  const { mutate: CheckNameDuplicate } = UseMutation_CheckDuplicateChartName();
  const { mutate: CheckCriteriaExist } = UseMutation_CheckCriteriaAttached();
  const [state, dispatch] = useSetupsStore();

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    getValues,
    watch,
    setError,
    formState: { errors, isValid, isDirty, isValidating },
  } = useForm<IChartCriteria>({
    mode: "all",
    resolver: yupResolver(schema),
  });
  const { actions } = useStoreContext();
  const theme = useTheme();
  const {
    assetConditions,
    terms,
    creditRatings,
    lenderProfile,
    AllFinancedAmounts,
    assetUsages,
  } = useContext(SetupContext);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [navigationState, setNavigationState] = useState<any>();
  const [leavePagePopupState, setLeavePageState] = useState<boolean>(false);
  const [assetUsage, setAssetUsage] = useState([]);
  const onSubmit = (data: IChartCriteria) => {
    data.criteria.lender_id = lenderProfile?.id;
    data.chart.lender_id = lenderProfile?.id;
    data.criteria.asset_make_name = assetMakeId;
    data.criteria.asset_model_name = assetModelId;
    data.criteria.asset_trim_name = assetTrimId;
    data.criteria.terms = assetTerm;
    data.criteria.credit_rating = assetCreditScore;
    data.criteria.asset_condition = assetCondition;
    data.criteria.asset_usages = assetUsage;
    data.criteria.financed_amount = financedAmounts;
    data.chart.chart_type_id = 5;
    if (actionType == ActionOptions.ADD_NEW_CHART) {
      // if (
      //   !(data?.criteria.financier_rate >= 0) ||
      //   data?.criteria.financier_rate == null
      // ) {
      if (
        chartId == null &&
        data?.criteria?.asset_make_name == null &&
        data?.criteria?.asset_model_name == null &&
        data?.criteria?.asset_trim_name == null &&
        // data?.criteria?.asset_classification == "" &&
        data?.criteria?.asset_usages.length == 0 &&
        data?.criteria?.credit_rating.length == 0 &&
        data?.criteria?.financed_amount.length == 0 &&
        data?.criteria?.terms.length == 0 &&
        data?.criteria?.asset_condition.length == 0 &&
        data?.criteria?.financier_rate == undefined &&
        data?.criteria?.dealer_rate == undefined &&
        data?.criteria?.manufacturer_rate == undefined &&
        data?.criteria?.rate == undefined
      ) {
        AddChart(data.chart, {
          onSuccess: (dataa, variables, context) => {
            setChartId(dataa.data.id);
            setValue("chart.chart_name", dataa.data.chart_name);
            setIsChartAdded(true);
            setTypeEnable(true);
            setChartEnable(true);
            onClose();
          },
          onError: (error) => {
            reset(undefined);
            setValue("chart.chart_name", data.chart.chart_name);
            if (error?.response?.data?.detail?.code === 400) {
              setError("chart.chart_name", {
                message: "Chart Name should be unique",
              });
              return;
            } else {
              actions.setToast({
                toastMessage:
                  error.response.data.detail.message ??
                  ToastMessages.Internal_Server_Error,
                toastState: true,
                variant: "error",
              });
            }
          },
        });
      } else if (
        (chartId == null && data?.criteria?.asset_make_name != null) ||
        data?.criteria?.asset_model_name != null ||
        data?.criteria?.asset_trim_name != null ||
        // data?.criteria?.asset_classification != "" ||
        data?.criteria?.asset_usages.length != 0 ||
        data?.criteria?.credit_rating.length != 0 ||
        data?.criteria?.terms.length != 0 ||
        data?.criteria?.asset_condition.length != 0 ||
        data?.criteria?.financed_amount.length != 0 ||
        data?.criteria?.financier_rate > 0 ||
        data?.criteria?.dealer_rate > 0 ||
        data?.criteria?.manufacturer_rate > 0 ||
        data?.criteria?.rate > 0
      ) {
        if (data?.criteria?.financier_rate != null) {
          addNewChartWithCriteria(data);
          setChartEnable(true);
          setTypeEnable(true);
        } else if (
          data.criteria?.dealer_rate != null ||
          data.criteria?.manufacturer_rate != null
        ) {
          addNewChartWithCriteria(data);
          setChartEnable(true);
          setTypeEnable(true);
        } else if (data.criteria?.rate != null) {
          addNewChartWithCriteria(data);
          setChartEnable(true);
          setTypeEnable(true);
        }
      }
      // } else if (data?.criteria?.financier_rate >= 0) {
      //   if (chartId != null) {
      //     data.criteria.chart_id = chartId;
      //     addCriteria(data.criteria);
      //     setIsChartAdded(true);
      //     setTypeEnable(true);
      //     setChartEnable(true);
      //   } else {
      //     addNewChartWithCriteria(data);
      //     setIsChartAdded(true);
      //     setChartEnable(true);
      //     setTypeEnable(true);
      //   }
      // }
      // else if (data?.criteria?.dealer_rate >= 0 || data.criteria?.manufacturer_rate >=0 ) {
      //   if (chartId != null) {
      //     data.criteria.chart_id = chartId;
      //     addCriteria(data.criteria);
      //     setIsChartAdded(true);
      //     setTypeEnable(true);
      //     setChartEnable(true);
      //   } else {
      //     addNewChartWithCriteria(data);
      //     setIsChartAdded(true);
      //     setChartEnable(true);
      //     setTypeEnable(true);
      //   }
      // }
    } else if (actionType == ActionOptions.RENAME_CHART) {
      data.chart.id = subsidyChartData.chart.id;
      updateChart(data.chart);
    } else if (actionType == ActionOptions.ADD_CRITERIA) {
      data.criteria.chart_id = subsidyChartData.chart.id;
      addCriteria(data.criteria);
      setChartEnable(true);
      setTypeEnable(true);
    }
    //edit criteria
    else if (actionType == ActionOptions.EDIT) {
      data.criteria.chart_id = subsidyChartData?.chart.id;
      data.criteria.id = subsidyChartData?.criteria.id;
      updateCriteria(data.criteria);
    }
  };
  const onClose = () => {
    let children = document.querySelectorAll(".drawer-header .pinned");
    if (children.length == 0) {
      setPopUpState(false);
      dispatch(setOpenDrawer(false));
    }
  };

  const { mutate: AddSubsidyChart } = useMutation_AddNewSubsidyCharts();
  const { mutate: AddChart } = UseMutation_AddCharts();
  const { mutate: UpdateChart } = UseMutation_UpdateCharts();
  const { mutate: AddNewSubsidyCriteria } = useMutation_AddNewSubsidyCriteria();
  const { mutate: UpdateSubsidyCriteria } =
    useMutation_UpdateSubsidyCharts(undefined);

  UseQuery_GetAllAssetMakes();
  UseQuery_GetAllAssetModels();
  UseQuery_GetAllAssetTrims();

  const assetMakes: Array<IAssetMake> = queryClient.getQueryData(
    QueryKeys.GET_ALL_ASSET_MAKES
  );
  const assetModels: Array<IAssetModel> = queryClient.getQueryData(
    QueryKeys.GET_ALL_ASSET_MODELS
  );
  const assetTrims: Array<IAssetTrim> = queryClient.getQueryData(
    QueryKeys.GET_ALL_ASSET_TRIMS
  );
  const [assetMakeId, setAssetMakeId] = useState(null);
  const [assetModelId, setAssetModelId] = useState(null);
  const [assetTrimId, setAssetTrimId] = useState(null);
  const [assetMake, setAssetMake] = useState(null);
  const [assetModel, setAssetModel] = useState(null);
  var [assetTerm, setAssetTerm] = useState([]);
  const [assetCondition, setAssetCondition] = useState([]);
  const [assetCreditScore, setAssetCreditScore] = useState([]);
  const [chartId, setChartId] = useState(null);
  const [chartEnable, setChartEnable] = useState<boolean>(false);
  const [criteriaEnable, setCriteriaEnable] = useState<boolean>(false);
  const [isChartAdded, setIsChartAdded] = useState<boolean>(false);
  const [typeEnable, setTypeEnable] = useState<boolean>(false);
  const [financedAmounts, setFinancedAmounts] = useState([]);
  const outputParameterType = watch("chart.output_parameter_type");
  useEffect(() => {
    if (actionType === ActionOptions.RENAME_CHART && subsidyChartData) {
      CheckCriteriaExist(
        {
          data: `name=${subsidyChartData.chart?.chart_name}&chart_type=${
            ChartTypes.SubsidyChart
          }&lender_id=${lenderProfile ? lenderProfile?.id : ""}`,
        },
        {
          onSuccess: (response) => {
            setTypeEnable(response.data);
          },
        }
      );
      setCriteriaEnable(true);
      setChartEnable(false);
      setValue("chart.chart_name", subsidyChartData.chart?.chart_name);
      setValue(
        "chart.output_parameter_type",
        subsidyChartData.chart?.output_parameter_type
      );
      setValue("chart.is_active", subsidyChartData.chart?.is_active);
    } else if (actionType === ActionOptions.ADD_CRITERIA && subsidyChartData) {
      setChartEnable(true);
      setCriteriaEnable(false);
      setTypeEnable(true);
      setValue("chart.chart_name", subsidyChartData.chart?.chart_name);
      setValue(
        "chart.output_parameter_type",
        subsidyChartData.chart?.output_parameter_type
      );
      setValue("chart.is_active", subsidyChartData.chart?.is_active);
      setValue("criteria.asset_condition", []);
      setValue("criteria.terms", []);
      setValue("criteria.credit_rating", []);
    } else if (actionType === ActionOptions.ADD_NEW_CHART && !isChartAdded) {
      setChartEnable(false);
      setCriteriaEnable(false);
      setTypeEnable(false);
      reset(undefined);
    }
    //edit criteria
    else if (actionType === ActionOptions.EDIT && subsidyChartData) {
      setChartEnable(true);
      setCriteriaEnable(false);
      setTypeEnable(true);
      setValue("chart.chart_name", subsidyChartData?.chart?.chart_name);
      setValue(
        "chart.output_parameter_type",
        subsidyChartData.chart?.output_parameter_type
      );
      setValue("chart.is_active", subsidyChartData?.chart?.is_active);
      setValue("criteria.id", subsidyChartData?.criteria?.id);
      setAssetMakeId(subsidyChartData?.criteria?.asset_make_name);
      setAssetModelId(subsidyChartData?.criteria?.asset_model_name);
      setAssetTrimId(subsidyChartData?.criteria?.asset_trim_name);
      if (subsidyChartData?.criteria?.terms) {
        setAssetTerm(subsidyChartData?.criteria?.terms);
      }
      if (subsidyChartData?.criteria?.asset_condition) {
        setAssetCondition(subsidyChartData?.criteria?.asset_condition);
      }
      if (subsidyChartData?.criteria?.credit_rating) {
        setAssetCreditScore(subsidyChartData?.criteria?.credit_rating);
      }
      if (subsidyChartData?.criteria?.financed_amount) {
        setFinancedAmounts(subsidyChartData?.criteria?.financed_amount);
      }
      // setValue(
      //   "criteria.asset_classification",
      //   subsidyChartData?.criteria?.asset_classification
      // );
      if (subsidyChartData?.criteria?.asset_usages) {
        setAssetUsage(subsidyChartData?.criteria?.asset_usages);
      }
      setValue(
        "criteria.asset_condition",
        subsidyChartData?.criteria?.asset_condition
      );
      setValue(
        "criteria.manufacturer_rate",
        subsidyChartData?.criteria?.manufacturer_rate
      );
      setValue("criteria.dealer_rate", subsidyChartData?.criteria?.dealer_rate);
      setValue(
        "criteria.financier_rate",
        subsidyChartData?.criteria?.financier_rate
      );
      setValue("criteria.rate", subsidyChartData?.criteria?.rate);
    }
  }, [subsidyChartData, criteriaEnable]);
  const [assetmakesSelection, setAssetmakesSelection] = useState<
    Array<ISelectItemList>
  >(
    assetMakes
      ?.filter((x) => x.is_active)
      .map((make) => ({
        value: make.id,
        text: make.name,
      }))
  );

  useEffect(() => {
    setAssetmakesSelection(
      assetMakes
        ?.filter((x) => x.is_active)
        .map((makes) => ({
          value: makes.name,
          text: makes.name,
        }))
    );
  }, [assetMakes, assetMakeId]);

  const [assetmodelsSelection, setAssetmodelsSelection] = useState<
    Array<ISelectItemList>
  >([]);

  const [assetTrimSelection, setAssettrimSelection] = useState<
    Array<ISelectItemList>
  >([]);

  const [creditRatingsSelection, setCreditRatingState] =
    useState<Array<ISelectItemList>>();
  const [financedAmountsSelection, setFinancedAmountsSelectionState] =
    useState<Array<ISelectItemList>>();
  useEffect(() => {
    const ratingsArray = creditRatings
      ?.filter((x) => x.is_active)
      ?.map((creditRating) => ({
        value: creditRating.description,
        text: creditRating.description,
      }));
    assetCreditScore?.forEach((desc) => {
      const selectedRating = creditRatings?.find(
        (x) => x.description == desc && !x.is_active
      );
      selectedRating &&
        ratingsArray.push({
          value: selectedRating?.description,
          text: selectedRating?.description,
        });
    });
    setCreditRatingState(ratingsArray);
  }, [creditRatings, assetCreditScore]);

  const [termsSelection, setTermsSelectionState] =
    useState<Array<ISelectItemList>>();
  const [assetConditionSelection, setAssetConditionSelectionState] =
    useState<Array<ISelectItemList>>();
  const [assetUsageSelection, setAssetUsageSelectionState] =
    useState<Array<ISelectItemList>>();

  useEffect(() => {
    const termsArray = terms
      ?.filter((x) => x.is_active)
      ?.map((term) => ({
        value: `${term.terms_from} - ${term.terms_to}`,
        text: `${term.terms_from} - ${term.terms_to}`,
      }));
    assetTerm?.forEach((id) => {
      const selectedTerms = terms?.find((x) => x.id == id && !x.is_active);
      selectedTerms &&
        termsArray.push({
          value: `${selectedTerms.terms_from} - ${selectedTerms.terms_to}`,
          text: `${selectedTerms.terms_from} - ${selectedTerms.terms_to}`,
        });
    });
    setTermsSelectionState(termsArray);
  }, [terms, assetTerm]);

  useEffect(() => {
    const assetConditionArray = assetConditions
      ?.filter((x) => x.is_active)
      ?.map((asset) => ({
        value: `${asset.description}`,
        text: `${asset.description}`,
      }));
    assetCondition.forEach((desc) => {
      const selectedAssetConditions = assetConditions?.find(
        (x) => x.description == desc && !x.is_active
      );
      selectedAssetConditions &&
        assetConditionArray.push({
          value: selectedAssetConditions?.description,
          text: selectedAssetConditions?.description,
        });
    });
    setAssetConditionSelectionState(assetConditionArray);
  }, [assetConditions, assetCondition]);

  useEffect(() => {
    const assetUsageArray = assetUsages
      ?.filter((x) => x.is_active)
      ?.map((asset) => ({
        value: `${asset.description}`,
        text: `${asset.description}`,
      }));
    assetUsage.forEach((desc) => {
      const selectedAssetUsages = assetUsages?.find(
        (x) => x.description == desc && !x.is_active
      );
      selectedAssetUsages &&
        assetUsageArray.push({
          value: selectedAssetUsages?.description,
          text: selectedAssetUsages?.description,
        });
    });
    setAssetUsageSelectionState(assetUsageArray);
  }, [assetUsages, assetUsage]);

  useEffect(() => {
    const financedAmountArray = AllFinancedAmounts?.filter(
      (x) => x.is_active
    )?.map((financed) => ({
      value: `${financed.financed_amount_from} - ${financed.financed_amount_to}`,
      text: `${financed.financed_amount_from.toLocaleString()} - ${financed.financed_amount_to.toLocaleString()}`,
    }));
    financedAmounts?.forEach((id) => {
      const selectedFinancedAmounts = AllFinancedAmounts?.find(
        (x) => x.id == id && !x.is_active
      );
      selectedFinancedAmounts &&
        financedAmountArray.push({
          value: `${selectedFinancedAmounts.financed_amount_from} - ${selectedFinancedAmounts.financed_amount_to}`,
          text: `${selectedFinancedAmounts.financed_amount_from.toLocaleString()} - ${selectedFinancedAmounts.financed_amount_to.toLocaleString()}`,
        });
    });
    setFinancedAmountsSelectionState(financedAmountArray);
  }, [AllFinancedAmounts, financedAmounts]);
  useEffect(() => {
    setAssetMake(assetMakes?.filter((x) => x.name == assetMakeId)[0]?.id);
    setAssetmodelsSelection(
      assetModels
        ?.filter(
          (models) =>
            assetMake > 0 && models.make_id === assetMake && models.is_active
        )
        ?.map((model) => ({
          value: model.model_name,
          text: model.model_name,
        }))
    );
  }, [assetModels, assetMakes, assetMakeId, assetModelId, assetMake]);

  useEffect(() => {
    setAssetModel(
      assetModels?.filter((x) => x.model_name == assetModelId)[0]?.id
    );
    setAssettrimSelection(
      assetTrims
        ?.filter(
          (trims) =>
            assetModel > 0 && trims.model_id === assetModel && trims.is_active
        )
        ?.map((trim) => ({
          value: trim.trim_name,
          text: trim.trim_name,
        }))
    );
  }, [
    assetTrims,
    assetModels,
    assetMakes,
    assetMakeId,
    assetModelId,
    assetTrimId,
    assetModel,
  ]);

  const onMakeSelection = (value) => {
    setAssetMakeId(value?.id);
    setAssetModelId(null);
    setAssetTrimId(null);
  };

  const onModelSelection = (value) => {
    setAssetModelId(value?.id);
    setAssetTrimId(null);
  };

  const onTrimSelection = (value) => {
    setAssetTrimId(value?.id);
  };

  const onTermsSelection = (e) => {
    setAssetTerm(
      typeof e.target.value === "string"
        ? e.target.value.split(",")
        : e.target.value
    );
  };

  const onCreditScoreSelection = (e) => {
    setAssetCreditScore(
      typeof e.target.value === "string"
        ? e.target.value.split(",")
        : e.target.value
    );
  };

  const onConditionSelection = (e) => {
    setAssetCondition(
      typeof e.target.value === "string"
        ? e.target.value.split(",")
        : e.target.value
    );
  };

  const onUsageSelection = (e) => {
    setAssetUsage(
      typeof e.target.value === "string"
        ? e.target.value.split(",")
        : e.target.value
    );
  };

  const onFinancedAmountSelection = (e) => {
    setFinancedAmounts(
      typeof e.target.value === "string"
        ? e.target.value.split(",")
        : e.target.value
    );
  };

  const addNewChartWithCriteria = (data: IChartCriteria) => {
    data.chart.lender_id = lenderProfile?.id;
    AddSubsidyChart(data, {
      onSuccess: (data, variables, context) => {
        setChartId(data.data.id);
      },
    });
    onClose();
  };

  const addCriteria = (data: ICriteria) => {
    AddNewSubsidyCriteria(data);
    onClose();
  };

  const updateCriteria = (data: ICriteria) => {
    UpdateSubsidyCriteria(data);
    onClose();
  };

  const updateChart = (data: ICharts) => {
    UpdateChart(data);
    reset();
    onClose();
  };
  const setFinancierValue = (val) => {
    if (val <= 100 && val >= 0) {
      setValue("criteria.financier_rate", parseFloat(val));
    }
  };
  const setRateValue = (val) => {
    if (val <= 100 && val >= 0) {
      setValue("criteria.rate", parseFloat(val));
    }
  };
  const setDealerValue = (val) => {
    if (val <= 100 && val >= 0) {
      setValue("criteria.dealer_rate", parseFloat(val));
    }
  };
  const setManufacturerValue = (val) => {
    if (val <= 100 && val >= 0) {
      setValue("criteria.manufacturer_rate", parseFloat(val));
    }
  };
  const onSaveConfirm = (e: any) => {
    handleSubmit(onSubmit)();
    setLeavePageState(false);
  };

  const onCancelConfirm = (e: any) => {
    navigate(".." + navigationState.location.pathname);
    if (navigationState.location.pathname == "/market-place/signup") {
      logout();
      localStorage.clear();
    }
    localStorage.setItem("DirtyState", "false");
  };
  usePrompt(
    "Leave screen?",
    isDirty,
    (dirty) => {
      setLeavePageState(dirty);
    },
    (tx) => {
      reset(getValues());
      setNavigationState(tx);
    }
  );
  return (
    <>
      <ConfirmationDialog
        openPopUp={leavePagePopupState}
        onConfirm={onSaveConfirm}
        onCancel={onCancelConfirm}
        setPopUpState={setLeavePageState}
        confirmationTitle={DialogMessages.saveTitle}
        confirmationText={DialogMessages.saveText}
        primaryBtnText={DialogMessages.saveBtnText}
        icon={<Icon name="DialogSaveIcon" />}
      />
      <PersistentDrawer
        title={title}
        openPopUp={openPopUp}
        setPopUpState={setPopUpState}
        theme={theme}
        onClose={onClose}
        enabledPin={true}
        children={
          <>
            <Controller
              name="chart.chart_name"
              control={control}
              // rules={{
              //   required: {
              //     value: true,
              //     message: ValidationMessages.CHART_NAME_REQUIRED,
              //   },
              //   validate: {
              //     checkUniqueName: () => {
              //       if (chartNameValidation) {
              //         return ValidationMessages.NAME_UNIQUE;
              //       }
              //       return true;
              //     },
              //   },
              // }}
              render={({ field: { onChange, value } }) => (
                <Input
                  id="drawerChartName"
                  fullWidth={true}
                  theme={theme}
                  label={"Chart Name"}
                  placeholder={"Type Here"}
                  value={value?.trimStart()}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  // onBlur={(e) => checkSubsidyChartName(e.target.value)}
                  disabled={chartEnable}
                  error={errors?.chart?.chart_name?.message}
                  type="text"
                />
              )}
            />
            <Controller
              name="chart.output_parameter_type"
              control={control}
              defaultValue={CalculationMethod.TARGET_BASE_SUBSIDY}
              render={({ field: { value, onChange } }) => (
                <Select
                  id="drawerSubsidyType"
                  theme={theme}
                  label={"Subsidy Type"}
                  items={[
                    {
                      text: CalculationMethod.TARGET_BASE_SUBSIDY,
                      value: CalculationMethod.TARGET_BASE_SUBSIDY,
                    },
                    {
                      text: CalculationMethod.PERCENTAGE_FROM_DEALER_AND_MANUFACTURER,
                      value:
                        CalculationMethod.PERCENTAGE_FROM_DEALER_AND_MANUFACTURER,
                    },
                    {
                      text: CalculationMethod.ADJUSTER_CUSTOMER_RATE,
                      value: CalculationMethod.ADJUSTER_CUSTOMER_RATE,
                    },
                  ]}
                  value={value}
                  onChange={onChange}
                  disabled={typeEnable}
                  selectError={errors?.chart?.output_parameter_type?.message}
                ></Select>
              )}
            />

            <Controller
              name="chart.is_active"
              control={control}
              defaultValue={true}
              render={({ field: { value, onChange } }) => (
                <Select
                  id="drawerStatus"
                  theme={theme}
                  label={"Status"}
                  items={[
                    { text: "Enabled", value: true },
                    { text: "Disabled", value: false },
                  ]}
                  value={value}
                  onChange={onChange}
                  disabled={chartEnable}
                ></Select>
              )}
            />
            <Box theme={theme} className="divider-with-text">
              <Box theme={theme} className="divider-text">
                BUSINESS CRITERIA
              </Box>
            </Box>
            {/* <Controller
              name="criteria.asset_classification"
              control={control}
              defaultValue={""}
              render={({ field: { value, onChange } }) => (
                <Select
                  id="drawerAssetClassification"
                  theme={theme}
                  label={"Asset Classification"}
                  items={[{ text: "Vehicles", value: "Vehicles" }]}
                  value={value}
                  onChange={onChange}
                  disabled={criteriaEnable}
                ></Select>
              )}
            /> */}
            <Controller
              name="criteria.asset_make_name"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  disablePortal
                  {...field}
                  id="drawerMake"
                  theme={theme}
                  disabled={criteriaEnable}
                  capitalizeLabel={true}
                  items={
                    assetmakesSelection
                      ? assetmakesSelection?.map((option: any) => {
                          return { label: option.text, id: option.value };
                        })
                      : []
                  }
                  onChange={(_e: any, value: any) => {
                    onMakeSelection(value);
                    field.onChange(value);
                  }}
                  label={assetMakeId ? "Asset Make" : "Asset Make"}
                  value={
                    assetMakeId
                      ? {
                          label: assetMakeId,
                          id: assetMakeId,
                        }
                      : { label: "", id: "" }
                  }
                  renderOption={(props, option, { selected }) => (
                    <li {...props} key={option.id}>
                      <span>{option.label}</span>
                    </li>
                  )}
                />
              )}
            />
            <Controller
              name="criteria.asset_model_name"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  disablePortal
                  id="drawerModel"
                  {...field}
                  theme={theme}
                  disabled={criteriaEnable}
                  capitalizeLabel={true}
                  items={
                    assetmodelsSelection
                      ? assetmodelsSelection?.map((option: any) => {
                          return { label: option.text, id: option.text };
                        })
                      : []
                  }
                  onChange={(_e: any, value: any) => {
                    onModelSelection(value);
                    field.onChange(value);
                  }}
                  label={assetModelId ? "Asset Model" : "Asset Model"}
                  value={
                    assetModelId
                      ? {
                          label: assetModelId,
                          id: assetModelId,
                        }
                      : { label: "", id: "" }
                  }
                  renderOption={(props, option, { selected }) => (
                    <li {...props} key={option.id}>
                      <span>{option.label}</span>
                    </li>
                  )}
                />
              )}
            />
            <Controller
              name="criteria.asset_trim_name"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  disablePortal
                  id="drawerTrim"
                  {...field}
                  theme={theme}
                  disabled={criteriaEnable}
                  capitalizeLabel={true}
                  items={
                    assetTrimSelection
                      ? assetTrimSelection?.map((option: any) => {
                          return { label: option.text, id: option.text };
                        })
                      : []
                  }
                  onChange={(_e: any, value: any) => {
                    onTrimSelection(value);
                    field.onChange(value);
                  }}
                  label={assetTrimId ? "Asset Trim" : "Asset Trim"}
                  value={
                    assetTrimId
                      ? {
                          label: assetTrimId,
                          id: assetTrimId,
                        }
                      : { label: "", id: "" }
                  }
                  renderOption={(props, option, { selected }) => (
                    <li {...props} key={option.id}>
                      <span>{option.label}</span>
                    </li>
                  )}
                />
              )}
            />
            <MultiSelectWrap>
              <Controller
                name="criteria.asset_condition"
                control={control}
                render={({ field: { value } }) => (
                  <MultiSelect
                    theme={theme}
                    id="DrawerAssetCondition"
                    sxProps={{ m: 0, width: "100%" }}
                    label={"Asset Condition"}
                    value={assetCondition || []}
                    items={assetConditionSelection}
                    onChange={onConditionSelection}
                    disabled={criteriaEnable}
                  />
                )}
              />
            </MultiSelectWrap>
            <Grid
              theme={theme}
              container
              rowSpacing={3}
              columnSpacing={2}
              sx={{ mb: 3 }}
            >
              <Grid theme={theme} item xs={6}>
                <MultiSelectWrap>
                  <Controller
                    name="criteria.terms"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <MultiSelect
                        theme={theme}
                        id="drawerTerms"
                        label={"Terms"}
                        value={assetTerm || []}
                        items={termsSelection}
                        onChange={onTermsSelection}
                        disabled={criteriaEnable}
                        sxProps={{ m: 0, width: "100%" }}
                      />
                    )}
                  />
                </MultiSelectWrap>
              </Grid>
              <Grid theme={theme} item xs={6}>
                <MultiSelectWrap>
                  <Controller
                    name="criteria.credit_rating"
                    control={control}
                    render={({ field }) => (
                      <MultiSelect
                        id="drawerCreditRating"
                        theme={theme}
                        label={"Credit Score"}
                        items={creditRatingsSelection}
                        value={assetCreditScore || []}
                        onChange={onCreditScoreSelection}
                        disabled={criteriaEnable}
                        sxProps={{ m: 0, width: "100%" }}
                      />
                    )}
                  />
                </MultiSelectWrap>
              </Grid>
            </Grid>
            <MultiSelectWrap>
              <Controller
                name="criteria.financed_amount"
                control={control}
                render={({ field: { value } }) => (
                  <MultiSelect
                    theme={theme}
                    id="DrawerFinancedAmountRange"
                    sxProps={{ m: 0, width: "100%" }}
                    label={"Financed Amount Range"}
                    value={financedAmounts || []}
                    items={financedAmountsSelection}
                    onChange={onFinancedAmountSelection}
                    disabled={criteriaEnable}
                  />
                )}
              />
            </MultiSelectWrap>
            <MultiSelectWrap>
              <Controller
                name="criteria.asset_usages"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <MultiSelect
                    theme={theme}
                    id="DrawerAssetUsage"
                    sxProps={{ m: 0, width: "100%" }}
                    label={"Asset Usage"}
                    value={assetUsage || []}
                    items={assetUsageSelection}
                    onChange={onUsageSelection}
                    disabled={criteriaEnable}
                  />
                )}
              />
            </MultiSelectWrap>
            <Box theme={theme} className="divider-with-text">
              <Box theme={theme} className="divider-text">
                OUTPUT PARAMETERS
              </Box>
            </Box>
            <>
              {outputParameterType ===
                CalculationMethod.TARGET_BASE_SUBSIDY && (
                <Controller
                  name="criteria.financier_rate"
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <Input
                      fullWidth
                      theme={theme}
                      id="drawerFinancierRate"
                      label={"Financier Rate"}
                      type="text"
                      value={value}
                      onChange={(val) => {
                        if (parseFloat(val) <= 100) {
                          onChange(val);
                          setFinancierValue(val);
                        } else {
                          onChange(val.substr(0, 3));
                          setFinancierValue(val.substr(0, 3));
                        }
                      }}
                      disabled={criteriaEnable}
                      adornmentOnFocus
                      endAdornment={
                        <>
                          <span className="MuiInputAdornment-root">%</span>
                        </>
                      }
                      masking
                      maskDecimalScale={4}
                      maskNumeric
                      error={error?.message as never} // Use 'error' from fieldState to display errors
                    />
                  )}
                />
              )}
              {outputParameterType ===
                CalculationMethod.PERCENTAGE_FROM_DEALER_AND_MANUFACTURER && (
                <>
                  <Controller
                    name="criteria.dealer_rate"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <Input
                        fullWidth
                        theme={theme}
                        id="drawerDealerRate"
                        type="text"
                        value={value}
                        onChange={(val) => {
                          if (parseFloat(val) <= 100) setDealerValue(val);
                          else setDealerValue(val.substr(0, 3));
                        }}
                        label={"Dealer Rate"}
                        adornmentOnFocus
                        endAdornment={
                          <>
                            <span className="MuiInputAdornment-root">%</span>
                          </>
                        }
                        masking
                        maskDecimalScale={4}
                        maskNumeric
                        // scale={4}
                        // numeric
                        disabled={criteriaEnable}
                        error={errors?.criteria?.dealer_rate?.message as never}
                      />
                    )}
                  />
                  <Controller
                    name="criteria.manufacturer_rate"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <Input
                        fullWidth
                        theme={theme}
                        id="drawerManufacturerRate"
                        type="text"
                        value={value}
                        onChange={(val) => {
                          if (parseFloat(val) <= 100) setManufacturerValue(val);
                          else setManufacturerValue(val.substr(0, 3));
                        }}
                        label={"Manufacturer Rate"}
                        adornmentOnFocus
                        endAdornment={
                          <>
                            <span className="MuiInputAdornment-root">%</span>
                          </>
                        }
                        disabled={criteriaEnable}
                        masking
                        maskDecimalScale={4}
                        maskNumeric
                        // scale={4}
                        // numeric
                        error={
                          errors?.criteria?.manufacturer_rate?.message as never
                        }
                      />
                    )}
                  />
                </>
              )}

              {outputParameterType !== CalculationMethod.TARGET_BASE_SUBSIDY &&
                outputParameterType !==
                  CalculationMethod.PERCENTAGE_FROM_DEALER_AND_MANUFACTURER && (
                  <Controller
                    name="criteria.rate"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <Input
                        fullWidth
                        theme={theme}
                        id="drawerRate"
                        label={"Rate"}
                        type="text"
                        value={value}
                        onChange={(val) => {
                          if (parseFloat(val) <= 100) setRateValue(val);
                          else setRateValue(val.substr(0, 3));
                        }}
                        disabled={criteriaEnable}
                        adornmentOnFocus
                        endAdornment={
                          <>
                            <span className="MuiInputAdornment-root">%</span>
                          </>
                        }
                        masking
                        maskDecimalScale={4}
                        maskNumeric
                        error={errors?.criteria?.rate?.message as never}
                        // scale={4}
                        // numeric
                      />
                    )}
                  />
                )}
            </>
          </>
        }
        customFooter={
          <Button
            theme={theme}
            id="saveCriteria"
            primary
            text={"Save"}
            fullWidth={true}
            onClick={handleSubmit(onSubmit)}
          />
        }
      />
    </>
  );
};
export default AddNewSubsidyCharts;

import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const CurrencyInputWrap: any = styled(
  Box,
  {}
)<Partial<IBoxProps>>(() => ({
  marginBottom: "24px",
  "&:last-child": {
    marginBottom: 0,
  },
  ".u-form-group": {
    ".u-form-control": {
      "&.MuiInputBase-adornedStart ~ .u-input-label": {
        transform: "translate(15px, 12px) scale(1) !important",
        "&.Mui-focused, &.MuiFormLabel-filled": {
          transform: "translate(14px, -9px) scale(0.85) !important",
        },
      },
    },
  },
}));

import { StoreActionType } from "Types";
import { ILenderProfile } from "Interface";
import { ActionTypes } from "Enums";

export const LenderProfileReducer = (
  state: ILenderProfile,
  action: StoreActionType
): ILenderProfile => {
  switch (action.type) {
    case ActionTypes.SET_LENDER_PROFILE_INIT_STATE:
      return action.payload;
    default:
      return state;
  }
};

export enum Stipulation {
  DEALER = "Dealer",
  CUSTOMER = "Customer",
  BOTH = "Both",
  FINANCED_AMOUNT = "Financed Amount",
  PERIODIC_PAYMENTS = "Periodic Payment",
  TERMS = "Terms",
  UPLOAD_DOCUMENT = "Upload Document",
  FINANCIAL = "Financial",
  NON_FINANCIAL = "Non Financial",
}

import { ConfigurationAPI } from "services";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QueryKeys, ToastMessages } from "Enums";
import { useStoreContext } from "Stores/SetupStore/Store";
import { ITerms } from "Interface";

export const getAllTermsFilterFromServer = (columnFilters: string) => {
  return ConfigurationAPI.get(`/term/filter?`.concat(columnFilters)).then(
    (response) => {
      return response.data;
    }
  );
};

export const getAllTermsFromServer = () => {
  return ConfigurationAPI.get("/terms").then((res) => {
    return res.data;
  });
};
export const getActiveTermsFromServer = () => {
  return ConfigurationAPI.get("/term/active").then((res) => {
    return res.data;
  });
};

export const UseQuery_GetAllTerms = () => {
  return useQuery(QueryKeys.GET_ALL_TERMS, async () => {
    return getAllTermsFromServer().then((data) => {
      return data;
    });
  });
};
export const UseQuery_GetActiveTerms = () => {
  return useQuery(QueryKeys.GET_ALL_TERMS_ACTIVE, async () => {
    return getActiveTermsFromServer().then((data) => {
      return data;
    });
  });
};

export const UseMutation_AddTerm = (): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  return useMutation<ITerms>(
    (term) => {
      return ConfigurationAPI.post("/terms", term);
    },
    {
      onSuccess: async (res: any) => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_TERMS_FILTER),
          queryClient.invalidateQueries(QueryKeys.GET_ALL_TERMS),
          actions.setToast({
            toastMessage: ToastMessages.Record_Added_Success,
            toastState: true,
          });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage: error?.response?.data?.detail?.message,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};

export const UseMutation_AddTermsMultiple = (): any => {
  const queryClient = useQueryClient();
  return useMutation<ITerms>(
    (terms) => {
      return ConfigurationAPI.post("/terms/add-multiple", terms);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_TERMS_FILTER);
        queryClient.invalidateQueries(QueryKeys.GET_ALL_TERMS);
      },
    }
  );
};

export const UseMutation_UpdateTerm = (termId: number): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  return useMutation<ITerms>(
    (term: any) => {
      return ConfigurationAPI.patch(`/terms/${term.id}`, term);
    },
    {
      onSuccess: async (response: any) => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_TERMS_FILTER);
        queryClient.invalidateQueries(QueryKeys.GET_ALL_TERMS),
          actions.setToast({
            toastMessage: ToastMessages.Record_Updated_Success,
            toastState: true,
          });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage: error?.response?.data?.detail?.message,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};

export const UseMutation_DeleteTerm = (): any => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();
  return useMutation<ITerms>(
    (termId) => {
      return ConfigurationAPI.delete(`/terms/${termId}`);
    },
    {
      onSuccess: async (response: any) => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_TERMS_FILTER);
        queryClient.invalidateQueries(QueryKeys.GET_ALL_TERMS),
          actions.setToast({
            toastMessage: ToastMessages.Record_Deleted_Success,
            toastState: true,
          });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage: error?.response?.data?.detail?.message,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};

export const UseQuery_getAllTerms_filter = (columnFilters: string) => {
  return useQuery(
    [QueryKeys.GET_ALL_TERMS_FILTER, columnFilters],
    async () => {
      return getAllTermsFilterFromServer(columnFilters).then((data) => {
        return data;
      });
    },
    { keepPreviousData: true }
  );
};

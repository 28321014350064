import { FC, useState, useEffect } from "react";
import { useTheme } from "@mui/material";
import { LayoutWithSideNav } from "layout";
import {
  Button,
  DataTableWrap,
  Grid,
  Typography,
  PageHeader,
} from "shared/components";
import { Icon, IGridProps, Box } from "@ntpkunity/controls";
import { ActionOptions } from "Enums";
import { useSetupsStore } from "Stores/SetupStore/Store";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "shared/config/links";
import ProgramsTable from "../modules/ProgramList/Programs-table";
import { removeFpAssetGroup } from "Stores/SetupStore/Actions";

const ProgramList: FC = () => {
  const theme = useTheme();
  const [state, dispatch] = useSetupsStore();
  const navigate = useNavigate();
  const { fpAssetGroup } = state;

  const handleSelection = (event, key, value) => {
    return navigate(APP_ROUTES.Program);
    // switch (key) {
    //   case ActionOptions.ADD:
    //   default:
    //     return;
    // }
  };

  useEffect(() => {
    dispatch(removeFpAssetGroup([]));
  }, []);

  return (
    <LayoutWithSideNav theme={theme}>
      <Grid container rowSpacing={2}>
        <Grid item xs={12}>
          <PageHeader
            theme={theme}
            container
            item
            spacing={2}
            className="main-page-header"
          >
            <Grid container spacing={2}>
              <Grid item lg={7} md={6} sm={12} xs={12}>
                <Typography
                  variant="h2"
                  component="h2"
                  data-testid="heading"
                  theme={theme}
                >
                  Programs
                </Typography>
              </Grid>
            </Grid>
          </PageHeader>
          <Grid xs={12} md={12}>
            <DataTableWrap className="table-pagination-button">
              <Box theme={theme} className="scroll">
                <Box theme={theme} className="scroll-hide spr-border" />
              </Box>
              <ProgramsTable />
              <Button
                secondary
                fullWidth={true}
                text={"Add New Program"}
                onClick={(e) =>
                  handleSelection(e, ActionOptions.ADD, undefined)
                }
              />
            </DataTableWrap>
          </Grid>
        </Grid>
      </Grid>
    </LayoutWithSideNav>
  );
};

export default ProgramList;

import { ConfigurationAPI } from "services";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { ILender, ILenderProfile } from "Interface";
import { QueryKeys, ToastMessages } from "Enums";
import { useSetupsStore, useStoreContext } from "Stores/SetupStore/Store";

export const getLenderProfileFromServer = () => {
  return ConfigurationAPI.get("/lender").then((res) => {
    return res.data;
  });
};

export const getLenderByTenantId = (tenant_id: number) => {
  return ConfigurationAPI.get(`${"/lender/by-tenant-id"}/${tenant_id}`).then(
    (res) => {
      return res.data;
    }
  );
};

export const UseQuery_getLenderProfile = () => {
  return useQuery(QueryKeys.GET_LENDER_PROFILE, async () => {
    return getLenderProfileFromServer().then((data) => {
      return data[0];
    });
  });
};

export const UseQuery_getLenderByTenantId = (tenant_id: number) => {
  return useQuery(
    QueryKeys.GET_LENDER_PROFILE,
    async () => {
      return getLenderByTenantId(tenant_id).then((data) => {
        return data;
      });
    },
    { enabled: !!tenant_id }
  );
};

export const UseMutation_AddLenderProfile = (): any => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();

  return useMutation<ILender>(
    (lender) => {
      return ConfigurationAPI.post("/lender/create-lender", lender);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(QueryKeys.GET_LENDER_PROFILE);
        actions.setToast({
          toastMessage: ToastMessages.Record_Added_Success,
          toastState: true,
        });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage:
            error.response.data.detail.message ??
            ToastMessages.Internal_Server_Error,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};

export const UseMutation_UpdateLenderProfile = (): any => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();

  return useMutation<ILender>(
    (lender: any) => {
      return ConfigurationAPI.patch(
        `/lender/${lender.lender_profile.id}`,
        lender
      );
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(QueryKeys.GET_LENDER_PROFILE);
        actions.setToast({
          toastMessage: ToastMessages.Record_Updated_Success,
          toastState: true,
        });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage:
            error.response.data.detail.message ??
            ToastMessages.Internal_Server_Error,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};

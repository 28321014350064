import { FC } from "react";
import { useTheme } from "@mui/material";
import { Typography, Box, IBoxProps, Grid } from "@ntpkunity/controls";
// import { Grid } from "shared/components";
import { styled } from "@mui/material/styles";
import { IVerifyAddressProps } from "../../modules/LenderAddress/general.type";

const VerifyAddressUi = styled(
  Box,
  {}
)<Partial<IBoxProps>>(() => ({
  ".MuiFormGroup-root": {
    ".MuiFormControlLabel-root": {
      marginRight: "24px !important",

      "&:last-child": {
        marginRight: 0,
      },
    },
  },
}));

const VerifyAddress: FC<IVerifyAddressProps> = ({
  verifyAddress,
  showCounty,
}) => {
  const theme = useTheme();
  return (
    <>
      <VerifyAddressUi theme={theme}>
        <Grid container rowSpacing={3} columnSpacing={3} theme={theme}>
          <Grid item xs={12} sm={12} theme={theme}>
            <Box theme={theme}>
              <Typography theme={theme} variant="caption" component="p">
                Address Line 1
              </Typography>
              <Typography theme={theme} variant="body1" component="h3">
                {verifyAddress?.address_line_1
                  ? verifyAddress?.address_line_1
                  : ""}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} theme={theme}>
            <Box theme={theme}>
              <Typography theme={theme} variant="caption" component="p">
                Address Line 2
              </Typography>
              <Typography theme={theme} variant="body1" component="h3">
                {verifyAddress?.address_line_2
                  ? verifyAddress?.address_line_2
                  : ""}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} theme={theme}>
            <Box theme={theme}>
              <Typography theme={theme} variant="caption" component="p">
                City
              </Typography>
              <Typography theme={theme} variant="body1" component="h3">
                {verifyAddress?.city ? verifyAddress?.city : ""}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} theme={theme}>
            <Box theme={theme}>
              <Typography theme={theme} variant="caption" component="p">
                State
              </Typography>
              <Typography theme={theme} variant="body1" component="h3">
                {verifyAddress?.state_name ? verifyAddress?.state_name : ""}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} theme={theme}>
            <Box theme={theme}>
              <Typography theme={theme} variant="caption" component="p">
                Zip Code
              </Typography>
              <Typography theme={theme} variant="body1" component="h3">
                {verifyAddress?.zip_code ? verifyAddress?.zip_code : ""}
              </Typography>
            </Box>
          </Grid>
          {showCounty && (
            <Grid item xs={12} sm={6} theme={theme}>
              <Box theme={theme}>
                <Typography theme={theme} variant="caption" component="p">
                  County
                </Typography>
                <Typography theme={theme} variant="body1" component="h3">
                  {verifyAddress?.county ? verifyAddress?.county : ""}
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      </VerifyAddressUi>
    </>
  );
};

export default VerifyAddress;

import { FC, useState, useEffect } from "react";
import { useTheme } from "@mui/material";
import { Box, Button } from "@ntpkunity/controls";
import { DataTableWrap } from "shared/components";
import { ActionOptions } from "Enums";
import { IFeeName } from "Interface";
import FeeNameTable from "./fee-name-table";
import { IFeeNameProps } from "./fee-name.type";
import { setOpenDrawer } from "Stores/SetupStore/Actions";
import { useSetupsStore } from "Stores/SetupStore/Store";
import NewFeeName from "../../components/AddNewFeeName/AddNewFeeName";

const FeeName: FC<IFeeNameProps> = () => {
  const [FeeNameState, setFeeNameState] = useState<boolean>(false);
  const [actionTitle, setActionTitle] = useState<string>("Add New Fee");
  const [actionType, setActionType] = useState<ActionOptions>(
    ActionOptions.ADD
  );
  const [state, dispatch] = useSetupsStore();
  const [actionData, setActionData] = useState<IFeeName>(undefined);
  const theme = useTheme();

  useEffect(() => {
    if (FeeNameState === false) {
      dispatch(setOpenDrawer(false));
    }
  }, [FeeNameState]);

  const handleSelection = (event, key, value) => {
    switch (key) {
      case ActionOptions.ADD:
        setActionType(ActionOptions.ADD);
        setActionTitle("Add New Fee");
        setActionData(value);
        setFeeNameState(true);
        dispatch(setOpenDrawer(true));
      default:
        return;
    }
  };
  const onEdit = (formData: IFeeName) => {
    setActionType(ActionOptions.EDIT);
    setActionTitle("Edit Fee");
    setActionData(formData);
    setFeeNameState(true);
    dispatch(setOpenDrawer(true));
  };
  return (
    <>
      <DataTableWrap className="table-tabs-pagination-button" key={1}>
        <Box theme={theme} className="scroll">
          <Box className="scroll-hide spr-border" theme={theme} />
        </Box>
        <FeeNameTable onEdit={(data) => onEdit(data)} />
        <Button
          theme={theme}
          secondary
          fullWidth={true}
          text={"Add New Fee"}
          onClick={(e) => handleSelection(e, ActionOptions.ADD, undefined)}
        />
        {FeeNameState && (
          <NewFeeName
            openPopUp={FeeNameState}
            setPopUpState={setFeeNameState}
            title={actionTitle}
            actionType={actionType}
            FeeNameData={actionData}
          />
        )}
      </DataTableWrap>
    </>
  );
};

export default FeeName;

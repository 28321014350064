import { ConfigurationAPI } from "services";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QueryKeys, ToastMessages } from "Enums";
import { useStoreContext } from "Stores/SetupStore/Store";
import { IStipulation } from "Interface";

export const getAllStipulationsFromServer = () => {
  return ConfigurationAPI.get("/stipulation").then((res) => {
    return res.data;
  });
};

export const getAllStipulationsFilterFromServer = (columnFilters: string) => {
  return ConfigurationAPI.get(
    `/stipulation/filter?`.concat(columnFilters)
  ).then((response) => {
    return response.data;
  });
};

export const UseQuery_getAllStipulations = () => {
  return useQuery(QueryKeys.GET_ALL_STIPULATIONS, async () => {
    return getAllStipulationsFromServer().then((data) => {
      return data;
    });
  });
};

export const UseQuery_getAllStipulations_filter = (columnFilters: string) => {
  return useQuery(
    [QueryKeys.GET_ALL_STIPULATIONS_FILTER, columnFilters],
    async () => {
      return getAllStipulationsFilterFromServer(columnFilters).then((data) => {
        return data;
      });
    },
    { keepPreviousData: true }
  );
};

export const UseMutation_AddStipulation = (): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  return useMutation<IStipulation>(
    (stipulation) => {
      return ConfigurationAPI.post("/stipulation", stipulation);
    },
    {
      onSuccess: async (response: any) => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_STIPULATIONS_FILTER);
        actions.setToast({
          toastMessage: ToastMessages.Record_Added_Success,
          toastState: true,
        });
      },
    }
  );
};

export const UseMutation_UpdateStipulation = (ratingId: number): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  return useMutation<IStipulation>(
    (stipulation: any) => {
      return ConfigurationAPI.patch(
        `/stipulation/${stipulation.id}`,
        stipulation
      );
    },
    {
      onSuccess: async (response: any) => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_STIPULATIONS_FILTER);
        actions.setToast({
          toastMessage: ToastMessages.Record_Updated_Success,
          toastState: true,
        });
      },
      onError: async (error: any) => {
        if (error?.response?.data?.detail?.code != 400) {
          actions.setToast({
            toastMessage: error?.response?.data?.detail?.message,
            toastState: true,
            variant: "error",
          });
        }
      },
    }
  );
};

export const UseMutation_DeleteStipulation = (): any => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();
  return useMutation<IStipulation>(
    (stipulationId) => {
      return ConfigurationAPI.delete(`/stipulation/${stipulationId}`);
    },
    {
      onSuccess: async (response: any) => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_STIPULATIONS_FILTER);
        actions.setToast({
          toastMessage: ToastMessages.Record_Deleted_Success,
          toastState: true,
        });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage: error?.response?.data?.detail?.message,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};

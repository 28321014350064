import { ConfigurationAPI } from "services";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QueryKeys, ToastMessages } from "Enums";
import { useStoreContext } from "Stores/SetupStore/Store";
import { IOptionCategory, IOptionCategoryFilter } from "Interface";
import { setToast } from "Stores/SetupStore/Actions";

export const getAllOptionCategorysFromServer = () => {
  return ConfigurationAPI.get(`/option-category`).then((res) => {
    return res.data;
  });
};
export const getActiveOptionCategorysFromServer = () => {
  return ConfigurationAPI.get(`/option-category/active`).then((res) => {
    return res.data;
  });
};

export const getAllOptionCategorysFilterFromServer = (
  columnFilters: string
) => {
  return ConfigurationAPI.get(
    `/option-category/filter/?`.concat(columnFilters)
  ).then((response) => {
    return response.data;
  });
};

export const UseQuery_getAllOptionCategorys = () => {
  return useQuery(QueryKeys.GET_ALL_OPTION_CATEGORIES, async () => {
    return getAllOptionCategorysFromServer().then((data) => {
      return data;
    });
  });
};

export const UseQuery_getActiveOptionCategorys = () => {
  return useQuery(QueryKeys.GET_ALL_OPTION_CATEGORIES_ACTIVE, async () => {
    return getActiveOptionCategorysFromServer().then((data) => {
      return data;
    });
  });
};

export const UseQuery_getAllOptionCategorys_filter = (
  columnFilters: string
) => {
  return useQuery(
    [QueryKeys.GET_ALL_OPTION_CATEGORY_FILTER, columnFilters],
    async () => {
      return getAllOptionCategorysFilterFromServer(columnFilters).then(
        (data) => {
          return data;
        }
      );
    },
    { keepPreviousData: true }
  );
};

export const UseMutation_AddOptionCategory = (): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  return useMutation<IOptionCategory>(
    (optionCategory) => {
      return ConfigurationAPI.post("/option-category", optionCategory);
    },
    {
      onSuccess: async (response: any) => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_OPTION_CATEGORY_FILTER);
        actions.setToast({
          toastMessage: ToastMessages.Record_Added_Success,
          toastState: true,
        });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage:
            error?.response?.data?.detail?.message ??
            ToastMessages.Internal_Server_Error,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};

export const UseMutation_UpdateOptionCategory = (ratingId: number): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  return useMutation<IOptionCategoryFilter>(
    (optionCategory: any) => {
      return ConfigurationAPI.patch(
        `/option-category/${optionCategory.id}`,
        optionCategory
      );
    },
    {
      onSuccess: async (response: any) => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_OPTION_CATEGORY_FILTER);
        actions.setToast({
          toastMessage: ToastMessages.Record_Updated_Success,
          toastState: true,
        });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage:
            error?.response?.data?.detail?.message ??
            ToastMessages.Internal_Server_Error,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};

export const UseMutation_DeleteOptionCategory = (): any => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();
  return useMutation<IOptionCategory>(
    (optionCategoryId) => {
      return ConfigurationAPI.delete(`/option-category/${optionCategoryId}`);
    },
    {
      onSuccess: async (response: any) => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_OPTION_CATEGORY_FILTER);
        actions.setToast({
          toastMessage: ToastMessages.Record_Deleted_Success,
          toastState: true,
        });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage:
            error.response.data.detail.message ??
            ToastMessages.Internal_Server_Error,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};

import { FC, useState } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useTheme } from "@mui/material";
import {
  Icon,
  Box,
  Button,
  Menu,
  Tooltip,
  Typography,
} from "@ntpkunity/controls";
import { ConfirmationDialog } from "shared/components";
import { useMutation_DeleteInterestChart } from "services";
import { ICharts, IInterestChartCriteria, IInterestCharts } from "Interface";
import { ActionOptions, DialogMessages } from "Enums";
import { convertArray } from "shared/helper/methods";
interface InterestCriteriaProps {
  interestCriteria: IInterestCharts;
  chart: ICharts;
  onEditCriteria?: (data) => unknown;
}

const tableOptions: any = [
  {
    optionText: (
      <>
        <Icon className="menu-icon" name="EditIcon" />
        {ActionOptions.EDIT}
      </>
    ),
    optionkey: ActionOptions.EDIT,
    optionValue: {},
  },
  {
    optionText: (
      <>
        <Icon className="menu-icon" name="DeleteIcon" />
        {ActionOptions.DELETE}
      </>
    ),
    optionkey: ActionOptions.DELETE,
    optionValue: {},
  },
];

const InterestCriteriaTable: FC<InterestCriteriaProps> = ({
  interestCriteria,
  chart,
  onEditCriteria,
}) => {
  const theme = useTheme();
  const [confirmationPopupState, setConfirmationPopupState] =
    useState<boolean>(false);
  const { mutate: deleteInterestChart } = useMutation_DeleteInterestChart();
  const [actionData, setActionData] =
    useState<IInterestChartCriteria>(undefined);

  const handleSelection = (event, key, value) => {
    switch (key) {
      case ActionOptions.EDIT:
        const data = {
          chart: chart,
          interestCriteria: value,
          chartId: 0,
        };
        onEditCriteria(data);
        return;
      case ActionOptions.DELETE:
        setActionData(value.id);
        setConfirmationPopupState(true);
        return;
      default:
        return;
    }
  };

  const onDeleteConfirm = (e: any) => {
    deleteInterestChart(actionData, {
      onSuccess: (data, variables, context) => {
        setActionData(undefined);
      },
    });
  };

  return (
    <>
      <TableRow className="child-tr level-two">
        <TableCell>
          <Box theme={theme} className="group-title"></Box>
        </TableCell>
        <TableCell>
          <Box theme={theme} display="flex" alignItems="center">
            <Tooltip
              theme={theme}
              title={
                <>
                  {interestCriteria.asset_make_name}{" "}
                  {interestCriteria.asset_model_name}{" "}
                  {interestCriteria.asset_trim_name}
                </>
              }
            >
              <Box theme={theme} display="flex" alignItems="center">
                {/* <Icon className="icon" name="InfoIcon" /> */}
                <Typography
                  theme={theme}
                  variant="body2"
                  sx={{ ml: 0.7 }}
                  component="span"
                >
                  {
                    <>
                      {interestCriteria.asset_make_name}{" "}
                      {interestCriteria.asset_model_name}{" "}
                      {interestCriteria.asset_trim_name}
                    </>
                  }
                </Typography>
              </Box>
            </Tooltip>
          </Box>
        </TableCell>
        <TableCell>
          <Box theme={theme} display="flex" alignItems="center">
            <Tooltip
              theme={theme}
              title={
                <>
                  {interestCriteria?.manufacturer
                    ? interestCriteria?.manufacturer.join(", ")
                    : "-"}
                </>
              }
            >
              <Box theme={theme} display="flex" alignItems="center">
                {/* <Icon className="icon" name="InfoIcon" /> */}
                <Typography
                  theme={theme}
                  variant="body2"
                  sx={{ ml: 0.7 }}
                  component="span"
                >
                  {
                    <>
                      {interestCriteria?.manufacturer
                        ? interestCriteria?.manufacturer.join(", ")
                        : "-"}
                    </>
                  }
                </Typography>
              </Box>
            </Tooltip>
          </Box>
        </TableCell>
        <TableCell>
          <Box theme={theme} display="flex" alignItems="center">
            <Tooltip
              theme={theme}
              title={
                <>
                  {interestCriteria?.purchase_option
                    ? interestCriteria?.purchase_option.join(", ")
                    : "-"}
                </>
              }
            >
              <Box theme={theme} display="flex" alignItems="center">
                {/* <Icon className="icon" name="InfoIcon" /> */}
                <Typography
                  theme={theme}
                  variant="body2"
                  sx={{ ml: 0.7 }}
                  component="span"
                >
                  {
                    <>
                      {interestCriteria?.purchase_option
                        ? interestCriteria?.purchase_option.join(", ")
                        : "-"}
                    </>
                  }
                </Typography>
              </Box>
            </Tooltip>
          </Box>
        </TableCell>
        <TableCell>
          <Box theme={theme} display="flex" alignItems="center">
            <Tooltip
              theme={theme}
              title={
                <>
                  {interestCriteria?.asset_condition
                    ? interestCriteria?.asset_condition.join(", ")
                    : "-"}
                </>
              }
            >
              <Box theme={theme} display="flex" alignItems="center">
                {/* <Icon className="icon" name="InfoIcon" /> */}
                <Typography
                  theme={theme}
                  variant="body2"
                  sx={{ ml: 0.7 }}
                  component="span"
                >
                  {
                    <>
                      {interestCriteria?.asset_condition
                        ? interestCriteria?.asset_condition.join(", ")
                        : "-"}
                    </>
                  }
                </Typography>
              </Box>
            </Tooltip>
          </Box>
        </TableCell>
        <TableCell>
          <Box theme={theme} display="flex" alignItems="center">
            <Tooltip
              theme={theme}
              title={
                <>
                  {interestCriteria?.asset_usages
                    ? interestCriteria?.asset_usages.join(", ")
                    : "-"}
                </>
              }
            >
              <Box theme={theme} display="flex" alignItems="center">
                {/* <Icon className="icon" name="InfoIcon" /> */}
                <Typography
                  theme={theme}
                  variant="body2"
                  sx={{ ml: 0.7 }}
                  component="span"
                >
                  {
                    <>
                      {interestCriteria?.asset_usages
                        ? interestCriteria?.asset_usages.join(", ")
                        : "-"}
                    </>
                  }
                </Typography>
              </Box>
            </Tooltip>
          </Box>
        </TableCell>
        <TableCell>
          <Box theme={theme} display="flex" alignItems="center">
            <Tooltip
              theme={theme}
              title={
                <>
                  {interestCriteria?.terms
                    ? interestCriteria?.terms.join(", ")
                    : "-"}
                </>
              }
            >
              <Box theme={theme} display="flex" alignItems="center">
                {/* <Icon className="icon" name="InfoIcon" /> */}
                <Typography
                  theme={theme}
                  variant="body2"
                  sx={{ ml: 0.7 }}
                  component="span"
                >
                  {
                    <>
                      {interestCriteria?.terms
                        ? interestCriteria?.terms.join(", ")
                        : "-"}
                    </>
                  }
                </Typography>
              </Box>
            </Tooltip>
          </Box>
        </TableCell>
        <TableCell>
          <Box theme={theme} display="flex" alignItems="center">
            <Tooltip
              theme={theme}
              title={
                <>
                  {interestCriteria?.credit_rating
                    ? interestCriteria?.credit_rating.join(", ")
                    : "-"}
                </>
              }
            >
              <Box theme={theme} display="flex" alignItems="center">
                {/* <Icon className="icon" name="InfoIcon" /> */}
                <Typography
                  theme={theme}
                  variant="body2"
                  sx={{ ml: 0.7 }}
                  component="span"
                >
                  {
                    <>
                      {interestCriteria?.credit_rating
                        ? interestCriteria?.credit_rating.join(", ")
                        : "-"}
                    </>
                  }
                </Typography>
              </Box>
            </Tooltip>
          </Box>
        </TableCell>
        <TableCell>
          <Box theme={theme} display="flex" alignItems="center">
            <Tooltip
              theme={theme}
              title={
                <>
                  {interestCriteria?.financed_amount
                    ? convertArray(interestCriteria?.financed_amount).join(", ")
                    : "-"}
                </>
              }
            >
              <Box theme={theme} display="flex" alignItems="center">
                {/* <Icon className="icon" name="InfoIcon" /> */}
                <Typography
                  theme={theme}
                  variant="body2"
                  sx={{ ml: 0.7 }}
                  component="span"
                >
                  {
                    <>
                      {interestCriteria?.financed_amount
                        ? convertArray(interestCriteria?.financed_amount).join(
                            ", "
                          )
                        : "-"}
                    </>
                  }
                </Typography>
              </Box>
            </Tooltip>
          </Box>
        </TableCell>
        <TableCell>
          <Box theme={theme} display="flex" alignItems="center">
            <Tooltip
              theme={theme}
              title={
                <>
                  {interestCriteria?.business_units
                    ? interestCriteria?.business_units.join(", ")
                    : "-"}
                </>
              }
            >
              <Box theme={theme} display="flex" alignItems="center">
                {/* <Icon className="icon" name="InfoIcon" /> */}
                <Typography
                  theme={theme}
                  variant="body2"
                  sx={{ ml: 0.7 }}
                  component="span"
                >
                  {
                    <>
                      {interestCriteria?.business_units
                        ? interestCriteria?.business_units.join(", ")
                        : "-"}
                    </>
                  }
                </Typography>
              </Box>
            </Tooltip>
          </Box>
        </TableCell>
        <TableCell>
          {interestCriteria?.late_charge_inclusive_adder == true
            ? "Yes"
            : interestCriteria?.late_charge_inclusive_adder === false
            ? "No"
            : "-"}
        </TableCell>
        <TableCell>
          {interestCriteria?.property_tax_inclusive_adder == true
            ? "Yes"
            : interestCriteria?.property_tax_inclusive_adder === false
            ? "No"
            : "-"}
        </TableCell>
        <TableCell>
          {" "}
          {interestCriteria?.origination_fee_inclusive_adder === true
            ? "Yes"
            : interestCriteria?.origination_fee_inclusive_adder === false
            ? "No"
            : "-"}
        </TableCell>
        <TableCell>{`${
          interestCriteria.margin
            ? `${interestCriteria?.margin.toFixed(4)}% `
            : "-"
        }`}</TableCell>
        <TableCell>
          {" "}
          {interestCriteria.minimum_margin && interestCriteria.maximum_margin
            ? interestCriteria.minimum_margin.toFixed(4) +
              "% - " +
              interestCriteria.maximum_margin.toFixed(4) +
              "%"
            : "-"}
        </TableCell>
        <TableCell />
        <TableCell className="action-cell fixed-cell">
          <Menu
            theme={theme}
            disablePortal
            options={tableOptions.map((option) => {
              return {
                optionText: option.optionText,
                optionkey: option.optionkey,
                optionValue: interestCriteria,
              };
            })}
            handleOptionClick={handleSelection}
            render={(onMenuSelection) => (
              <Button
                defaultBtn
                iconText={<Icon name="MoreIcon" />}
                onClick={onMenuSelection}
              />
            )}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          />
        </TableCell>
      </TableRow>

      {confirmationPopupState && (
        <ConfirmationDialog
          openPopUp={confirmationPopupState}
          onConfirm={onDeleteConfirm}
          setPopUpState={setConfirmationPopupState}
          confirmationTitle={DialogMessages.confirmationTitle}
          confirmationText={DialogMessages.confirmationText}
          primaryBtnText={DialogMessages.deleteBtnText}
          icon={<Icon name="DialogDeleteIcon" />}
        />
      )}
    </>
  );
};

export default InterestCriteriaTable;

import Http from "shared/helper/http-api";
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  EP_GET_REFERENCE_DATA_BY_DEALER_CODE,
  EP_VEHICLE,
  EP_GET_VEHICLES_BY_DEALER_CODE,
  EP_GET_VEHICLES_BY_FILTER,
  EP_GET_VEHICLES_INVENTORY_BY_DEALER_CODE,
  EP_REFERRAL_INVITE,
  EP_VEHICLE_DETAIL,
  EP_GET_INVENTORY_OPTIONS_DETAIL,
  EP_UPDATE_VEHICLE_DETAIL,
} from "shared/config/endpoints";
import { IVehicle, IVehicleDetail } from "Interface";
import { useStoreContext } from "Stores/SetupStore/Store";
import { ConfigurationAPI } from "../services/http-common";
import { QueryKeys } from "Enums";

export const useGetInventory = (): any => {
  const { data, isLoading, error, mutate } = useMutation<{}, any>(() => {
    const apiService = Http.createConnection();
    return apiService.get<any>(EP_VEHICLE);
  });
  return { data, isLoading, error, mutate };
};

export const useGetInventoryByDealerCode = (): any => {
  const { data, isLoading, error, mutate } = useMutation<
    { dealer_code: string },
    any
  >((body: any) => {
    const apiService = Http.createConnection();
    return apiService.get<any>(
      `${EP_GET_VEHICLES_BY_DEALER_CODE}/${body.dealer_code}`
    );
  });
  return { data, isLoading, error, mutate };
};

export const useGetFilterReferenceData = (): any => {
  const { data, isLoading, error, mutate } = useMutation<
    { dealer_code: string },
    any
  >((body: any) => {
    const apiService = Http.createConnection();
    return apiService.get<any>(
      `${EP_GET_REFERENCE_DATA_BY_DEALER_CODE}/${body.dealer_code}`
    );
  });
  return { data, isLoading, error, mutate };
};

export const useGetInventoryByFilters = (): any => {
  const { data, isLoading, error, mutate } = useMutation<{ data: string }, any>(
    (body: any) => {
      const apiService = Http.createConnection();
      let params = body.data ? `?${body.data}` : "";
      return apiService.get<any>(
        params
          ? `${EP_GET_VEHICLES_BY_FILTER}/${params}`
          : EP_GET_VEHICLES_BY_FILTER
      );
    }
  );
  return { data, isLoading, error, mutate };
};

export const useGetInventoryData = (): any => {
  const { actions } = useStoreContext();
  const { data, isLoading, error, mutate } = useMutation<
    { dealer_code: string },
    any
  >(
    (body: any) => {
      const apiService = Http.createConnection();
      let params = body.data ? `?${body.data}` : "";
      return apiService.get<any>(
        params
          ? `${EP_GET_VEHICLES_INVENTORY_BY_DEALER_CODE}${params}`
          : EP_GET_VEHICLES_BY_DEALER_CODE
      );
    },
    {
      onSuccess(_response: any) {
        actions.setAssetInventoryInitialData(_response);
      },
    }
  );
  return { data, isLoading, error, mutate };
};

export const useUpdateVehicleDetailByVin = (): any => {
  const { data, isLoading, mutate } = useMutation<{ vehicleDetail: any }, any>(
    (body: any) => {
      const apiService = Http.createConnection();
      return apiService.patch<any>(`${EP_VEHICLE_DETAIL}/${body.id}`, body);
    },
    {
      onSuccess() {},
    }
  );
  return { data, isLoading, mutate };
};
export const useUpdateVehicleDetailByVid = (): any => {
  const { data, isLoading, mutate } = useMutation<IVehicleDetail, any>(
    (body: any) => {
      const apiService = Http.createConnection();
      return apiService.patch<IVehicleDetail>(
        `${EP_UPDATE_VEHICLE_DETAIL}/${body.id}`,
        body
      );
    },
    {
      onSuccess() {},
    }
  );
  return { data, isLoading, mutate };
};

export const useUpdateVehicleByVin = (): any => {
  const { data, isLoading, mutate } = useMutation<
    { id: number; vehicle: IVehicle },
    any
  >(
    (body: any) => {
      const apiService = Http.createConnection();
      return apiService.patch<any>(`${EP_VEHICLE}/${body.id}`, body.vehicle);
    },
    {
      onSuccess() {},
    }
  );
  return { data, isLoading, mutate };
};

// export const useSendReferralInvite = (): any => {
//   const { data, isLoading, mutate } = useMutation<
//     {
//       first_name: string;
//       last_name: string;
//       email: string;
//       vin: string;
//       dealer_code: string;
//     },
//     any
//   >(
//     (body: any) => {
//       const apiService = Http.createConnection();
//       return apiService.post<any>(`${EP_REFERRAL_INVITE}`, body);
//     },
//     {
//       onSuccess() {},
//     }
//   );
//   return { data, isLoading, mutate };
// };

export const useGetInventoryOptions = (): any => {
  const { data, isLoading, error, mutate } = useMutation<{}, any>(
    (body: any) => {
      let params = body.data ? `${body.data}` : "";
      body.dealer_options = 1;
      const apiService = Http.createConnection();
      return apiService.get<any>(
        params
          ? `${EP_GET_INVENTORY_OPTIONS_DETAIL}?${params}`
          : EP_GET_INVENTORY_OPTIONS_DETAIL
      );
    }
  );
  return { data, isLoading, error, mutate };
};

export const useMutation_BulkAddInventory: any = () => {
  // const queryClient = useQueryClient();
  return useMutation<{ dealer_code: string; obj: any }, any>(
    (inventoryFile: any) => {
      return ConfigurationAPI.post(
        `/inventory-list-import/${inventoryFile.dealer_code}/${inventoryFile.obj.tenant_id}`,
        inventoryFile.obj.files
      );
    },
    {
      onSuccess: async () => {},
    }
  );
};

const getAllInventoryFilterFromServer = (columnFilters?: string) => {
  return ConfigurationAPI.get(
    `${EP_GET_VEHICLES_INVENTORY_BY_DEALER_CODE}?${columnFilters}`
  ).then((response) => {
    return response.data;
  });
};

export const useGetInventoryFilter = (columnFilters: string) => {
  const { actions, states } = useStoreContext();
  return useQuery(
    [QueryKeys.GET_ALL_INVENTORY_FILTER, columnFilters],
    async () => {
      return getAllInventoryFilterFromServer(columnFilters).then((data) => {
        actions.setAssetInventoryInitialData(data);
        return data;
      });
    },
    { keepPreviousData: true }
  );
};
export const getAllInventoryOptionsFilterFromServer = (
  columnFilters: string
) => {
  return ConfigurationAPI.get(
    `/inventory/attached-option/filter?`.concat(columnFilters)
  ).then((response) => {
    return response.data;
  });
};

export const useGetAllInventoryOptionFilter = (columnFilters: string) => {
  return useQuery(
    [QueryKeys.GET_ALL_INVENTORY_OPTION_FILTER, columnFilters],
    async () => {
      return getAllInventoryOptionsFilterFromServer(columnFilters).then(
        (data) => {
          return data;
        }
      );
    },
    { keepPreviousData: true }
  );
};

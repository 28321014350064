import { FC, useState } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useTheme } from "@mui/material";
import { Button, Menu, Icon, Switch } from "@ntpkunity/controls";
import { ConfirmationDialog } from "shared/components";
import {
  UseMutation_DeleteCharts,
  UseMutation_UpdateCharts,
  UseQuery_GetAllInterestCharts,
} from "services";
import {
  IAssetCondition,
  IBusinessUnits,
  IOtherCosts,
  ICharts,
  ICreditRating,
  IInterestChartCriteria,
  IInterestCharts,
  ITerms,
  IFinancedAmount,
} from "Interface";
import { ActionOptions, DialogMessages, QueryKeys } from "Enums";
import NonAssetInterestCriteriaTable from "./na-interest-criteria-table";
import { useQueryClient } from "react-query";

interface InterestChartProps {
  chart: ICharts;
  onOptionsClick?: (key, value) => unknown;
  onEditCriteria?: (data) => unknown;
  AllAssetConditions?: IAssetCondition[];
  AllBusinessUnits?: IBusinessUnits[];
  AllOtherCosts?: IOtherCosts[];
  AllFinancedAmounts?: IFinancedAmount[];
  AllTerms?: ITerms[];
  AllCreditRatings?: ICreditRating[];
}

const tableOptions: any = [
  {
    optionText: (
      <>
        <Icon className="menu-icon" name="EditIcon" />
        {ActionOptions.RENAME_CHART}
      </>
    ),
    optionkey: ActionOptions.RENAME_CHART,
    optionValue: {},
  },
  {
    optionText: (
      <>
        <Icon className="menu-icon" name="AddIcon" />
        {ActionOptions.ADD_CRITERIA}
      </>
    ),
    optionkey: ActionOptions.ADD_CRITERIA,
    optionValue: {},
  },
  {
    optionText: (
      <>
        <Icon className="menu-icon" name="DeleteIcon" />
        {ActionOptions.DELETE}
      </>
    ),
    optionkey: ActionOptions.DELETE,
    optionValue: {},
  },
];

const NonAssetInterestChartTable: FC<InterestChartProps> = ({
  chart,
  onOptionsClick,
  onEditCriteria,
  AllAssetConditions,
  AllBusinessUnits,
  AllOtherCosts,
  AllFinancedAmounts,
  AllTerms,
  AllCreditRatings,
}) => {
  UseQuery_GetAllInterestCharts();
  const queryClient = useQueryClient();

  const [open, setOpen] = useState(false);
  const interestCharts: Array<IInterestCharts> = queryClient.getQueryData(
    QueryKeys.GET_ALL_INTEREST_CHARTS
  );
  const [confirmationPopupState, setConfirmationPopupState] =
    useState<boolean>(false);
  const { mutate: deleteChart } = UseMutation_DeleteCharts();
  const { mutate: UpdateMake } = UseMutation_UpdateCharts();

  const [chartActionData, setActionData] =
    useState<IInterestChartCriteria>(undefined);
  const handleSelection = (event, key, value) => {
    onOptionsClick(key, value);
    if (key === ActionOptions.DELETE) {
      setActionData(value.id);
      setConfirmationPopupState(true);
    }
  };

  const onDeleteConfirm = (e: any) => {
    deleteChart(chartActionData, {
      onSuccess: (data, variables, context) => {
        setActionData(undefined);
      },
    });
  };

  const handleEnableChange = (data: ICharts, event) => {
    let updatedData = {
      chart_name: data.chart_name,
      chart_type_id: data.chart_type_id,
      id: data.id,
      is_active: event?.target?.checked,
    };
    UpdateMake(updatedData);
  };

  const theme = useTheme();

  return (
    <>
      <TableRow className="child-tr">
        <TableCell colSpan={10} className="indent-cell">
          {interestCharts?.filter((item) => item.chart_id == chart.id).length >=
          0 ? (
            open ? (
              <Icon
                name="ChevronDown"
                className="toggle-btn ch-right"
                onClick={() => setOpen(!open)}
              />
            ) : (
              <Icon
                name="ChevronDown"
                className="toggle-btn"
                onClick={() => setOpen(!open)}
              />
            )
          ) : (
            <></>
          )}
          {chart?.chart_name}
        </TableCell>
        <TableCell>
          <Switch
            theme={theme}
            varient={"basic"}
            label={chart.is_active ? "Enabled" : "Disabled"}
            switchEnabled={chart.is_active}
            onChange={(event) => handleEnableChange(chart, event)}
          />
        </TableCell>
        <TableCell className="action-cell fixed-cell space-fixed">
          <Menu
            theme={theme}
            disablePortal
            options={tableOptions.map((option) => {
              return {
                optionText: option.optionText,
                optionkey: option.optionkey,
                optionValue: chart,
              };
            })}
            handleOptionClick={handleSelection}
            render={(onMenuSelection) => (
              <Button
                defaultBtn
                iconText={<Icon name="MoreIcon" />}
                onClick={onMenuSelection}
              />
            )}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          />
        </TableCell>
      </TableRow>

      {open ? (
        <>
          <TableRow>
            <TableCell colSpan={12} className="second-level-cell chart-header">
              {"Chart Criteria ("}
              {interestCharts &&
                interestCharts.filter((item) => item.chart_id == chart.id)
                  .length}
              {")"}
            </TableCell>
          </TableRow>

          {interestCharts &&
            interestCharts
              .filter((item) => item.chart_id == chart.id)
              .map((item, index) => (
                <NonAssetInterestCriteriaTable
                  key={index}
                  chart={chart}
                  interestCriteria={item}
                  onEditCriteria={(data) => onEditCriteria(data)}
                  AssetCondition={AllAssetConditions}
                  BusinessUnit={AllBusinessUnits}
                  OtherCost={AllOtherCosts}
                  FinancedAmount={AllFinancedAmounts}
                  terms={AllTerms}
                  creditRatings={AllCreditRatings}
                />
              ))}
        </>
      ) : null}

      {confirmationPopupState && (
        <ConfirmationDialog
          openPopUp={confirmationPopupState}
          onConfirm={onDeleteConfirm}
          setPopUpState={setConfirmationPopupState}
          confirmationTitle={DialogMessages.confirmationTitle}
          confirmationText={DialogMessages.confirmationText}
          primaryBtnText={DialogMessages.deleteBtnText}
          icon={<Icon name="DialogDeleteIcon" />}
        />
      )}
    </>
  );
};

export default NonAssetInterestChartTable;

import { ConfigurationAPI } from "services";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { IAddress } from "Interface";
import { QueryKeys, ToastMessages } from "Enums";
import { useSetupsStore, useStoreContext } from "Stores/SetupStore/Store";
import { setAddressInitialData } from "Stores/SetupStore/Actions";

export const getAllAddressessFromServer = () => {
  return ConfigurationAPI.get("/address/").then((res) => {
    return res.data;
  });
};

export const UseQuery_getAllAddressess = () => {
  const [, dispatch] = useSetupsStore();

  return useQuery(QueryKeys.GET_ALL_ADDRESSESS, async () => {
    return getAllAddressessFromServer().then((data) => {
      //
      dispatch(setAddressInitialData(data));
      return data;
    });
  });
};

export const UseMutation_AddAddresses = (): any => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();

  return useMutation<IAddress>(
    (lenderAdress) => {
      return ConfigurationAPI.post("/address", lenderAdress);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_ADDRESSESS);
        actions.setToast({
          toastMessage: ToastMessages.Record_Added_Success,
          toastState: true,
        });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage:
            error.response.data.detail.message ??
            ToastMessages.Internal_Server_Error,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};

export const UseMutation_UpdateAddresses = (): any => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();

  return useMutation<IAddress>(
    (lenderAdress: any) => {
      return ConfigurationAPI.patch(
        `/address/${(lenderAdress.lender_id, lenderAdress)}`,
        lenderAdress
      );
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_ADDRESSESS);
        actions.setToast({
          toastMessage: ToastMessages.Record_Updated_Success,
          toastState: true,
        });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage:
            error.response.data.detail.message ??
            ToastMessages.Internal_Server_Error,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};

export const UseMutation_DeleteAddress = (): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  return useMutation<IAddress>(
    (addressId) => {
      return ConfigurationAPI.delete(`/address/${addressId}`);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(QueryKeys.GET_LENDER_PROFILE);
        actions.setToast({
          toastMessage: ToastMessages.Record_Deleted_Success,
          toastState: true,
        });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage:
            error.response.data.detail.message ??
            ToastMessages.Internal_Server_Error,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};

import { FC, useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import {
  Icon,
  PersistentDrawer,
  Input,
  Select,
  Button,
} from "@ntpkunity/controls";
import { ConfirmationDialog } from "shared/components";
import { validation } from "shared/helper/methods";
import { Controller, useForm } from "react-hook-form";
import {
  ActionOptions,
  QueryKeys,
  ValidationMessages,
  DialogMessages,
} from "Enums";
import { useQueryClient } from "react-query";
import { INewIntegrationTypePopup } from "./AddNewIntegration.type";
import {
  UseQuery_getLenderIntegrations,
  useSaveLenderIntegration,
  useUpdateLenderIntegration,
  useGetVerifyAddress,
} from "services";
import { useStoreContext } from "Stores/SetupStore/Store";
import { usePrompt } from "utilities/usePrompt";
import { useNavigate } from "react-router-dom";
import { logout } from "@ntpkunity/controls-ums";
interface IFormInputs {
  id?: number;
  integration_type: string;
  provider_name: string;
  route: string;
  is_dealer_input_required: boolean;
  is_active: boolean;
}
const AddNewIntegration: FC<INewIntegrationTypePopup> = ({
  openPopUp,
  setPopUpState,
  title,
  actionType,
  integrationTypeData,
  enabledPin,
  ParentIntegrationState,
}) => {
  UseQuery_getLenderIntegrations();
  const theme = useTheme();
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  const lenderProfile: any = queryClient.getQueryData(
    QueryKeys.GET_LENDER_PROFILE
  );
  const integrationsData: any = queryClient.getQueryData(
    QueryKeys.GET_ALL_LENDER_INTEGRATIONS
  );
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors, isDirty },
    watch,
  } = useForm<IFormInputs>();
  const onClose = () => {
    let children = document.querySelectorAll(".drawer-header .pinned");
    if (children.length == 0) {
      setPopUpState(false);
    }
  };
  const navigate = useNavigate();
  const [integrationTypeSelection, setIntegrationTypeSelection] = useState("");
  const [providerSelection, setProviderSelection] = useState("");
  const [navigationState, setNavigationState] = useState<any>();
  const [leavePagePopupState, setLeavePageState] = useState<boolean>(false);
  const data = {
    integration_types: [
      {
        integration_type: "Payment Processing",
        provider_name: [
          {
            name: "Stripe Connect",
            is_active: "true",
            is_dealer_input_required: "true",
            user_input: [
              {
                name: "api_secret_key",
                value: "",
                data_type: "text",
                label: "API Secret Key",
              },
            ],
          },
          {
            name: "Plaid",
            is_active: "true",
            is_dealer_input_required: "true",
            user_input: [
              {
                name: "app_name",
                value: "",
                data_type: "text",
                label: "APP Name",
              },
              {
                name: "client_secret",
                value: "",
                data_type: "text",
                label: "Client Secret",
              },
              {
                name: "client_id",
                value: "",
                data_type: "text",
                label: "Client ID",
              },
            ],
          },
        ],
      },
      {
        integration_type: "Existing Customer Check",
        provider_name: [
          {
            name: "Cdk",
            is_active: "true",
            is_dealer_input_required: "true",
          },
          {
            name: "Tekion",
            is_active: "true",
            is_dealer_input_required: "true",
          },
        ],
      },
      {
        integration_type: "Inventory Upload",
        provider_name: [
          {
            name: "Shift Digital",
            is_active: "true",
            is_dealer_input_required: "true",
            user_input: [
              {
                name: "user_name",
                value: "",
                data_type: "text",
                label: "Username",
              },
              {
                name: "password",
                value: "",
                data_type: "text",
                label: "Password",
              },
              {
                name: "file_storage_path",
                value: "",
                data_type: "text",
                label: "File Storage Path",
              },
              {
                name: "file_syncing_time",
                value: "",
                data_type: "text",
                label: "File Syncing Time",
              },
            ],
          },
        ],
      },
      {
        integration_type: "F&I Products",
        provider_name: [
          {
            name: "Provider Exchange Network",
            is_active: "true",
            is_dealer_input_required: "true",
            user_input: [
              {
                name: "user_name",
                value: "",
                data_type: "text",
                label: "Username",
              },
              {
                name: "password",
                value: "",
                data_type: "text",
                label: "Password",
              },
              {
                name: "token",
                value: "",
                data_type: "text",
                label: "Token",
              },
            ],
          },
        ],
      },
      {
        integration_type: "Fraud Identification",
        provider_name: [
          {
            name: "Socure",
            is_active: "true",
            is_dealer_input_required: "true",
            user_input: [
              {
                name: "user_name",
                value: "",
                data_type: "text",
                label: "Username",
              },
              {
                name: "password",
                value: "",
                data_type: "text",
                label: "Password",
              },
              {
                name: "file_storage_path",
                value: "",
                data_type: "text",
                label: "File Storage Path",
              },
              {
                name: "api_secret_key",
                value: "",
                data_type: "text",
                label: "API Secret Key",
              },
            ],
          },
        ],
      },
      {
        integration_type: "Identity Verification",
        provider_name: [
          {
            name: "Mitek",
            is_active: "true",
            is_dealer_input_required: "true",
            user_input: [
              {
                name: "client_id",
                value: "",
                data_type: "text",
                label: "Identity ​Client ID",
              },
              {
                name: "client_secret",
                value: "",
                data_type: "text",
                label: "Identity Client Secret",
              },
              {
                name: "​manual_client_id",
                value: "",
                data_type: "text",
                label: "​Manual Client ID",
              },
              {
                name: "manual_client_secret",
                value: "",
                data_type: "text",
                label: "Manual Client Secret",
              },
            ],
          },
        ],
      },
      {
        integration_type: "Address Validation",
        provider_name: [
          {
            name: "Google",
            is_active: "true",
            is_dealer_input_required: "true",
            user_input: [
              {
                name: "api_secret_key",
                value: "",
                data_type: "text",
                label: "API Secret Key",
              },
            ],
          },
        ],
      },
      {
        integration_type: "Trade In Valuation",
        provider_name: [
          {
            name: "Kelly Blue Book",
            is_active: "true",
            is_dealer_input_required: "true",
            user_input: [
              {
                name: "api_secret_key",
                value: "",
                data_type: "text",
                label: "API Secret Key",
              },
            ],
          },
        ],
      },
    ],
  };
  const { mutate: createLenderIntegration } = useSaveLenderIntegration();
  const { mutate: updateLenderIntegration } = useUpdateLenderIntegration();
  const { mutate: getAddress } = useGetVerifyAddress();

  const [providerState, setProviderState] = useState<any>();
  const [selectedProviderState, setSelectedProviderState] = useState<string[]>(
    []
  );
  const [inputFields, setInputFields] = useState<any>(undefined);
  const [lenderIntegrations, setLenderIntegrations] = useState(
    JSON.parse(JSON.stringify(data)).integration_types
  );
  const integrationOptions = lenderIntegrations?.map((item) => {
    const options = {
      value: item.integration_type,
      text: item.integration_type,
    };
    return options;
  });
  useEffect(() => {
    if (lenderProfile) {
      if (lenderProfile.existing_customer_check === "External") {
        const externalIntegrationTypes = JSON.parse(
          JSON.stringify(data)
        ).integration_types;
        setLenderIntegrations(externalIntegrationTypes);
      } else if (lenderProfile.existing_customer_check === "Internal") {
        const filteredIntegrationTypes = lenderIntegrations?.filter((item) => {
          return item.integration_type !== "Existing Customer Check";
        });
        setLenderIntegrations(filteredIntegrationTypes);
      }
    }
  }, [lenderProfile]);
  useEffect(() => {
    if (actionType !== ActionOptions.ADD) {
      !ParentIntegrationState && setValue("id", integrationTypeData?.id);
      setValue("integration_type", integrationTypeData?.integration_type);
      setValue(
        "is_dealer_input_required",
        integrationTypeData?.is_dealer_input_required
      );
      const providers = lenderIntegrations
        .filter(
          (item: any) =>
            item.integration_type === integrationTypeData?.integration_type
        )
        .shift()
        ?.provider_name.map((item) => {
          const provider = {
            value: item.name,
            text: item.name,
            user_input: item.user_input,
            is_dealer_input_required: item.is_dealer_input_required,
          };
          if (ParentIntegrationState) {
            if (selectedProviderState.indexOf(provider?.value) === -1) {
              return provider;
            } else return;
          } else {
            return provider;
          }
        });
      setProviderState(providers?.filter((item) => item !== undefined));
      !ParentIntegrationState &&
        setValue("provider_name", integrationTypeData?.provider_name);
      setValue(
        "is_active",
        integrationTypeData ? integrationTypeData.is_active : true
      );
      if (!ParentIntegrationState) {
        let userInputs = [];
        userInputs = integrationTypeData?.lender_integration_user_inputs?.map(
          (userInput) => {
            setValue(userInput.user_input_name, userInput.user_input_value);
            return {
              name: userInput.user_input_name,
              label: userInput.user_input_label,
            };
          }
        );
        setInputFields(userInputs);
      }
    }
  }, [integrationTypeData, selectedProviderState]);

  useEffect(() => {
    if (integrationsData) {
      const selectedProviders = integrationsData?.map((integrationData) => {
        return integrationData.provider_name;
      });
      setSelectedProviderState(selectedProviders);
    }
  }, [integrationsData]);

  const integrationUpdate = (integration_type) => {
    setInputFields(null);
    const providers = lenderIntegrations
      .filter((item: any) => item.integration_type === integration_type)
      .shift()
      .provider_name.map((item) => {
        const provider = {
          value: item.name,
          text: item.name,
          user_input: item.user_input,
          is_dealer_input_required: item.is_dealer_input_required,
        };
        if (selectedProviderState.indexOf(provider?.value) === -1) {
          return provider;
        } else return;
      });
    setProviderState(providers.filter((item) => item !== undefined));

    const is_active = watch("is_active");
    reset({
      integration_type: integration_type,
      provider_name: "",
      is_active: is_active,
    });
  };
  const providerValueChange = (providervalue, providerObj) => {
    const selectedProvider = providerState
      .filter((item: any) => item?.value === providervalue)
      .shift();
    const is_dealer_input_required = selectedProvider.is_dealer_input_required;
    const userInputArr = selectedProvider.user_input;
    setInputFields(userInputArr);
    const integration_type = watch("integration_type");
    const is_active = watch("is_active");
    reset({
      integration_type: integration_type,
      provider_name: providervalue,
      is_active: is_active,
      is_dealer_input_required: is_dealer_input_required,
    });
  };

  const testConnection = (data) => {
    if (watch("provider_name") === "Google") {
      getAddress(
        { address: "USA", googleAPIKey: data.api_secret_key },
        {
          onSuccess(response: any) {
            if (response.status === "OK") {
              onSubmit(data);
            } else {
              actions.setToast({
                toastMessage: "Provided Information is incorrect",
                toastState: true,
                variant: "error",
              });
            }
          },
          onError(response: any) {
            actions.setToast({
              toastMessage: response?.error?.toString(),
              toastState: true,
              variant: "error",
            });
          },
        }
      );
    } else {
      onSubmit(data);
    }
  };

  const onSubmit = (data) => {
    data.lender_id = lenderProfile.id;
    let lender_integration_user_input = [];
    for (const [key, value] of Object.entries(data)) {
      if (
        key !== "lender_id" &&
        key !== "integration_type" &&
        key !== "route" &&
        key !== "provider_name" &&
        key !== "is_dealer_input_required" &&
        key !== "is_active" &&
        key !== "id"
      ) {
        const userInput =
          integrationTypeData?.lender_integration_user_inputs?.filter(
            (user_input) => user_input.user_input_name === key
          );
        const inputField = inputFields.filter((field) => field.name === key);
        lender_integration_user_input.push({
          user_input_name: key,
          user_input_value: value,
          user_input_label:
            inputField && inputField.length > 0 ? inputField[0].label : "",
          lender_integration_id: data?.id,
          id: userInput ? userInput[0]?.id : undefined,
        });
      }
    }
    data = {
      ...data,
      lender_integration_user_inputs: lender_integration_user_input,
    };
    if (actionType === ActionOptions.ADD || ParentIntegrationState) {
      createLenderIntegration(data, {
        onSuccess: (response) => {
          reset();
          onClose();
        },
      });
    } else if (actionType === ActionOptions.EDIT) {
      updateLenderIntegration(data, {
        onSuccess: (response) => {
          reset();
          onClose();
        },
      });
    }
  };
  const onSaveConfirm = (e: any) => {
    handleSubmit(onSubmit)();
    setLeavePageState(false);
  };

  const onCancelConfirm = (e: any) => {
    navigate(".." + navigationState.location.pathname);
    if (navigationState.location.pathname == "/market-place/signup") {
      logout();
      localStorage.clear();
    }
    localStorage.setItem("DirtyState", "false");
  };
  usePrompt(
    "Leave screen?",
    isDirty,
    (dirty) => {
      setLeavePageState(dirty);
    },
    (tx) => {
      reset(getValues());
      setNavigationState(tx);
    }
  );
  return (
    <>
      <ConfirmationDialog
        openPopUp={leavePagePopupState}
        onConfirm={onSaveConfirm}
        onCancel={onCancelConfirm}
        setPopUpState={setLeavePageState}
        confirmationTitle={DialogMessages.saveTitle}
        confirmationText={DialogMessages.saveText}
        primaryBtnText={DialogMessages.saveBtnText}
        icon={<Icon name="DialogSaveIcon" />}
      />
      <PersistentDrawer
        title={title}
        openPopUp={openPopUp}
        setPopUpState={setPopUpState}
        theme={theme}
        enabledPin={true}
        children={
          <>
            <form>
              <Controller
                name="integration_type"
                control={control}
                rules={validation("Integration Type", true)}
                defaultValue={""}
                render={({ field: { onChange, value } }) => (
                  <Select
                    theme={theme}
                    label={"Integration Type"}
                    placeholder={"Select"}
                    items={integrationOptions}
                    onChange={(e) => {
                      integrationUpdate(e.target.value);
                      onChange(e);
                    }}
                    value={value ?? ""}
                    sxProps={undefined}
                    selectError={errors?.integration_type?.message}
                    disabled={actionType === ActionOptions.EDIT}
                  />
                )}
              />
              <Controller
                name="route"
                control={control}
                defaultValue={"Hubex"}
                render={({ field: { value, onChange } }) => (
                  <Select
                    theme={theme}
                    label={"Route"}
                    placeholder={"Select"}
                    items={[{ text: "Hubex", value: "Hubex" }]}
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                    }}
                  />
                )}
              />
              <Controller
                name="provider_name"
                control={control}
                defaultValue={""}
                rules={validation("Provider", true)}
                render={({ field: { onChange, value } }) => (
                  <Select
                    theme={theme}
                    label={"Provider"}
                    items={providerState}
                    value={value}
                    onChange={(e, providerState) => {
                      providerValueChange(e.target.value, providerState);
                      onChange(e);
                    }}
                    sxProps={undefined}
                    selectError={errors?.provider_name?.message}
                    disabled={
                      actionType === ActionOptions.EDIT &&
                      !ParentIntegrationState
                    }
                  />
                )}
              />
              {inputFields &&
                inputFields?.map((inputField, index) => {
                  return (
                    <Controller
                      key={index}
                      name={inputField.name}
                      control={control}
                      defaultValue={""}
                      rules={validation(inputField.label, true)}
                      render={({ field }) => (
                        <Input
                          theme={theme}
                          fullWidth={true}
                          label={inputField.label}
                          placeholder={"Type here..."}
                          type="text"
                          {...field}
                          value={field.value}
                          error={errors?.[inputField.name]?.message}
                          // onChange={(e) => {
                          //     setUserInput(e.target.value)
                          //     console.log("User Input => ",userInput)
                          // }}
                        />
                      )}
                    />
                  );
                })}
              <Controller
                name="is_active"
                control={control}
                defaultValue={true}
                render={({ field: { value, onChange } }) => (
                  <Select
                    theme={theme}
                    label={"Status"}
                    placeholder={"Select"}
                    items={[
                      { text: "Enabled", value: true },
                      { text: "Disabled", value: false },
                    ]}
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                    }}
                    sxProps={undefined}
                  />
                )}
              />
            </form>
          </>
        }
        customFooter={
          <>
            <Button
              theme={theme}
              primary
              type="submit"
              text={
                watch("provider_name") === "Google" ? "Test & Save" : "Save"
              }
              fullWidth
              onClick={handleSubmit(testConnection)}
            />
          </>
        }
      />
    </>
  );
};
export default AddNewIntegration;

export const ACCESS_TOKEN_KEY = "access_token";
export const PASSWORD_REGEX =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z]).{6,35}$/;
export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const ZIP_CODE_REGEX = /^\d{5,5}?(-\d{4})?$/;
export const WEBSITE_REGEX =
  /(http:\/\/|https:\/\/|www\.|[a-zA-Z])+([a-zA-Z0-9-]+\.){1,}[a-zA-Z]{2,6}/;
export const EXCEL_FILE_URL =
  "https://unitydmsbucket.s3.us-east-1.amazonaws.com/Asset_Catalogue_Template.xlsx";
export const INVENTORY_EXCEL_FILE_URL =
  "https://unitydmsbucket.s3.us-east-1.amazonaws.com/Inventory_Template.xlsx";
export const NEXT_SCREEN_URL = `/market-place/app-user-profile`;

import { FC, useEffect, useState } from "react";
import { INewAssetUsagePopUp } from "./AddNewAssetUsage.type";
import {
  PersistentDrawer,
  Icon,
  Input,
  Select,
  Button,
} from "@ntpkunity/controls";
import { ConfirmationDialog } from "shared/components";
import { useTheme } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import { IAssetUsage } from "Interface";
import { logout } from "@ntpkunity/controls-ums";
import {
  ActionOptions,
  ValidationMessages,
  QueryKeys,
  DialogMessages,
} from "Enums";
import {
  UseMutation_AddAssetUsage,
  UseMutation_UpdateAssetUsage,
} from "services";
import { useQueryClient } from "react-query";
import { usePrompt } from "utilities/usePrompt";
import { useStoreContext } from "Stores/SetupStore/Store";
const AddNewAssetUsage: FC<INewAssetUsagePopUp> = ({
  openPopUp,
  setPopUpState,
  title,
  actionType,
  AssetUsageData,
  enabledPin,
}) => {
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    setError,
    reset,
    formState: { errors, isDirty },
  } = useForm<IAssetUsage>();
  const navigate = useNavigate();
  const { actions } = useStoreContext();
  const theme = useTheme();
  const { mutate: AddAssetUsage } = UseMutation_AddAssetUsage();
  const { mutate: UpdateAssetUsage } = UseMutation_UpdateAssetUsage(
    getValues("id")
  );
  const [confirmationPopupState, setConfirmationPopupState] =
    useState<boolean>(true);
  const [leavePagePopupState, setLeavePageState] = useState<boolean>(false);
  const [navigationState, setNavigationState] = useState<any>();
  const queryClient = useQueryClient();
  const lenderProfile: any = queryClient.getQueryData(
    QueryKeys.GET_LENDER_PROFILE
  );

  useEffect(() => {
    if (actionType !== ActionOptions.ADD) {
      setValue("id", AssetUsageData?.id);
    }
    setValue("code", AssetUsageData?.code);
    setValue("description", AssetUsageData?.description);
    setValue("is_active", AssetUsageData ? AssetUsageData.is_active : true);
    setValue("lender_id", AssetUsageData?.lender_id);
  }, [AssetUsageData]);

  const onSubmit = (data: IAssetUsage) => {
    data.lender_id = lenderProfile?.id;
    if (data.id && data.id > 0) {
      UpdateAssetUsage(data, {
        onSuccess: (response) => {
          reset();
          onClose();
        },
        onError: (error) => {
          if (
            error?.response?.data?.detail?.message ===
            "Code and Description should be unique"
          ) {
            setError("code", { message: "Code should be unique" });
            setError("description", {
              message: "Description should be unique",
            });
            return;
          } else if (
            error?.response?.data?.detail?.message === "Code should be unique"
          ) {
            setError("code", { message: "Code should be unique" });
            return;
          }
          if (
            error?.response?.data?.detail?.message ===
            "Description should be unique"
          ) {
            setError("description", {
              message: "Description should be unique",
            });
            return;
          } else {
            onClose();
            actions.setToast({
              toastMessage: error?.response?.data?.detail?.message,
              toastState: true,
              variant: "error",
            });
          }
        },
      });
    } else {
      AddAssetUsage(data, {
        onSuccess: (response) => {
          reset();
          onClose();
        },
        onError: (error) => {
          if (
            error?.response?.data?.detail?.message ===
            "Code and Description should be unique"
          ) {
            setError("code", { message: "Code should be unique" });
            setError("description", {
              message: "Description should be unique",
            });
            return;
          } else if (
            error?.response?.data?.detail?.message === "Code should be unique"
          ) {
            setError("code", { message: "Code should be unique" });
            return;
          }
          if (
            error?.response?.data?.detail?.message ===
            "Description should be unique"
          ) {
            setError("description", {
              message: "Description should be unique",
            });
            return;
          } else {
            onClose();
            actions.setToast({
              toastMessage: error?.response?.data?.detail?.message,
              toastState: true,
              variant: "error",
            });
          }
        },
      });
    }
  };
  const onClose = () => {
    let children = document.querySelectorAll(".drawer-header .pinned");
    if (children.length == 0) {
      setPopUpState(false);
    }
  };
  const onSaveConfirm = (e: any) => {
    handleSubmit(onSubmit)();
    setLeavePageState(false);
  };
  const onCancelConfirm = (e: any) => {
    navigate(".." + navigationState.location.pathname);
    if (navigationState.location.pathname == "/market-place/signup") {
      logout();
      localStorage.clear();
    }
    localStorage.setItem("DirtyState", "false");
  };
  usePrompt(
    "Leave screen?",
    isDirty,
    (data) => {
      setLeavePageState(data);
      localStorage.setItem("DirtyState", data);
    },
    (tx) => {
      reset(getValues());
      setNavigationState(tx);
    }
  );
  return (
    <>
      <ConfirmationDialog
        openPopUp={leavePagePopupState}
        onConfirm={onSaveConfirm}
        onCancel={onCancelConfirm}
        setPopUpState={setLeavePageState}
        confirmationTitle={DialogMessages.saveTitle}
        confirmationText={DialogMessages.saveText}
        primaryBtnText={DialogMessages.saveBtnText}
        icon={<Icon name="DialogSaveIcon" />}
      />
      <PersistentDrawer
        title={title}
        openPopUp={openPopUp}
        setPopUpState={setPopUpState}
        theme={theme}
        enabledPin={true}
        children={
          <>
            <Controller
              name="code"
              control={control}
              rules={{
                maxLength: {
                  value: 5,
                  message: ValidationMessages.ASSET_CONDITION_CODE_MAX_LENGTH,
                },
                pattern: {
                  value: /^[^\s]/,
                  message: ValidationMessages.CODE_REQUIRED,
                },
                required: ValidationMessages.CODE_REQUIRED,
              }}
              render={({ field: { onChange, value } }) => (
                <Input
                  theme={theme}
                  fullWidth={true}
                  placeholder={"Type here..."}
                  label={"Code"}
                  type="text"
                  value={value?.trimStart()}
                  onChange={onChange}
                  error={errors?.code?.message}
                />
              )}
            />
            <Controller
              name="description"
              control={control}
              rules={{
                required: ValidationMessages.DESCRIPTION_REQUIRED,
                pattern: {
                  value: /^[^\s]/,
                  message: ValidationMessages.DESCRIPTION_REQUIRED,
                },
              }}
              render={({ field: { value, onChange } }) => (
                <Input
                  theme={theme}
                  fullWidth={true}
                  label={"Description"}
                  placeholder={"Description"}
                  value={value?.trimStart()}
                  onChange={onChange}
                  error={errors?.description?.message}
                  type="text"
                />
              )}
            />
            <Controller
              name="is_active"
              control={control}
              defaultValue={true}
              render={({ field: { value, onChange } }) => (
                <Select
                  theme={theme}
                  label={"Status"}
                  placeholder={"Select"}
                  items={[
                    { text: "Enabled", value: true },
                    { text: "Disabled", value: false },
                  ]}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </>
        }
        customFooter={
          <Button
            theme={theme}
            primary
            text="Save"
            fullWidth
            onClick={handleSubmit(onSubmit)}
          />
        }
      />
    </>
  );
};
export default AddNewAssetUsage;

import { FC, useEffect, useState } from "react";
import { useTheme, ToggleButton, styled } from "@mui/material";
import {
  Box,
  Grid,
  Icon,
  Select,
  Typography,
  Autocomplete,
  PhoneInput,
  Button,
  Input,
  IBoxProps,
  Tooltip,
} from "@ntpkunity/controls";
import { Controller } from "react-hook-form";
import { AutoSearch } from "shared/components";
import { IGeneralAddress } from "./general.type";
import { AddressType, QueryKeys, ValidationMessages } from "Enums";
import { useQueryClient } from "react-query";
import { ICountry, IStates } from "Interface";


const AddressLayoutWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.address-layout-wrap': {
    '.u-form-group': {
      '.u-form-control': {
        '&.MuiInputBase-adornedEnd': {
          '.styled-tooltip': {
            height: 24,
            '.MuiTooltip-popper': {
              '&[data-popper-placement*="bottom"]': {
                '.MuiTooltip-arrow': {
                  transform: 'none !important',
                  marginLeft: 7
                }
              },
              '&[data-popper-placement*="left"]': {
                marginTop: '-7px !important'
              }
            }
          },
        }
      }
    },
    '.tooltip-content': {
      '.fw-bold': {
        fontWeight: theme.typography.fontWeightBold
      }
    }
  }
}))

const MailingAdress: FC<IGeneralAddress> = ({
  control,
  errors,
  verifyAddress,
  mailingAddressState,
  setMailingAddressState,
  googleAPIKey,
  isAddressValidationActive,
  lenderCountries,
  setValue,
  setConcatMailingAddress,
  setFormValue,
  hasAddressTooltip,
  tooltipPlacement = "left"
}) => {
  const [open, setOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<ICountry>({
    id: null,
    code: "",
    name: "",
    country_code: "",
    address_template: null,
  });
  const queryClient = useQueryClient();

  const usaStates: Array<IStates> = queryClient.getQueryData(
    QueryKeys.GET_ALL_USA_STATES
  );
  const lenderProfile: any = queryClient.getQueryData(
    QueryKeys.GET_LENDER_PROFILE
  );
  useEffect(() => {
    if (lenderProfile?.addresses) {
      let country_id = lenderProfile.addresses?.find(
        (x) => x.address_type == AddressType.MAILING
      )?.country_id;
      if (country_id && lenderCountries?.find((x) => x?.id == country_id)) {
        setSelectedCountry(lenderCountries?.find((x) => x?.id == country_id));
        setFormValue(
          "MailingAddress",
          lenderProfile.addresses?.find(
            (x) => x.address_type == AddressType.MAILING
          )
        );
      } else {
        setSelectedCountry(lenderCountries ? lenderCountries?.[0] : null);
      }
    } else {
      setSelectedCountry(lenderCountries ? lenderCountries?.[0] : null);
    }
  }, [lenderProfile]);
  useEffect(() => {
    if (lenderCountries?.length > 0 && selectedCountry) {
      setValue("MailingAddress.country_id", {
        label: selectedCountry?.name,
        id: selectedCountry?.id,
      });
    } else {
      setValue("MailingAddress.country_id", { label: "", id: "" });
      setConcatMailingAddress("");
    }
  }, [selectedCountry]);
  const verifyMailingAddress = () => {
    //Verify address from google api
    verifyAddress(AddressType.MAILING);
  };
  const resetMailingAddress = () => {
    setValue("MailingAddress.address_line_1", "");
    setValue("MailingAddress.address_line_2", "");
    setValue("MailingAddress.city", "");
    setValue("MailingAddress.contact_number", "");
    setValue("MailingAddress.county", "");
    setValue("MailingAddress.zip_code", "");
    setValue("MailingAddress.state_id", "");
    setValue("MailingAddress.state_name", "");
    setValue("MailingAddress.field5", "");
    setValue("MailingAddress.field6", "");
    setValue("MailingAddress.verified", false);
    setMailingAddressState(false);
  };
  const theme = useTheme();
  return (
    <AddressLayoutWrap theme={theme} className="address-layout-wrap">
      <Grid theme={theme} container item spacing={3}>
        <Grid theme={theme} item xs={12}>
          <AutoSearch theme={theme} className="auto-search">
            <Typography
              variant="body2"
              component="span"
              sx={{ mr: 1 }}
              theme={theme}
            >
              Select Country:
            </Typography>
            <ToggleButton
              value="check"
              selected={open}
              onChange={() => {
                setOpen(!open);
              }}
            >
              {selectedCountry?.name}
              <Icon name="ChevronDown" />
            </ToggleButton>
            {open && (
              <Box theme={theme} className="list-wrap">
                <Controller
                  name={"MailingAddress.country_id"}
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      id="select_mailing_country"
                      theme={theme}
                      disablePortal={true}
                      capitalizeLabel={true}
                      open={open}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      defaultValue={
                        selectedCountry
                          ? {
                            label: selectedCountry?.name,
                            id: selectedCountry?.id,
                          }
                          : { label: "", id: "" }
                      }
                      onChange={(_e: any, value: any) => {
                        field.onChange(value);
                        setSelectedCountry(
                          lenderCountries.find((x) => x.id == value.id)
                        );
                        resetMailingAddress();
                        setConcatMailingAddress("");
                        setOpen(!open);
                      }}
                      value={field.value || null}
                      items={
                        lenderCountries
                          ? lenderCountries.map((x) => {
                            return { label: x?.name, id: x?.id };
                          })
                          : null
                      }
                      renderOption={(props, option, { selected }) => (
                        <li {...props} key={option.id}>
                          <span>{option.label}</span>
                        </li>
                      )}
                    />
                  )}
                />
              </Box>
            )}
          </AutoSearch>
        </Grid>
        <Grid theme={theme} item xs={12}>
          <Controller
            name={"MailingAddress.address_line_1"}
            control={control}
            rules={{
              required: ValidationMessages.ADDRESS_LINE_1,
            }}
            render={({ field: { onChange, value } }) => (
              <Input
                theme={theme}
                fullWidth={true}
                label={"Address Line 1"}
                placeholder="House number, street name"
                type="text"
                onChange={(e) => {
                  onChange(e);
                  setMailingAddressState(true);
                }}
                endAdornment={
                  <>
                    {hasAddressTooltip
                      ? (
                        <>
                          <Tooltip
                            placement={tooltipPlacement}
                            title={
                              <Box theme={theme} className='tooltip-content'>
                                <Typography theme={theme} variant='body2' component='span' display={'block'}>
                                  <span className='fw-bold'>Street Address:</span> 38-30 211th St.
                                </Typography>
                                <Typography theme={theme} variant='body2' component='span' display={'block'}>
                                  <span className='fw-bold'>PO Box:</span> PO Box 450
                                </Typography>
                                <Typography theme={theme} variant='body2' component='span' display={'block'}>
                                  <span className='fw-bold'>Rural Route:</span> RR 21 Box 450
                                </Typography>
                              </Box>
                            }
                            theme={theme}>
                            <Icon name="InfoIcon" />
                          </Tooltip>
                        </>
                      )
                      : ''
                    }
                  </>
                }
                value={value}
                error={errors?.MailingAddress?.address_line_1?.message}
              ></Input>
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={12}>
          <Controller
            name={"MailingAddress.address_line_2"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Input
                theme={theme}
                fullWidth={true}
                label={"Address Line 2 (Optional)"}
                placeholder="e.g. company, apartment, building..."
                type="text"
                onChange={(e) => {
                  onChange(e);
                  setMailingAddressState(true);
                }}
                endAdornment={
                  <>
                    {hasAddressTooltip
                      ? (
                        <>
                          <Tooltip
                            placement={tooltipPlacement}
                            title={
                              <Box theme={theme} className='tooltip-content'>
                                <Typography theme={theme} variant='body2' component='span' display={'block'}>
                                  <span className='fw-bold'>Apartment #:</span> Apt. #2
                                </Typography>
                                <Typography theme={theme} variant='body2' component='span' display={'block'}>
                                  <span className='fw-bold'>Suite #:</span>  Ste. #450
                                </Typography>
                              </Box>
                            }
                            theme={theme}>
                            <Icon name="InfoIcon" />
                          </Tooltip>
                        </>
                      )
                      : ''
                    }
                  </>
                }
                value={value}
              ></Input>
            )}
          />
        </Grid>
        {(selectedCountry?.address_template == null ||
          selectedCountry?.address_template?.field1_visible) && (
            <Grid theme={theme} item xs={3}>
              <Controller
                name={"MailingAddress.city"}
                control={control}
                rules={{
                  required: ValidationMessages.CITY_REQUIRED,
                }}
                render={({ field: { onChange, value } }) => (
                  <Input
                    theme={theme}
                    fullWidth={true}
                    label={
                      selectedCountry?.address_template?.field1_label
                        ? selectedCountry?.address_template?.field1_label
                        : "City"
                    }
                    type="text"
                    onChange={(e) => {
                      onChange(e);
                      setMailingAddressState(true);
                    }}
                    value={value}
                    error={
                      errors?.MailingAddress?.city?.message
                        ? (selectedCountry?.address_template?.field1_label
                          ? selectedCountry?.address_template?.field1_label
                          : "City") + " is required"
                        : ""
                    }
                  ></Input>
                )}
              />
            </Grid>
          )}
        {selectedCountry?.address_template?.field2_visible && (
          <Grid theme={theme} item xs={3}>
            <Controller
              name={"MailingAddress.state_id"}
              control={control}
              rules={{
                required: ValidationMessages.STATE_REQUIRED,
              }}
              render={({ field: { onChange, value } }) => (
                <Select
                  items={usaStates
                    ?.filter((x) => x.country_id === selectedCountry?.id)
                    ?.map((x) => {
                      return {
                        value: x.id,
                        text: x.name,
                      };
                    })}
                  theme={theme}
                  fullWidth={true}
                  label={selectedCountry?.address_template?.field2_label}
                  type="text"
                  onChange={(e) => {
                    onChange(e);
                    setMailingAddressState(true);
                  }}
                  value={value ?? ""}
                  sxProps={undefined}
                  selectError={
                    errors?.MailingAddress?.state_id?.message
                      ? selectedCountry?.address_template?.field2_label +
                      " is required"
                      : ""
                  }
                ></Select>
              )}
            />
          </Grid>
        )}
        {(selectedCountry?.address_template == null ||
          selectedCountry?.address_template?.field3_visible) && (
            <Grid theme={theme} item xs={3}>
              <Controller
                name={"MailingAddress.zip_code"}
                control={control}
                rules={{
                  required: {
                    value: selectedCountry?.address_template?.field3_visible,
                    message: ValidationMessages.ZIP_CODE_REQUIRED,
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <Input
                    theme={theme}
                    fullWidth={true}
                    label={
                      selectedCountry?.address_template?.field3_label
                        ? selectedCountry?.address_template?.field3_label
                        : "Postal Code"
                    }
                    type="text"
                    placeholder="Type here..."
                    onChange={(e) => {
                      onChange(e);
                      setMailingAddressState(true);
                    }}
                    value={value}
                    error={
                      errors?.MailingAddress?.zip_code?.message
                        ? (selectedCountry?.address_template?.field3_label
                          ? selectedCountry?.address_template?.field3_label
                          : "Postal Code") + " is required"
                        : ""
                    }
                  ></Input>
                )}
              />
            </Grid>
          )}
        {selectedCountry?.address_template?.field4_visible && (
          <Grid theme={theme} item xs={3}>
            <Controller
              name={"MailingAddress.county"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <Input
                  theme={theme}
                  fullWidth={true}
                  label={selectedCountry?.address_template?.field4_label}
                  disabled={isAddressValidationActive}
                  type="text"
                  onChange={onChange}
                  value={value}
                ></Input>
              )}
            />
          </Grid>
        )}
        {selectedCountry?.address_template == null && (
          <Grid theme={theme} item xs={3}>
            <Controller
              name={"MailingAddress.state_name"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <Input
                  theme={theme}
                  fullWidth={true}
                  label={"State/Province/Region"}
                  type="text"
                  onChange={onChange}
                  value={value}
                ></Input>
              )}
            />
          </Grid>
        )}
        {selectedCountry?.address_template?.field5_visible && (
          <Grid theme={theme} item xs={3}>
            <Controller
              name={"MailingAddress.field5"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <Input
                  theme={theme}
                  fullWidth={true}
                  label={selectedCountry?.address_template?.field5_label}
                  type="text"
                  onChange={onChange}
                  value={value}
                ></Input>
              )}
            />
          </Grid>
        )}
        {selectedCountry?.address_template?.field6_visible && (
          <Grid theme={theme} item xs={3}>
            <Controller
              name={"MailingAddress.field6"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <Input
                  theme={theme}
                  fullWidth={true}
                  label={selectedCountry?.address_template?.field6_label}
                  type="text"
                  onChange={onChange}
                  value={value}
                ></Input>
              )}
            />
          </Grid>
        )}
        <Grid theme={theme} item xs={3}>
          {selectedCountry?.country_code && (
            <Controller
              name={"MailingAddress.contact_number"}
              control={control}
              rules={{
                validate: {
                  minLength: (value) => {
                    if (selectedCountry?.country_code)
                      return (
                        parseInt(value) >
                        selectedCountry?.country_code.length ||
                        ValidationMessages.CONTACT_NUMBER_REQUIRED
                      );
                    else if (value)
                      return (
                        value.length > 1 ||
                        ValidationMessages.CONTACT_NUMBER_REQUIRED
                      );
                  },
                },
              }}
              render={({ field }) => (
                <PhoneInput
                  theme={theme}
                  type={"text"}
                  {...field}
                  label={"Contact Number"}
                  fullWidth
                  phoneCountryCode={selectedCountry?.country_code}
                  error={errors?.MailingAddress?.contact_number?.message}
                />
              )}
            />
          )}
        </Grid>
        <Grid theme={theme} item>
          {mailingAddressState && googleAPIKey && (
            <Button
              theme={theme}
              secondary
              text={"Verify Address"}
              onClick={verifyMailingAddress}
            />
          )}
        </Grid>
      </Grid>
    </AddressLayoutWrap>
  );
};
export default MailingAdress;

import { FC, useState, useEffect, useRef } from "react";
import { useTheme } from "@mui/material";
import { Input, Box, Button } from "shared/components";
import { PersistentDrawer } from "@ntpkunity/controls";
import { Controller, useForm } from "react-hook-form";
import { ActionOptions, QueryKeys } from "Enums";
import { IIntegrationTypePopup } from "./EditProviderIntegration.type";
import { useQueryClient } from "react-query";
import {
  usePenSetDealer,
  useStripeRegister,
  useStripeAccountLink,
  useGetStripeAccountById,
} from "services/integration-api.service";
import { useStoreContext } from "Stores/SetupStore/Store";

import {
  useUpdateDealerProfilebyId,
  useUpdatePenDealerId,
  useUpdateshiftDigitalDealerId,
  UseQuery_getAllStates,
  useUpdateStripeConnectId,
  useGetDealerProfileByDealerId,
  useUnregisterDealerPen,
} from "services/dealer-configurations.service";
import { useDeleteAllFinancialInsuranceByDealerId } from "services/financial-insurance.service";
import { validation } from "shared/helper/methods";

const controlTypes = {
  BUTTON: "button",
};

const userInputName = {
  STRIPE_REG: "stripe_reg_button",
  DEALER_CODE: "dealer_code",
  REGISTER_FNI: "pen_reg_button",
  UPDATE_FNI: "update_reg_button",
  UNREGISTER_FNI: "de_register_button",
};

const EditProvider: FC<IIntegrationTypePopup> = ({
  openPopUp,
  setPopUpState,
  title,
  data,
  dealer_profile_data,
  dealer_code,
  isProfileDirty,
  penDealerId,
  setPenDealerId,
  form,
  setDetailSubmitted,
  detailedSubmited,
}) => {
  const theme = useTheme();
  UseQuery_getAllStates();
  const queryClient = useQueryClient();
  const StatesData: any = queryClient.getQueryData(QueryKeys.GET_ALL_STATES);
  const dealerIntegration: any = queryClient.getQueryData([
    QueryKeys.GET_ALL_DEALER_INTEGRATION_FILTER,
    dealer_code,
  ]);
  const [inputFields, setInputFields] = useState<any>(data);
  const [provider, setProvider] = useState<any>();
  const [showButton, setShowButton] = useState<boolean>(true);
  const [stripeAccountLinkCreated, setStripeAccountLinkCreated] =
    useState<boolean>(false);
  const {
    mutate: updateStripeConnectId,
    isLoading: updateStripeConnectIdLoading,
  } = useUpdateStripeConnectId();
  const { mutate: getStripeAccountID, isLoading: stipeRegisterLoading } =
    useStripeRegister();
  const { mutate: stripeAccountLink, isLoading: stripeAccountLinkLoading } =
    useStripeAccountLink();

  const JsonData = {
    intergration_types: [
      {
        integration_type: "Payment Processing",
        provider_name: [
          {
            name: "Stripe Connect",
            tooltip: "Register with Stripe Connect",
            userInput: [
              {
                name: "stripe_reg_button",
                value: "value",
                dataType: "",
                label:
                  updateStripeConnectIdLoading ||
                  stipeRegisterLoading ||
                  stripeAccountLinkLoading
                    ? "Processing"
                    : "Registration",
                controlType: "button",
              },
            ],
          },
        ],
      },
      {
        integration_type: "Inventory Uplaod",
        provider_name: [
          {
            name: "Shift Digital",
            tooltip: "Provide Shift Digital Dealer Code",
            userInput: [
              {
                name: "dealer_code",
                value: "",
                dataType: "text",
                label: "Shift Digital Dealer Code",
                controlType: "input",
              },
            ],
          },
        ],
      },
      {
        integration_type: "F&I",
        provider_name: [
          {
            name: "Provider Exchange Network",
            tooltip: "Register with PEN",
            userInput: [
              {
                name: "pen_reg_button",
                value: "value",
                dataType: "",
                label: "Register with PEN",
                controlType: "button",
              },
              {
                name: "update_reg_button",
                value: "value",
                dataType: "",
                label: "Update Info with PEN",
                controlType: "button",
              },
              {
                name: "de_register_button",
                value: "value",
                dataType: "",
                label: "Un-register with PEN",
                controlType: "button",
              },
            ],
          },
        ],
      },
    ],
  };
  const [jsonObj, setJsonObject] = useState(
    JSON.parse(JSON.stringify(JsonData)).intergration_types
  );
  useEffect(() => {
    jsonObj?.map((x) => {
      x.provider_name
        ?.filter((x) => x?.name == data?.provider_name)
        .map((provider) => {
          setProvider(provider);
          return;
        });
    });
  }, []);
  useEffect(() => {
    if (provider?.userInput?.[0].controlType === controlTypes.BUTTON) {
      setShowButton(false);
    } else {
      setShowButton(true);
    }
  }, [provider]);

  const [stripeDealerId, setStripeDealerId] = useState("");
  const { mutate: setDealer } = usePenSetDealer();
  const { actions } = useStoreContext();
  // const { mutate: updateDealerProfilebyId } = useUpdateDealerProfilebyId();
  const { mutate: updatePenDealerId } = useUpdatePenDealerId();
  const { mutate: unRegisterDealerPen } = useUnregisterDealerPen();
  const { mutate: deleteAllFnI } = useDeleteAllFinancialInsuranceByDealerId();
  const { mutate: updateShiftDigitalDealerId } =
    useUpdateshiftDigitalDealerId();
  const { mutate: getStripeAccountById } = useGetStripeAccountById();

  const {
    mutate: getDealerrProfileByDealerId,
    isLoading: dealerProfileLoading,
  } = useGetDealerProfileByDealerId();
  const URL = useRef("");
  const [dealerCodeInputField, setdealerCodeInputField] = useState("");
  const [dealerCodeInputFieldError, setdealerCodeInputFieldError] =
    useState("");
  const [stateCode, setStateCode] = useState(null);
  useEffect(() => {
    const state = StatesData?.filter(
      (state) => state.id === dealer_profile_data?.dealer_address?.state_id
    );
    setStateCode(state && state.length > 0 ? state[0].code : null);
  }, [StatesData, dealer_profile_data?.pen_dealer_id]);
  useEffect(() => {
    setInputFields(data);
    // getDealerrProfileByDealerId(
    //   { dealer_code: dealer_code },
    //   {
    //     onSuccess(response) {
    //       setValue("dealer_code", response.shift_digital_dealer_code);
    //     },
    //   }
    // );
  }, [data]);

  useEffect(() => {
    getDealerrProfileByDealerId(
      { dealer_id: dealer_code },
      {
        onSuccess(response) {
          setValue("dealer_code", response.shift_digital_dealer_id);
          setdealerCodeInputField(response.shift_digital_dealer_id);
          setPenDealerId(response?.pen_dealer_id);
          const stripeDealerId = response?.stripe_dealer_id;
          if (stripeDealerId && data?.provider_name === "Stripe Connect") {
            const accountData = {
              payload: {
                type: "account_onboarding",
                account_id: stripeDealerId,
                return_url: `${process.env.NEXT_SCREEN_BASE_URL}/configuration/dealer-configurations`,
                refresh_url: `${process.env.NEXT_SCREEN_BASE_URL}/configuration/dealer-configurations`,
              },
              stripeApiKey:
                inputFields?.lender_integration_user_inputs[0]
                  ?.user_input_value,
            };
            stripeAccountLink(accountData, {
              onSuccess(response: any) {
                setStripeAccountLinkCreated(true);
                URL.current = response?.url;
              },
            });
          }
        },
      }
    );
  }, []);

  function UserGreeting() {
    if (showButton) {
      return (
        <Button
          primary
          type="submit"
          text="Save Changes"
          fullWidth
          onClick={() => HandleClick()}
        />
      );
    } else <></>;
  }

  const { control, setValue } = useForm<any>();
  const onClose = () => {
    setPopUpState(false);
  };

  const HandleClick = () => {
    if (
      dealerCodeInputField == undefined ||
      dealerCodeInputField == null ||
      dealerCodeInputField == ""
    ) {
      setdealerCodeInputFieldError("Shift Digital Dealer Code is required");
      return;
    } else {
      setdealerCodeInputFieldError("");
    }
    updateShiftDigitalDealerId(
      {
        dealer_code: dealer_code,
        shift_dealer_code: dealerCodeInputField,
      },
      {
        onSuccess() {
          actions.setToast({
            toastMessage:
              "Shift Digital Dealer Code has Been Saved Successfully",
            toastState: true,
          });
          onClose();
        },
      }
    );
  };

  const stripeReg = (data: any) => {
    getStripeAccountID(data, {
      onSuccess(response: any) {
        if (response) {
          const stripeDealer = response.id;
          updateStripeConnectId(
            {
              dealer_code: dealer_code,
              stripe_connect_id: response.id,
            },
            {
              onSuccess() {
                actions.setToast({
                  toastMessage: "Stripe Account ID has been Saved successfully",
                  toastState: true,
                });
                // onClose();
                const accountData = {
                  payload: {
                    type: "account_onboarding",
                    account_id: stripeDealer,
                    return_url: `${process.env.NEXT_SCREEN_BASE_URL}/configuration/dealer-configurations`,
                    refresh_url: `${process.env.NEXT_SCREEN_BASE_URL}/configuration/dealer-configurations`,
                  },
                  stripeApiKey:
                    inputFields?.lender_integration_user_inputs[0]
                      ?.user_input_value,
                };
                const request = {
                  account_id: response?.id,
                  stripeApiKey:
                    inputFields?.lender_integration_user_inputs[0]
                      ?.user_input_value,
                };
                getStripeAccountById(request, {
                  onSuccess(response: any) {
                    {
                      setDetailSubmitted(response.details_submitted);
                    }
                  },
                });
                stripeAccountLink(accountData, {
                  onSuccess(response: any) {
                    setStripeAccountLinkCreated(true);
                    URL.current = response?.url;
                  },
                });
              },
            }
          );
        } else {
          actions.setToast({
            toastMessage: response?.error?.description,
            toastState: true,
            variant: "error",
          });
          return;
        }
      },
    });
  };
  const shiftDigital = () => {};

  const registerFnI = () => {
    const penRegisterData = {
      action: "REGISTER",
      address1: dealer_profile_data?.dealer_address?.address_line_1,
      address2: dealer_profile_data?.dealer_address?.address_line_2,
      city: dealer_profile_data?.dealer_address?.city,
      contactName: dealer_profile_data?.contact_person_name,
      penDealerId: 0,
      dealershipName: dealer_code,
      email: dealer_profile_data?.email,
      fax: dealer_profile_data?.fax_number,
      isTestDealer: false,
      observesDayLightSaving: dealer_profile_data?.day_light_saving,
      phone: dealer_profile_data?.contact_number,
      state: stateCode,
      timeZone: dealer_profile_data?.time_zone,
      webSite: dealer_profile_data?.website,
      zipCode: dealer_profile_data?.dealer_address?.zip_code,
      pen_user_name:
        inputFields?.lender_integration_user_inputs[0]?.user_input_value,
      pen_user_password:
        inputFields?.lender_integration_user_inputs[1]?.user_input_value,
      pen_token:
        inputFields?.lender_integration_user_inputs[2]?.user_input_value,
    };
    let is_data_missing = false;
    for (var i = 0; i < Object.values(penRegisterData).length; i++) {
      if (
        (Object.values(penRegisterData)[i] === null ||
          Object.values(penRegisterData)[i] === undefined ||
          Object.values(penRegisterData)[i] === "") &&
        Object.keys(penRegisterData)[i] !== "address2" &&
        Object.keys(penRegisterData)[i] !== "penDealerId" &&
        Object.keys(penRegisterData)[i] !== "isTestDealer" &&
        Object.keys(penRegisterData)[i] !== "fax"
      ) {
        is_data_missing = true;
        break;
      }
    }

    if (isProfileDirty) {
      actions.setToast({
        toastMessage:
          " Action can not be performed as there are unsaved changes on General tab of Dealer Profile",
        toastState: true,
        variant: "error",
      });
      return;
    } else if (is_data_missing) {
      actions.setToast({
        toastMessage:
          "Action can not be performed as mandatory information is missing on General tab of Dealer Profile",

        toastState: true,
        variant: "error",
      });

      return;
    }
    setDealer(
      { penRegisterData },
      {
        onSuccess(response: any) {
          if (response.success === "true") {
            setPenDealerId(response.dealer_id);

            updatePenDealerId(
              {
                dealer_code: dealer_code,
                pen_dealer_id: response.dealer_id,
              },
              {
                onSuccess() {
                  form.setValue("profile.pen_dealer_id", response.dealer_id);
                  actions.setToast({
                    toastMessage:
                      "The account has been registered successfully",
                    toastState: true,
                  });
                  onClose();
                },
              }
            );
          } else {
            actions.setToast({
              toastMessage: response?.error?.description,
              toastState: true,
              variant: "error",
            });
            return;
          }
        },
        onError(error) {
          actions.setToast({
            toastMessage: error?.error?.toString(),
            toastState: true,
            variant: "error",
          });
        },
      }
    );
  };

  const unRegisterFnI = () => {
    const penUnRegisterData = {
      action: "UNREGISTER",
      address1: dealer_profile_data?.dealer_address?.address_line_1,
      address2: dealer_profile_data?.dealer_address?.address_line_2,
      city: dealer_profile_data?.dealer_address?.city,
      contactName: dealer_profile_data?.contact_person_name,
      penDealerId: penDealerId,
      dealershipName: dealer_code,
      email: dealer_profile_data?.email,
      fax: dealer_profile_data?.fax_number,
      isTestDealer: true,
      observesDayLightSaving: dealer_profile_data?.day_light_saving,
      phone: dealer_profile_data?.contact_number,
      state: stateCode,
      timeZone: dealer_profile_data?.time_zone,
      webSite: dealer_profile_data?.website,
      zipCode: dealer_profile_data?.dealer_address.zip_code,
      pen_user_name:
        inputFields?.lender_integration_user_inputs[0]?.user_input_value,
      pen_user_password:
        inputFields?.lender_integration_user_inputs[1]?.user_input_value,
      pen_token:
        inputFields?.lender_integration_user_inputs[2]?.user_input_value,
    };

    let is_data_missing = false;
    for (var i = 0; i < Object.values(penUnRegisterData).length; i++) {
      if (
        (Object.values(penUnRegisterData)[i] === null ||
          Object.values(penUnRegisterData)[i] === undefined ||
          Object.values(penUnRegisterData)[i] === "") &&
        Object.keys(penUnRegisterData)[i] !== "address2" &&
        Object.keys(penUnRegisterData)[i] !== "penDealerId" &&
        Object.keys(penUnRegisterData)[i] !== "isTestDealer" &&
        Object.keys(penUnRegisterData)[i] !== "fax"
      ) {
        is_data_missing = true;
        break;
      }
    }

    if (isProfileDirty) {
      actions.setToast({
        toastMessage:
          "Action can not be performed as there are unsaved changes on General tab of Dealer Profile",
        toastState: true,
        variant: "error",
      });
      return;
    } else if (is_data_missing) {
      actions.setToast({
        toastMessage:
          "Action can not be performed as mandatory information is missing on General tab of Dealer Profile",
        toastState: true,
        variant: "error",
      });
      return;
    }
    setDealer(
      { penRegisterData: penUnRegisterData },
      {
        onSuccess(response: any) {
          if (response.success === "true") {
            setPenDealerId(null);
            unRegisterDealerPen(dealer_code);
            deleteAllFnI(dealer_code);
            actions.setToast({
              toastMessage: "Your account has been unregistered",
              toastState: true,
            });
            onClose();
          } else {
            actions.setToast({
              toastMessage: response?.error?.description,
              toastState: true,
              variant: "error",
            });
            return;
          }
        },
        onError(error) {
          actions.setToast({
            toastMessage: error?.error?.toString(),
            toastState: true,
            variant: "error",
          });
        },
      }
    );
  };

  const updateFnI = () => {
    const penUpdateData = {
      action: "UPDATE",
      address1: dealer_profile_data?.dealer_address?.address_line_1,
      address2: dealer_profile_data?.dealer_address?.address_line_2,
      city: dealer_profile_data?.dealer_address?.city,
      contactName: dealer_profile_data?.contact_person_name,
      penDealerId: penDealerId,
      dealershipName: dealer_code,
      email: dealer_profile_data?.email,
      fax: dealer_profile_data?.fax_number,
      isTestDealer: true,
      observesDayLightSaving: dealer_profile_data?.day_light_saving,
      phone: dealer_profile_data?.contact_number,
      state: stateCode,
      timeZone: dealer_profile_data?.time_zone,
      webSite: dealer_profile_data?.website,
      zipCode: dealer_profile_data?.dealer_address.zip_code,
      pen_user_name:
        inputFields?.lender_integration_user_inputs[0]?.user_input_value,
      pen_user_password:
        inputFields?.lender_integration_user_inputs[1]?.user_input_value,
      pen_token:
        inputFields?.lender_integration_user_inputs[2]?.user_input_value,
    };
    let is_data_missing = false;
    for (var i = 0; i < Object.values(penUpdateData).length; i++) {
      if (
        (Object.values(penUpdateData)[i] === null ||
          Object.values(penUpdateData)[i] === undefined ||
          Object.values(penUpdateData)[i] === "") &&
        Object.keys(penUpdateData)[i] !== "address2" &&
        Object.keys(penUpdateData)[i] !== "penDealerId" &&
        Object.keys(penUpdateData)[i] !== "isTestDealer" &&
        Object.keys(penUpdateData)[i] !== "fax"
      ) {
        is_data_missing = true;
        break;
      }
    }

    if (isProfileDirty) {
      actions.setToast({
        toastMessage:
          "Action can not be performed as there are unsaved changes on General tab of Dealer Profile",
        toastState: true,
        variant: "error",
      });
      return;
    } else if (is_data_missing) {
      actions.setToast({
        toastMessage:
          "Action can not be performed as mandatory information is missing on General tab of Dealer Profile",
        toastState: true,
        variant: "error",
      });
      return;
    }
    setDealer(
      { penRegisterData: penUpdateData },
      {
        onSuccess(response: any) {
          if (response.success === "true") {
            // User cannot update pen info until he saved the changes on dealer profile general tab
            // updateDealerProfilebyId(dealer_profile_data);
            actions.setToast({
              toastMessage: "The changes have been saved successfully",
              toastState: true,
            });
            onClose();
          } else {
            actions.setToast({
              toastMessage: response?.error?.description,
              toastState: true,
              variant: "error",
            });
            return;
          }
        },
        onError(error) {
          actions.setToast({
            toastMessage: error?.error?.toString(),
            toastState: true,
            variant: "error",
          });
        },
      }
    );
  };

  const handleDynamicClick = (inputName: string) => {
    switch (inputName) {
      case userInputName.STRIPE_REG:
        if (stripeAccountLinkCreated) {
          window.location.replace(URL.current);
        } else {
          const data = {
            payload: {
              type: "standard",
              metadata: {},
            },
            stripeApiKey:
              inputFields?.lender_integration_user_inputs[0]?.user_input_value,
          };
          stripeReg(data);
        }
        break;
      case userInputName.DEALER_CODE:
        shiftDigital();
        break;
      case userInputName.REGISTER_FNI:
        registerFnI();
        break;
      case userInputName.UPDATE_FNI:
        updateFnI();
        break;
      case userInputName.UNREGISTER_FNI:
        unRegisterFnI();
        break;
    }
  };
  return (
    <>
      <PersistentDrawer
        title={title}
        openPopUp={openPopUp}
        setPopUpState={setPopUpState}
        theme={theme}
        customFooter={<UserGreeting />}
      >
        <Controller
          name="intergrationType"
          control={control}
          defaultValue={inputFields?.integration_type}
          render={({ field }) => (
            <Input
              theme={theme}
              fullWidth
              label={"Integration Type"}
              type="text"
              disabled
              value={field.value}
              {...field}
            />
          )}
        />

        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: { sm: "1fr" },
            gap: 1,
          }}
        >
          <Controller
            name="provider"
            control={control}
            defaultValue={inputFields?.provider_name}
            render={({ field }) => (
              <Input
                theme={theme}
                fullWidth={true}
                label={"Provider"}
                type="text"
                disabled
                {...field}
              />
            )}
          />
          {provider?.userInput
            ?.filter((x) => {
              if (provider.name === "Provider Exchange Network") {
                if (penDealerId) {
                  return x.name != "pen_reg_button";
                } else if (
                  dealerIntegration?.find(
                    (x) =>
                      x?.LenderIntegration?.integration_type ==
                        "F&I Products" &&
                      x?.LenderIntegration?.provider_name ===
                        "Provider Exchange Network" &&
                      x?.LenderIntegration?.is_active
                  )
                ) {
                  return x.name == "pen_reg_button";
                }
              } else {
                return true;
              }
            })
            .map((item, index) => {
              const isLoading =
                updateStripeConnectIdLoading ||
                stipeRegisterLoading ||
                stripeAccountLinkLoading ||
                dealerProfileLoading;
              const label = detailedSubmited
                ? "Registered"
                : item.label !== "Registration"
                ? item.label
                : isLoading
                ? "Processing"
                : stripeAccountLinkCreated
                ? "Continue Registration"
                : "Registration";
              return item?.controlType == controlTypes.BUTTON ? (
                <Button
                  key={index}
                  primary
                  type="submit"
                  disabled={isLoading || detailedSubmited}
                  onClick={() => handleDynamicClick(item.name)}
                  text={label}
                  fullWidth
                />
              ) : (
                <Controller
                  key={index}
                  name={item.name}
                  control={control}
                  defaultValue={""}
                  rules={validation(item.label, true)}
                  render={({ field }) => (
                    <Input
                      theme={theme}
                      fullWidth={true}
                      label={item.label}
                      placeholder={"Type here..."}
                      type="text"
                      {...field}
                      value={field.value || ""}
                      onChange={(e) => {
                        setdealerCodeInputField(e);
                        field.onChange(e);
                      }}
                      error={dealerCodeInputFieldError}
                    />
                  )}
                />
              );
            })}
        </Box>
      </PersistentDrawer>
    </>
  );
};
export default EditProvider;

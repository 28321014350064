import { FC, useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import { Box, Button } from "@ntpkunity/controls";
import { DataTableWrap } from "shared/components";
import { ActionOptions } from "Enums";
import { IInterestChartCriteria } from "Interface";
import SubsidyTable from "./subsidy-table";
import { setOpenDrawer } from "Stores/SetupStore/Actions";
import { useSetupsStore } from "Stores/SetupStore/Store";
import { AddNewSubsidyCharts } from "components";

const SubsidyChart: FC = () => {
  const [interestChartState, setInterestChartState] = useState<boolean>(false);
  const [actionTitle, setActionTitle] = useState<string>("Add New Subsidy");
  const [actionType, setActionType] = useState<ActionOptions>(
    ActionOptions.ADD
  );
  const [actionData, setActionData] =
    useState<IInterestChartCriteria>(undefined);

  const [state, dispatch] = useSetupsStore();

  useEffect(() => {
    if (interestChartState === false) {
      dispatch(setOpenDrawer(false));
    }
  }, [interestChartState]);

  const handleSelection = (event, key, value) => {
    switch (key) {
      case ActionOptions.ADD:
        setActionType(ActionOptions.ADD_NEW_CHART);
        setActionTitle("Add New Chart");
        setActionData(value);
        setInterestChartState(true);
        dispatch(setOpenDrawer(true));
      default:
        return;
    }
  };
  const onOptionsClick = (key, value) => {
    switch (key) {
      case ActionOptions.ADD_NEW_CHART:
        const criteriaData = {
          chart: value,
          criteria: null,
          chartId: 0,
        };
        setActionType(ActionOptions.ADD_NEW_CHART);
        setActionTitle("Add New Criteria");
        setActionData(criteriaData);
        dispatch(setOpenDrawer(true));
        setInterestChartState(true);
        return;
      case ActionOptions.RENAME_CHART:
        const editData = {
          chart: value,
          criteria: null,
          chartId: 0,
        };
        setActionType(ActionOptions.RENAME_CHART);
        setActionTitle("Edit Chart");
        setActionData(editData);
        dispatch(setOpenDrawer(true));
        setInterestChartState(true);
        return;
      case ActionOptions.ADD_CRITERIA:
        const data = {
          chart: value,
          criteria: null,
          chartId: 0,
        };
        setActionType(ActionOptions.ADD_CRITERIA);
        setActionTitle("Add Criteria");
        setActionData(data);
        dispatch(setOpenDrawer(true));
        setInterestChartState(true);
        return;
      default:
        return;
    }
  };
  const onEditCriteria = (data) => {
    setActionType(ActionOptions.EDIT);
    setActionTitle("Edit Criteria");
    setActionData(data);
    dispatch(setOpenDrawer(true));
    setInterestChartState(true);
  };
  const theme = useTheme();
  return (
    <>
      <DataTableWrap className="table-tabs-pagination-button">
        <Box theme={theme} className="scroll">
          <Box className="scroll-hide sh-fixed-cell spr-border" theme={theme} />
        </Box>
        <SubsidyTable
          onOptionsClick={(key, value) => onOptionsClick(key, value)}
          onEditCriteria={(data) => onEditCriteria(data)}
        />
        <Button
          theme={theme}
          secondary
          fullWidth={true}
          text={"Add New Chart"}
          onClick={(e) => handleSelection(e, ActionOptions.ADD, undefined)}
        />
        {interestChartState && (
          <AddNewSubsidyCharts
            openPopUp={interestChartState}
            setPopUpState={setInterestChartState}
            title={actionTitle}
            actionType={actionType}
            subsidyChartData={actionData}
          />
        )}
      </DataTableWrap>
    </>
  );
};
export default SubsidyChart;

import { createContext } from "react";
import {
  IAssetCondition,
  IAssetUsage,
  ICreditRating,
  ICurrencies,
  IFinancedAmount,
  ILenderProfile,
  ITerms,
} from "Interface";

interface setupProps {
  assetConditions: IAssetCondition[];
  terms: ITerms[];
  creditRatings: ICreditRating[];
  currencies: ICurrencies[];
  lenderProfile: ILenderProfile;
  AllFinancedAmounts: IFinancedAmount[];
  assetUsages: IAssetUsage[];
}

const SetupContext = createContext<setupProps>({
  assetConditions: [],
  terms: [],
  creditRatings: [],
  currencies: [],
  lenderProfile: {
    name: "",
    trading_name: "",
    url: "",
    id: 0,
    email: "",
    tax_id: "",
    mileage_unit: "",
    minimum_financed_amount: 0,
    default_currency: "",
    preferred_currency_ids: "",
    existing_customer_check: "",
  },
  AllFinancedAmounts: [],
  assetUsages: [],
});

export default SetupContext;

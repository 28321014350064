import { FC, useState, useEffect } from "react";

import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useTheme } from "@mui/material";
import {
  Icon,
  Box,
  Button,
  Menu,
  Tooltip,
  Typography,
} from "@ntpkunity/controls";
import { ConfirmationDialog } from "shared/components";
import {
  UseQuery_GetAllAssetTrims,
  UseQuery_GetAllAssetMakes,
  UseQuery_GetAllAssetModels,
  useMutation_DeleteInterestChart,
} from "services";
import { useSetupsStore } from "Stores/SetupStore/Store";
import {
  IAssetCondition,
  IAssetMake,
  IAssetModel,
  IAssetTrim,
  IBusinessUnits,
  IOtherCosts,
  ICharts,
  ICreditRating,
  IInterestChartCriteria,
  IInterestCharts,
  ISelectItemList,
  IFinancedAmount,
  ITerms,
} from "Interface";
import { ActionOptions, DialogMessages, QueryKeys } from "Enums";
import { useQueryClient } from "react-query";
import { convertArray } from "shared/helper/methods";

interface InterestCriteriaProps {
  interestCriteria: IInterestCharts;
  chart: ICharts;
  onEditCriteria?: (data) => unknown;
  AssetCondition?: IAssetCondition[];
  BusinessUnit?: IBusinessUnits[];
  OtherCost?: IOtherCosts[];
  FinancedAmount?: IFinancedAmount[];
  terms?: ITerms[];
  creditRatings?: ICreditRating[];
}

const tableOptions: any = [
  {
    optionText: (
      <>
        <Icon className="menu-icon" name="EditIcon" />
        {ActionOptions.EDIT}
      </>
    ),
    optionkey: ActionOptions.EDIT,
    optionValue: {},
  },
  {
    optionText: (
      <>
        <Icon className="menu-icon" name="DeleteIcon" />
        {ActionOptions.DELETE}
      </>
    ),
    optionkey: ActionOptions.DELETE,
    optionValue: {},
  },
];

const NonAssetInterestCriteriaTable: FC<InterestCriteriaProps> = ({
  interestCriteria,
  chart,
  onEditCriteria,
  AssetCondition,
  BusinessUnit,
  OtherCost,
  FinancedAmount,
  terms,
  creditRatings,
}) => {
  UseQuery_GetAllAssetMakes();
  UseQuery_GetAllAssetModels();
  UseQuery_GetAllAssetTrims();
  const theme = useTheme();
  const [state, dispatch] = useSetupsStore();
  const queryClient = useQueryClient();
  const assetMakes: Array<IAssetMake> = queryClient.getQueryData(
    QueryKeys.GET_ALL_ASSET_MAKES
  );
  const assetModels: Array<IAssetModel> = queryClient.getQueryData(
    QueryKeys.GET_ALL_ASSET_MODELS
  );
  const assetTrims: Array<IAssetTrim> = queryClient.getQueryData(
    QueryKeys.GET_ALL_ASSET_TRIMS
  );
  const [termsArr, setTermsArr] = useState([]);
  const [assetConditionArr, setAssetConditionArr] = useState([]);
  const [creditRatingArr, setCreditRatingArr] = useState([]);
  const [confirmationPopupState, setConfirmationPopupState] =
    useState<boolean>(false);
  const { mutate: deleteInterestChart } = useMutation_DeleteInterestChart();
  const [actionData, setActionData] =
    useState<IInterestChartCriteria>(undefined);

  useEffect(() => {
    setTermsArr(interestCriteria?.terms);
    setAssetConditionArr(interestCriteria?.asset_condition);
    setCreditRatingArr(interestCriteria.credit_rating);
  }, [interestCriteria]);

  const [termsSelection, setTermsSelectionState] = useState<
    Array<ISelectItemList>
  >(
    terms
      ?.filter((x) => x.is_active)
      ?.map((term) => ({
        value: term.id,
        text: `${term.terms_from} - ${term.terms_to}`,
      }))
  );
  const [financedAmountSelection, setFinancedAmountSelection] = useState<
    Array<ISelectItemList>
  >(
    FinancedAmount?.filter((x) => x.is_active)?.map((financed) => ({
      value: financed.id,
      text: `${financed.financed_amount_from} - ${financed.financed_amount_to}`,
    }))
  );
  const [assetConditionSelection, setAssetConditionSelectionState] = useState<
    Array<ISelectItemList>
  >(
    AssetCondition?.filter((x) => x.is_active)?.map((asset) => ({
      value: asset.id,
      text: `${asset.description}`,
    }))
  );

  const [businessUnitSelection, setBusinessUnitSelectionState] = useState<
    Array<ISelectItemList>
  >(
    BusinessUnit?.filter((x) => x.is_active)?.map((business) => ({
      value: business.id,
      text: `${business.description}`,
    }))
  );

  const [otherCostSelection, setOtherCostSelectionState] = useState<
    Array<ISelectItemList>
  >(
    OtherCost?.filter((x) => x.is_active)?.map((cost) => ({
      value: cost.id,
      text: `${cost.description}`,
    }))
  );

  useEffect(() => {
    setTermsSelectionState(
      terms
        ?.filter((x) => x.is_active)
        ?.map((term) => ({
          value: term.id,
          text: `${term.terms_from} - ${term.terms_to}`,
        }))
    );

    setFinancedAmountSelection(
      FinancedAmount?.filter((x) => x.is_active)?.map((financed) => ({
        value: financed.id,
        text: `${financed.financed_amount_from} - ${financed.financed_amount_to}`,
      }))
    );

    setAssetConditionSelectionState(
      AssetCondition?.filter((x) => x.is_active)?.map((asset) => ({
        value: asset.id,
        text: `${asset.description}`,
      }))
    );
    setBusinessUnitSelectionState(
      BusinessUnit?.filter((x) => x.is_active)?.map((business) => ({
        value: business.id,
        text: `${business.description}`,
      }))
    );
    setOtherCostSelectionState(
      OtherCost?.filter((x) => x.is_active)?.map((code) => ({
        value: code.id,
        text: `${code.description}`,
      }))
    );
  }, [terms, AssetCondition, BusinessUnit, FinancedAmount, OtherCost]);
  const handleSelection = (event, key, value) => {
    switch (key) {
      case ActionOptions.EDIT:
        const data = {
          chart: chart,
          interestCriteria: value,
          chartId: 0,
        };
        onEditCriteria(data);
        return;
      case ActionOptions.DELETE:
        setActionData(value.id);
        setConfirmationPopupState(true);
        return;
      default:
        return;
    }
  };

  const onDeleteConfirm = (e: any) => {
    deleteInterestChart(actionData, {
      onSuccess: (data, variables, context) => {
        setActionData(undefined);
      },
    });
  };

  return (
    <>
      <TableRow className="child-tr level-two">
        <TableCell>
          <Box theme={theme} className="group-title"></Box>
        </TableCell>
        <TableCell>
          <Box theme={theme} display="flex" alignItems="center">
            <Tooltip
              theme={theme}
              title={
                <>
                  {interestCriteria?.cost_name
                    ? interestCriteria?.cost_name.join(", ")
                    : "-"}
                </>
              }
            >
              <Box theme={theme} display="flex" alignItems="center">
                {/* <Icon className="icon" name="InfoIcon" /> */}
                <Typography
                  theme={theme}
                  variant="body2"
                  sx={{ ml: 0.7 }}
                  component="span"
                >
                  {
                    <>
                      {interestCriteria?.cost_name
                        ? interestCriteria?.cost_name.join(", ")
                        : "-"}
                    </>
                  }
                </Typography>
              </Box>
            </Tooltip>
          </Box>
        </TableCell>
        <TableCell>
          <Box theme={theme} display="flex" alignItems="center">
            <Tooltip
              theme={theme}
              title={
                <>
                  {interestCriteria?.financed_amount
                    ? convertArray(interestCriteria?.financed_amount).join(", ")
                    : "-"}
                </>
              }
            >
              <Box theme={theme} display="flex" alignItems="center">
                {/* <Icon className="icon" name="InfoIcon" /> */}
                <Typography
                  theme={theme}
                  variant="body2"
                  sx={{ ml: 0.7 }}
                  component="span"
                >
                  {
                    <>
                      {interestCriteria?.financed_amount
                        ? convertArray(interestCriteria?.financed_amount).join(
                            ", "
                          )
                        : "-"}
                    </>
                  }
                </Typography>
              </Box>
            </Tooltip>
          </Box>
        </TableCell>
        <TableCell>
          <Box theme={theme} display="flex" alignItems="center">
            <Tooltip
              theme={theme}
              title={
                <>
                  {interestCriteria?.terms
                    ? interestCriteria?.terms.join(", ")
                    : "-"}
                </>
              }
            >
              <Box theme={theme} display="flex" alignItems="center">
                {/* <Icon className="icon" name="InfoIcon" /> */}
                <Typography
                  theme={theme}
                  variant="body2"
                  sx={{ ml: 0.7 }}
                  component="span"
                >
                  {
                    <>
                      {interestCriteria?.terms
                        ? interestCriteria?.terms.join(", ")
                        : "-"}
                    </>
                  }
                </Typography>
              </Box>
            </Tooltip>
          </Box>
        </TableCell>

        <TableCell>
          <Box theme={theme} display="flex" alignItems="center">
            <Tooltip
              theme={theme}
              title={
                <>
                  {interestCriteria?.business_units
                    ? interestCriteria?.business_units.join(", ")
                    : "-"}
                </>
              }
            >
              <Box theme={theme} display="flex" alignItems="center">
                {/* <Icon className="icon" name="InfoIcon" /> */}
                <Typography
                  theme={theme}
                  variant="body2"
                  sx={{ ml: 0.7 }}
                  component="span"
                >
                  {
                    <>
                      {interestCriteria?.business_units
                        ? interestCriteria?.business_units.join(", ")
                        : "-"}
                    </>
                  }
                </Typography>
              </Box>
            </Tooltip>
          </Box>
        </TableCell>
        <TableCell>
          {interestCriteria?.late_charge_inclusive_adder == true
            ? "Yes"
            : interestCriteria?.late_charge_inclusive_adder === false
            ? "No"
            : "-"}
        </TableCell>
        <TableCell>
          {interestCriteria?.property_tax_inclusive_adder == true
            ? "Yes"
            : interestCriteria?.property_tax_inclusive_adder === false
            ? "No"
            : "-"}
        </TableCell>
        <TableCell>
          {" "}
          {interestCriteria?.origination_fee_inclusive_adder === true
            ? "Yes"
            : interestCriteria?.origination_fee_inclusive_adder === false
            ? "No"
            : "-"}
        </TableCell>
        <TableCell>{`${
          interestCriteria.margin
            ? `${interestCriteria?.margin.toFixed(4)}% `
            : "-"
        }`}</TableCell>
        <TableCell>
          {" "}
          {interestCriteria.minimum_margin && interestCriteria.maximum_margin
            ? interestCriteria.minimum_margin.toFixed(4) +
              "% - " +
              interestCriteria.maximum_margin.toFixed(4) +
              "%"
            : "-"}
        </TableCell>
        <TableCell></TableCell>
        <TableCell className="action-cell fixed-cell">
          <Menu
            theme={theme}
            disablePortal
            options={tableOptions.map((option) => {
              return {
                optionText: option.optionText,
                optionkey: option.optionkey,
                optionValue: interestCriteria,
              };
            })}
            handleOptionClick={handleSelection}
            render={(onMenuSelection) => (
              <Button
                defaultBtn
                iconText={<Icon name="MoreIcon" />}
                onClick={onMenuSelection}
              />
            )}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          />
        </TableCell>
      </TableRow>

      {confirmationPopupState && (
        <ConfirmationDialog
          openPopUp={confirmationPopupState}
          onConfirm={onDeleteConfirm}
          setPopUpState={setConfirmationPopupState}
          confirmationTitle={DialogMessages.confirmationTitle}
          confirmationText={DialogMessages.confirmationText}
          primaryBtnText={DialogMessages.deleteBtnText}
          icon={<Icon name="DialogDeleteIcon" />}
        />
      )}
    </>
  );
};

export default NonAssetInterestCriteriaTable;

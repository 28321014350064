import { FC, useState, useEffect } from "react";
import { useTheme } from "@mui/material";
import { Box, Button } from "@ntpkunity/controls";
import { DataTableWrap } from "shared/components";
import { NewFinancedAmount } from "components";
import { ActionOptions } from "Enums";
import { IFinancedAmount } from "Interface";
import FinancedAmountTable from "./financed-amount-table";
import { IFinancedAmountProps } from "./financed-amount.type";
import { setOpenDrawer } from "Stores/SetupStore/Actions";
import { useSetupsStore } from "Stores/SetupStore/Store";

const FinancedAmount: FC<IFinancedAmountProps> = () => {
  const [financedAmountState, setFinancedAmountState] =
    useState<boolean>(false);
  const [actionTitle, setActionTitle] = useState<string>(
    "Add New Annual Mileage"
  );
  const [state, dispatch] = useSetupsStore();
  const [actionType, setActionType] = useState<ActionOptions>(
    ActionOptions.ADD
  );
  const [actionData, setActionData] = useState<IFinancedAmount>(undefined);

  useEffect(() => {
    if (financedAmountState === false) {
      dispatch(setOpenDrawer(false));
    }
  }, [financedAmountState]);

  const handleSelection = (event, key, value) => {
    switch (key) {
      case ActionOptions.ADD:
        setActionType(ActionOptions.ADD);
        setActionTitle("Add New Range");
        setActionData(value);
        setFinancedAmountState(true);
        dispatch(setOpenDrawer(true));
      default:
        return;
    }
  };
  const onEdit = (formData: IFinancedAmount) => {
    setActionType(ActionOptions.EDIT);
    setActionTitle("Edit Financed Amount");
    setActionData(formData);
    setFinancedAmountState(true);
    dispatch(setOpenDrawer(true));
  };
  const theme = useTheme();
  return (
    <>
      <DataTableWrap className="table-tabs-pagination-button">
        <Box theme={theme} className="scroll">
          <Box className="scroll-hide spr-border" theme={theme} />
        </Box>
        <FinancedAmountTable onEdit={(data) => onEdit(data)} />
        <Button
          theme={theme}
          secondary
          fullWidth={true}
          text={"Add New Financed Amount Range"}
          onClick={(e) => handleSelection(e, ActionOptions.ADD, undefined)}
        />
        {financedAmountState && (
          <NewFinancedAmount
            openPopUp={financedAmountState}
            setPopUpState={setFinancedAmountState}
            title={actionTitle}
            actionType={actionType}
            financedAmountData={actionData}
          />
        )}
      </DataTableWrap>
    </>
  );
};
export default FinancedAmount;

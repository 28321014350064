import { FC, useState, useEffect } from "react";
import { useTheme } from "@mui/material";
import {
  Icon,
  PersistentDrawer,
  Input,
  Select,
  Button,
} from "@ntpkunity/controls";
import { ConfirmationDialog } from "shared/components";
import { INewOtherCostPopup } from "./AddNewCost.type";
import { Controller, useForm } from "react-hook-form";
import { IOtherCosts } from "Interface";
import {
  ActionOptions,
  QueryKeys,
  ValidationMessages,
  DialogMessages,
} from "Enums";
import {
  UseMutation_AddOtherCost,
  UseMutation_UpdateOtherCost,
} from "services";
import { useQueryClient } from "react-query";
import { usePrompt } from "utilities/usePrompt";
import { useNavigate } from "react-router-dom";
import { logout } from "@ntpkunity/controls-ums";
import { useStoreContext } from "Stores/SetupStore/Store";

const AddNewOtherCost: FC<INewOtherCostPopup> = ({
  openPopUp,
  setPopUpState,
  title,
  actionType,
  enabledPin,
  otherCostData,
}) => {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const lenderProfile: any = queryClient.getQueryData(
    QueryKeys.GET_LENDER_PROFILE
  );
  const [leavePagePopupState, setLeavePageState] = useState<boolean>(false);
  const navigate = useNavigate();
  const [navigationState, setNavigationState] = useState<any>();
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    setError,
    reset,
    formState: { errors, isDirty },
  } = useForm<IOtherCosts>();
  const { actions } = useStoreContext();
  const { mutate: AddOtherCost } = UseMutation_AddOtherCost();
  const { mutate: UpdateOtherCost } = UseMutation_UpdateOtherCost(
    getValues("id")
  );

  useEffect(() => {
    if (actionType !== ActionOptions.ADD) {
      setValue("id", otherCostData?.id);
      setValue("lender_id", otherCostData?.lender_id);
    }
    setValue("code", otherCostData?.code);
    setValue("description", otherCostData?.description);
    setValue("is_active", otherCostData ? otherCostData.is_active : true);
  }, [otherCostData]);

  function onSubmit(data) {
    if (actionType === ActionOptions.ADD) {
      data.lender_id = lenderProfile.id;
      AddOtherCost(data, {
        onSuccess: (response) => {
          reset();
          onClose();
        },
        onError: (error) => {
          if (
            error?.response?.data?.detail?.message ===
            "Code and Description should be unique"
          ) {
            setError("code", { message: "Code should be unique" });
            setError("description", {
              message: "Description should be unique",
            });
            return;
          } else if (
            error?.response?.data?.detail?.message === "Code should be unique"
          ) {
            setError("code", { message: "Code should be unique" });
            return;
          }
          if (
            error?.response?.data?.detail?.message ===
            "Description should be unique"
          ) {
            setError("description", {
              message: "Description should be unique",
            });
            return;
          } else {
            onClose();
            actions.setToast({
              toastMessage: error?.response?.data?.detail?.message,
              toastState: true,
              variant: "error",
            });
          }
        },
      });
    }
    if (actionType === ActionOptions.EDIT) {
      UpdateOtherCost(data, {
        onSuccess: (response) => {
          reset();
          onClose();
        },
        onError: (error) => {
          if (
            error?.response?.data?.detail?.message ===
            "Code and Description should be unique"
          ) {
            setError("code", { message: "Code should be unique" });
            setError("description", {
              message: "Description should be unique",
            });
            return;
          } else if (
            error?.response?.data?.detail?.message === "Code should be unique"
          ) {
            setError("code", { message: "Code should be unique" });
            return;
          }
          if (
            error?.response?.data?.detail?.message ===
            "Description should be unique"
          ) {
            setError("description", {
              message: "Description should be unique",
            });
            return;
          } else {
            onClose();
            actions.setToast({
              toastMessage: error?.response?.data?.detail?.message,
              toastState: true,
              variant: "error",
            });
          }
        },
      });
    }
  }
  const onClose = () => {
    let children = document.querySelectorAll(".drawer-header .pinned");
    if (children.length == 0) {
      setPopUpState(false);
    }
  };
  const onSaveConfirm = (e: any) => {
    handleSubmit(onSubmit)();
    setLeavePageState(false);
  };
  const onCancelConfirm = (e: any) => {
    navigate(".." + navigationState.location.pathname);
    if (navigationState.location.pathname == "/market-place/signup") {
      logout();
      localStorage.clear();
    }
    localStorage.setItem("DirtyState", "false");
  };
  usePrompt(
    "Leave screen?",
    isDirty,
    (dirty) => {
      setLeavePageState(dirty);
    },
    (tx) => {
      reset(getValues());
      setNavigationState(tx);
    }
  );

  return (
    <>
      <ConfirmationDialog
        openPopUp={leavePagePopupState}
        onConfirm={onSaveConfirm}
        onCancel={onCancelConfirm}
        setPopUpState={setLeavePageState}
        confirmationTitle={DialogMessages.saveTitle}
        confirmationText={DialogMessages.saveText}
        primaryBtnText={DialogMessages.saveBtnText}
        icon={<Icon name="DialogSaveIcon" />}
      />
      <PersistentDrawer
        title={title}
        openPopUp={openPopUp}
        setPopUpState={setPopUpState}
        theme={theme}
        enabledPin={true}
        children={
          <>
            <Controller
              name="code"
              control={control}
              defaultValue={""}
              rules={{
                maxLength: {
                  value: 5,
                  message: ValidationMessages.BUSINESS_CODE_MAX_LENGTH,
                },
                required: ValidationMessages.BUSINESS_CODE_REQUIRED,
              }}
              render={({ field: { onChange, value } }) => (
                <Input
                  theme={theme}
                  fullWidth
                  placeholder={"Type here.."}
                  label={"Code"}
                  type="text"
                  value={value?.trimStart()}
                  onChange={(e) => {
                    onChange(e.replace(/[^a-zA-Z0-9]/gi, ""));
                  }}
                  error={errors?.code?.message}
                />
              )}
            />
            <Controller
              name="description"
              control={control}
              rules={{
                maxLength: {
                  value: 150,
                  message: ValidationMessages.BUSINESS_DESCRIPTION_MAX_LENGTH,
                },
                required: ValidationMessages.BUSINESS_DESCRIPTION_REQUIRED,
              }}
              render={({ field: { onChange, value } }) => (
                <Input
                  theme={theme}
                  fullWidth={true}
                  placeholder={"Type here..."}
                  label={"Cost Description"}
                  type="text"
                  value={value?.trimStart()}
                  onChange={(e) => {
                    onChange(
                      e.replace(/[^a-zA-Z0-9!@#$%^&*()|/,.<>;:""'{}[]]/gi, "")
                    );
                  }}
                  error={errors?.description?.message}
                />
              )}
            />
            <Controller
              name="is_active"
              control={control}
              defaultValue={true}
              render={({ field: { value, onChange } }) => (
                <Select
                  theme={theme}
                  label={"Status"}
                  placeholder={"Select"}
                  items={[
                    { text: "Enabled", value: true },
                    { text: "Disabled", value: false },
                  ]}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </>
        }
        customFooter={
          <Button
            theme={theme}
            primary
            type="submit"
            text="Save"
            fullWidth
            onClick={handleSubmit(onSubmit)}
          />
        }
      />
    </>
  );
};
export default AddNewOtherCost;

import { FC, useEffect, useRef, useState } from "react";
import {
  useTheme,
  Button as MuiButton,
  TableRow,
  TableCell,
} from "@mui/material";
import {
  Box,
  Button,
  DataTable,
  Dialog,
  Icon,
  CircleLoader,
  Grid,
  Tooltip,
  unityTheme,
} from "@ntpkunity/controls";
import {
  DataTableWrap,
  FileUploadTable,
  TheadHiddenScroll,
  Typography,
} from "shared/components";
import { styled } from "@mui/system";
import { useSetupsStore, useStoreContext } from "Stores/SetupStore/Store";
import { FireTruck } from "@mui/icons-material";
import { mapQueryStatusFilter } from "react-query/types/core/utils";
import { useNavigate } from "react-router-dom";

const Loader = styled(
  Box,
  {}
)<any>(({ theme }) => ({
  ".loader-wrap": {
    ".loader": {
      top: "75% !important",
    },
  },
}));

const InventoryPopup: FC<{
  openPopup: boolean;
  setopenInventoryDialog: any;
  inventoryData: any;
}> = ({ openPopup, setopenInventoryDialog, inventoryData }) => {
  const { actions } = useStoreContext();
  const navigate = useNavigate();
  const [activeInventory, setactiveInventory] = useState(null);
  const handleCloseDialog = () => {
    setopenInventoryDialog(false);
  };
  const record_count: any = inventoryData?.[0];
  const inventory_records: any = inventoryData?.[1];
  const theme = useTheme();
  const Data: FC = () => {
    return (
      <>
        {inventoryData.length > 0 ? (
          <FileUploadTable>
            <Box
              theme={theme}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Box theme={theme} className="counter-box" sx={{ mr: 1, mb: 3 }}>
                <Box theme={theme} className="counter-text" sx={{ mr: 2 }}>
                  Total Record(s)
                </Box>
                <Box theme={theme} className="counter-num">
                  {record_count.total_records}
                </Box>
              </Box>
              <Box theme={theme} className="counter-box" sx={{ mr: 1, mb: 3 }}>
                <Box theme={theme} className="counter-text" sx={{ mr: 2 }}>
                  Successfull Record(s)
                </Box>
                <Box theme={theme} className="counter-num">
                  {record_count.inserted_records}
                </Box>
              </Box>
              <Box theme={theme} className="counter-box" sx={{ mr: 1, mb: 3 }}>
                <Box theme={theme} className="counter-text" sx={{ mr: 2 }}>
                  Errors
                </Box>
                <Box theme={theme} className="counter-num">
                  {record_count.error_records}
                </Box>
              </Box>
            </Box>
            {inventory_records.length != 0 ? (
              <>
                <Box theme={theme} sx={{ mb: 3 }} className="error">
                  <Typography variant="body2" component="p">
                    <Typography
                      variant="subtitle2"
                      component="span"
                      className="asterisk"
                    >
                      *{" "}
                    </Typography>
                    Please fix the error(s) and re-upload the complete file.
                  </Typography>
                </Box>
                <DataTable
                  theme={theme}
                  variant={"basic"}
                  theadChildren={
                    <>
                      <TableRow>
                        <TableCell className="action-cell head"></TableCell>
                        <TableCell className="head">Stock Number</TableCell>
                        <TableCell className="head">Make</TableCell>
                        <TableCell className="head">Model</TableCell>
                        <TableCell className="head">Trim Code</TableCell>
                        <TableCell className="head">Trim Description</TableCell>
                        <TableCell className="head">Year</TableCell>
                        <TableCell className="head">Selling Price</TableCell>
                        <TableCell className="head">MSRP</TableCell>
                        <TableCell className="head">Mileage</TableCell>
                        <TableCell className="head">Listing Status</TableCell>
                        <TableCell className="head">Status</TableCell>
                        <TableCell className="head">Asset Condition</TableCell>
                        <TableCell className="head">
                          Exterior Color Description
                        </TableCell>
                        <TableCell className="head">
                          Interior Color Description
                        </TableCell>
                        <TableCell className="head">
                          Transmission Type
                        </TableCell>
                        <TableCell className="head">Body Type</TableCell>
                        <TableCell className="head">Body Style</TableCell>
                        <TableCell className="head">Doors</TableCell>
                        <TableCell className="head">Engine Cylinders</TableCell>
                        <TableCell className="head">
                          Engine Description
                        </TableCell>
                        <TableCell className="head">Image Url</TableCell>
                        <TableCell className="head">VIN</TableCell>
                        <TableCell className="head">Drive Train</TableCell>
                        <TableCell className="head">Fuel Type</TableCell>
                      </TableRow>
                    </>
                  }
                  tbodyChildren={
                    <>
                      {inventory_records?.map(
                        (inventory: any, index: number) => {
                          return (
                            <TableRow key={index} className="child-tr">
                              <TableCell className="action-cell head">
                                {inventory.row + 2}
                              </TableCell>
                              <TableCell>{inventory.stock_number}</TableCell>
                              {inventory.make?.length == 0 ? (
                                <TableCell className="error">
                                  <Box theme={theme} className="red-box">
                                    <Box
                                      theme={theme}
                                      display="flex"
                                      alignItems="center"
                                    >
                                      <Tooltip
                                        theme={theme}
                                        title={"Field is required"}
                                      >
                                        <Box
                                          theme={theme}
                                          display="flex"
                                          alignItems="center"
                                        >
                                          <Icon
                                            className="icon"
                                            name="InfoIcon"
                                          />
                                          <Typography
                                            variant="body2"
                                            sx={{ ml: 0.7 }}
                                          >
                                            {" "}
                                          </Typography>
                                        </Box>
                                      </Tooltip>
                                    </Box>
                                  </Box>
                                </TableCell>
                              ) : (
                                <TableCell>{inventory.make}</TableCell>
                              )}
                              {inventory.model?.length == 0 ? (
                                <TableCell className="error">
                                  <Box theme={theme} className="red-box">
                                    <Box
                                      theme={theme}
                                      display="flex"
                                      alignItems="center"
                                    >
                                      <Tooltip
                                        theme={theme}
                                        title={"Field is required"}
                                      >
                                        <Box
                                          theme={theme}
                                          display="flex"
                                          alignItems="center"
                                        >
                                          <Icon
                                            className="icon"
                                            name="InfoIcon"
                                          />
                                          <Typography
                                            variant="body2"
                                            sx={{ ml: 0.7 }}
                                          >
                                            {" "}
                                          </Typography>
                                        </Box>
                                      </Tooltip>
                                    </Box>
                                  </Box>
                                </TableCell>
                              ) : (
                                <TableCell>{inventory.model}</TableCell>
                              )}
                              {inventory.trim_code?.length == 0 ? (
                                <TableCell className="error">
                                  <Box theme={theme} className="red-box">
                                    <Box
                                      theme={theme}
                                      display="flex"
                                      alignItems="center"
                                    >
                                      <Tooltip
                                        theme={theme}
                                        title={"Field is required"}
                                      >
                                        <Box
                                          theme={theme}
                                          display="flex"
                                          alignItems="center"
                                        >
                                          <Icon
                                            className="icon"
                                            name="InfoIcon"
                                          />
                                          <Typography
                                            variant="body2"
                                            sx={{ ml: 0.7 }}
                                          >
                                            {" "}
                                          </Typography>
                                        </Box>
                                      </Tooltip>
                                    </Box>
                                  </Box>
                                </TableCell>
                              ) : (
                                <TableCell>{inventory.trim_code}</TableCell>
                              )}
                              {inventory.trim_description?.length == 0 ? (
                                <TableCell className="error">
                                  <Box theme={theme} className="red-box">
                                    <Box
                                      theme={theme}
                                      display="flex"
                                      alignItems="center"
                                    >
                                      <Tooltip
                                        theme={theme}
                                        title={"Field is required"}
                                      >
                                        <Box
                                          theme={theme}
                                          display="flex"
                                          alignItems="center"
                                        >
                                          <Icon
                                            className="icon"
                                            name="InfoIcon"
                                          />
                                          <Typography
                                            variant="body2"
                                            sx={{ ml: 0.7 }}
                                          >
                                            {" "}
                                          </Typography>
                                        </Box>
                                      </Tooltip>
                                    </Box>
                                  </Box>
                                </TableCell>
                              ) : (
                                <TableCell>
                                  {inventory.trim_description}
                                </TableCell>
                              )}
                              {inventory.year?.length == 0 ? (
                                <TableCell className="error">
                                  <Box theme={theme} className="red-box">
                                    <Box
                                      theme={theme}
                                      display="flex"
                                      alignItems="center"
                                    >
                                      <Tooltip
                                        theme={theme}
                                        title={"Field is required"}
                                      >
                                        <Box
                                          theme={theme}
                                          display="flex"
                                          alignItems="center"
                                        >
                                          <Icon
                                            className="icon"
                                            name="InfoIcon"
                                          />
                                          <Typography
                                            variant="body2"
                                            sx={{ ml: 0.7 }}
                                          >
                                            {" "}
                                          </Typography>
                                        </Box>
                                      </Tooltip>
                                    </Box>
                                  </Box>
                                </TableCell>
                              ) : (
                                <TableCell>{inventory.year}</TableCell>
                              )}
                              {inventory.selling_price.length == 0 ? (
                                <TableCell className="error">
                                  <Box theme={theme} className="red-box">
                                    <Box
                                      theme={theme}
                                      display="flex"
                                      alignItems="center"
                                    >
                                      <Tooltip
                                        theme={theme}
                                        title={"Field is required"}
                                      >
                                        <Box
                                          theme={theme}
                                          display="flex"
                                          alignItems="center"
                                        >
                                          <Icon
                                            className="icon"
                                            name="InfoIcon"
                                          />
                                          <Typography
                                            variant="body2"
                                            sx={{ ml: 0.7 }}
                                          >
                                            {" "}
                                          </Typography>
                                        </Box>
                                      </Tooltip>
                                    </Box>
                                  </Box>
                                </TableCell>
                              ) : (
                                <TableCell>{inventory.selling_price}</TableCell>
                              )}
                              {inventory.MSRP?.length == 0 ? (
                                <TableCell className="error">
                                  <Box theme={theme} className="red-box">
                                    <Box
                                      theme={theme}
                                      display="flex"
                                      alignItems="center"
                                    >
                                      <Tooltip
                                        theme={theme}
                                        title={"Field is required"}
                                      >
                                        <Box
                                          theme={theme}
                                          display="flex"
                                          alignItems="center"
                                        >
                                          <Icon
                                            className="icon"
                                            name="InfoIcon"
                                          />
                                          <Typography
                                            variant="body2"
                                            sx={{ ml: 0.7 }}
                                          >
                                            {" "}
                                          </Typography>
                                        </Box>
                                      </Tooltip>
                                    </Box>
                                  </Box>
                                </TableCell>
                              ) : (
                                <TableCell>{inventory.MSRP}</TableCell>
                              )}
                              {inventory.mileage?.length == 0 ? (
                                <TableCell className="error">
                                  <Box theme={theme} className="red-box">
                                    <Box
                                      theme={theme}
                                      display="flex"
                                      alignItems="center"
                                    >
                                      <Tooltip
                                        theme={theme}
                                        title={"Field is required"}
                                      >
                                        <Box
                                          theme={theme}
                                          display="flex"
                                          alignItems="center"
                                        >
                                          <Icon
                                            className="icon"
                                            name="InfoIcon"
                                          />
                                          <Typography
                                            variant="body2"
                                            sx={{ ml: 0.7 }}
                                          >
                                            {" "}
                                          </Typography>
                                        </Box>
                                      </Tooltip>
                                    </Box>
                                  </Box>
                                </TableCell>
                              ) : (
                                <TableCell>{inventory.mileage}</TableCell>
                              )}
                              {inventory.listing_status?.length == 0 ? (
                                <TableCell className="error">
                                  <Box theme={theme} className="red-box">
                                    <Box
                                      theme={theme}
                                      display="flex"
                                      alignItems="center"
                                    >
                                      <Tooltip
                                        theme={theme}
                                        title={"Field is required"}
                                      >
                                        <Box
                                          theme={theme}
                                          display="flex"
                                          alignItems="center"
                                        >
                                          <Icon
                                            className="icon"
                                            name="InfoIcon"
                                          />
                                          <Typography
                                            variant="body2"
                                            sx={{ ml: 0.7 }}
                                          >
                                            {" "}
                                          </Typography>
                                        </Box>
                                      </Tooltip>
                                    </Box>
                                  </Box>
                                </TableCell>
                              ) : (
                                <TableCell>
                                  {inventory.listing_status}
                                </TableCell>
                              )}
                              {inventory.status?.length == 0 ? (
                                <TableCell className="error">
                                  <Box theme={theme} className="red-box">
                                    <Box
                                      theme={theme}
                                      display="flex"
                                      alignItems="center"
                                    >
                                      <Tooltip
                                        theme={theme}
                                        title={"Field is required"}
                                      >
                                        <Box
                                          theme={theme}
                                          display="flex"
                                          alignItems="center"
                                        >
                                          <Icon
                                            className="icon"
                                            name="InfoIcon"
                                          />
                                          <Typography
                                            variant="body2"
                                            sx={{ ml: 0.7 }}
                                          >
                                            {" "}
                                          </Typography>
                                        </Box>
                                      </Tooltip>
                                    </Box>
                                  </Box>
                                </TableCell>
                              ) : (
                                <TableCell>{inventory.status}</TableCell>
                              )}
                              {inventory.asset_condition?.length == 0 ? (
                                <TableCell className="error">
                                  <Box theme={theme} className="red-box">
                                    <Box
                                      theme={theme}
                                      display="flex"
                                      alignItems="center"
                                    >
                                      <Tooltip
                                        theme={theme}
                                        title={"Field is required"}
                                      >
                                        <Box
                                          theme={theme}
                                          display="flex"
                                          alignItems="center"
                                        >
                                          <Icon
                                            className="icon"
                                            name="InfoIcon"
                                          />
                                          <Typography
                                            variant="body2"
                                            sx={{ ml: 0.7 }}
                                          >
                                            {" "}
                                          </Typography>
                                        </Box>
                                      </Tooltip>
                                    </Box>
                                  </Box>
                                </TableCell>
                              ) : (
                                <TableCell>
                                  {inventory.asset_condition}
                                </TableCell>
                              )}
                              <TableCell>
                                {inventory.exterior_color_description}
                              </TableCell>
                              <TableCell>
                                {inventory.interior_color_description}
                              </TableCell>
                              <TableCell>
                                {inventory.transmission_type}
                              </TableCell>
                              <TableCell>{inventory.body_type}</TableCell>
                              <TableCell>{inventory.body_style}</TableCell>
                              <TableCell>{inventory.doors}</TableCell>
                              <TableCell>
                                {inventory.engine_cylinders}
                              </TableCell>
                              <TableCell>
                                {inventory.engine_description}
                              </TableCell>
                              <TableCell>{inventory.image_url}</TableCell>
                              {inventory.vin?.length == 0 ? (
                                <TableCell className="error">
                                  <Box theme={theme} className="red-box">
                                    <Box
                                      theme={theme}
                                      display="flex"
                                      alignItems="center"
                                    >
                                      <Tooltip
                                        theme={theme}
                                        title={"Field is required"}
                                      >
                                        <Box
                                          theme={theme}
                                          display="flex"
                                          alignItems="center"
                                        >
                                          <Icon
                                            className="icon"
                                            name="InfoIcon"
                                          />
                                          <Typography
                                            variant="body2"
                                            sx={{ ml: 0.7 }}
                                          >
                                            {" "}
                                          </Typography>
                                        </Box>
                                      </Tooltip>
                                    </Box>
                                  </Box>
                                </TableCell>
                              ) : (
                                <TableCell>{inventory.vin}</TableCell>
                              )}
                              <TableCell>{inventory.drivetrain}</TableCell>
                              <TableCell>{inventory.fuel_type}</TableCell>
                            </TableRow>
                          );
                        }
                      )}
                    </>
                  }
                ></DataTable>
              </>
            ) : (
              <></>
            )}
          </FileUploadTable>
        ) : (
          <Loader>
            <CircleLoader theme={theme} />
          </Loader>
        )}
      </>
    );
  };
  return (
    <>
      <Dialog
        variant={undefined}
        size="xl"
        title="Review And Finalize"
        open={openPopup}
        onCloseDialog={handleCloseDialog}
        noFooter
        theme={theme}
        disablePortal
        children={
          <>
            <Data />
          </>
        }
      />
    </>
  );
};

export default InventoryPopup;

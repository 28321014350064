import Http from "shared/helper/http-api";
import { ConfigurationAPI } from "services";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useStoreContext } from "Stores/SetupStore/Store";
import {
  EP_CHECK_DEALER_REGISTRATION_WITH_PEN,
  EP_CHECK_DEALER_REGISTRATION_WITH_STRIPE,
  EP_LENDER_INTEGRATION,
  EP_UPDATE_LENDER_INTEGRATION_STATUS,
} from "shared/config/endpoints";
import { IFinancialInsurance } from "Interface";
import { QueryKeys, ToastMessages } from "Enums";

export const getLenderIntegrationsFromServer = () => {
  return ConfigurationAPI.get(`${EP_LENDER_INTEGRATION}`).then((res) => {
    return res.data;
  });
};

export const getAllLenderIntegrationFilterFromServer = (
  columnFilters?: string
) => {
  return ConfigurationAPI.get(
    `integrations/lender/filter?`.concat(columnFilters)
  ).then((response) => {
    return response.data;
  });
};
export const UseQuery_getAllLenderIntegration_filter = (
  columnFilters: string
) => {
  return useQuery(
    [QueryKeys.GET_ALL_LENDER_INTEGRATIONS_FILTER, columnFilters],
    async () => {
      return getAllLenderIntegrationFilterFromServer(columnFilters).then(
        (data) => {
          return data;
        }
      );
    },
    { keepPreviousData: true }
  );
};

export const useSaveLenderIntegration = (): any => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();
  const { data, isLoading, mutate } = useMutation<any>(
    (body: any) => {
      return ConfigurationAPI.post<any>(`${EP_LENDER_INTEGRATION}`, body);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_LENDER_INTEGRATIONS_FILTER
        );
        queryClient.invalidateQueries(QueryKeys.GET_ALL_LENDER_INTEGRATIONS);
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_DEALER_INTEGRATION_FILTER
        );

        actions.setToast({
          toastMessage: ToastMessages.Record_Added_Success,
          toastState: true,
        });
      },
      onError(response: any) {
        actions.setToast({
          toastMessage: response?.error?.toString(),
          toastState: true,
          variant: "error",
        });
      },
    }
  );
  return { data, isLoading, mutate };
};

export const UseQuery_getLenderIntegrations = () => {
  return useQuery<any>(QueryKeys.GET_ALL_LENDER_INTEGRATIONS, async () => {
    return getLenderIntegrationsFromServer().then((data) => {
      return data;
    });
  });
};
export const useDeleteLenderIntegration = (): any => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();
  const { data, isLoading, mutate } = useMutation<IFinancialInsurance>(
    (body: any) => {
      return ConfigurationAPI.delete(`${EP_LENDER_INTEGRATION}/${body}`);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_LENDER_INTEGRATIONS_FILTER
        );
        queryClient.invalidateQueries(QueryKeys.GET_ALL_LENDER_INTEGRATIONS);
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_DEALER_INTEGRATION_FILTER
        );
        actions.setToast({
          toastMessage: ToastMessages.Record_Deleted_Success,
          toastState: true,
        });
      },
      onError(response: any) {
        actions.setToast({
          toastMessage: response?.error?.toString(),
          toastState: true,
          variant: "error",
        });
      },
    }
  );
  return { data, isLoading, mutate };
};
export const useUpdateLenderIntegration = (): any => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();
  const { data, isLoading, mutate } = useMutation<IFinancialInsurance>(
    (body: any) => {
      return ConfigurationAPI.patch(
        `${EP_LENDER_INTEGRATION}/${body.id}`,
        body
      );
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_LENDER_INTEGRATIONS_FILTER
        );
        queryClient.invalidateQueries(QueryKeys.GET_ALL_LENDER_INTEGRATIONS);
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_DEALER_INTEGRATION_FILTER
        );
        actions.setToast({
          toastMessage: ToastMessages.Record_Updated_Success,
          toastState: true,
        });
      },
      onError(response: any) {
        actions.setToast({
          toastMessage: response?.error?.toString(),
          toastState: true,
          variant: "error",
        });
      },
    }
  );
  return { data, isLoading, mutate };
};

export const useMutation_CheckDealerRegistrationWithPen: any = () => {
  return useMutation(() => {
    return ConfigurationAPI.get(`${EP_CHECK_DEALER_REGISTRATION_WITH_PEN}`);
  });
};

export const useMutation_CheckDealerRegistrationWithStripe: any = () => {
  return useMutation(() => {
    return ConfigurationAPI.get(`${EP_CHECK_DEALER_REGISTRATION_WITH_STRIPE}`);
  });
};

export const useUpdateLenderIntegrationParentStatus = (): any => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();
  const { data, isLoading, mutate } = useMutation<any>(
    (body: any) => {
      return ConfigurationAPI.patch(
        `${EP_UPDATE_LENDER_INTEGRATION_STATUS}/${body.id}`,
        body
      );
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_LENDER_INTEGRATIONS_FILTER
        );
        queryClient.invalidateQueries(QueryKeys.GET_ALL_LENDER_INTEGRATIONS);
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_DEALER_INTEGRATION_FILTER
        );
        actions.setToast({
          toastMessage: ToastMessages.Record_Updated_Success,
          toastState: true,
        });
      },
      onError(response: any) {
        actions.setToast({
          toastMessage: response?.error?.toString(),
          toastState: true,
          variant: "error",
        });
      },
    }
  );
  return { data, isLoading, mutate };
};

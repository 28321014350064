import { FC, useContext, useEffect, useState } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useTheme } from "@mui/material";
import {
  Icon,
  Box,
  Button,
  Menu,
  Tooltip,
  Typography,
} from "@ntpkunity/controls";
import { ConfirmationDialog } from "shared/components";
import {
  UseMutation_DeleteCommissionChart,
  useMutation_DeleteDownPaymentChart,
  UseMutation_DeleteSubsidyChart,
} from "services";
import { ICharts, ICriteria } from "Interface";
import { ActionOptions, ChartTypes, DialogMessages } from "Enums";
import { SetupContext } from "context";
import { convertArray } from "shared/helper/methods";
interface CriteriaProps {
  criteria: ICriteria;
  chart: ICharts;
  onEditCriteria?: (data) => unknown;
  chartType: number;
}

const tableOptions: any = [
  {
    optionText: (
      <>
        <Icon className="menu-icon" name="EditIcon" />
        {ActionOptions.EDIT}
      </>
    ),
    optionkey: ActionOptions.EDIT,
    optionValue: {},
  },
  {
    optionText: (
      <>
        <Icon className="menu-icon" name="DeleteIcon" />
        {ActionOptions.DELETE}
      </>
    ),
    optionkey: ActionOptions.DELETE,
    optionValue: {},
  },
];

const CriteriaTable: FC<CriteriaProps> = ({
  criteria,
  chart,
  onEditCriteria,
  chartType,
}) => {
  const theme = useTheme();
  const [confirmationPopupState, setConfirmationPopupState] =
    useState<boolean>(false);
  const { mutate: deleteDownPaymentChart } =
    useMutation_DeleteDownPaymentChart();
  const { mutate: deleteCommissionChart } = UseMutation_DeleteCommissionChart();
  const { mutate: deleteSubsidyChart } = UseMutation_DeleteSubsidyChart();
  const { currencies, lenderProfile } = useContext(SetupContext);

  const [criteriaId, setCriteriaId] = useState(undefined);
  const [defaultCurrency, setDefaultCurrency] = useState<string>("");

  const handleSelection = (event, key, value) => {
    switch (key) {
      case ActionOptions.EDIT:
        const data = {
          chart: chart,
          criteria: value,
          chartId: 0,
        };
        onEditCriteria(data);
        return;
      case ActionOptions.DELETE:
        setCriteriaId(value.id);
        setConfirmationPopupState(true);
        return;
      default:
        return;
    }
  };

  const onDeleteConfirm = (e: any) => {
    switch (chartType) {
      case ChartTypes.DownPaymentChart:
        deleteDownPaymentChart(criteriaId, {
          onSuccess: () => {
            setCriteriaId(undefined);
          },
        });
        break;
      case ChartTypes.CommissionChart:
        deleteCommissionChart(criteriaId, {
          onSuccess: () => {
            setCriteriaId(undefined);
          },
        });
        break;
      case ChartTypes.SubsidyChart:
        deleteSubsidyChart(criteriaId, {
          onSuccess: () => {
            setCriteriaId(undefined);
          },
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const filteredCurrency = currencies?.filter(
      (currency: any) => currency.id == lenderProfile?.default_currency
    );
    setDefaultCurrency(
      filteredCurrency && filteredCurrency.length > 0
        ? filteredCurrency[0].symbol
        : ""
    );
  }, [lenderProfile]);

  return (
    <>
      <TableRow className="child-tr level-two">
        <TableCell>
          <Box theme={theme} className="group-title"></Box>
        </TableCell>
        {/* <TableCell>{criteria.asset_classification}</TableCell> */}
        <TableCell>
          <Box theme={theme} display="flex" alignItems="center">
            <Tooltip
              theme={theme}
              title={
                <>
                  {criteria.asset_make_name} {criteria.asset_model_name}{" "}
                  {criteria.asset_trim_name}
                </>
              }
            >
              <Box theme={theme} display="flex" alignItems="center">
                <Typography
                  theme={theme}
                  variant="body2"
                  sx={{ ml: 0.7 }}
                  component="span"
                >
                  {
                    <>
                      {criteria.asset_make_name} {criteria.asset_model_name}{" "}
                      {criteria.asset_trim_name}
                    </>
                  }
                </Typography>
              </Box>
            </Tooltip>
          </Box>
        </TableCell>
        <TableCell>
          <Box theme={theme} display="flex" alignItems="center">
            <Tooltip
              theme={theme}
              title={
                <>
                  {criteria?.asset_condition
                    ? criteria?.asset_condition.join(", ")
                    : "-"}
                </>
              }
            >
              <Box theme={theme} display="flex" alignItems="center">
                <Typography
                  theme={theme}
                  variant="body2"
                  sx={{ ml: 0.7 }}
                  component="span"
                >
                  {
                    <>
                      {criteria?.asset_condition
                        ? criteria?.asset_condition.join(", ")
                        : "-"}
                    </>
                  }
                </Typography>
              </Box>
            </Tooltip>
          </Box>
        </TableCell>
        <TableCell>
          <Box theme={theme} display="flex" alignItems="center">
            <Tooltip
              theme={theme}
              title={
                <>
                  {criteria?.asset_usages
                    ? criteria?.asset_usages.join(", ")
                    : "-"}
                </>
              }
            >
              <Box theme={theme} display="flex" alignItems="center">
                <Typography
                  theme={theme}
                  variant="body2"
                  sx={{ ml: 0.7 }}
                  component="span"
                >
                  {
                    <>
                      {criteria?.asset_usages
                        ? criteria?.asset_usages.join(", ")
                        : "-"}
                    </>
                  }
                </Typography>
              </Box>
            </Tooltip>
          </Box>
        </TableCell>
        <TableCell>
          <Box theme={theme} display="flex" alignItems="center">
            <Tooltip
              theme={theme}
              title={<>{criteria?.terms ? criteria?.terms.join(", ") : "-"}</>}
            >
              <Box theme={theme} display="flex" alignItems="center">
                <Typography
                  theme={theme}
                  variant="body2"
                  sx={{ ml: 0.7 }}
                  component="span"
                >
                  {<>{criteria?.terms ? criteria?.terms.join(", ") : "-"}</>}
                </Typography>
              </Box>
            </Tooltip>
          </Box>
        </TableCell>
        <TableCell>
          <Box theme={theme} display="flex" alignItems="center">
            <Tooltip
              theme={theme}
              title={
                <>
                  {criteria?.credit_rating
                    ? criteria?.credit_rating.join(", ")
                    : "-"}
                </>
              }
            >
              <Box theme={theme} display="flex" alignItems="center">
                <Typography
                  theme={theme}
                  variant="body2"
                  sx={{ ml: 0.7 }}
                  component="span"
                >
                  {
                    <>
                      {criteria?.credit_rating
                        ? criteria?.credit_rating.join(", ")
                        : "-"}
                    </>
                  }
                </Typography>
              </Box>
            </Tooltip>
          </Box>
        </TableCell>
        <TableCell>
          <Box theme={theme} display="flex" alignItems="center">
            <Tooltip
              theme={theme}
              title={
                <>
                  {criteria?.financed_amount
                    ? convertArray(criteria?.financed_amount).join(", ")
                    : "-"}
                </>
              }
            >
              <Box theme={theme} display="flex" alignItems="center">
                {/* <Icon className="icon" name="InfoIcon" /> */}
                <Typography
                  theme={theme}
                  variant="body2"
                  sx={{ ml: 0.7 }}
                  component="span"
                >
                  {
                    <>
                      {criteria?.financed_amount
                        ? convertArray(criteria?.financed_amount).join(", ")
                        : "-"}
                    </>
                  }
                </Typography>
              </Box>
            </Tooltip>
          </Box>
        </TableCell>
        {chartType == ChartTypes.CommissionChart && (
          <>
            <TableCell>
              {criteria?.purchase_option
                ? criteria?.purchase_option.join(", ")
                : "-"}
            </TableCell>
            <TableCell>{criteria.calculation_basis ?? "-"}</TableCell>
            {criteria.output_parameter_type == "Percentage" ? (
              <>
                <TableCell>{`${
                  criteria.default_commission
                    ? `${criteria?.default_commission.toFixed(4)}%
                   `
                    : "-"
                }`}</TableCell>
                <TableCell>
                  {" "}
                  {criteria.minimum_commission && criteria.maximum_commission
                    ? criteria.minimum_commission.toFixed(4) +
                      `${"%"} - ` +
                      criteria.maximum_commission.toFixed(4) +
                      `${"%"}`
                    : "-"}
                </TableCell>
              </>
            ) : (
              <>
                <TableCell>{`${
                  criteria.default_commission
                    ? `${defaultCurrency}${criteria?.default_commission.toFixed(
                        2
                      )}
                   `
                    : "-"
                }`}</TableCell>
                <TableCell>
                  {" "}
                  {criteria.minimum_commission && criteria.maximum_commission
                    ? `${defaultCurrency}${criteria.minimum_commission.toFixed(
                        2
                      )}` +
                      ` - ` +
                      `${defaultCurrency}${criteria.maximum_commission.toFixed(
                        2
                      )}`
                    : "-"}
                </TableCell>
              </>
            )}
            {/* <TableCell>{`${
              criteria.gl_split
                ? `${criteria?.gl_split.toFixed(2)}%
                   `
                : "-"
            }`}</TableCell>
            <TableCell>{`${
              criteria.customer_split
                ? `${criteria?.customer_split.toFixed(2)}%
                   `
                : "-"
            }`}</TableCell>
            <TableCell>{`${
              criteria.manufacturer_split
                ? `${criteria?.manufacturer_split.toFixed(2)}%
                   `
                : "-"
            }`}</TableCell>
            <TableCell>{`${
              criteria.sales_representative_split
                ? `${criteria?.sales_representative_split.toFixed(2)}%
                   `
                : "-"
            }`}</TableCell> */}
          </>
        )}
        {chartType == ChartTypes.DownPaymentChart && (
          <>
            <TableCell>{`${
              criteria.default_down_payment
                ? `${criteria?.default_down_payment.toFixed(4)}% `
                : "-"
            }`}</TableCell>
            <TableCell>
              {" "}
              {criteria.minimum_down_payment && criteria.maximum_down_payment
                ? criteria.minimum_down_payment.toFixed(4) +
                  "% - " +
                  criteria.maximum_down_payment.toFixed(4) +
                  "%"
                : "-"}
            </TableCell>
          </>
        )}

        {chartType == ChartTypes.SubsidyChart && (
          <>
            <TableCell>{`${
              criteria.rate ? `${criteria?.rate.toFixed(4)}% ` : "-"
            }`}</TableCell>
            <TableCell>{`${
              criteria.financier_rate
                ? `${criteria?.financier_rate.toFixed(4)}% `
                : "-"
            }`}</TableCell>
            <TableCell>{`${
              criteria.dealer_rate
                ? `${criteria?.dealer_rate.toFixed(4)}% `
                : "-"
            }`}</TableCell>
            <TableCell>{`${
              criteria.manufacturer_rate
                ? `${criteria?.manufacturer_rate.toFixed(4)}% `
                : "-"
            }`}</TableCell>
          </>
        )}
        <TableCell />
        <TableCell className="action-cell fixed-cell">
          <Menu
            theme={theme}
            disablePortal
            options={tableOptions.map((option) => {
              return {
                optionText: option.optionText,
                optionkey: option.optionkey,
                optionValue: criteria,
              };
            })}
            handleOptionClick={handleSelection}
            render={(onMenuSelection) => (
              <Button
                defaultBtn
                iconText={<Icon name="MoreIcon" />}
                onClick={onMenuSelection}
              />
            )}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          />
        </TableCell>
      </TableRow>

      {confirmationPopupState && (
        <ConfirmationDialog
          openPopUp={confirmationPopupState}
          onConfirm={onDeleteConfirm}
          setPopUpState={setConfirmationPopupState}
          confirmationTitle={DialogMessages.confirmationTitle}
          confirmationText={DialogMessages.confirmationText}
          primaryBtnText={DialogMessages.deleteBtnText}
          icon={<Icon name="DialogDeleteIcon" />}
        />
      )}
    </>
  );
};

export default CriteriaTable;

export const debounce = (func: (...args: any) => void, delay: number) => {
  let timer: any;
  return function <Type>(this: any, ...args: Type[]) {
    const that = this as unknown as any;
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(that, args);
    }, delay);
  };
};

import { FC, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { styled } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useTheme, TablePagination } from "@mui/material";
import { Button, Icon, Menu, Input, DataTable, Box } from "@ntpkunity/controls";
import { ConfirmationDialog, DataTableWrap, Switch } from "shared/components";
import {
  useGetDealersFilter,
  useUpdateDealerById,
  useDeleteDealerById,
  useGetDealerById,
  UseQuery_GetActiveDealers,
  useDeleteUserById,
} from "services";
import { IDealerFilter, IDealer } from "Interface";
import { DialogMessages, QueryKeys, ToastMessages } from "Enums";
import ChevronDown from "../../shared/assets/images/chevron-down";
import { useStoreContext } from "Stores/SetupStore/Store";

const ManageDealersTable: FC<{ onEdit: (data: any) => unknown }> = ({
  onEdit,
}) => {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [columnFilters, setColumnFilters] = useState(
    `page_number=${pageNumber}&page_size=${pageSize}`
  );

  const { mutate: updateDealer } = useUpdateDealerById();
  const { mutate: deleteDealer } = useDeleteDealerById();
  const { mutate: deleteUser } = useDeleteUserById();
  const { mutate: getDealer } = useGetDealerById();
  const { actions } = useStoreContext();
  UseQuery_GetActiveDealers();

  const [actionData, setActionData] = useState<IDealer>(undefined);
  const [confirmationPopupState, setConfirmationPopupState] =
    useState<boolean>(false);
  const [nameFilter, setNameFilter] = useState("");
  const [codeFilter, setCodeFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [contactPersonNameFilter, setContactPersonNameFilter] = useState("");
  const [contactPersonEmailFilter, setContactPersonEmailFilter] = useState("");
  const [updatedOnFilter, setUpdatedOnFilter] = useState("");
  const setQueryString = (pgNo, pgSize) => {
    let query_string: string = `page_number=${pgNo}&page_size=${pgSize}`;
    if (nameFilter.trimStart() && nameFilter.trimStart() != "") {
      query_string = query_string.concat(`&name=${nameFilter}`);
    }
    if (
      contactPersonNameFilter.trimStart() &&
      contactPersonNameFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(
        `&contact_person_name=${contactPersonNameFilter}`
      );
    }
    if (
      contactPersonEmailFilter.trimStart() &&
      contactPersonEmailFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(
        `&contact_person_email=${contactPersonEmailFilter}`
      );
    }
    if (updatedOnFilter.trimStart() && updatedOnFilter.trimStart() != "") {
      query_string = query_string.concat(`&updated_at=${updatedOnFilter}`);
    }

    if (codeFilter.trimStart() != null && codeFilter.trimStart() != "") {
      query_string = query_string.concat(`&code=${codeFilter}`);
    }
    if (statusFilter.trimStart() != null && statusFilter.trimStart() != "") {
      if (
        "enabled".startsWith(
          statusFilter.toLowerCase().slice(0, Math.max("enabled".length - 1, 1))
        )
      ) {
        query_string = query_string.concat(`&is_active=${true}`);
      } else if (
        "disabled".startsWith(
          statusFilter
            .toLowerCase()
            .slice(0, Math.max("disabled".length - 1, 1))
        )
      ) {
        query_string = query_string.concat(`&is_active=${false}`);
      } else {
        query_string = query_string.concat(`&is_active=None`);
      }
    }
    return query_string;
  };
  const { data } = useGetDealersFilter(columnFilters);

  const dealersData: IDealerFilter = data;

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPageNumber(newPage);
    setColumnFilters(setQueryString(newPage, pageSize));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(+event.target.value);
    setPageNumber(0);
    setColumnFilters(setQueryString(0, +event.target.value));
  };

  const handleFilter = () => {
    setPageNumber(0);
    setColumnFilters(setQueryString(0, pageSize));
  };

  const onDeleteConfirm = (e: any) => {
    var access_token_local = JSON.parse(localStorage.getItem("settings"));
    deleteDealer(
      { id: actionData?.id },
      {
        onSuccess: (data, variables, context) => {
          setActionData(undefined);
          if (access_token_local?.isDealex == true) {
            deleteUser(
              { id: actionData?.user_id },
              {
                onSuccess: () => {
                  setActionData(undefined);
                },
              }
            );
          } else {
            actions.setToast({
              toastMessage: ToastMessages.Record_Deleted_Success,
              toastState: true,
            });
          }
        },
        onError: (error: any) => {
          actions.setToast({
            toastMessage: error?.response?.data?.detail?.message,
            toastState: true,
            variant: "error",
          });
        },
      }
    );
  };
  return (
    <>
      <DataTableWrap className="table-pagination">
        <Box theme={theme} className="scroll">
          <Box theme={theme} className="scroll-hide spr-border" />
        </Box>
        <DataTable
          theme={theme}
          variant="basic"
          sx={{ mb: 3 }}
          theadChildren={
            <>
              <TableRow>
                <TableCell id="dealerNameHead">Dealer Name</TableCell>
                <TableCell id="codeHead">Code</TableCell>
                <TableCell id="fisrtLastNameHead">First/Last Name</TableCell>
                <TableCell id="emailAddressHead">Email Address</TableCell>
                <TableCell id="statusHead">Status</TableCell>
                {/* <TableCell>Last Updated On</TableCell> */}
                <TableCell className="action-cell fixed-cell" />
              </TableRow>
              <TableRow className="filters-row">
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      name="dealerNameSearch"
                      theme={theme}
                      fullWidth={true}
                      placeholder={"Search..."}
                      type="text"
                      startAdornment={undefined}
                      endAdornment={undefined}
                      id={""}
                      value={nameFilter?.trimStart()}
                      onChange={(value) => {
                        setNameFilter(value);
                      }}
                      onBlur={() => {
                        handleFilter();
                      }}
                    />
                    <Button
                      defaultBtn
                      id={"dealerNameSearchId"}
                      iconText={<Icon name="IconFilter" />}
                    />
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      name="codeSearch"
                      theme={theme}
                      fullWidth={true}
                      placeholder={"Search..."}
                      type="text"
                      startAdornment={undefined}
                      endAdornment={undefined}
                      id={"codeSearchId"}
                      value={codeFilter.trimStart()}
                      onChange={(value) => {
                        setCodeFilter(value);
                      }}
                      onBlur={() => {
                        handleFilter();
                      }}
                    />
                    <Button
                      defaultBtn
                      id="codeFilter"
                      iconText={<Icon name="IconFilter" />}
                    />
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      name="firstLastNameSearch"
                      theme={theme}
                      fullWidth={true}
                      placeholder={"Search..."}
                      type="text"
                      startAdornment={undefined}
                      endAdornment={undefined}
                      id={"firstLastNameSearchId"}
                      value={contactPersonNameFilter?.trimStart()}
                      onChange={(value) => {
                        setContactPersonNameFilter(value);
                      }}
                      onBlur={() => {
                        handleFilter();
                      }}
                    />
                    <Button
                      defaultBtn
                      id="contactNameFilter"
                      iconText={<Icon name="IconFilter" />}
                    />
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      name="contactEmailSearch"
                      theme={theme}
                      fullWidth={true}
                      placeholder={"Search..."}
                      type="text"
                      startAdornment={undefined}
                      endAdornment={undefined}
                      id={"contactEmailSearchId"}
                      value={contactPersonEmailFilter?.trimStart()}
                      onChange={(value) => {
                        setContactPersonEmailFilter(value);
                      }}
                      onBlur={() => {
                        handleFilter();
                      }}
                    />
                    <Button
                      defaultBtn
                      id="contactEmailFilter"
                      iconText={<Icon name="IconFilter" />}
                    />
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      name="statusSearch"
                      theme={theme}
                      fullWidth={true}
                      placeholder={"Search..."}
                      type="text"
                      startAdornment={undefined}
                      endAdornment={undefined}
                      id={"statusSearchId"}
                      value={statusFilter?.trimStart()}
                      onChange={(value) => {
                        setStatusFilter(value);
                      }}
                      onBlur={() => {
                        handleFilter();
                      }}
                    />
                    <Button
                      defaultBtn
                      id="statusFilter"
                      iconText={<Icon name="IconFilter" />}
                    />
                  </Box>
                </TableCell>
                {/* <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={"Search..."}
                      type="text"
                      startAdornment={undefined}
                      endAdornment={undefined}
                      id={""}
                      value={updatedOnFilter}
                      onChange={(value) => {
                        setUpdatedOnFilter(value);
                      }}
                      onBlur={() => {
                        handleFilter();
                      }}
                    />
                    <Button defaultBtn iconText={<Icon name="IconFilter" />} />
                  </Box>
                </TableCell> */}
                <TableCell className="action-cell fixed-cell" />
              </TableRow>
            </>
          }
          tbodyChildren={
            dealersData?.result &&
            dealersData.result.map((dealer: IDealer, index) => (
              <TableRow key={index} className="child-tr">
                <TableCell id={"DealerName" + dealer.dealer_code}>
                  {dealer.dealer_name}
                </TableCell>
                <TableCell id={"code" + dealer.dealer_code}>
                  {dealer.dealer_code}
                </TableCell>
                <TableCell
                  id={"contactName" + dealer.dealer_code}
                >{`${dealer.contact_person_first_name} ${dealer.contact_person_last_name}`}</TableCell>
                <TableCell id={"email" + dealer.dealer_code}>
                  {dealer.contact_person_email}
                </TableCell>
                <TableCell>
                  <Switch
                    theme={theme}
                    varient={"basic"}
                    label={dealer.is_active ? "Enabled" : "Disabled"}
                    switchEnabled={dealer.is_active}
                    onChange={(event) => {
                      updateDealer(
                        {
                          ...dealer,
                          is_active: event.target.checked,
                        },
                        {
                          onSuccess: () => {
                            queryClient.invalidateQueries(QueryKeys.GET_ALL_DEALERS);
                            localStorage.removeItem("dealer_code");
                          },
                          onError: (error) => {
                            actions.setToast({
                              toastMessage: error?.response?.data?.detail,
                              toastState: true,
                              variant: "error",
                            });
                          },
                        }
                      );
                    }}
                  />
                </TableCell>
                {/* <TableCell>{dealer.updated_at}</TableCell> */}
                <TableCell className="action-cell fixed-cell">
                  <Menu
                    theme={theme}
                    disablePortal
                    options={[
                      {
                        optionText: (
                          <>
                            <Icon className="menu-icon" name="EditIcon" /> Edit
                          </>
                        ),
                        optionValue: "edit",
                      },
                      {
                        optionText: (
                          <>
                            <Icon className="menu-icon" name="DeleteIcon" />{" "}
                            Delete
                          </>
                        ),
                        optionValue: "delete",
                      },
                    ]}
                    handleOptionClick={(_event, _key, value) => {
                      switch (value) {
                        case "delete":
                          setActionData(dealer);
                          setConfirmationPopupState(true);
                          break;
                        case "edit":
                          getDealer(
                            { id: dealer.id },
                            {
                              onSuccess(response: any) {
                                onEdit(response.data);
                              },
                            }
                          );
                          break;
                      }
                    }}
                    render={(onMenuSelection) => (
                      <Button
                        defaultBtn
                        id={"moreBtn" + dealer.dealer_code}
                        iconText={<Icon name="MoreIcon" />}
                        onClick={onMenuSelection}
                      ></Button>
                    )}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  />
                </TableCell>
              </TableRow>
            ))
          }
        />
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20]}
          component="div"
          count={dealersData ? dealersData?.total_results : -1}
          rowsPerPage={pageSize}
          page={pageNumber}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          SelectProps={{
            IconComponent: ChevronDown,
          }}
        />
      </DataTableWrap>
      {confirmationPopupState && (
        <ConfirmationDialog
          openPopUp={confirmationPopupState}
          onConfirm={onDeleteConfirm}
          setPopUpState={setConfirmationPopupState}
          confirmationTitle={DialogMessages.confirmationTitle}
          confirmationText={DialogMessages.confirmationText}
          primaryBtnText={DialogMessages.deleteBtnText}
          icon={<Icon name="DialogDeleteIcon" />}
        />
      )}
    </>
  );
};

export default ManageDealersTable;

import { FC, useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import {
  PersistentDrawer,
  Icon,
  Input,
  Select,
  Button,
} from "@ntpkunity/controls";
import { ConfirmationDialog } from "shared/components";
import { logout } from "@ntpkunity/controls-ums";
import { IAddNewDecisionStatusPopUp } from "./AddNewDecisionStatus.type";
import { Controller, useForm } from "react-hook-form";
import {
  UseMutation_AddDecisionStatus,
  UseMutation_UpdateDecisionStatus,
  UseQuery_GetAll_DecisionStatuses,
} from "services";
import { IAssetMake, IDecisionStatus, ISelectItemList } from "Interface";
import {
  ActionOptions,
  QueryKeys,
  ValidationMessages,
  DecisionStatus,
  DialogMessages,
} from "Enums";
import { useQueryClient } from "react-query";
import { usePrompt } from "utilities/usePrompt";
import { useNavigate } from "react-router-dom";
import { useStoreContext } from "Stores/SetupStore/Store";
const AddNewDecisionStatus: FC<IAddNewDecisionStatusPopUp> = ({
  openPopUp,
  setPopUpState,
  title,
  actionType,
  decisionStatusData,
  enabledPin,
  ...props
}) => {
  const theme = useTheme();
  const {
    register,
    control,
    handleSubmit,
    reset,
    setError,
    clearErrors,
    getValues,
    setValue,
    formState: { errors, isDirty },
  } = useForm<IDecisionStatus>();
  const { mutate: AddDecisionStatus } = UseMutation_AddDecisionStatus();
  const { mutate: EditDecisionStatus } = UseMutation_UpdateDecisionStatus(
    getValues("id")
  );
  const { actions } = useStoreContext();
  const navigate = useNavigate();
  const [navigationState, setNavigationState] = useState<any>();
  const [leavePagePopupState, setLeavePageState] = useState<boolean>(false);
  const [orderStatus, setOrderStatus] = useState(DecisionStatus);

  const [lenderId, setLenderId] = useState<number>(null);

  useEffect(() => {
    if (actionType !== ActionOptions.ADD) {
      setValue("id", decisionStatusData.id);
    }
    setValue("code", decisionStatusData?.code);
    setValue("description", decisionStatusData?.description);
    setValue("order_status", decisionStatusData?.order_status);

    setValue(
      "is_active",
      decisionStatusData ? decisionStatusData.is_active : true
    );
  }, [decisionStatusData]);

  const queryClient = useQueryClient();
  const lenderProfile: any = queryClient.getQueryData(
    QueryKeys.GET_LENDER_PROFILE
  );

  function onSubmit(data) {
    if (actionType === ActionOptions.ADD) {
      data.lender_id = lenderProfile?.id;
      AddDecisionStatus(data, {
        onSuccess: (response) => {
          reset();
          onClose();
        },
        onError: (error: any) => {
          if (
            error?.response?.data?.detail?.message ===
            "Code and Order Status should be unique"
          ) {
            setError("code", { message: "Code should be unique" });
            setError("order_status", {
              message: "Order Status should be unique",
            });
            return;
          } else if (
            error?.response?.data?.detail?.message === "Code should be unique"
          ) {
            setError("code", { message: "Code should be unique" });
            return;
          } else if (
            error?.response?.data?.detail?.message ===
            "Order Status should be unique"
          ) {
            setError("order_status", {
              message: "Order Status should be unique",
            });
            return;
          } else {
            onClose();
            actions.setToast({
              toastMessage: error?.response?.data?.detail?.message,
              toastState: true,
              variant: "error",
            });
          }
        },
      });
    } else if (actionType === ActionOptions.EDIT) {
      EditDecisionStatus(data, {
        onSuccess: (response) => {
          reset();
          onClose();
        },
        onError: (error: any) => {
          if (
            error?.response?.data?.detail?.message ===
            "Code and Order Status should be unique"
          ) {
            setError("code", { message: "Code should be unique" });
            setError("order_status", {
              message: "Order Status should be unique",
            });
            return;
          } else if (
            error?.response?.data?.detail?.message === "Code should be unique"
          ) {
            setError("code", { message: "Code should be unique" });
            return;
          } else if (
            error?.response?.data?.detail?.message ===
            "Order Status should be unique"
          ) {
            setError("order_status", {
              message: "Order Status should be unique",
            });
            return;
          } else {
            onClose();
            actions.setToast({
              toastMessage: error?.response?.data?.detail?.message,
              toastState: true,
              variant: "error",
            });
          }
        },
      });
    }
  }
  const onClose = () => {
    let children = document.querySelectorAll(".drawer-header .pinned");
    if (children.length == 0) {
      setPopUpState(false);
    }
    // dispatch(setOpenDrawer(false));
  };

  useEffect(() => {
    setLenderId(lenderProfile?.id);
  }, []);
  const onSaveConfirm = (e: any) => {
    handleSubmit(onSubmit)();
    setLeavePageState(false);
  };
  const onCancelConfirm = (e: any) => {
    navigate(".." + navigationState.location.pathname);
    if (navigationState.location.pathname == "/market-place/signup") {
      logout();
      localStorage.clear();
    }
    localStorage.setItem("DirtyState", "false");
  };
  usePrompt(
    "Leave screen?",
    isDirty,
    (dirty) => {
      setLeavePageState(dirty);
    },
    (tx) => {
      reset(getValues());
      setNavigationState(tx);
    }
  );
  return (
    <>
      <ConfirmationDialog
        openPopUp={leavePagePopupState}
        onConfirm={onSaveConfirm}
        onCancel={onCancelConfirm}
        setPopUpState={setLeavePageState}
        confirmationTitle={DialogMessages.saveTitle}
        confirmationText={DialogMessages.saveText}
        primaryBtnText={DialogMessages.saveBtnText}
        icon={<Icon name="DialogSaveIcon" />}
      />
      <PersistentDrawer
        openPopUp={openPopUp}
        setPopUpState={setPopUpState}
        title={title}
        theme={theme}
        enabledPin={true}
        children={
          <>
            <Controller
              name="code"
              control={control}
              rules={{
                maxLength: {
                  value: 5,
                  message: ValidationMessages.DOCUMENT_CODE_MAX_LENGTH,
                },
                pattern: {
                  value: /^[^\s]/,
                  message: ValidationMessages.RESPONSE_CODE_REQUIRED,
                },
                required: ValidationMessages.RESPONSE_CODE_REQUIRED,
              }}
              render={({ field: { value, onChange } }) => (
                <Input
                  // {...field}
                  theme={theme}
                  fullWidth={true}
                  placeholder={"Type here..."}
                  label={"Response Code"}
                  type="string"
                  id="name"
                  onChange={onChange}
                  value={value?.trimStart()}
                  error={errors?.code?.message}
                ></Input>
              )}
            />
            <Controller
              name="description"
              control={control}
              rules={{
                maxLength: {
                  value: 150,
                  message: ValidationMessages.DOCUMENT_DESCRIPTION_MAX_LENGTH,
                },
                pattern: {
                  value: /^[^\s]/,
                  message: ValidationMessages.RESPONSE_DESCRIPTION_REQUIRED,
                },
                required: ValidationMessages.RESPONSE_DESCRIPTION_REQUIRED,
              }}
              render={({ field: { value, onChange } }) => (
                <Input
                  //{...field}
                  theme={theme}
                  fullWidth={true}
                  placeholder={"Type here..."}
                  label={"Response Description"}
                  id="description"
                  type="string"
                  value={value?.trimStart()}
                  onChange={onChange}
                  error={errors?.description?.message}
                ></Input>
              )}
            />
            <Controller
              name="order_status"
              control={control}
              rules={{
                required: ValidationMessages.ORDER_STATUS_REQUIRED,
              }}
              render={({ field: { value, onChange } }) => (
                <Select
                  fullWidth={true}
                  theme={theme}
                  placeholder={"Type here..."}
                  label={"Order Status"}
                  items={Object.values(orderStatus).map((item) => ({
                    text: item,
                    value: item,
                  }))}
                  type="string"
                  id="order_status"
                  value={value ?? ""}
                  onChange={onChange}
                  selectError={errors?.order_status?.message}
                ></Select>
              )}
            />
            <Controller
              name="is_active"
              control={control}
              defaultValue={true}
              render={({ field: { value, onChange } }) => (
                <Select
                  theme={theme}
                  label={"Status"}
                  placeholder={"Select"}
                  items={[
                    { text: "Enabled", value: true },
                    { text: "Disabled", value: false },
                  ]}
                  value={value}
                  onChange={onChange}
                ></Select>
              )}
            />
          </>
        }
        customFooter={
          <Button
            theme={theme}
            primary
            text={"Save"}
            fullWidth={true}
            style={{ paddingRight: "82px", paddingLeft: "82px" }}
            onClick={handleSubmit(onSubmit)}
          />
        }
      />
    </>
  );
};

export default AddNewDecisionStatus;

import { ConfigurationAPI } from "services";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { IBaseChart, ICharts } from "Interface";
import { QueryKeys, ToastMessages, ChartTypes } from "Enums";
import { useStoreContext } from "Stores/SetupStore/Store";

export const getAllChartsFromServer = () => {
  return ConfigurationAPI.get("/charts").then((res) => {
    return res.data;
  });
};

export const UseQuery_getAllCharts = () => {
  return useQuery(QueryKeys.GET_ALL_CHARTS, async () => {
    return getAllChartsFromServer().then((data) => {
      return data;
    });
  });
};

export const getAllInterestChartsFilterFromServer = (columnFilters: string) => {
  return ConfigurationAPI.get(
    `/interest-chart/filter?`.concat(columnFilters)
  ).then((response) => {
    return response.data;
  });
};

export const UseQuery_getAllInterestCharts_filter = (columnFilters: string) => {
  return useQuery(
    [QueryKeys.GET_ALL_INTEREST_CHARTS_FILTER, columnFilters],
    async () => {
      return getAllInterestChartsFilterFromServer(columnFilters).then(
        (data) => {
          return data;
        }
      );
    },
    { keepPreviousData: true }
  );
};

export const UseMutation_AddCharts = (): any => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();
  return useMutation<ICharts>(
    (Charts) => {
      return ConfigurationAPI.post("/charts", Charts);
    },
    {
      onSuccess: async (response: any) => {
        switch (response?.data?.chart_type_id) {
          case ChartTypes.InterestChart:
            queryClient.invalidateQueries(
              QueryKeys.GET_ALL_INTEREST_CHARTS_FILTER
            );
            queryClient.invalidateQueries(QueryKeys.GET_ALL_CHARTS);
            break;
          case ChartTypes.RvChart:
            queryClient.invalidateQueries(QueryKeys.GET_ALL_RV_CHARTS_FILTER);
            queryClient.invalidateQueries(QueryKeys.GET_ALL_CHARTS);
            break;
          case ChartTypes.DownPaymentChart:
            queryClient.invalidateQueries(
              QueryKeys.GET_ALL_DOWN_PAYMENT_CHARTS_FILTER
            );
            queryClient.invalidateQueries(
              QueryKeys.GET_ALL_DOWN_PAYMENT_CHARTS
            );
            break;
          case ChartTypes.CommissionChart:
            queryClient.invalidateQueries(
              QueryKeys.GET_ALL_COMMISSION_CHARTS_FILTER
            );
            queryClient.invalidateQueries(QueryKeys.GET_ALL_COMMISSION_CHARTS);
            break;
          case ChartTypes.SubsidyChart:
            queryClient.invalidateQueries(
              QueryKeys.GET_ALL_SUBSIDY_CHARTS_FILTER
            );
            queryClient.invalidateQueries(QueryKeys.GET_ALL_SUBSIDY_CHARTS);
            break;
          default:
            break;
        }
        if (response?.data?.chart_type_id == 1)
          queryClient.invalidateQueries(
            QueryKeys.GET_ALL_INTEREST_CHARTS_FILTER
          );
        else if (response?.data?.chart_type_id == 2)
          queryClient.invalidateQueries(QueryKeys.GET_ALL_RV_CHARTS_FILTER);
        else if (response?.data?.chart_type_id == 3)
          queryClient.invalidateQueries(
            QueryKeys.GET_ALL_DOWN_PAYMENT_CHARTS_FILTER
          );
        else if (response?.data?.chart_type_id == 4)
          queryClient.invalidateQueries(
            QueryKeys.GET_ALL_COMMISSION_CHARTS_FILTER
          );
        else if (response?.data?.chart_type_id == 5)
          queryClient.invalidateQueries(
            QueryKeys.GET_ALL_SUBSIDY_CHARTS_FILTER
          );
        queryClient.invalidateQueries(QueryKeys.GET_ALL_CHARTS);
        actions.setToast({
          toastMessage: ToastMessages.Record_Added_Success,
          toastState: true,
        });
      },
      onError: async (error: any) => {},
    }
  );
};

export const UseMutation_AddChartsMultiple = (): any => {
  const queryClient = useQueryClient();
  return useMutation<Array<ICharts>>(
    (Charts) => {
      return ConfigurationAPI.post("/Charts/add-multiple", Charts);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_INTEREST_CHARTS_FILTER);
        queryClient.invalidateQueries(QueryKeys.GET_ALL_CHARTS);
      },
    }
  );
};

export const UseMutation_UpdateCharts = (): any => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();
  return useMutation<ICharts>(
    (Charts: any) => {
      return ConfigurationAPI.patch(`/charts/${Charts.id}`, Charts);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_INTEREST_CHARTS_FILTER);
        queryClient.invalidateQueries(QueryKeys.GET_ALL_RV_CHARTS_FILTER);
        queryClient.invalidateQueries(QueryKeys.GET_ALL_SUBSIDY_CHARTS_FILTER);
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_COMMISSION_CHARTS_FILTER
        );
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_DOWN_PAYMENT_CHARTS_FILTER
        );
        queryClient.invalidateQueries(QueryKeys.GET_ALL_CHARTS);
        actions.setToast({
          toastMessage: ToastMessages.Record_Updated_Success,
          toastState: true,
        });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage:
            error.response.data.detail.message ??
            ToastMessages.Internal_Server_Error,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};

export const UseMutation_DeleteCharts = (): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  return useMutation<ICharts>(
    (id) => {
      return ConfigurationAPI.delete(`/charts/${id}`);
    },
    {
      onSuccess: async (response: any) => {
        switch (response?.data?.chart_type_id) {
          case ChartTypes.InterestChart:
            queryClient.invalidateQueries(
              QueryKeys.GET_ALL_INTEREST_CHARTS_FILTER
            );
            queryClient.invalidateQueries(QueryKeys.GET_ALL_CHARTS);
            break;
          case ChartTypes.RvChart:
            queryClient.invalidateQueries(QueryKeys.GET_ALL_RV_CHARTS_FILTER);
            queryClient.invalidateQueries(QueryKeys.GET_ALL_CHARTS);
            break;
          case ChartTypes.DownPaymentChart:
            queryClient.invalidateQueries(
              QueryKeys.GET_ALL_DOWN_PAYMENT_CHARTS_FILTER
            );
            queryClient.invalidateQueries(
              QueryKeys.GET_ALL_DOWN_PAYMENT_CHARTS
            );
            break;
          case ChartTypes.CommissionChart:
            queryClient.invalidateQueries(
              QueryKeys.GET_ALL_COMMISSION_CHARTS_FILTER
            );
            queryClient.invalidateQueries(QueryKeys.GET_ALL_COMMISSION_CHARTS);
            break;
          case ChartTypes.SubsidyChart:
            queryClient.invalidateQueries(
              QueryKeys.GET_ALL_SUBSIDY_CHARTS_FILTER
            );
            queryClient.invalidateQueries(QueryKeys.GET_ALL_SUBSIDY_CHARTS);
            break;
          default:
            break;
        }
        actions.setToast({
          toastMessage: ToastMessages.Record_Deleted_Success,
          toastState: true,
        });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage:
            error.response.data.detail.message ??
            ToastMessages.Internal_Server_Error,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};

export const UseMutation_CheckDuplicateChartName: any = () => {
  const { data, isLoading, mutate } = useMutation(
    (body: any) => {
      let params = body.data ? `${body.data}` : "";
      return ConfigurationAPI.get<any>(
        params ? `${"/chart/name"}?${params}` : "/chart/name"
      );
    },
    {
      onSuccess(_response: any) {},
    }
  );
  return { data, isLoading, mutate };
};

export const UseQuery_getAllChartsFilter = (columnFilters: string) => {
  return useQuery(
    [QueryKeys.GET_ALL_CHARTS_FILTER, columnFilters],
    async () => {
      return ConfigurationAPI.get("/charts/filter/?".concat(columnFilters))
        .then((res) => {
          return res.data;
        })
        .then((data) => {
          return data;
        });
    },
    { keepPreviousData: true }
  );
};

export const UseMutation_AddBaseRateCharts = (): any => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();
  return useMutation<IBaseChart>(
    (Charts) => {
      return ConfigurationAPI.post("/charts/base_rate/", Charts);
    },
    {
      onSuccess: async (response: any) => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_CHARTS_FILTER);
        actions.setToast({
          toastMessage: ToastMessages.Record_Added_Success,
          toastState: true,
        });
      },
      onError: (error: any) => {
        actions.setToast({
          toastMessage:
            error?.response?.data?.detail?.message ?? "Internal Server Error",
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};

export const UseMutation_DeleteBaseRateChart = (): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  return useMutation(
    (id) => {
      return ConfigurationAPI.delete(`/charts/base_rate/${id}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_CHARTS_FILTER);
        actions.setToast({
          toastMessage: ToastMessages.Record_Deleted_Success,
          toastState: true,
        });
      },
      onError: (error: any) => {
        actions.setToast({
          toastMessage:
            error?.response?.data?.detail?.message ?? "Internal Server Error",
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};

export const UseMutation_UpdateBaseCharts = (): any => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();
  return useMutation<IBaseChart>(
    (Charts: any) => {
      return ConfigurationAPI.patch(`/charts/base_rate/${Charts.id}`, Charts);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_CHARTS_FILTER);
        actions.setToast({
          toastMessage: ToastMessages.Record_Updated_Success,
          toastState: true,
        });
      },
      onError: (error: any) => {
        actions.setToast({
          toastMessage:
            error?.response?.data?.detail ?? "Internal Server Error",
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};

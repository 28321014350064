import { FC, useEffect, useState } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { DataTable, Box, Icon, Input, Button } from "@ntpkunity/controls";
import { TablePagination, useTheme } from "@mui/material";
import ChevronDown from "../../shared/assets/images/chevron-down";
import {
  UseQuery_getAllCharts,
  UseQuery_getAllInterestCharts_filter,
} from "services";

import InterestChartTable from "./interest-chart-table";
import { ChartTypes } from "Enums";
import { useQueryClient } from "react-query";

const ChartTable: FC<{
  onOptionsClick: (key, value) => unknown;
  onEditCriteria: (data) => unknown;
  chartTypeValue: string;
}> = ({ onOptionsClick, onEditCriteria, chartTypeValue }) => {
  UseQuery_getAllCharts();
  const queryClient = useQueryClient();
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [columnFilters, setColumnFilters] = useState(
    `page_number=${pageNumber}&page_size=${pageSize}&output_parameter_type=${chartTypeValue}`
  );

  const { data } = UseQuery_getAllInterestCharts_filter(columnFilters);
  const InterestChartsFilter: any = data;
  const [nameFilter, setNameFilter] = useState("");
  const [assetsFilter, setAssetsFilter] = useState("");
  const [manufacturerFilter, setManufacturerFilter] = useState("");
  const [purchaseOptionFilter, setPurchaseOptionFilter] = useState("");
  const [assetConditionFilter, setAssetConditionFilter] = useState("");
  const [termsFilter, setTermsFilter] = useState("");
  const [creditsFilter, setCreditsFilter] = useState("");
  const [financedAmountFilter, setFinancedAmountFilter] = useState("");
  const [marginFilter, setMarginFilter] = useState("");
  const [businessUnitFilter, setBusinessUnitFilter] = useState("");
  const [lateChargeFilter, setLateChargeFilter] = useState("");
  const [propertyTaxFilter, setPropertyTaxFilter] = useState("");
  const [originationFeeFilter, setOriginationFeeFilter] = useState("");
  const [assetUsageFilter, setAssetUsageFilter] = useState("");
  const [marginRangeFilter, setMarginRangeFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  useEffect(() => {
    setColumnFilters(
      `page_number=${pageNumber}&page_size=${pageSize}&output_parameter_type=${chartTypeValue}`
    );
  }, [chartTypeValue]);
  const setQueryString = (pgNo, pgSize) => {
    let query_string: string = `page_number=${pgNo}&page_size=${pgSize}&output_parameter_type=${chartTypeValue}`;
    if (nameFilter.trimStart() != null && nameFilter.trimStart() != "") {
      query_string = query_string.concat(`&name=${nameFilter}`);
    }
    if (assetsFilter.trimStart() != null && assetsFilter.trimStart() != "") {
      query_string = query_string.concat(`&assets=${assetsFilter}`);
    }
    if (
      manufacturerFilter.trimStart() != null &&
      manufacturerFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(`&manufacturer=${manufacturerFilter}`);
    }
    if (
      purchaseOptionFilter.trimStart() != null &&
      purchaseOptionFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(
        `&purchase_option=${purchaseOptionFilter}`
      );
    }
    if (
      assetConditionFilter.trimStart() != null &&
      assetConditionFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(
        `&asset_condition=${assetConditionFilter}`
      );
    }
    if (
      assetUsageFilter.trimStart() != null &&
      assetUsageFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(`&asset_usages=${assetUsageFilter}`);
    }
    if (termsFilter.trimStart() != null && termsFilter.trimStart() != "") {
      query_string = query_string.concat(`&terms=${termsFilter}`);
    }
    if (creditsFilter.trimStart() != null && creditsFilter.trimStart() != "") {
      query_string = query_string.concat(`&credit_score=${creditsFilter}`);
    }
    if (
      financedAmountFilter.trimStart() != null &&
      financedAmountFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(
        `&financed_amount=${financedAmountFilter}`
      );
    }
    if (
      businessUnitFilter.trimStart() != null &&
      businessUnitFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(
        `&business_units=${businessUnitFilter}`
      );
    }
    if (
      lateChargeFilter.trimStart() != null &&
      lateChargeFilter.trimStart() != ""
    ) {
      if (
        "yes".startsWith(
          lateChargeFilter.toLowerCase().slice(0, Math.max("yes".length - 1, 1))
        )
      ) {
        query_string = query_string.concat(
          `&late_charge_inclusive_adder=${true}`
        );
      } else if (
        "no".startsWith(
          lateChargeFilter.toLowerCase().slice(0, Math.max("no".length - 1, 1))
        )
      ) {
        query_string = query_string.concat(
          `&late_charge_inclusive_adder=${false}`
        );
      } else {
        query_string = query_string.concat(`&late_charge_inclusive_adder=None`);
      }
    }
    if (
      propertyTaxFilter.trimStart() != null &&
      propertyTaxFilter.trimStart() != ""
    ) {
      if (
        "yes".startsWith(
          propertyTaxFilter
            .toLowerCase()
            .slice(0, Math.max("yes".length - 1, 1))
        )
      ) {
        query_string = query_string.concat(
          `&property_tax_inclusive_adder=${true}`
        );
      } else if (
        "no".startsWith(
          propertyTaxFilter.toLowerCase().slice(0, Math.max("no".length - 1, 1))
        )
      ) {
        query_string = query_string.concat(
          `&property_tax_inclusive_adder=${false}`
        );
      } else {
        query_string = query_string.concat(
          `&property_tax_inclusive_adder=None`
        );
      }
    }
    if (
      originationFeeFilter.trimStart() != null &&
      originationFeeFilter.trimStart() != ""
    ) {
      if (
        "yes".startsWith(
          originationFeeFilter
            .toLowerCase()
            .slice(0, Math.max("yes".length - 1, 1))
        )
      ) {
        query_string = query_string.concat(
          `&origination_fee_inclusive_adder=${true}`
        );
      } else if (
        "no".startsWith(
          originationFeeFilter
            .toLowerCase()
            .slice(0, Math.max("no".length - 1, 1))
        )
      ) {
        query_string = query_string.concat(
          `&origination_fee_inclusive_adder=${false}`
        );
      } else {
        query_string = query_string.concat(
          `&origination_fee_inclusive_adder=None`
        );
      }
    }
    if (marginFilter.trimStart() != null && marginFilter.trimStart() != "") {
      query_string = query_string.concat(`&margin=${marginFilter}`);
    }
    if (
      marginRangeFilter.trimStart() != null &&
      marginRangeFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(`&margin_range=${marginRangeFilter}`);
    }
    if (statusFilter.trimStart() != null && statusFilter.trimStart() != "") {
      if (
        "enabled".startsWith(
          statusFilter.toLowerCase().slice(0, Math.max("enabled".length - 1, 1))
        )
      ) {
        query_string = query_string.concat(`&is_active=${true}`);
      } else if (
        "disabled".startsWith(
          statusFilter
            .toLowerCase()
            .slice(0, Math.max("disabled".length - 1, 1))
        )
      ) {
        query_string = query_string.concat(`&is_active=${false}`);
      } else {
        query_string = query_string.concat(`&is_active=None`);
      }
    }
    return query_string;
  };
  // useEffect(() => {
  //   if (charts != undefined && charts != null) {
  //     setChartsData(
  //       charts.filter((item) => item.chart_type_id == ChartTypes.InterestChart)
  //     );
  //   }
  // }, [charts]);

  const handleFilter = () => {
    setPageNumber(0);
    setColumnFilters(setQueryString(0, pageSize));
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPageNumber(newPage);
    setColumnFilters(setQueryString(newPage, pageSize));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(+event.target.value);
    setPageNumber(0);
    setColumnFilters(setQueryString(0, +event.target.value));
  };

  const theme = useTheme();

  return (
    <>
      <DataTable
        theme={theme}
        theadChildren={
          <>
            <TableRow>
              <TableCell>Chart Name </TableCell>
              <TableCell className="w-auto">Asset Make/Model/Trim</TableCell>
              <TableCell>Manufacturer</TableCell>
              <TableCell>Purchase Option</TableCell>
              <TableCell>Asset Condition</TableCell>
              <TableCell>Asset Usage</TableCell>
              <TableCell>Terms</TableCell>
              <TableCell>Credit Score </TableCell>
              <TableCell>Financed Amount </TableCell>
              <TableCell>Business Unit </TableCell>
              <TableCell className="w-auto">
                Late Charge Inclusive Adder{" "}
              </TableCell>
              <TableCell className="w-auto">
                Property Tax Inclusive Adder{" "}
              </TableCell>
              <TableCell className="w-auto">
                Origination Fee Inclusive Adder{" "}
              </TableCell>
              <TableCell>Margin</TableCell>
              <TableCell>Margin Range</TableCell>
              <TableCell>Status</TableCell>
              <TableCell className="action-cell fixed-cell space-fixed" />
            </TableRow>
            <TableRow className="filters-row">
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setNameFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={nameFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>

              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setAssetsFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={assetsFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setManufacturerFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={manufacturerFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setPurchaseOptionFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={purchaseOptionFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setAssetConditionFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={assetConditionFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setAssetUsageFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={assetUsageFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>

              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setTermsFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={termsFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>

              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setCreditsFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={creditsFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setFinancedAmountFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={financedAmountFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setBusinessUnitFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={businessUnitFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setLateChargeFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={lateChargeFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setPropertyTaxFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={propertyTaxFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>

              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setOriginationFeeFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={originationFeeFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setMarginFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={marginFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>

              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setMarginRangeFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={marginRangeFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>

              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setStatusFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={statusFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>

              <TableCell className="action-cell fixed-cell space-fixed" />
            </TableRow>
          </>
        }
        tbodyChildren={
          <>
            {InterestChartsFilter &&
              InterestChartsFilter.result
                ?.filter((x) => x.chart_type_id == ChartTypes.InterestChart)
                ?.map((item, index) => (
                  <InterestChartTable
                    key={index}
                    chart={item}
                    onOptionsClick={(key, value) => onOptionsClick(key, value)}
                    onEditCriteria={(data) => onEditCriteria(data)}
                  />
                ))}
          </>
        }
      />
      <TablePagination
        rowsPerPageOptions={[5, 10, 15, 20]}
        component="div"
        count={InterestChartsFilter ? InterestChartsFilter?.total_results : -1}
        rowsPerPage={pageSize}
        page={pageNumber}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        SelectProps={{
          IconComponent: ChevronDown,
        }}
      />
    </>
  );
};

export default ChartTable;

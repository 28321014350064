import { FC, useState, useEffect } from "react";
import { useTheme } from "@mui/material";
import { Accordion, Button, Icon, Input } from "@ntpkunity/controls";
import { Controller } from "react-hook-form";

const DealerSocial: FC<{
  form: any;
}> = ({ form }) => {
  const theme = useTheme();
  const {
    control,
    resetField,
  } = form;

  const [isExpanded, setIsExpanded] = useState("facebook");

  return (
    <>
      <Accordion
        theme={theme}
        varient="grouped"
        items={[
          {
            title: "Facebook",
            subtitle: "www.facebook.com/sample-text",
            isExpanded: isExpanded.includes("facebook"),
            key: "0",
            content: (
              <>
                <Controller
                  name="profile.facebook"
                  control={control}
                  render={({ field }) => (
                    <Input
                      fullWidth
                      type={"text"}
                      theme={theme}
                      {...field}
                      label="URL"
                      placeholder="Type here.."
                      value={field?.value ?? ""}
                      onChange={(e) => {
                        setIsExpanded("facebook");
                        field.onChange(e);
                      }}
                    />
                  )}
                />
              </>
            ),
            actionBtn: (
              <>
                <Button
                  theme={theme}
                  defaultBtn
                  iconText={<Icon name="DeleteIcon" />}
                  onClick={() => {
                    setIsExpanded("facebook");
                    resetField("profile.facebook", { defaultValue: "" });
                  }}
                />
              </>
            ),
          },
          {
            title: "Google",
            isExpanded: isExpanded.includes("google"),
            key: "1",
            content: (
              <>
                <Controller
                  name="profile.google"
                  control={control}
                  render={({ field }) => (
                    <Input
                      fullWidth
                      type={"text"}
                      theme={theme}
                      {...field}
                      label="URL"
                      placeholder="Type here.."
                      value={field?.value ?? ""}
                      onChange={(e) => {
                        setIsExpanded("google");
                        field.onChange(e);
                      }}
                    />
                  )}
                />
              </>
            ),
            actionBtn: (
              <>
                <Button
                  theme={theme}
                  defaultBtn
                  iconText={<Icon name="DeleteIcon" />}
                  onClick={() => {
                    setIsExpanded("google");
                    resetField("profile.google", { defaultValue: "" });
                  }}
                />
              </>
            ),
          },
          {
            title: "Instagram",
            isExpanded: isExpanded.includes("instagram"),
            key: "2",
            content: (
              <>
                <Controller
                  name="profile.instagram"
                  control={control}
                  render={({ field }) => (
                    <Input
                      fullWidth
                      type={"text"}
                      theme={theme}
                      label="URL"
                      placeholder="Type here.."
                      value={field?.value ?? ""}
                      onChange={(e) => {
                        setIsExpanded("instagram");
                        field.onChange(e);
                      }}
                    />
                  )}
                />
              </>
            ),
            actionBtn: (
              <>
                <Button
                  theme={theme}
                  defaultBtn
                  iconText={<Icon name="DeleteIcon" />}
                  onClick={(event) => {
                    setIsExpanded("instagram");
                    resetField("profile.instagram", { defaultValue: "" });
                  }}
                />
              </>
            ),
          },
          {
            title: "LinkedIn",
            subtitle: "www.linkedin.com/sample-text",
            isExpanded: isExpanded.includes("linkedin"),
            key: "3",
            content: (
              <>
                <Controller
                  name="profile.linkedin"
                  control={control}
                  render={({ field }) => (
                    <Input
                      fullWidth
                      type={"text"}
                      theme={theme}
                      label="URL"
                      placeholder="Type here.."
                      value={field?.value ?? ""}
                      onChange={(e) => {
                        setIsExpanded("linkedin");
                        field.onChange(e);
                      }}
                    />
                  )}
                />
              </>
            ),
            actionBtn: (
              <>
                <Button
                  theme={theme}
                  defaultBtn
                  iconText={<Icon name="DeleteIcon" />}
                  onClick={() => {
                    setIsExpanded("linkedin");
                    resetField("profile.linkedin", { defaultValue: "" });
                  }}
                />
              </>
            ),
          },
          {
            title: "Twitter",
            subtitle: "www.twitter.com/sample-text",
            isExpanded: isExpanded.includes("twitter"),
            key: "4",
            content: (
              <>
                <Controller
                  name="profile.twitter"
                  control={control}
                  render={({ field }) => (
                    <Input
                      fullWidth
                      type={"text"}
                      theme={theme}
                      label="URL"
                      placeholder="Type here.."
                      value={field?.value ?? ""}
                      onChange={(e) => {
                        setIsExpanded("twitter");
                        field.onChange(e);
                      }}
                    />
                  )}
                />
              </>
            ),
            actionBtn: (
              <>
                <Button
                  theme={theme}
                  defaultBtn
                  iconText={<Icon name="DeleteIcon" />}
                  onClick={() => {
                    setIsExpanded("youtube");
                    resetField("profile.twitter", { defaultValue: "" });
                  }}
                />
              </>
            ),
          },
          {
            title: "Youtube",
            isExpanded: isExpanded.includes("youtube"),
            key: "5",
            content: (
              <>
                <Controller
                  name="profile.youtube"
                  control={control}
                  render={({ field }) => (
                    <Input
                      fullWidth
                      type={"text"}
                      theme={theme}
                      label="URL"
                      placeholder="Type here.."
                      value={field?.value ?? ""}
                      onChange={(e) => {
                        setIsExpanded("youtube");
                        field.onChange(e);
                      }}
                    />
                  )}
                />
              </>
            ),
            actionBtn: (
              <>
                <Button
                  theme={theme}
                  defaultBtn
                  iconText={<Icon name="DeleteIcon" />}
                  onClick={(event) => {
                    setIsExpanded("youtube");
                    resetField("profile.youtube", { defaultValue: "" });
                  }}
                />
              </>
            ),
          },
        ]}
      />
    </>
  );
};

export default DealerSocial;

import { FC, useState, useEffect } from "react";
import { useTheme } from "@mui/material";
import { DataTableWrap } from "../../shared/components";
import { Box, Button } from "@ntpkunity/controls";
import { AddNewBusinessUnit } from "../../components";
import { IBusinessUnits } from "../../Interface";
import BusinessUnitTable from "./business-unit-table";
import { IBusinessUnitProps } from "./business-unit";
import { setOpenDrawer } from "../../Stores/SetupStore/Actions";
import { useSetupsStore } from "../../Stores/SetupStore/Store";
import { ActionOptions } from "Enums";

const BusinessUnit = () => {
  const [businessUnitState, setBusinessUnitState] = useState<boolean>(false);
  const [actionTitle, setActionTitle] = useState<string>(
    "Add New Business Unit"
  );
  const [actionType, setActionType] = useState<ActionOptions>(
    ActionOptions.ADD
  );
  const [state, dispatch] = useSetupsStore();
  const [actionData, setActionData] = useState<IBusinessUnits>();
  const theme = useTheme();

  useEffect(() => {
    if (businessUnitState === false) {
      dispatch(setOpenDrawer(false));
    }
  }, [businessUnitState]);

  const handleSelection = (event, key, value) => {
    switch (key) {
      case ActionOptions.ADD:
        setActionType(ActionOptions.ADD);
        setActionTitle("Add New Business Unit");
        setActionData(value);
        setBusinessUnitState(true);
        dispatch(setOpenDrawer(true));
      default:
        return;
    }
  };

  const onEdit = (formData: IBusinessUnits) => {
    setActionType(ActionOptions.EDIT);
    setActionTitle("Edit Business Unit");
    setActionData(formData);
    setBusinessUnitState(true);
    dispatch(setOpenDrawer(true));
  };
  return (
    <>
      <DataTableWrap className="table-tabs-pagination-button" key={1}>
        <Box theme={theme} className="scroll">
          <Box theme={theme} className="scroll-hide spr-border" />
        </Box>
        <BusinessUnitTable onEdit={(data) => onEdit(data)} />
        <Button
          theme={theme}
          secondary
          fullWidth={true}
          text={"Add New Business Unit"}
          onClick={(e) => handleSelection(e, ActionOptions.ADD, undefined)}
        ></Button>
        {businessUnitState && (
          <AddNewBusinessUnit
            openPopUp={businessUnitState}
            setPopUpState={setBusinessUnitState}
            title={actionTitle}
            actionType={actionType}
            businessUnitData={actionData}
          />
        )}
      </DataTableWrap>
    </>
  );
};
export default BusinessUnit;

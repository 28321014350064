import { Theme } from "@mui/material";
import {
  Button,
  Grid,
  Input,
  Box,
  Icon,
  FileDragDrop,
  IBoxProps,
  Tooltip,
  CircleLoader,
} from "@ntpkunity/controls";
import React, { useEffect } from "react";
import { Controller } from "react-hook-form";
import { styled } from "@mui/material/styles";
import {
  UseMutation_uploadS3Image,
  UseQuery_getThemeBuilderbyEmail,
  useMutation_CheckS3Credentials,
} from "services";
import { useQueryClient } from "react-query";
import { ActionTypes, QueryKeys } from "Enums";
import { Switch } from "shared/components";
const UserProfileWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  // display: "table",
  // margin: "10vh auto",
  // maxWidth: 464,
  width: "100%",
  display: "inline-block",
  marginBottom: 32,
  ".left-section": {
    borderRight: "1px solid" + theme.palette.grey[100],
    paddingRight: 32,
    ".profile": {
      maxWidth: 464,
      margin: "auto",
      marginTop: 80,
      ".section": {
        marginBottom: 40,
        paddingBottom: 32,
        borderBottom: "1px solid" + theme.palette.divider,
        display: "inline-block",
      },
    },
  },

  ".u-custom-file-upload": {
    marginBottom: 0,
    ".root-drag-file": {
      height: 261,
    },
  },
  ".u-form-group": {
    ".MuiInputBase-root": {
      ".startAdornment": {
        span: {
          opacity: "60%",
        },
      },
      ".color-picker": {
        cursor: "pointer",
        ".color": {
          display: "block",
          width: 16,
          height: 16,
          borderRadius: 4,
        },
      },
    },
  },

  ".toggle-bar": {
    backgroundColor: theme.customVariables.tableStripedBg,
    borderRadius: theme.shape.borderRadius,
    padding: 16,
    width: "100%",
    display: "inline-block",
  },

  "@media screen and (max-width: 960px)": {
    ".left-section": {
      paddingLeft: 0,
      borderRight: "none",
      borderBottom: "1px solid" + theme.palette.grey[100],
      ".profile": {
        marginBottom: 32,
        maxWidth: "100%",
      },
    },
    ".u-custom-file-upload": {
      marginBottom: 0,
      ".root-drag-file": {
        height: 261,
      },
    },
  },
}));

const ImgLoader = styled(Box)(() => ({
  ".loader-wrap": {
    ".loader": {
      position: "absolute !important",
      transform: "initial !important",
      top: "50%",
      left: "50%",
    },
  },
}));

const CustomImageUploader = styled("div")(({ theme }) => ({
  marginBottom: 24,
  position: "relative",
  ".img-wrap": {
    width: "100%",
    height: 224,
    display: "flex",
    verticalAlign: "middle",
    textAlign: "center",
    borderRadius: theme.shape.borderRadius,
    border: "2px dashed" + theme.palette.grey[100],
    overflow: "hidden",

    img: {
      maxWidth: "100%",
      maxHeight: "100%",
      width: "auto",
      height: "auto",
      display: "block",
      margin: "auto",
    },

    ".btn-close": {
      backgroundColor: theme.palette.common.white,
      padding: "5px !important",
      position: "absolute",
      top: 10,
      right: 10,
      height: 26,
      width: 26,
      border: "1px solid" + theme.palette.grey[100],
      borderRadius: "100%",
      "&:hover": {
        backgroundColor: theme.palette.grey[50],
      },
    },
  },
}));

interface IThemeProps {
  theme: Theme;
  value?: any;
  form: any;
  defaultSwitch?: boolean;
  setDealerThemeBuilder?: any;
}
const ThemeBuilderLayout: React.FC<IThemeProps> = ({
  theme,
  value,
  form,
  defaultSwitch,
  setDealerThemeBuilder,
}) => {
  const {
    control,
    formState: { isDirty, errors },
    setValue,
    trigger,
    watch,
    getValues,
  } = form;
  const { mutate: uploadImage } = UseMutation_uploadS3Image();
  const queryClient = useQueryClient();
  const LenderProfileData: any = queryClient.getQueryData(
    QueryKeys.GET_LENDER_PROFILE
  );
  const settings: any = localStorage?.getItem("settings");
  const parsedSettingsObject = JSON.parse(settings);
  const { mutate: checkS3Credentials, data: s3Response } =
    useMutation_CheckS3Credentials();
  const { data } = UseQuery_getThemeBuilderbyEmail(LenderProfileData?.email);
  useEffect(() => {
    checkS3Credentials(parsedSettingsObject?.tenant_id);
  }, []);

  const onimageUpload = (e: any, name: any) => {
    if (e && e?.target && e?.target?.files && e?.target?.files.length > 0) {
      const obj: any = {
        tenant_id: parsedSettingsObject?.tenant_id,
      };
      const body = new FormData();
      const file = e.target.files[0];
      body.append("files", file);
      obj.files = body;
      uploadImage(obj, {
        onSuccess(response: any) {
          setValue(name, { location: response.data[0].presigned_url });
          setValue(name + "_url", {
            url: response.data[0].presigned_url,
            key: response.data[0].key,
          });
        },
      });
    }
  };
  const onimageDrop = (e: any, name: any) => {
    if (
      e &&
      e?.dataTransfer &&
      e?.dataTransfer?.files &&
      e?.dataTransfer?.files.length > 0
    ) {
      const obj: any = {
        tenant_id: parsedSettingsObject?.tenant_id,
      };
      const body = new FormData();
      const file = e.dataTransfer.files[0];
      body.append("files", file);
      obj.files = body;
      uploadImage(obj, {
        onSuccess(response: any) {
          setValue(name, response.data[0].presigned_url);
          setValue(name + "_url", {
            url: response.data[0].presigned_url,
            key: response.data[0].key,
          });
        },
      });
    }
  };

  const onFileChange = (event: any, type: "theme.favicon" | "theme.logo") => {
    setValue(type, (event?.dataTransfer ?? event?.target).files?.[0], {
      shouldDirty: true,
    });
  };
  const isValidUrl = (url: any) => {
    const urlPattern =
      /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;
    return urlPattern.test(url);
  };

  const logoImage = (image: any) => {
    let image_url;
    const validUrl = isValidUrl(image);
    if (!validUrl) {
      image_url = URL.createObjectURL(image as unknown as File);
    }
    const url = validUrl ? image : image_url ?? "";
    return url;
  };
  const hasFavicon = watch("theme.favicon");
  const hasLogo = watch("theme.logo");

  const handleButtonClick = async (stylesheetlink: any) => {
    const s3FileLink = stylesheetlink;
    try {
      const response = await fetch(s3FileLink);
      const cssContent = await response.text();
      const primaryColorRegex = /--primary:\s*#?([a-fA-F0-9]+)\b/;
      const secondaryColorRegex = /--secondary:\s*#?([a-fA-F0-9]+)\b/;

      // Extract primary and secondary color values from the CSS string
      const primaryColorMatch = cssContent.match(primaryColorRegex);
      const secondaryColorMatch = cssContent.match(secondaryColorRegex);
      // const matches = cssContent.match(colorRegex);
      const primaryColor = primaryColorMatch
        ? primaryColorMatch[1].trim()
        : null;
      const secondaryColor = secondaryColorMatch
        ? secondaryColorMatch[1].trim()
        : null;

      setValue("theme.primary_color", primaryColor);
      setValue("theme.secondary_color", secondaryColor);
      return { primaryColor: primaryColor, secondaryColor: secondaryColor };
    } catch (error: any) {
      console.error("Error:", error.message);
    }
  };
  const lenderTheme = (event) => {
    if (event.target.checked == true && data) {
      setValue("theme.is_lender_theme_active", true, {
        shouldDirty: true,
      });
      updateFormValues(data);
    } else {
      setValue("theme.is_lender_theme_active", false, {
        shouldDirty: true,
      });
    }
  };
  const updateFormValues = (response: any) => {
    setValue("theme.stylesheet", response.stylesheet);
    setValue("theme.favicon", response.favicon);
    setValue("theme.logo", response.logo);
    setValue("theme.user_email", response.user_email);
    handleButtonClick(data.stylesheet);
  };
  return (
    <>
      <form>
        <UserProfileWrap theme={theme}>
          {defaultSwitch && (
            <Box theme={theme} className="toggle-bar" sx={{ mb: 3 }}>
              <Controller
                name="theme.is_lender_theme_active"
                control={control}
                defaultValue={true}
                render={({ field: { value, onChange } }) => (
                  <Switch
                    varient="basic"
                    theme={theme}
                    label="Use lender personalisation"
                    switchEnabled={value}
                    onChange={(event) => {
                      lenderTheme(event);
                    }}
                  />
                )}
              />
            </Box>
          )}
          <Grid theme={theme} container item spacing={3}>
            <Grid theme={theme} item lg={6} md={6} sm={6} xs={12}>
              <Controller
                name="theme.primary_color"
                control={control}
                defaultValue={""}
                rules={{
                  required: "Primary Color is Required",
                  minLength: {
                    value: 3,
                    message: "Please provide valid hex code",
                  },
                }}
                render={({ field: { value, onChange } }) => (
                  <Input
                    theme={theme}
                    fullWidth={true}
                    label={"Primary Color"}
                    type="text"
                    placeholder="00249C"
                    value={value?.trimStart()}
                    onChange={onChange}
                    disabled={watch("theme.is_lender_theme_active")}
                    startAdornment={
                      <>
                        <Box theme={theme} className="startAdornment">
                          <span>#</span>
                        </Box>
                      </>
                    }
                    endAdornment={
                      <>
                        <Box theme={theme} className="color-picker">
                          <Box
                            theme={theme}
                            className="color"
                            sx={{
                              backgroundColor: `#${watch(
                                "theme.primary_color"
                              )}`,
                            }}
                          ></Box>
                        </Box>
                      </>
                    }
                    error={errors?.theme?.primary_color?.message?.toString()}
                  />
                )}
              />
            </Grid>
            <Grid theme={theme} item lg={6} md={6} sm={6} xs={12}>
              <Controller
                name="theme.secondary_color"
                control={control}
                defaultValue={""}
                rules={{
                  required: "Secondary Color is Required",
                  minLength: {
                    value: 3,
                    message: "Please provide valid hex code",
                  },
                }}
                render={({ field: { value, onChange } }) => (
                  <Input
                    theme={theme}
                    fullWidth={true}
                    label={"Secondary Color"}
                    type="text"
                    value={value?.trimStart()}
                    onChange={onChange}
                    placeholder="5F5F5F"
                    disabled={watch("theme.is_lender_theme_active")}
                    startAdornment={
                      <>
                        <Box theme={theme} className="startAdornment">
                          <span>#</span>
                        </Box>
                      </>
                    }
                    endAdornment={
                      <>
                        <Box theme={theme} className="color-picker">
                          <Box
                            theme={theme}
                            className="color"
                            sx={{
                              backgroundColor: `#${watch(
                                "theme.secondary_color"
                              )}`,
                            }}
                          ></Box>
                        </Box>
                      </>
                    }
                    error={errors?.theme?.secondary_color?.message?.toString()}
                  />
                )}
              />
            </Grid>
            <Grid theme={theme} item xs={6}>
              {hasFavicon ? (
                <CustomImageUploader>
                  <Box theme={theme} className="img-wrap">
                    <Button
                      onClick={() =>
                        setValue("theme.favicon", "", {
                          shouldDirty: true,
                        })
                      }
                      disabled={
                        watch("theme.is_lender_theme_active") ||
                        !s3Response?.data
                      }
                      theme={theme}
                      className="btn-close"
                      iconText={<Icon name="CloseBlack" />}
                    />
                    {typeof hasFavicon == "object" ||
                    typeof hasFavicon == "string" ? (
                      <img
                        src={
                          typeof hasFavicon == "object"
                            ? (getValues("theme.favicon").location as any)
                            : getValues("theme.favicon")
                        }
                        alt="FaviconImage"
                      />
                    ) : (
                      <ImgLoader theme={theme} sx={{ mb: 1, mt: 3 }}>
                        <CircleLoader theme={theme} size="xs" />
                      </ImgLoader>
                    )}
                  </Box>
                </CustomImageUploader>
              ) : (
                <>
                  <Controller
                    name="theme.favicon"
                    control={control}
                    defaultValue={
                      "https://unitydmsbucket.s3.us-east-1.amazonaws.com/images/dealex.svg"
                    }
                    rules={{
                      validate: (_value) => {
                        if (
                          !watch("theme.favicon") ||
                          watch("theme.favicon") == ""
                        ) {
                          return "Favicon is required";
                        }
                        return true;
                      },
                    }}
                    render={({ field }) => (
                      <Tooltip
                        theme={theme}
                        title={
                          !s3Response?.data
                            ? ActionTypes.STORAGE_PATH_NOT_CONFIGURED
                            : ""
                        }
                      >
                        <FileDragDrop
                          {...field}
                          theme={theme}
                          id="favicon_id"
                          hoverLabel="Drag and drop favicon image file, or browse"
                          onChange={(e) => {
                            onFileChange(e, "theme.favicon");
                            onimageUpload(e, "theme.favicon");
                          }}
                          onDrop={(e) => {
                            onFileChange(e, "theme.favicon");
                            onimageDrop(e, "theme.favicon");
                          }}
                          error={errors?.theme?.favicon?.message?.toString()}
                          disabled={
                            watch("theme.is_lender_theme_active") ||
                            !s3Response?.data
                          }
                        />
                      </Tooltip>
                    )}
                  />
                </>
              )}
            </Grid>
            <Grid theme={theme} item xs={6}>
              {hasLogo ? (
                <CustomImageUploader>
                  <Box theme={theme} className="img-wrap">
                    <Button
                      onClick={() =>
                        setValue("theme.logo", "", {
                          shouldDirty: true,
                        })
                      }
                      theme={theme}
                      className="btn-close"
                      disabled={
                        watch("theme.is_lender_theme_active") ||
                        !s3Response?.data
                      }
                      iconText={<Icon name="CloseBlack" />}
                    />
                    {typeof hasLogo == "object" ||
                    typeof hasLogo == "string" ? (
                      <img
                        src={
                          typeof hasLogo == "object"
                            ? (getValues("theme.logo").location as any)
                            : getValues("theme.logo")
                        }
                        alt="CloseIcon"
                      />
                    ) : (
                      <ImgLoader theme={theme} sx={{ mb: 1, mt: 3 }}>
                        <CircleLoader theme={theme} size="xs" />
                      </ImgLoader>
                    )}
                  </Box>
                </CustomImageUploader>
              ) : (
                <>
                  <Controller
                    name="theme.logo"
                    control={control}
                    rules={{
                      validate: (_value) => {
                        if (!watch("theme.logo") || watch("theme.logo") == "") {
                          return "Logo is required";
                        }
                        return true;
                      },
                    }}
                    // defaultValue={bmwLogo}
                    render={({ field }) => (
                      <Tooltip
                        theme={theme}
                        title={
                          !s3Response?.data
                            ? ActionTypes.STORAGE_PATH_NOT_CONFIGURED
                            : ""
                        }
                      >
                        <FileDragDrop
                          {...field}
                          theme={theme}
                          id="logo_id"
                          hoverLabel="Drag and drop logo image file, or browse"
                          onChange={(e) => {
                            onFileChange(e, "theme.logo");
                            onimageUpload(e, "theme.logo");
                          }}
                          onDrop={(e) => {
                            onFileChange(e, "theme.logo");
                            onimageDrop(e, "theme.logo");
                          }}
                          error={errors?.theme?.logo?.message}
                          disabled={
                            watch("theme.is_lender_theme_active") ||
                            !s3Response?.data
                          }
                        />
                      </Tooltip>
                    )}
                  />
                </>
              )}
            </Grid>
            {/* <Grid theme={theme} item xs={12}>
          <Button
            theme={theme}
            primary
            fullWidth
            text={'Save Changes'}
            disabled={!isDirty}
            onClick={handleSubmit(onSave)}
          />
        </Grid> */}
          </Grid>
        </UserProfileWrap>
      </form>
    </>
  );
};

export default ThemeBuilderLayout;

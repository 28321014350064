import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { FormHelperText, styled } from "@mui/material";
import { ISelect } from "@ntpkunity/controls";
import Checkbox from "shared/components/Checkbox/Checkbox";
import ChevronDown from "shared/assets/images/chevron-down";
import clsx from "clsx";
import { useEffect } from "react";

const CustomizedSelectWrapper = styled(
  "div",
  {}
)<Partial<ISelect>>(({ theme }) => ({
  "&.u-form-group": {
    marginBottom: 24,
    "&:last-child": {
      marginBottom: 0,
    },
    ".u-form-control": {
      height: 48,
      color: "rgba(0,0,0,0.87)",
      "&.u-select": {
        ".MuiInputBase-input": {
          padding: "12px 40px 12px 16px",
        },
        svg: {
          position: "absolute",
          top: "auto",
          right: 12,
          cursor: "pointer",
          userSelect: "none",
          PointerEvent: "none",
          "&.Mui-disabled": {
            opacity: 0.5,
          },
        },
      },
      "&:hover": {
        fieldset: {
          borderColor: theme.palette.primary.main,
        },
      },
      "&.Mui-focused": {
        fieldset: {
          borderColor: theme.palette.primary.main,
        },
      },
      "&.Mui-disabled": {
        color: "rgba(0, 0, 0, 0.38)",
        ".MuiInputBase-input": {
          "&.Mui-disabled": {
            color: "rgba(0, 0, 0, 0.38)",
            textFillColor: "rgba(0, 0, 0, 0.38)",
            cursor: "not-allowed",
          },
        },
        fieldset: {
          borderColor: "rgba(0, 0, 0, 0.12)",
        },
        "+": {
          ".u-input-label": {
            color: "rgba(0, 0, 0, 0.38)",
            "+": {
              ".u-help-text": {
                color: "rgba(0, 0, 0, 0.38)",
              },
            },
          },
        },
      },
      "&.MuiInputBase-adornedStart": {
        ".MuiInputBase-input": {
          padding: "12px 40px 12px 0px",
        },
        fieldset: {
          legend: {
            maxWidth: 0,
          },
        },
        "&.Mui-focused": {
          fieldset: {
            legend: {
              maxWidth: "100%",
            },
          },
        },
        "~": {
          ".u-input-label": {
            transform: "translate(38px, 12px) scale(1)",
            "&.Mui-focused": {
              transform: "translate(14px, -9px) scale(0.85)",
            },
            "&.MuiFormLabel-filled": {
              transform: "translate(14px, -9px) scale(0.85)",
            },
          },
        },
      },
      "&.MuiInputBase-adornedEnd": {
        ".MuiInputBase-input": {
          padding: "12px 0px 12px 16px",
        },
      },
      "&.Mui-error": {
        fieldset: {
          borderWidth: 2,
          borderColor: theme.palette.error.main,
        },
        "&:hover": {
          fieldset: {
            borderColor: theme.palette.error.main,
          },
        },
        "~": {
          ".u-input-label": {
            color: theme.palette.error.main,
            "&.Mui-focused": {
              color: theme.palette.error.main,
            },
            "&.MuiFormLabel-filled": {
              color: theme.palette.error.main,
            },
          },
        },
      },
    },
    ".u-input-label": {
      color: "rgba(0,0,0,0.87)",
      lineHeight: theme.typography.body1.lineHeight,
      transform: "translate(14px, 12px) scale(1)",
      padding: "0 5px 0 0px",
      backgroundColor: theme.palette.common.white,
      "&.Mui-focused": {
        color: theme.palette.text.secondary,
        transform: "translate(14px, -9px) scale(0.85)",
        maxWidth: "100%",
      },
      "&.MuiFormLabel-filled": {
        color: theme.palette.text.secondary,
        transform: "translate(14px, -9px) scale(0.85)",
        maxWidth: "100%",
      },
    },
    ".u-help-text": {
      marginLeft: 15,
    },
    ".u-error-text": {
      color: theme.palette.error.main,
      marginLeft: 15,
    },
    ".text-capitalize": {
      textTransform: "capitalize",
    },
    ".text-uppercase": {
      textTransform: "uppercase",
    },
  },
}));

const MenuSx = {
  borderStyle: "solid",
  borderWidth: 1,
  borderColor: "#EBECF2",
  boxShadow: "0px 16px 32px rgba(0, 0, 0, 0.16)",
  padding: "0",
  color: "#515466",
  scrollbarWidth: "thin",
  maxHeight: 250,
  ".MuiMenu-list": {
    padding: "8px",
  },
  "&::-webkit-scrollbar": {
    width: 7,
    height: 7,
  },
  "&::-webkit-scrollbar-track": {
    padding: "0 1px",
    backgroundColor: "#f0f0f0",
  },
  "&::-webkit-scrollbar-thumb": {
    transition: "all 0.3s",
    backgroundColor: "#cdcdcd",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#a6a6a6",
  },
  ".u-dropdown-item": {
    padding: "12px 24px 12px 16px",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: "#F8F8F8",
      color: "#12131A",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#F8F8F8",
      color: "#12131A",
    },
    "&.Mui-selected": {
      backgroundColor: "transparent",
      "&:hover": {
        backgroundColor: "rgba(0, 36, 156, 0.05)",
      },
      "&.Mui-focusVisible": {
        backgroundColor: "rgba(0, 36, 156, 0.05)",
      },
    },
    ".MuiTouchRipple-root": {
      display: "none",
    },
    ".custom-checkbox-wrap": {
      ".u-custom-control-label": {
        margin: 0,
      },
    },
  },
};

const MultiSelect: React.FC<ISelect> = ({
  theme,
  onChange: onChangePorps,
  name,
  items = [],
  label,
  value = [],
  helperText = "",
  disabled = false,
  id = "select-simple",
  capitalizeLabel,
  uppercaseLabel,
  selectError,
}) => {
  const [selectedValues, setSelectedValues] = React.useState<any[]>(
    value as any[]
  );
  useEffect(() => {
    setSelectedValues(value as any[]);
  }, [value]);

  const handleChange = (
    event: SelectChangeEvent<typeof selectedValues>
  ): void => {
    const {
      target: { value },
    } = event;
    if (selectedValues.includes(value)) {
      setSelectedValues(selectedValues.filter((item) => item !== value));
    } else {
      setSelectedValues(typeof value === "string" ? value.split(",") : value);
    }
    onChangePorps?.(event, selectedValues);
  };

  return (
    <CustomizedSelectWrapper theme={theme} className="u-form-group">
      <FormControl fullWidth>
        <Select
          MenuProps={{
            PaperProps: {
              sx: MenuSx,
            },
          }}
          inputProps={{
            name: [name],
          }}
          disabled={disabled}
          className="u-form-control u-select"
          labelId="demo-simple-select-label"
          id={id}
          multiple
          value={value as any[]}
          label={label}
          error={!!selectError}
          onChange={handleChange}
          renderValue={(selected: any[]) =>
            selected
              ? items
                  ?.filter((item) => selected.includes(item.value))
                  ?.map((item) => item.text)
                  ?.join(", ")
              : []
          }
          sx={{ borderRadius: "8px" }}
          IconComponent={ChevronDown}
        >
          {items?.map((item: any) => (
            <MenuItem
              className="u-dropdown-item"
              key={item.value}
              value={item.value}
            >
              <Checkbox
                checkBoxChecked={selectedValues?.includes(item.value)}
                label={item.text}
                theme={theme}
              />
            </MenuItem>
          ))}
        </Select>
        <InputLabel
          className={clsx({
            "u-input-label": true,
            "text-capitalize": capitalizeLabel,
            "text-uppercase": uppercaseLabel,
          })}
          id="demo-simple-select-label"
        >
          {label}
        </InputLabel>
        {selectError && (
          <FormHelperText className="u-error-text" id={id}>
            {selectError}
          </FormHelperText>
        )}
        <FormHelperText className="u-help-text">{helperText}</FormHelperText>
      </FormControl>
    </CustomizedSelectWrapper>
  );
};

export default MultiSelect;

import { useState, useEffect } from "react";
import { useTheme } from "@mui/material";
import { Box, Button } from "@ntpkunity/controls";
import { DataTableWrap } from "shared/components";
import { AddNewTemplate } from "components";
import { IOptionCategory, IStationery } from "Interface";
import { ActionOptions } from "Enums";
import { setOpenDrawer } from "Stores/SetupStore/Actions";
import { useSetupsStore } from "Stores/SetupStore/Store";
import StationeryTable from "./template-table";
import { UseQuery_getAllStates } from "services/dealer-configurations.service";

const Stationery = () => {
  UseQuery_getAllStates();
  const [stationeryState, setStationeryState] = useState<boolean>(false);
  const [actionTitle, setActionTitle] = useState<string>("Add New Template");
  const [actionType, setActionType] = useState<ActionOptions>(
    ActionOptions.ADD
  );

  const [actionData, setActionData] = useState<IStationery>(undefined);

  const [state, dispatch] = useSetupsStore();

  const theme = useTheme();

  const handleSelection = (event, key, value) => {
    switch (key) {
      case ActionOptions.ADD:
        setActionType(ActionOptions.ADD);
        setActionTitle("Add New Template");
        setActionData(value);
        setStationeryState(true);
        dispatch(setOpenDrawer(true));
      default:
        return;
    }
  };
  useEffect(() => {
    if (stationeryState === false) {
      dispatch(setOpenDrawer(false));
    }
  }, [stationeryState]);
  const onEdit = (formData: IStationery) => {
    setActionType(ActionOptions.EDIT);
    setActionTitle("Edit Template");
    setActionData(formData);
    setStationeryState(true);
    dispatch(setOpenDrawer(true));
  };
  return (
    <>
      <DataTableWrap className="table-tabs-pagination-button" key={1}>
        <Box theme={theme} className="scroll">
          <Box className="scroll-hide spr-border" theme={theme} />
        </Box>
        <StationeryTable onEdit={(data) => onEdit(data)} />
        <Button
          id="addNewStationery"
          theme={theme}
          secondary
          fullWidth={true}
          text={"Add New Template"}
          onClick={(e) => handleSelection(e, ActionOptions.ADD, undefined)}
        ></Button>
        {stationeryState && (
          <AddNewTemplate
            openPopUp={stationeryState}
            setPopUpState={setStationeryState}
            title={actionTitle}
            actionType={actionType}
            stationeryData={actionData}
          />
        )}
      </DataTableWrap>
    </>
  );
};

export default Stationery;

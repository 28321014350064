import { FC, useEffect, useState } from "react";
import { INewStipulationPopUp } from "./AddNewStipulation.type";
import { useTheme } from "@mui/material";
import {
  PersistentDrawer,
  Icon,
  Input,
  Select,
  Button,
} from "@ntpkunity/controls";
import { ConfirmationDialog } from "shared/components";
import { Controller, useForm } from "react-hook-form";
import { IStipulation, ISelectItemList, IDocumentTypes } from "Interface";
import {
  ActionOptions,
  QueryKeys,
  Stipulation,
  ValidationMessages,
  DialogMessages,
} from "Enums";
import {
  UseMutation_AddStipulation,
  UseMutation_UpdateStipulation,
} from "services";
import { useQueryClient } from "react-query";
import { setOpenDrawer } from "Stores/SetupStore/Actions";
import { useSetupsStore } from "Stores/SetupStore/Store";
import { usePrompt } from "utilities/usePrompt";
import { useNavigate } from "react-router-dom";
import { logout } from "@ntpkunity/controls-ums";

const AddNewStipulation: FC<INewStipulationPopUp> = ({
  openPopUp,
  setPopUpState,
  title,
  actionType,
  enabledPin,
  stipulationData,
}) => {
  const [state] = useSetupsStore();

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    setError,
    formState: { errors, isDirty },
  } = useForm<IStipulation>();
  const theme = useTheme();
  const { mutate: AddStipulation } = UseMutation_AddStipulation();
  const { mutate: UpdateStipulation } = UseMutation_UpdateStipulation(
    getValues("id")
  );
  const [, dispatch] = useSetupsStore();
  const queryClient = useQueryClient();
  const lenderProfile: any = queryClient.getQueryData(
    QueryKeys.GET_LENDER_PROFILE
  );
  const navigate = useNavigate();
  const [navigationState, setNavigationState] = useState<any>();
  const [parameterValues, setParameterValues] =
    useState<Array<ISelectItemList>>();
  const [leavePagePopupState, setLeavePageState] = useState<boolean>(false);
  const [showDocumentType, setShowDocumentType] = useState<boolean>(false);
  const documentTypes: Array<IDocumentTypes> = queryClient.getQueryData(
    QueryKeys.GET_ALL_DOCUMENT_TYPES
  );
  useEffect(() => {
    if (actionType !== ActionOptions.ADD) {
      setValue("id", stipulationData?.id);
      setValue("lender_id", stipulationData?.lender_id);
    }
    setValue("stipulation_code", stipulationData?.stipulation_code);
    setValue("description", stipulationData?.description);
    setValue("stipulation_type", stipulationData?.stipulation_type);
    changeParameterValues(stipulationData?.stipulation_type);
    setValue("parameter", stipulationData?.parameter);
    stipulationData?.parameter == Stipulation.UPLOAD_DOCUMENT
      ? setShowDocumentType(true)
      : setShowDocumentType(false);
    setValue("document_type_id", stipulationData?.document_type_id);
    setValue("assignee", stipulationData?.assignee);
  }, [stipulationData]);

  const changeParameterValues = (stipulation_type_value) => {
    if (stipulation_type_value == Stipulation.FINANCIAL) {
      setParameterValues([
        {
          value: Stipulation.FINANCED_AMOUNT,
          text: "Financed Amount",
        },
        {
          value: Stipulation.PERIODIC_PAYMENTS,
          text: "Periodic Payment",
        },
        {
          value: Stipulation.TERMS,
          text: "Terms",
        },
      ]);
    } else if (stipulation_type_value == Stipulation.NON_FINANCIAL) {
      setParameterValues([
        {
          value: Stipulation.UPLOAD_DOCUMENT,
          text: "Upload Document ",
        },
      ]);
    }
  };
  const onClose = () => {
    let children = document.querySelectorAll(".drawer-header .pinned");
    if (children.length == 0) {
      setPopUpState(false);
      dispatch(setOpenDrawer(false));
    }
  };

  const onSubmit = (data: IStipulation) => {
    data.lender_id = lenderProfile?.id;
    if (data.id && data.id > 0) {
      UpdateStipulation(data, {
        onSuccess: (response) => {
          reset();
          onClose();
        },
        onError: (error: any) => {
          if (
            error?.response?.data?.detail?.message === "Code should be unique"
          ) {
            setError("stipulation_code", { message: "Code should be unique" });
            return;
          }
        },
      });
    } else {
      AddStipulation(data, {
        onSuccess: (response) => {
          reset();
          onClose();
        },
        onError: (error) => {
          if (
            error?.response?.data?.detail?.message === "Code should be unique"
          ) {
            setError("stipulation_code", { message: "Code should be unique" });
            return;
          }
        },
      });
    }
  };
  const onSaveConfirm = (e: any) => {
    handleSubmit(onSubmit)();
    setLeavePageState(false);
  };
  const onCancelConfirm = (e: any) => {
    navigate(".." + navigationState.location.pathname);
    if (navigationState.location.pathname == "/market-place/signup") {
      logout();
      localStorage.clear();
    }
    localStorage.setItem("DirtyState", "false");
  };
  usePrompt(
    "Leave screen?",
    isDirty,
    (dirty) => {
      setLeavePageState(dirty);
    },
    (tx) => {
      reset(getValues());
      setNavigationState(tx);
    }
  );
  return (
    <>
      <ConfirmationDialog
        openPopUp={leavePagePopupState}
        onConfirm={onSaveConfirm}
        onCancel={onCancelConfirm}
        setPopUpState={setLeavePageState}
        confirmationTitle={DialogMessages.saveTitle}
        confirmationText={DialogMessages.saveText}
        primaryBtnText={DialogMessages.saveBtnText}
        icon={<Icon name="DialogSaveIcon" />}
      />
      <PersistentDrawer
        title={title}
        openPopUp={openPopUp}
        setPopUpState={setPopUpState}
        theme={theme}
        enabledPin={true}
        children={
          <>
            <Controller
              name="stipulation_code"
              control={control}
              rules={{
                maxLength: {
                  value: 5,
                  message: ValidationMessages.STIPULATION_CODE_MAX_LENGTH,
                },
                required: ValidationMessages.STIPULATION_CODE_REQUIRED,
              }}
              render={({ field: { onChange, value } }) => (
                <Input
                  id="drawerStipulationCode"
                  theme={theme}
                  fullWidth={true}
                  placeholder={"Type here..."}
                  label={"Stipulation Code"}
                  type="text"
                  value={value?.trimStart()}
                  onChange={onChange}
                  error={errors?.stipulation_code?.message}
                />
              )}
            />
            <Controller
              name="stipulation_type"
              control={control}
              rules={{ required: ValidationMessages.STIPULATION_TYPE_REQUIRED }}
              render={({ field: { value, onChange } }) => (
                <Select
                  id="drawerStipulationType"
                  theme={theme}
                  label={"Stipulation Type"}
                  placeholder={"Stipulation Type"}
                  items={[
                    { text: "Financial", value: Stipulation.FINANCIAL },
                    { text: "Non-Financial", value: Stipulation.NON_FINANCIAL },
                  ]}
                  value={value ?? ""}
                  onChange={(e) => {
                    onChange(e);
                    changeParameterValues(e.target.value);
                  }}
                  selectError={errors?.stipulation_type?.message as never}
                />
              )}
            />
            <Controller
              name="parameter"
              control={control}
              rules={{
                required: ValidationMessages.STIPULATION_PARAMAETER_REQUIRED,
              }}
              render={({ field: { value, onChange } }) => (
                <Select
                  id="drawerParameter"
                  theme={theme}
                  label={"Parameter"}
                  placeholder={"Parameter"}
                  items={parameterValues}
                  value={value ?? ""}
                  onChange={(e) => {
                    onChange(e);
                    if (e.target.value == Stipulation.UPLOAD_DOCUMENT)
                      setShowDocumentType(true);
                    else {
                      setValue("document_type_id", null);
                      setShowDocumentType(false);
                    }
                  }}
                  selectError={errors?.parameter?.message as never}
                />
              )}
            />
            {showDocumentType && (
              <Controller
                name="document_type_id"
                control={control}
                rules={{
                  required: {
                    value: showDocumentType,
                    message:
                      ValidationMessages.STIPULATION_DOCUMENT_TYPE_REQUIRED,
                  },
                }}
                render={({ field: { value, onChange } }) => (
                  <Select
                    id="drawerDocumentId"
                    theme={theme}
                    label={"Document Type"}
                    placeholder={"Document Type"}
                    items={documentTypes
                      ?.filter(
                        (document) => document.is_active || document.id == value
                      )
                      ?.map((document) => ({
                        value: document.id,
                        text: document.description,
                      }))}
                    value={value ?? ""}
                    onChange={onChange}
                    selectError={errors?.document_type_id?.message as never}
                  />
                )}
              />
            )}
            <Controller
              name="assignee"
              control={control}
              rules={{
                required: ValidationMessages.STIPULATION_ASSIGNEE_REQUIRED,
              }}
              render={({ field: { onChange, value } }) => (
                <Select
                  id="drawerStipulationAssignee"
                  theme={theme}
                  label={"Assignee"}
                  placeholder={"Assignee"}
                  items={[
                    { text: "Dealer", value: Stipulation.DEALER },
                    { text: "Customer", value: Stipulation.CUSTOMER },
                    { text: "Both", value: Stipulation.BOTH },
                  ]}
                  value={value ?? ""}
                  onChange={onChange}
                  selectError={errors?.assignee?.message as never}
                />
              )}
            />
            <Controller
              name="description"
              control={control}
              rules={{
                required: ValidationMessages.DESCRIPTION_REQUIRED,
              }}
              render={({ field: { onChange, value } }) => (
                <Input
                  id="drawerDescription"
                  theme={theme}
                  fullWidth={true}
                  placeholder={"Type here..."}
                  label={"Message Description"}
                  type="text"
                  value={value?.trimStart()}
                  onChange={onChange}
                  error={errors?.description?.message}
                />
              )}
            />
          </>
        }
        customFooter={
          <Button
            theme={theme}
            id="saveStipulation"
            primary
            text="Save"
            fullWidth
            onClick={handleSubmit(onSubmit)}
          />
        }
      />
    </>
  );
};
export default AddNewStipulation;

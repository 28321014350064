import { useMutation } from "react-query";
import { useStoreContext } from "Stores/SetupStore/Store";
import { ConfigurationAPI } from "services";

export const useMutation_CheckS3Credentials: any = () => {
  return useMutation(
    (tenant_id) => {
      return ConfigurationAPI.get(`/check-s3-credentials/${tenant_id}`);
    },
    {
      onSuccess: async (response: any) => {},
    }
  );
};

export const UseMutation_uploadS3Image = (): any => {
  const { actions } = useStoreContext();
  return useMutation(
    (obj: any) => {
      return ConfigurationAPI.post(
        `/bucket/upload-file/${obj.tenant_id}`,
        obj.files
      );
    },
    {
      onError: (response: any) => {
        actions.setToast({
          toastMessage: response?.detail?.message,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};

export const UseMutation_deleteS3Image = (): any => {
  const { actions } = useStoreContext();
  return useMutation(
    (obj: any) => {
      return ConfigurationAPI.delete(
        `/bucket/delete-file/${obj.fileKey}/${obj.tenant_id}`
      );
    },
    {
      onError: (response: any) => {
        actions.setToast({
          toastMessage: response?.detail?.message,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};

import Http from "shared/helper/http-api";
import { ConfigurationAPI } from "services";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { QueryKeys, ToastMessages } from "Enums";
import { useSetupsStore } from "Stores/SetupStore/Store";
import { IDealerIntegrations } from "Interface";
import { setToast } from "Stores/SetupStore/Actions";

export const getAllDealerIntegrationFromServer = (dealer_code: string) => {
  return ConfigurationAPI.get("/integration/dealer/" + dealer_code).then(
    (res) => {
      return res.data;
    }
  );
};

export const getAllDealerIntergrationFilterFromServer = (
  integration?: string
) => {
  var query_string = `integration=${integration}`;
  if (integration != null && integration != "") {
    query_string = query_string.concat(`&integration=${integration}`);
  }
  const apiService = Http.createConnection();
  return apiService
    .get(`integrations/dealer/filter?`.concat(query_string))
    .then((response) => {
      return response;
    });
};

export const UseQuery_getAllDealerIntergrations = (dealer_code: string) => {
  const key = [QueryKeys.GET_ALL_DEALER_INTEGRATION_FILTER, dealer_code];
  return useQuery(
    key,
    async () => {
      return getAllDealerIntegrationFromServer(dealer_code).then((data) => {
        return data;
      });
    },
    { refetchOnWindowFocus: false, enabled: !!dealer_code }
  );
};

export const useGetAllDealeIntegrations = (): any => {
  // const queryClient = useQueryClient();
  // const [, dispatch] = useSetupsStore();
  return useMutation<IDealerIntegrations>(
    (body: any) => {
      return ConfigurationAPI.get("/integration/dealer");
    },
    {
      onSuccess(_response: any) {},
    }
  );
};

export const UseQuery_getAllDealerIntegration_byParams = (): any => {
  const queryClient = useQueryClient();
  return useMutation<any>(
    QueryKeys.GET_ALL_DEALER_INTEGRATION_FILTER,
    (params: any) => {
      return getAllDealerIntergrationFilterFromServer(params.integration).then(
        (data: any) => {
          return data?.result;
        }
      );
    },
    {
      onSuccess: async (response: any) => {
        queryClient.setQueryData(
          [QueryKeys.GET_ALL_DEALER_INTEGRATION_FILTER],
          response
        );
      },
    }
  );
};

import { styled } from "@mui/material";
import { unityTheme } from "@ntpkunity/controls";

export const FileUploadTable = styled("div")(({ theme }) => ({
  textAlign: "center",
  ".counter-box": {
    backgroundColor:
      theme.palette.mode === "dark"
        ? theme.palette.grey[700]
        : theme.palette.grey[50],
    borderRadius: theme.shape.borderRadius,
    padding: "6px 6px 6px 16px",
    display: "flex",
    alignItems: "center",
    ".counter-text": {
      color: theme.palette.grey[900],
      fontSize: theme.typography.fontSize,
      fontFamily: theme.typography.fontFamily,
      fontWeight: theme.typography.fontWeightMedium,
    },
    ".counter-num": {
      color: unityTheme.palette.grey[900],
      fontSize: theme.typography.fontSize,
      fontFamily: theme.typography.fontFamily,
      fontWeight: theme.customVariables.fontWeightSemiBold,
      backgroundColor: theme.palette.common.white,
      borderRadius: theme.shape.borderRadius,
      padding: 8,
    },
  },
  ".error": {
    ".MuiTypography-root": {
      color: theme.palette.grey[600],
      ".asterisk": {
        color: theme.palette.error.main,
      },
    },
  },
  ".u-table-wrap": {
    ".u-table-container": {
      maxHeight: "calc(100vh - 375px) !important",
      backgroundColor: theme.customVariables.tableStripedBg,
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "0 1px 0 0",
      },
      "&::-webkit-scrollbar-track": {
        marginTop: 44,
        borderRadius: "0 3px 0 0",
        // backgroundColor: "#474747",
        marginLeft: 49,
      },
      ".u-table": {
        ".u-table-head": {
          "td, th": {
            borderRight: "1px solid",
            borderColor: theme.palette.grey[100],
            backgroundColor:
              theme.palette.mode === "dark"
                ? theme.palette.grey[700]
                : theme.customVariables.tableStripedBg,
          },
        },
        ".u-table-body": {
          tr: {
            "td, th": {
              borderRight: "1px solid",
              borderColor: theme.palette.grey[100],
              backgroundColor:
                theme.palette.mode === "dark"
                  ? theme.palette.grey[800]
                  : theme.palette.common.white + "!important",
              "&.head": {
                position: "sticky",
                left: 0,
                Zindex: "1200",
              },
              "&.error": {
                padding: 8,
                ".red-box": {
                  background: "rgba(255, 51, 51, 0.25)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 4,
                  ".icon": {
                    path: {
                      stroke: theme.palette.error.main,
                    },
                  },
                  ".MuiTooltip-tooltip": {
                    maxWidth: "200px !important",
                    whiteSpace: "normal",
                  },
                },
              },
            },
            "&:hover": {
              "td, th": {
                backgroundColor:
                  theme.palette.mode === "dark"
                    ? unityTheme.palette.grey[900]
                    : theme.customVariables.tableHoverBg + "!important",
              },
            },
          },
          "tr:nth-child(even), tr:nth-child(odd)": {
            "td, th": {
              backgroundColor:
                theme.palette.mode === "dark"
                  ? theme.palette.grey[800]
                  : theme.palette.common.white + "!important",
              "&.head": {
                backgroundColor:
                  theme.palette.mode === "dark"
                    ? theme.palette.grey[700]
                    : theme.customVariables.tableStripedBg + "!important",
              },
            },
            "&:hover": {
              "td, th": {
                backgroundColor:
                  theme.palette.mode === "dark"
                    ? unityTheme.palette.grey[900]
                    : theme.customVariables.tableHoverBg + "!important",
              },
            },
          },
        },
      },
    },
  },
  ".action-cell": {
    textAlign: "center",
    fontWeight: theme.customVariables.fontWeightSemiBold,
  },
  ".MuiButtonBase-root": {
    margin: "0px 8px",
  },
}));

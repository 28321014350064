import { RegisterOptions } from "react-hook-form";

export const validation = (
  name: string,
  required: boolean,
  matchPattern: boolean = false,
  passwordRegex: RegExp = new RegExp(""),
  regexMessage: string = ""
): RegisterOptions => ({
  required: {
    value: required,
    message: `${name} is required`,
  },
  validate: {
    matchesPattern: (value: string | undefined) => {
      if (matchPattern && value && !passwordRegex.exec(value)) {
        return `${regexMessage}`;
      }
      return true;
    },
  },
});
export const convertArray = (arr) => {
  const formattedArray = arr.map((value) => {
    const range = value.split(" - ");
    const formattedRange = range.map((num) => {
      const formattedNum = Number(num).toLocaleString();
      return formattedNum;
    });
    return formattedRange.join(" - ");
  });

  return formattedArray;
};

// export const getUser = (): any | undefined => {
//   const localStorage: LocalStorage = new LocalStorage();
//   return (
//     jwt_decode(localStorage.getItemByKey(ACCESS_TOKEN_KEY) as string) ||
//     undefined
//   );
// };

import { FC, useState, useEffect } from "react";
import { Grid, Box, Button } from "@ntpkunity/controls";
import { DataTableWrap } from "shared/components";
import { useTheme } from "@mui/material";
import { AddNewAssetUsage } from "components";
import { ActionOptions } from "Enums";
import { IAssetUsage } from "Interface";
import { setOpenDrawer } from "Stores/SetupStore/Actions";
import { useSetupsStore } from "Stores/SetupStore/Store";
import AssetUsageTable from "./asset-usage-table";

const AssetUsage: FC = () => {
  const [AssetUsageState, setAssetUsageState] = useState<boolean>(false);
  const [actionTitle, setActionTitle] = useState<string>("Add New Asset Usage");
  const [actionType, setActionType] = useState<ActionOptions>(
    ActionOptions.ADD
  );
  const [state, dispatch] = useSetupsStore();
  const [actionData, setActionData] = useState<IAssetUsage>(undefined);
  const theme = useTheme();

  useEffect(() => {
    if (AssetUsageState === false) {
      dispatch(setOpenDrawer(false));
    }
  }, [AssetUsageState]);

  const handleSelection = (event, key, value) => {
    switch (key) {
      case ActionOptions.ADD:
        setActionType(ActionOptions.ADD);
        setActionTitle("Add New Asset Usage");
        setActionData(value);
        setAssetUsageState(true);
        dispatch(setOpenDrawer(true));
      default:
        return;
    }
  };
  const onEdit = (formData: IAssetUsage) => {
    setActionType(ActionOptions.EDIT);
    setActionTitle("Edit Asset Usage");
    setActionData(formData);
    setAssetUsageState(true);
    dispatch(setOpenDrawer(true));
  };

  return (
    <>
      <DataTableWrap className="table-tabs-pagination-button">
        <Box theme={theme} className="scroll">
          <Box theme={theme} className="scroll-hide spr-border" />
        </Box>
        <AssetUsageTable onEdit={(data) => onEdit(data)} />
        <Button
          theme={theme}
          secondary
          fullWidth={true}
          text={"Add New Asset Usage"}
          onClick={(e) => handleSelection(e, ActionOptions.ADD, undefined)}
        />
        {AssetUsageState && (
          <AddNewAssetUsage
            openPopUp={AssetUsageState}
            setPopUpState={setAssetUsageState}
            title={actionTitle}
            actionType={actionType}
            AssetUsageData={actionData}
          />
        )}
      </DataTableWrap>
    </>
  );
};

export default AssetUsage;

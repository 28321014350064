import { FC, useState, useEffect } from "react";
import { useTheme } from "@mui/material";
import {
  IGridProps,
  DatePicker,
  Grid,
  Input,
  Select,
} from "@ntpkunity/controls";
import { CustomGrid } from "shared/components";

import { IGeneralProps } from "./general.type";
import { Controller, useForm } from "react-hook-form";
import { margin, styled, textAlign } from "@mui/system";
import { ActionOptions, ValidationMessages } from "Enums";

const ProgramGeneral: FC<IGeneralProps> = ({
  actionType,
  control,
  setValue,
  getValues,
  errors,
  clearErrors,
  onProductNameChange,
  onProductCodeChange,
  setErrorDate,
  errorCode,
  errorName,
  errorDate,
}) => {
  const theme = useTheme();
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  useEffect(() => {
    if (actionType == ActionOptions.EDIT) {
    }
    if (actionType == ActionOptions.VIEW) {
      setIsDisabled(true);
    }
  }, [isDisabled, actionType]);
  return (
    <>
      <CustomGrid
        className="main-content-section"
        theme={theme}
        container
        item
        spacing={3}
      >
        <Grid theme={theme} item xs={12} lg={3} md={6} data-testid="tid_Name">
          <Controller
            name="financial_product_general.name"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Input
                formControlClass="string"
                theme={theme}
                id="programName"
                inputClass="string"
                label="Program Name"
                onChange={(e) => {
                  onChange(e);
                  onProductNameChange(e);
                }}
                placeholder="Type here..."
                type="string"
                value={value?.trimStart()}
                wrapperClass="string"
                fullWidth={true}
                disabled={isDisabled}
                error={
                  errors?.financial_product_general?.name?.message || errorName
                }
              />
            )}
          />
        </Grid>

        <Grid
          theme={theme}
          item
          xs={12}
          lg={3}
          md={6}
          data-testid="tid_productID"
        >
          <Controller
            name="financial_product_general.financial_product_code"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Input
                formControlClass="string"
                id="programId"
                inputClass="string"
                label="Program ID"
                onChange={(e) => {
                  onChange(e);
                  onProductCodeChange(e);
                }}
                placeholder="Type here..."
                theme={theme}
                type="string"
                wrapperClass="string"
                value={value?.trimStart()}
                fullWidth={true}
                disabled={isDisabled}
                error={
                  errors?.financial_product_general?.financial_product_code
                    ?.message || errorCode
                }
              />
            )}
          />
        </Grid>
        <Grid
          theme={theme}
          item
          xs={12}
          lg={3}
          md={6}
          data-testid="tid_validity_start_date"
        >
          <Controller
            name="financial_product_general.validity_start_date"
            control={control}
            render={({ field: { value } }) => (
              <>
                <DatePicker
                  disablePast
                  theme={theme}
                  label={"Date From"}
                  value={value ?? ""}
                  clearable
                  onChange={(e: Date) => {
                    if (
                      getValues("financial_product_general.validity_end_date")
                    ) {
                      const startDate = new Date(e.toISOString());
                      const endDate = new Date(
                        getValues("financial_product_general.validity_end_date")
                      );
                      endDate.setHours(0, 0, 0, 0);
                      startDate.setHours(0, 0, 0, 0);
                      endDate <= startDate
                        ? setErrorDate(
                            ValidationMessages.DATE_TO_SHOULD_BE_GREATER_THAN_DATE_FROM
                          )
                        : setErrorDate("");
                    }
                    setValue(
                      "financial_product_general.validity_start_date",
                      e.getFullYear() === 1970 ? null : e.toISOString(),
                      {
                        shouldDirty: true,
                      }
                    );
                    clearErrors(
                      "financial_product_general.validity_start_date"
                    );
                  }}
                  disabled={isDisabled}
                  error={
                    errors?.financial_product_general?.validity_start_date
                      ?.message
                  }
                />
              </>
            )}
          />
        </Grid>
        <Grid
          theme={theme}
          item
          xs={12}
          lg={3}
          md={6}
          data-testid="tid_validity_end_date"
        >
          <Controller
            name="financial_product_general.validity_end_date"
            control={control}
            render={({ field: { value, onChange } }) => (
              <DatePicker
                disablePast
                theme={theme}
                label={"Date To"}
                value={value ?? ""}
                clearable
                // clearText
                onChange={(e: Date) => {
                  if (e.getFullYear() !== 1970) {
                    const endDate = new Date(e.toISOString());
                    const startDate = new Date(
                      getValues("financial_product_general.validity_start_date")
                    );
                    endDate.setHours(0, 0, 0, 0);
                    startDate.setHours(0, 0, 0, 0);
                    endDate <= startDate
                      ? setErrorDate(
                          ValidationMessages.DATE_TO_SHOULD_BE_GREATER_THAN_DATE_FROM
                        )
                      : setErrorDate("");
                  } else {
                    setErrorDate("");
                  }
                  setValue(
                    "financial_product_general.validity_end_date",
                    e.getFullYear() === 1970 ? null : e.toISOString(),
                    {
                      shouldDirty: true,
                    }
                  );
                }}
                disabled={isDisabled}
                error={errorDate}
              />
            )}
          />
        </Grid>
        <Grid
          theme={theme}
          item
          xs={12}
          lg={3}
          md={6}
          data-testid="tid_finance_type"
        >
          <Controller
            name={"financial_product_general.finance_type"}
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
                id="programFinanceType"
                theme={theme}
                label={"Finance Type"}
                placeholder={"Select"}
                items={[
                  { text: "Lease", value: "Lease" },
                  { text: "Finance", value: "Finance" },
                ]}
                value={value ?? ""}
                onChange={onChange}
                disabled={isDisabled}
                selectError={
                  errors?.financial_product_general?.finance_type
                    ?.message as never
                }
              />
            )}
          />
        </Grid>
      </CustomGrid>
    </>
  );
};
export default ProgramGeneral;

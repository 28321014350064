import { FC, useEffect, useRef, useState } from "react";
import { useTheme } from "@mui/material";
import { Button, Box } from "@ntpkunity/controls";
import { DataTableWrap } from "shared/components";
import { AddNewDecisionStatus, AddNewStipulation } from "components";
import { IDecisionStatus, IStipulation, ISelectItemList } from "Interface";
import { ActionOptions, DecisionStatusOptions } from "Enums";
import DecisionStatusTable from "./decision-status-table";
import { useSetupsStore } from "Stores/SetupStore/Store";
import { setOpenDrawer } from "Stores/SetupStore/Actions";

const DecisionStatus: FC = () => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [state, dispatch] = useSetupsStore();
  const [decisionStatusState, setDecisionStatusState] =
    useState<boolean>(false);
  const [decisionStatusActionTitle, setDecisionStatusActionTitle] =
    useState<string>("Add New Decision Status ");

  const [decisionStatusActionData, setDecisionStatusActionData] =
    useState<IDecisionStatus>(undefined);

  const [ActionType, setActionType] = useState<ActionOptions>(
    ActionOptions.ADD
  );

  useEffect(() => {
    if (decisionStatusState === false) {
      dispatch(setOpenDrawer(false));
    }
  }, [decisionStatusState]);

  const theme = useTheme();

  const handleSelection = (event, key, value) => {
    switch (key) {
      case ActionOptions.ADD:
        setActionType(ActionOptions.ADD);
        setDecisionStatusActionTitle("Add New Decision Status");
        setDecisionStatusActionData(value);
        setDecisionStatusState(true);
        dispatch(setOpenDrawer(true));
      default:
        return;
    }
  };
  const onEdit = (formData: IDecisionStatus) => {
    setActionType(ActionOptions.EDIT);
    setDecisionStatusActionTitle("Edit Decision Status");
    setDecisionStatusActionData(formData);
    setDecisionStatusState(true);
    dispatch(setOpenDrawer(true));
  };
  return (
    <>
      <DataTableWrap className="table-tabs-pagination-button">
        <Box theme={theme} className="scroll">
          <Box theme={theme} className="scroll-hide spr-border" />
        </Box>
        <DecisionStatusTable onEditDecisionStatus={(data) => onEdit(data)} />
        <Button
          theme={theme}
          secondary
          fullWidth={true}
          text={"Add New Decision Status"}
          onClick={(e) => handleSelection(e, ActionOptions.ADD, undefined)}
        />

        {decisionStatusState && (
          <AddNewDecisionStatus
            openPopUp={decisionStatusState}
            setPopUpState={setDecisionStatusState}
            title={decisionStatusActionTitle}
            actionType={ActionType}
            decisionStatusData={decisionStatusActionData}
          />
        )}
      </DataTableWrap>
    </>
  );
};
export default DecisionStatus;

import { FC, useState } from "react";
import { useTheme } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import {
  IBoxProps,
  Textarea,
  Grid,
  Typography,
  Box,
  Button,
  Radio,
  Icon,
} from "@ntpkunity/controls";
import { IFeedback } from "Interface";
import { styled } from "@mui/system";
import { UseMutation_sendFeedbackEmail } from "services";
import { feedbackOptionsValue, ValidationMessages } from "Enums";
import { LayoutWithSideNav } from "layout";
import FeedbackImgLight from "../shared/assets/images/feedback-img.svg";
import FeedbackImgDark from "../shared/assets/images/feedback-img-dark.svg";
import Amazing from "../shared/assets/images/amazing.svg";
import Good from "../shared/assets/images/good.svg";
import Okay from "../shared/assets/images/okay.svg";
import Bad from "../shared/assets/images/bad.svg";
import Terrible from "../shared/assets/images/terrible.svg";

const FeedbackStyle = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  padding: "5vh 0",
  ".text-dark": {
    color: theme.palette.grey[900],
  },
  ".feedback-description": {
    borderRight: "1px solid" + theme.palette.divider,
    ".description": {
      maxWidth: 440,
      display: "grid",
      margin: "auto",

      img: {
        margin: "0 auto 40px auto",
        height: "40vh",
        display: "block",

        "&.fb-img-dark": {
          display: "none",
        },
      },

      ".mini-text": {
        color: theme.palette.grey[600],
      },
    },
  },

  ".submit-feedback": {
    maxWidth: 440,
    display: "grid",
    margin: "auto",
    ".MuiInputBase-input": {
      height: "140px !important",
    },
    ".radio-rating-group": {
      ".custom-radio-wrap": {
        width: "100%",
        ".MuiFormGroup-row": {
          width: "100%",
          ".u-custom-control-label": {
            alignItems: "center",
            width: "17.8%",
            display: "inline-block",
            padding: "16px 8px",
            border: "1px solid" + theme.palette.grey[100],
            borderRadius: theme.shape.borderRadius,
            textAlign: "center",
            transition: "all 0.3s",
            height: "92px",
            ".custom-radio": {
              width: "100%",
              clear: "both",
              "&:before": {
                display: "block",
                content: '" "',
                width: 35,
                height: 34,
                backgroundPosition: "center",
                backgroundSize: 34,
                opacity: 0.55,
                zIndex: 1,
                position: "relative",
                backgroundRepeat: "no-repeat",
              },
              "&:after": {
                backgroundColor: "#FFCC00",
                display: "inline-block",
                width: 32,
                height: 32,
                borderRadius: 32,
                position: "absolute",
                content: "''",
                top: 3,
                right: 10,
                left: "inherit",
                zIndex: -1,
                opacity: 0,
                visibility: "hidden",
                transition: "all 0.3s",
                boxShadow: "0 0 0 transparent",
              },
            },
            svg: {
              display: "none",
            },
            ".MuiFormControlLabel-label": {
              width: "100%",
              fontSize: theme.typography.caption.fontSize,
              color: theme.palette.grey[600],
              display: "block",
              paddingTop: "8px",
              clear: "both",
            },
            "&:first-child": {
              ".custom-radio": {
                "&:before": {
                  backgroundImage: `url(${Amazing})`,
                },
              },
            },
            "&:nth-child(2)": {
              ".custom-radio": {
                "&::before": {
                  backgroundImage: `url(${Good})`,
                },
              },
            },
            "&:nth-child(3)": {
              ".custom-radio": {
                "&::before": {
                  backgroundImage: `url(${Okay})`,
                },
              },
            },
            "&:nth-child(4)": {
              ".custom-radio": {
                "&::before": {
                  backgroundImage: `url(${Bad})`,
                },
              },
            },
            "&:last-child": {
              ".custom-radio": {
                "&::before": {
                  backgroundImage: `url(${Terrible})`,
                },
              },
            },
            "&:hover, &.selected": {
              borderColor:
                theme.palette.mode === "dark"
                  ? theme.palette.common.white
                  : theme.palette.common.black,
              color:
                theme.palette.mode === "dark"
                  ? theme.palette.common.white
                  : theme.palette.common.black,
              ".custom-radio": {
                "&:after": {
                  opacity: 1,
                  visibility: "visible",
                },
              },
            },
            "&.selected": {
              ".MuiFormControlLabel-label": {
                fontWeight: theme.customVariables.fontWeightSemiBold,
                color:
                  theme.palette.mode === "dark"
                    ? theme.palette.common.white
                    : theme.palette.grey[600],
              },
            },
          },
        },
      },
    },
    ".u-form-group": {
      ".MuiFormControl-root": {
        ".textarea": {
          ".MuiInputBase-root": {
            ".MuiInputBase-input::placeholder": {
              opacity: 0.38,
            },
          },
        },
      },
    },
    ".fb-radio-group": {
      width: "100%",
      display: "inline-block",
      paddingBottom: 22,
      marginBottom: 34,
      borderBottom: "1px solid" + theme.palette.divider,
    },
  },
}));

const Feedback: FC = () => {
  const theme = useTheme();
  const {
    control,
    reset,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<IFeedback>();
  const { mutate: sendFeedback } = UseMutation_sendFeedbackEmail();
  const [isDisabled, setIsDisabled] = useState(false);
  const [reaction, setReaction] = useState<string>();
  function onSubmit(data) {
    var access_token_local = JSON.parse(localStorage.getItem("settings"));
    setIsDisabled(true);
    data.name = access_token_local?.user_name;
    data.email = access_token_local?.email;
    data.phone = "03331234567";
    data.subject = "Index- Client Feedback";
    data.rating = getValues("rating");
    data.follow_up = getValues("follow_up");
    if (data.rating == "") {
      data.rating = "None";
    }
    sendFeedback(data, {
      onSuccess: () => {
        reset();
        setIsDisabled(false);
        setReaction(undefined);
      },
      onerror: () => {
        setIsDisabled(false);
      },
    });
  }
  const getLabelClassName = (feedbackOption: string): string =>
    reaction === feedbackOption ? "selected" : "";

  const feedbackOptions = [
    {
      label: "Amazing",
      value: feedbackOptionsValue.AMAZING,
      labelClassName: getLabelClassName(feedbackOptionsValue.AMAZING),
      Icon: "emoji-amazing",
    },
    {
      label: "Good",
      value: feedbackOptionsValue.GOOD,
      labelClassName: getLabelClassName(feedbackOptionsValue.GOOD),
    },
    {
      label: "Okay",
      value: feedbackOptionsValue.OKAY,
      labelClassName: getLabelClassName(feedbackOptionsValue.OKAY),
    },
    {
      label: "Bad",
      value: feedbackOptionsValue.BAD,
      labelClassName: getLabelClassName(feedbackOptionsValue.BAD),
    },
    {
      label: "Terrible",
      value: feedbackOptionsValue.TERRIBLE,
      labelClassName: getLabelClassName(feedbackOptionsValue.TERRIBLE),
    },
  ];

  return (
    <LayoutWithSideNav theme={theme}>
      <FeedbackStyle theme={theme}>
        <Grid theme={theme} container spacing={0}>
          <Grid
            theme={theme}
            item
            sm={6}
            xs={12}
            className="feedback-description"
          >
            <Box theme={theme} className="description">
              <img
                src={FeedbackImgLight}
                alt="FeedbackImgLight"
                className="fb-img-light"
              />
              <img
                src={FeedbackImgDark}
                alt="FeedbackImgDark"
                className="fb-img-dark"
              />
              <Typography
                theme={theme}
                className="text-dark"
                variant="h2"
                component="h2"
                data-testid="feedbackHeading"
                sx={{ mb: 2 }}
                children="Your feedback matters to us! We’d love to hear from you."
              />
              <Typography
                theme={theme}
                className="mini-text text-dark"
                variant="body1"
                component="p"
                data-testid="feedbackContent"
                children="Please feel free to drop us a suggestion and give us your feedback. We will look into it and be in touch with you as soon as possible."
              />
            </Box>
          </Grid>
          <Grid theme={theme} item sm={6} xs={12}>
            <Box theme={theme} className="submit-feedback">
              <Typography
                theme={theme}
                className="text-dark"
                variant="subtitle2"
                component="p"
                sx={{ mb: 1.5 }}
                children="How is your experience about using our product?"
              />
              <Box theme={theme} className="radio-rating-group" sx={{ mb: 2 }}>
                <Controller
                  name="rating"
                  control={control}
                  defaultValue={""}
                  render={({ field }) => (
                    <Radio
                      theme={theme}
                      row={true}
                      options={feedbackOptions}
                      defaultValue={field.value}
                      {...field}
                      onChange={(e: any) => {
                        field.onChange(e);
                        setReaction(e);
                      }}
                    />
                  )}
                />
              </Box>
              <Typography
                theme={theme}
                className="text-dark"
                variant="subtitle2"
                component="p"
                sx={{ mb: 1.5 }}
                children="Give us your feedback"
              />
              <Box theme={theme} sx={{ mb: 5 }}>
                <Controller
                  name="email_text"
                  control={control}
                  defaultValue={""}
                  rules={{
                    required: ValidationMessages.FEEDBACK_REQUIRED,
                    maxLength: {
                      value: 1000,
                      message: ValidationMessages.FEEDBACK_MAX_LENGTH,
                    },
                  }}
                  render={({ field }) => (
                    <Textarea
                      theme={theme}
                      fullWidth={true}
                      rows={2}
                      placeholder={"Type here.."}
                      type="text"
                      {...field}
                      id={"feedback"}
                      error={errors?.email_text?.message}
                      data-testid={"feedback"}
                    />
                  )}
                />
              </Box>
              <Box theme={theme} className="fb-radio-group">
                <Grid container theme={theme} rowSpacing={0} columnSpacing={2}>
                  <Grid item theme={theme} md={8} sm={12}>
                    <Typography
                      theme={theme}
                      className="text-dark"
                      variant="subtitle2"
                      component="p"
                      sx={{ mb: 1.5 }}
                      children="May we follow you up on your feedback?"
                    />
                  </Grid>
                  <Grid
                    item
                    theme={theme}
                    md={4}
                    sm={12}
                    sx={{ textAlign: "right" }}
                  >
                    <Controller
                      name="follow_up"
                      control={control}
                      defaultValue={"Yes"}
                      render={({ field }) => (
                        <Radio
                          theme={theme}
                          row={true}
                          defaultValue={"Yes"}
                          options={[
                            { label: "Yes", value: "Yes" },
                            { label: "No", value: "No" },
                          ]}
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Button
                theme={theme}
                type={"submit"}
                primary
                text={"Submit Feedback"}
                fullWidth
                onClick={handleSubmit(onSubmit)}
                disabled={isDisabled}
              />
            </Box>
          </Grid>
        </Grid>
      </FeedbackStyle>
    </LayoutWithSideNav>
  );
};

export default Feedback;

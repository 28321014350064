import { FC, useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import { AddressType, QueryKeys, ValidationMessages } from "Enums";
import { Select, Input, Grid, MultiSelect } from "@ntpkunity/controls";
import { CurrencyInputWrap, MultiSelectWrap } from "shared/components";
import { Controller } from "react-hook-form";
import { UseQuery_GetAll_CURRENCIES } from "services";
import { IGeneralProps } from "./general.type";
import { useSetupsStore } from "Stores/SetupStore/Store";
import {
  ISelectItemList,
  ILenderProfile,
  IAutoCompleteOptions,
  ICountry,
} from "Interface";
import { useQueryClient } from "react-query";

const LenderProfile: FC<IGeneralProps> = ({
  control,
  errors,
  setValue,
  getValues,
  setLenderFieldState,
  setDefaultCurrency,
  defaultCurrency,
  selectedDefaultCurrencyId,
  setSelectedDefaultCurrencyId,
  setLenderCountries,
  resetMailingAddress,
}) => {
  const theme = useTheme();
  const [state, dispatch] = useSetupsStore();
  let items = [
    { text: "Internal", value: "Internal" },
    { text: "External", value: "External" },
  ];

  //const { lenderProfile } = state;
  const queryClient = useQueryClient();
  const lenderProfile: any = queryClient.getQueryData(
    QueryKeys.GET_LENDER_PROFILE
  );
  const allCurrencies: any = queryClient.getQueryData(
    QueryKeys.GET_ALL_CURRENCIES
  );
  const allCountries: any = queryClient.getQueryData(
    QueryKeys.GET_ALL_COUNTRIES
  );

  const alldefaultRadius: any = queryClient.getQueryData(
    QueryKeys.GET_ALL_RADIUS
  );
  const { currencies } = state;
  const [Currencies, setCurrencies] = useState<Array<ISelectItemList>>();
  const [countries, setCountries] = useState<Array<ISelectItemList>>();
  const [defaultRadius, setDefaultRadius] = useState<ISelectItemList[]>([]);
  const [mileageUnit, setMileageUnit] = useState("");
  const [selectedDefaultCurrency, setSelectedDefaultCurrency] =
    useState<string>("");
  const setDefaultDropdown = (values) => {
    let arr: Array<ISelectItemList> = [];
    if (values) {
      for (let i = 0; i < values?.length; i++) {
        let foundCurrency = currencies?.find((x) => x.id == values[i]);
        if (foundCurrency)
          arr.push({ text: foundCurrency.name, value: foundCurrency.id });
      }
      if (arr?.length > 0) {
        setValue("LenderProfile.default_currency", arr?.[0]?.value, {
          shouldValidate: true,
        });
        setSelectedDefaultCurrencyId(Number(arr[0]?.value));
      } else if (arr?.length === 0 && values?.length === 0) {
        setValue("LenderProfile.default_currency", "", {
          shouldValidate: true,
        });
      }
      setDefaultCurrency(arr);
    }
  };

  useEffect(() => {
    if (lenderProfile) {
      localStorage.setItem("lender_id", lenderProfile?.id);
    }
  }, [lenderProfile]);
  useEffect(() => {
    setSelectedDefaultCurrency(
      currencies?.filter(
        (currency) => currency.id === Number(selectedDefaultCurrencyId)
      )?.length > 0
        ? currencies?.filter(
            (currency) => currency.id === Number(selectedDefaultCurrencyId)
          )[0].symbol
        : ""
    );
  }, [selectedDefaultCurrencyId, lenderProfile]);

  useEffect(() => {
    setCurrencies(
      currencies?.map((currency) => ({
        value: currency.id,
        text: currency.name,
      }))
    );
    let LenderProfile: ILenderProfile = lenderProfile;
    setDefaultDropdown(
      LenderProfile?.preferred_currency_ids?.split(",").map(Number)
    );
  }, [currencies, lenderProfile]);
  useEffect(() => {
    setCountries(
      allCountries?.map((country) => ({
        value: country.id,
        text: country.name,
      }))
    );
  }, [allCountries]);

  const storedMileageUnit = getValues("LenderProfile.mileage_unit");
  useEffect(() => {
    if (storedMileageUnit) {
      setMileageUnit(storedMileageUnit);
      setDefaultRadius(
        alldefaultRadius?.map((radius: any) => ({
          text: `${radius.radius_value} ${storedMileageUnit}`,
          value: radius.radius_value,
        }))
      );
    }
  }, [alldefaultRadius, storedMileageUnit]);

  const handleMileageUnitChange = (newValue) => {
    setValue("LenderProfile.mileage_unit", newValue);
    setMileageUnit(newValue);
    setDefaultRadius(
      alldefaultRadius?.map((radius: any) => ({
        text: `${radius.radius_value} ${newValue || mileageUnit}`,
        value: radius.radius_value,
      }))
    );
  };

  return (
    <Grid theme={theme} container spacing={3}>
      <Grid theme={theme} item xs={12} lg={3} md={6} data-testid="tid_Name">
        <Controller
          name="LenderProfile.name"
          control={control}
          rules={{
            required: ValidationMessages.NAME_REQUIRED,
            pattern: {
              value: /^[A-Za-z0-9 ]+$/,
              message: ValidationMessages.LENDER_NAME_FORMAT,
            },
          }}
          render={({ field: { onChange, value } }) => (
            <Input
              formControlClass="string"
              theme={theme}
              id="lender_name"
              inputClass="string"
              label="Lender Name"
              onChange={(e) => {
                onChange(e);
                setLenderFieldState(true);
              }}
              placeholder="Type here..."
              type="string"
              value={value}
              wrapperClass="string"
              fullWidth={true}
              error={errors?.LenderProfile?.name?.message}
            />
          )}
        />
      </Grid>

      <Grid
        theme={theme}
        item
        xs={12}
        lg={3}
        md={6}
        data-testid="tid_TradingName"
      >
        <Controller
          name="LenderProfile.trading_name"
          control={control}
          rules={{
            required: ValidationMessages.TRADING_NAME_REQUIRED,
            pattern: {
              value: /^[A-Za-z0-9 ]+$/,
              message: ValidationMessages.TRADING_NAME_FORMAT,
            },
          }}
          render={({ field: { onChange, value } }) => (
            <Input
              formControlClass="string"
              id="lender_trading_name"
              inputClass="string"
              label="Lender Trading Name"
              onChange={(e) => {
                onChange(e);
                setLenderFieldState(true);
              }}
              placeholder="Type here..."
              theme={theme}
              type="string"
              wrapperClass="string"
              value={value}
              fullWidth={true}
              error={errors?.LenderProfile?.trading_name?.message}
            />
          )}
        />
      </Grid>

      <Grid
        theme={theme}
        item
        xs={12}
        lg={3}
        md={6}
        data-testid="tid_CompanyWebsite"
      >
        <Controller
          name="LenderProfile.url"
          control={control}
          rules={{
            required: ValidationMessages.URL_REQUIRED,
            pattern: {
              value:
                /(http:\/\/|https:\/\/|www\.|[a-zA-Z])+([a-zA-Z0-9-]+\.){1,}[a-zA-Z]{2,6}/,
              message: ValidationMessages.INVALID_URL,
            },
          }}
          render={({ field: { onChange, value } }) => (
            <Input
              formControlClass="string"
              id="lender_url"
              inputClass="string"
              label="Company's Website"
              onChange={(e) => {
                onChange(e);
                setLenderFieldState(true);
              }}
              placeholder="www.sample.com"
              theme={theme}
              type="string"
              wrapperClass="string"
              value={value}
              error={errors?.LenderProfile?.url?.message}
              fullWidth={true}
            />
          )}
        />
      </Grid>
      <Grid theme={theme} item xs={12} lg={3} md={6} data-testid="tid_email">
        <Controller
          name="LenderProfile.email"
          control={control}
          rules={{
            required: ValidationMessages.EMAIL_REQUIRED,
            pattern: {
              value:
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: ValidationMessages.INVALID_EMAIL,
            },
          }}
          render={({ field: { onChange, value } }) => (
            <Input
              formControlClass="string"
              id="lender_email"
              inputClass="string"
              label="Email"
              onChange={(e) => {
                onChange(e);
                setLenderFieldState(true);
              }}
              theme={theme}
              placeholder={"sample@sample.com"}
              type="string"
              wrapperClass="string"
              value={value}
              error={errors?.LenderProfile?.email?.message}
              fullWidth={true}
            />
          )}
        />
      </Grid>
      <Grid theme={theme} item xs={12} lg={3} md={6} data-testid="tid_taxId">
        <Controller
          name="LenderProfile.tax_id"
          control={control}
          rules={{
            required: ValidationMessages.TAX_ID_REQUIRED,
            minLength: {
              value: 9,
              message: ValidationMessages.INVALID_TAX_ID_FORMAT,
            },
            maxLength: {
              value: 9,
              message: ValidationMessages.INVALID_TAX_ID_FORMAT,
            },
          }}
          render={({ field: { onChange, value } }) => (
            <>
              <Input
                id="lender_tax_id"
                label="Tax ID"
                onChange={(e) => {
                  onChange(e);
                  if (e !== lenderProfile?.tax_id) setLenderFieldState(true);
                }}
                placeholder="00-0000000"
                theme={theme}
                type="text"
                value={value}
                error={errors?.LenderProfile?.tax_id?.message}
                format="##-#######"
                masking={true}
                fullWidth={true}
                maskNumeric={true}
              />
            </>
          )}
        />
      </Grid>
      <Grid
        theme={theme}
        item
        xs={12}
        lg={3}
        md={6}
        data-testid="tid_mileageUnits"
      >
        <Controller
          name="LenderProfile.mileage_unit"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Select
              theme={theme}
              disablePortal
              id="lender_mileage_unit"
              label={"Mileage Unit"}
              placeholder={"Select"}
              items={[
                { text: "Miles", value: "Miles" },
                { text: "Kilometers", value: "Kilometers" },
              ]}
              value={value ?? ""}
              onChange={(e) => {
                onChange(e);
                handleMileageUnitChange(e.target.value as string);
                setLenderFieldState(true);
              }}
            />
          )}
        />
      </Grid>
      <Grid
        theme={theme}
        item
        xs={12}
        lg={3}
        md={6}
        data-testid="tid_default_search_radius"
      >
        <Controller
          name="LenderProfile.default_radius"
          control={control}
          rules={{
            required: ValidationMessages.DEFAULT_RADIUS,
          }}
          render={({ field: { value, onChange } }) => (
            <Select
              id={"lender_default_search_radius"}
              theme={theme}
              disablePortal
              label={"Default Search Radius"}
              placeholder={"Select"}
              items={defaultRadius}
              value={value ?? ""}
              onChange={(e: any) => {
                onChange(e.target.value);
                setLenderFieldState(true);
              }}
              selectError={errors?.LenderProfile?.default_radius?.message}
              sxProps={{ m: 0, width: "100%" }}
            />
          )}
        />
      </Grid>
      <Grid theme={theme} item xs={12} lg={3} md={6} data-testid="tid_initials">
        <Controller
          name="LenderProfile.initials"
          control={control}
          rules={{
            required: ValidationMessages.INITIALS_REQUIRED,
            maxLength: {
              value: 2,
              message: ValidationMessages.INVALID_INITIAL,
            },
          }}
          render={({ field: { onChange, value } }) => (
            <Input
              formControlClass="string"
              id="lender_initials"
              inputClass="string"
              label="Initials"
              onChange={(e) => {
                onChange(
                  e
                    .replace(/[^a-z]/gi, "")
                    .toUpperCase()
                    .slice(0, 2)
                );
                setLenderFieldState(true);
              }}
              placeholder="Type here..."
              theme={theme}
              type="text"
              wrapperClass="string"
              fullWidth={true}
              format="##"
              masking={false}
              maskNumeric={false}
              value={value}
              error={errors?.LenderProfile?.initials?.message}
            />
          )}
        />
      </Grid>
      <Grid
        theme={theme}
        item
        xs={12}
        lg={3}
        md={6}
        data-testid="tid_minimum_financed_amount"
      >
        <CurrencyInputWrap>
          <Controller
            name="LenderProfile.minimum_financed_amount"
            control={control}
            defaultValue={""}
            rules={{
              required: ValidationMessages.MINIMUM_FINANCED_AMOUNT_IS_REQUIRED,
            }}
            render={({ field: { onChange, value } }) => (
              <Input
                theme={theme}
                fullWidth
                label="Minimum Financed Amount"
                placeholder="0.00"
                id="lender_minimum_financed_amount"
                value={value ? parseFloat(value) : undefined}
                onChange={(e) => {
                  onChange(e);
                  if (e != lenderProfile?.minimum_financed_amount)
                    setLenderFieldState(true);
                }}
                type="text"
                startAdornment={
                  <>
                    <span className="adornment-text">
                      {selectedDefaultCurrency}
                    </span>
                  </>
                }
                masking
                // scale={2}
                // numeric
                maskDecimalScale={2}
                maskNumeric
                error={errors?.LenderProfile?.minimum_financed_amount?.message}
              />
            )}
          />
        </CurrencyInputWrap>
      </Grid>
      <Grid
        theme={theme}
        item
        xs={12}
        lg={3}
        md={6}
        data-testid="tid_preferred_currrency"
      >
        <MultiSelectWrap>
          <Controller
            name="LenderProfile.countries"
            control={control}
            rules={{
              required: ValidationMessages.COUNTRY_REQUIRED,
            }}
            render={({ field: { value, onChange } }) => (
              <MultiSelect
                // multi-select-all={true}
                id={"lender_countries"}
                theme={theme}
                label={"Country"}
                placeholder={""}
                items={countries}
                value={value || []}
                onChange={(e: any) => {
                  let arr: Array<ICountry> = [];
                  if (e.target.value) {
                    for (let i = 0; i < e.target.value?.length; i++) {
                      arr.push(
                        allCountries?.find((x) => x.id == e.target.value[i])
                      );
                    }
                  }
                  setLenderCountries(arr);
                  setLenderFieldState(true);
                  onChange(e);
                  const country_id = lenderProfile.addresses?.find(
                    (x) => x.address_type == AddressType.MAILING
                  ).country_id;
                  if (!arr.find((x) => x.id == country_id)) {
                    resetMailingAddress();
                  }
                }}
                selectError={errors?.LenderProfile?.countries?.message}
                sxProps={{ m: 0, width: "100%" }}
              />
            )}
          />
        </MultiSelectWrap>
      </Grid>
      <Grid
        theme={theme}
        item
        xs={12}
        lg={3}
        md={6}
        data-testid="tid_preferred_currrency"
      >
        <MultiSelectWrap>
          <Controller
            name="LenderProfile.preferred_currency_ids"
            control={control}
            rules={{
              required: ValidationMessages.PREFERRED_CURRENCY_REQUIRED,
            }}
            render={({ field: { value, onChange } }) => (
              <MultiSelect
                // multi-select-all={true}
                id={"lender_preferred_currrency"}
                theme={theme}
                label={"Preferred Currency"}
                placeholder={""}
                items={Currencies}
                value={value || []}
                onChange={(e: any) => {
                  setDefaultDropdown(e.target.value);
                  setLenderFieldState(true);
                  onChange(e);
                }}
                selectError={
                  errors?.LenderProfile?.preferred_currency_ids?.message
                }
                sxProps={{ m: 0, width: "100%" }}
              />
            )}
          />
        </MultiSelectWrap>
      </Grid>

      <Grid
        theme={theme}
        item
        xs={12}
        lg={3}
        md={6}
        data-testid="tid_default_currency"
      >
        <Controller
          name="LenderProfile.default_currency"
          control={control}
          rules={{
            required: ValidationMessages.DEFAULT_CURRENCY_REQUIRED,
          }}
          render={({ field: { value, onChange } }) => (
            <Select
              id={"lender_default_currency"}
              theme={theme}
              disablePortal
              label={"Default Currency"}
              placeholder={"Select"}
              value={defaultCurrency?.length == 0 ? "" : value ?? ""}
              selectError={errors?.LenderProfile?.default_currency?.message}
              items={defaultCurrency}
              onChange={(e) => {
                onChange(e);
                setLenderFieldState(true);
                setSelectedDefaultCurrencyId(Number(e.target.value));
              }}
            />
          )}
        />
      </Grid>
      <Grid
        theme={theme}
        item
        xs={12}
        lg={3}
        md={6}
        data-testid="tid_mileageUnits"
      >
        <Controller
          name="LenderProfile.existing_customer_check"
          control={control}
          rules={{
            required: ValidationMessages.Existing_Customer_Check,
          }}
          defaultValue={"Internal"}
          render={({ field: { value, onChange } }) => (
            <Select
              theme={theme}
              disablePortal
              id="lender_existing_customer_check"
              label={"Existing Customer Check"}
              placeholder={"Select"}
              defaultValue={"Internal"}
              items={items}
              value={value ?? "Internal"}
              onChange={(e) => {
                onChange(e);
                setLenderFieldState(true);
              }}
            />
          )}
        />
      </Grid>
      <Grid
        theme={theme}
        item
        xs={12}
        lg={3}
        md={6}
        data-testid="tid_CompanySlug"
      >
        <Controller
          name="LenderProfile.slug"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Input
              formControlClass="string"
              id="lender_slug"
              inputClass="string"
              label="Slug"
              onChange={(e) => {
                onChange(e);
                setLenderFieldState(true);
              }}
              placeholder="sample-slug"
              theme={theme}
              type="string"
              wrapperClass="string"
              value={value}
              fullWidth={true}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default LenderProfile;

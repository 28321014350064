import { FC, useContext, useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import { LayoutWithSideNav } from "layout";
import { DarkTheme, IndexTheme } from "../shared/styles/theme";
import {
  Icon,
  Typography,
  Grid,
  Button,
  unityDarkTheme,
} from "@ntpkunity/controls";
import { useStoreContext } from "../Stores/SetupStore/Store";
import { UserListingComponent, UMSStoreContext } from "@ntpkunity/controls-ums";
import { DrawerWrap, PageHeader } from "shared/components";
import { ThemeContext } from "context";

const ManageUsers: FC = () => {
  const { actions } = useStoreContext();
  const [response, setResponse] = useState<any>(undefined);
  const [open, setOpen] = useState<boolean>(false);
  const [tenantId, setTenantId] = useState<number>(null);
  useEffect(() => {
    if (localStorage.getItem("settings") != null) {
      var access_token_local = JSON.parse(localStorage.getItem("settings"));
      setTenantId(+access_token_local?.tenant_id);
    }
  }, []);
  useEffect(() => {
    if (response?.status == 200) {
      actions.setToast({
        toastMessage: response?.data?.message,
        toastState: true,
      });
    } else if (response?.status) {
      actions.setToast({
        toastMessage: response?.message,
        toastState: true,
        variant: "error",
      });
    }
  }, [response]);
  const {
    states: { productId: productId },
  } = useContext(UMSStoreContext) as any;

  const theme = useTheme();

  const { selectedTheme } = useContext(ThemeContext);

  return (
    <>
      <DrawerWrap open={open}>
        <LayoutWithSideNav theme={theme}>
          <PageHeader
            className="main-page-header page-header"
            theme={theme}
            container
            item
            spacing={2}
          >
            <Grid container spacing={2} theme={theme}>
              <Grid item lg={7} md={6} sm={12} xs={12} theme={theme}>
                <Typography variant="h2" component="h2" theme={theme}>
                  Manage Users
                </Typography>
              </Grid>
              <Grid
                item
                lg={5}
                md={6}
                sm={12}
                xs={12}
                textAlign="right"
                theme={theme}
              >
                <Button
                  theme={theme}
                  primary
                  text={"Add New User"}
                  startIcon={<Icon name="AddIcon" />}
                  onClick={(e) => {
                    setOpen(true);
                  }}
                ></Button>
              </Grid>
            </Grid>
          </PageHeader>
          {tenantId !== null && (
            <UserListingComponent
              theme={selectedTheme == "dark" ? unityDarkTheme : IndexTheme}
              // theme={theme}
              setResponse={setResponse}
              tenantId={tenantId}
              value={""}
              productId={+productId?.productId}
              setopenAddComponent={setOpen}
              openAddComponent={open}
              actionType={"add"}
              componentBehavior={"drawer"}
              nextScreenUrl={`/market-place/app-user-profile`}
              openPopup={false}
              close={() => {}}
              layoutText={{
                nameLabel: "First/Last Name",
                emailAddressLabel: "Email Address",
                productsLabel: "Product(s)",
                rolesLabel: "Role(s)",
                contactNumLabel: "Contact Number",
                statusLabel: "Status",
              }}
            ></UserListingComponent>
          )}
        </LayoutWithSideNav>
      </DrawerWrap>
    </>
  );
};
export default ManageUsers;

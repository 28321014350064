import { FC, useEffect, useState } from "react";
import { useTheme, ToggleButton, styled } from "@mui/material";
import {
  Grid,
  Select,
  Icon,
  Typography,
  Box,
  Autocomplete,
  PhoneInput,
  Button,
  Input,
  IBoxProps,
  Tooltip,
} from "@ntpkunity/controls";
import { Controller } from "react-hook-form";
import { AutoSearch } from "shared/components";
import { IGeneralAddress } from "./general.type";
import { AddressType, QueryKeys, ValidationMessages } from "Enums";
import { useQueryClient } from "react-query";
import { ICountry, IStates } from "Interface";

const AddressLayoutWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.address-layout-wrap': {
    '.u-form-group': {
      '.u-form-control': {
        '&.MuiInputBase-adornedEnd': {
          '.styled-tooltip': {
            height: 24,
            '.MuiTooltip-popper': {
              '&[data-popper-placement*="bottom"]': {
                '.MuiTooltip-arrow': {
                  transform: 'none !important',
                  marginLeft: 7
                }
              },
              '&[data-popper-placement*="left"]': {
                  marginTop: '-7px !important'
              }
            }
          },
        }
      }
    },
    '.tooltip-content': {
      '.fw-bold': {
        fontWeight: theme.typography.fontWeightBold
      }
    }
  }
}))

const InvoiceAddress: FC<IGeneralAddress> = ({
  control,
  errors,
  getValues,
  verifyAddress,
  invoiceingAddressState,
  setInvoiceingAddressState,
  applyInvoiceValidation,
  setApplyInvoiceValidation,
  googleAPIKey,
  isAddressValidationActive,
  lenderCountries,
  setValue,
  setFormValue,
  setConcatInvoiceAddress,
  hasAddressTooltip,
  tooltipPlacement = "left"
}) => {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<ICountry>({
    id: null,
    code: "",
    name: "",
    country_code: "",
    address_template: null,
  });
  const usaStates: Array<IStates> = queryClient.getQueryData(
    QueryKeys.GET_ALL_USA_STATES
  );
  const lenderProfile: any = queryClient.getQueryData(
    QueryKeys.GET_LENDER_PROFILE
  );
  function setError(): boolean {
    let obj = getValues("InvoicingAddress");
    let check = false;
    for (var x in obj) {
      if (x === "verified" || x === "address_type" || x === "country_id")
        continue;
      if (obj[x] !== null && obj[x] !== "" && obj[x] !== undefined) {
        check = true;
        break;
      }
    }
    return check;
  }

  const verifyInvoiceAddress = () => {
    verifyAddress(AddressType.INVOICE);
  };
  useEffect(() => {
    if (lenderProfile?.addresses) {
      let country_id = lenderProfile.addresses?.find(
        (x) => x.address_type == AddressType.INVOICE
      )?.country_id;
      if (country_id && lenderCountries.find((x) => x.id == country_id)) {
        setSelectedCountry(lenderCountries.find((x) => x.id == country_id));
        setFormValue(
          "InvoicingAddress",
          lenderProfile.addresses?.find(
            (x) => x.address_type == AddressType.INVOICE
          )
        );
      } else {
        setSelectedCountry(lenderCountries ? lenderCountries?.[0] : null);
        resetInvoicingAddress();
      }
    } else {
      setSelectedCountry(lenderCountries ? lenderCountries?.[0] : null);
    }
  }, [lenderProfile]);
  useEffect(() => {
    if (lenderCountries.length > 0 && selectedCountry) {
      setValue("InvoicingAddress.country_id", {
        label: selectedCountry?.name,
        id: selectedCountry?.id,
      });
    } else {
      setValue("InvoicingAddress.country_id", { label: "", id: "" });
      resetInvoicingAddress();
      setConcatInvoiceAddress("");
    }
  }, [selectedCountry, lenderProfile]);
  const resetInvoicingAddress = () => {
    setValue("InvoicingAddress.address_line_1", "");
    setValue("InvoicingAddress.address_line_2", "");
    setValue("InvoicingAddress.city", "");
    setValue("InvoicingAddress.contact_number", "");
    setValue("InvoicingAddress.county", "");
    setValue("InvoicingAddress.zip_code", "");
    setValue("InvoicingAddress.state_id", "");
    setValue("InvoicingAddress.state_name", "");
    setValue("InvoicingAddress.field5", "");
    setValue("InvoicingAddress.field6", "");
    setValue("InvoicingAddress.verified", false);
  };
  return (
    <AddressLayoutWrap theme={theme} className="address-layout-wrap">
      <Grid theme={theme} container item spacing={3}>
        <Grid theme={theme} item xs={12}>
          <AutoSearch theme={theme} className="auto-search">
            <Typography
              variant="body2"
              component="span"
              sx={{ mr: 1 }}
              theme={theme}
            >
              Select Country:
            </Typography>
            <ToggleButton
              value="check"
              selected={open}
              onChange={() => {
                setOpen(!open);
              }}
            >
              {selectedCountry?.name}
              <Icon name="ChevronDown" />
            </ToggleButton>
            {open && (
              <Box theme={theme} className="list-wrap">
                <Controller
                  name={"InvoicingAddress.country_id"}
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      id="select_invoice_country"
                      theme={theme}
                      disablePortal={true}
                      capitalizeLabel={true}
                      open={open}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      defaultValue={
                        selectedCountry
                          ? {
                              label: selectedCountry?.name,
                              id: selectedCountry?.id,
                            }
                          : { label: "", id: "" }
                      }
                      onChange={(_e: any, value: any) => {
                        field.onChange(value);
                        setSelectedCountry(
                          lenderCountries.find((x) => x.id == value.id)
                        );
                        resetInvoicingAddress();
                        setConcatInvoiceAddress("");
                        setOpen(!open);
                      }}
                      value={field.value || null}
                      items={
                        lenderCountries
                          ? lenderCountries.map((x) => {
                              return { label: x.name, id: x.id };
                            })
                          : null
                      }
                      renderOption={(props, option, { selected }) => (
                        <li {...props} key={option.id}>
                          <span>{option.label}</span>
                        </li>
                      )}
                    />
                  )}
                />
              </Box>
            )}
          </AutoSearch>
        </Grid>
        <Grid theme={theme} item xs={12}>
          <Controller
            name={"InvoicingAddress.address_line_1"}
            control={control}
            rules={{
              required: {
                value: applyInvoiceValidation,
                message: ValidationMessages.ADDRESS_LINE_1,
              },
            }}
            render={({ field: { onChange, value } }) => (
              <Input
                theme={theme}
                fullWidth={true}
                label={"Address Line 1"}
                placeholder="House number, street name"
                type="text"
                onChange={(e) => {
                  onChange(e);
                  setApplyInvoiceValidation(setError());
                  setInvoiceingAddressState(true);
                }}
                endAdornment={
                  <>
                    {hasAddressTooltip
                      ?(
                        <>
                          <Tooltip
                            placement={tooltipPlacement}
                            title={
                              <Box theme={theme} className='tooltip-content'>
                                <Typography theme={theme} variant='body2' component='span' display={'block'}>
                                  <span className='fw-bold'>Street Address:</span> 38-30 211th St.
                                </Typography>
                                <Typography theme={theme} variant='body2' component='span' display={'block'}>
                                  <span className='fw-bold'>PO Box:</span> PO Box 450
                                </Typography>
                                <Typography theme={theme} variant='body2' component='span' display={'block'}>
                                  <span className='fw-bold'>Rural Route:</span> RR 21 Box 450
                                </Typography>
                              </Box>
                            }
                            theme={theme}>
                              <Icon name="InfoIcon" />
                            </Tooltip>
                        </>
                      )
                    : ''
                    }
                  </>                    
                }
                value={value}
                error={errors?.InvoicingAddress?.address_line_1?.message}
              ></Input>
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={12}>
          <Controller
            name={"InvoicingAddress.address_line_2"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Input
                theme={theme}
                fullWidth={true}
                label={"Address Line 2 (Optional)"}
                placeholder="e.g. company, apartment, building..."
                type="text"
                onChange={(e) => {
                  onChange(e);
                  setApplyInvoiceValidation(setError);
                  setInvoiceingAddressState(true);
                }}
                endAdornment={
                  <>
                    {hasAddressTooltip
                      ?(
                        <>
                          <Tooltip
                            placement={tooltipPlacement}
                            title={
                              <Box theme={theme} className='tooltip-content'>
                                <Typography theme={theme} variant='body2' component='span' display={'block'}>
                                  <span className='fw-bold'>Apartment #:</span> Apt. #2
                                </Typography>
                                <Typography theme={theme} variant='body2' component='span' display={'block'}>
                                  <span className='fw-bold'>Suite #:</span>  Ste. #450
                                </Typography>
                              </Box>
                            }
                            theme={theme}>
                              <Icon name="InfoIcon" />
                            </Tooltip>
                        </>
                      )
                    : ''
                    }
                  </>                    
                }  
                value={value}
              ></Input>
            )}
          />
        </Grid>
        {(selectedCountry?.address_template == null ||
          selectedCountry?.address_template?.field1_visible) && (
          <Grid theme={theme} item xs={3}>
            <Controller
              name={"InvoicingAddress.city"}
              control={control}
              rules={{
                required: {
                  value: applyInvoiceValidation,
                  message: ValidationMessages.CITY_REQUIRED,
                },
              }}
              render={({ field: { onChange, value } }) => (
                <Input
                  theme={theme}
                  fullWidth={true}
                  label={
                    selectedCountry?.address_template?.field1_label
                      ? selectedCountry?.address_template?.field1_label
                      : "City"
                  }
                  type="text"
                  onChange={(e) => {
                    onChange(e);
                    setApplyInvoiceValidation(setError);
                    setInvoiceingAddressState(true);
                  }}
                  value={value}
                  error={
                    errors?.InvoicingAddress?.city?.message
                      ? (selectedCountry?.address_template?.field1_label
                          ? selectedCountry?.address_template?.field1_label
                          : "City") + " is required"
                      : ""
                  }
                ></Input>
              )}
            />
          </Grid>
        )}
        {selectedCountry?.address_template?.field2_visible && (
          <Grid theme={theme} item xs={3}>
            <Controller
              name={"InvoicingAddress.state_id"}
              control={control}
              rules={{
                required: {
                  value: applyInvoiceValidation,
                  message: ValidationMessages.STATE_REQUIRED,
                },
              }}
              render={({ field: { onChange, value } }) => (
                <Select
                  theme={theme}
                  fullWidth={true}
                  label={selectedCountry?.address_template?.field2_label}
                  type="text"
                  onChange={(e) => {
                    onChange(e);
                    setApplyInvoiceValidation(setError);
                    setInvoiceingAddressState(true);
                  }}
                  value={value ?? ""}
                  items={usaStates
                    ?.filter((x) => x.country_id === selectedCountry?.id)
                    ?.map((x) => {
                      return {
                        value: x.id,
                        text: x.name,
                      };
                    })}
                  sxProps={undefined}
                  selectError={
                    errors?.InvoicingAddress?.state_id?.message
                      ? selectedCountry?.address_template?.field2_label +
                        " is required"
                      : ""
                  }
                ></Select>
              )}
            />
          </Grid>
        )}
        {(selectedCountry?.address_template == null ||
          selectedCountry?.address_template?.field3_visible) && (
          <Grid theme={theme} item xs={3}>
            <Controller
              name={"InvoicingAddress.zip_code"}
              control={control}
              rules={{
                required: {
                  value:
                    applyInvoiceValidation &&
                    selectedCountry?.address_template?.field3_visible,
                  message: ValidationMessages.ZIP_CODE_REQUIRED,
                },
              }}
              render={({ field: { onChange, value } }) => (
                <Input
                  theme={theme}
                  fullWidth={true}
                  label={
                    selectedCountry?.address_template?.field3_label
                      ? selectedCountry?.address_template?.field3_label
                      : "Postal Code"
                  }
                  type="text"
                  placeholder="Type here..."
                  onChange={(e) => {
                    onChange(e);
                    setApplyInvoiceValidation(setError);
                    setInvoiceingAddressState(true);
                  }}
                  value={value}
                  error={
                    errors?.InvoicingAddress?.zip_code?.message
                      ? (selectedCountry?.address_template?.field3_label
                          ? selectedCountry?.address_template?.field3_label
                          : "Postal Code") + " is required"
                      : ""
                  }
                ></Input>
              )}
            />
          </Grid>
        )}
        {selectedCountry?.address_template?.field4_visible && (
          <Grid theme={theme} item xs={3}>
            <Controller
              name={"InvoicingAddress.county"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <Input
                  theme={theme}
                  fullWidth={true}
                  label={selectedCountry?.address_template?.field4_label}
                  type="text"
                  disabled={isAddressValidationActive}
                  onChange={(e) => {
                    onChange(e);
                    setApplyInvoiceValidation(setError);
                  }}
                  value={value}
                ></Input>
              )}
            />
          </Grid>
        )}
        {selectedCountry?.address_template == null && (
          <Grid theme={theme} item xs={3}>
            <Controller
              name={"InvoicingAddress.state_name"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <Input
                  theme={theme}
                  fullWidth={true}
                  label={"State/Province/Region"}
                  type="text"
                  onChange={onChange}
                  value={value}
                ></Input>
              )}
            />
          </Grid>
        )}
        {selectedCountry?.address_template?.field5_visible && (
          <Grid theme={theme} item xs={3}>
            <Controller
              name={"InvoicingAddress.field5"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <Input
                  theme={theme}
                  fullWidth={true}
                  label={selectedCountry?.address_template?.field5_label}
                  type="text"
                  onChange={onChange}
                  value={value}
                ></Input>
              )}
            />
          </Grid>
        )}
        {selectedCountry?.address_template?.field6_visible && (
          <Grid theme={theme} item xs={3}>
            <Controller
              name={"InvoicingAddress.field6"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <Input
                  theme={theme}
                  fullWidth={true}
                  label={selectedCountry?.address_template?.field6_label}
                  type="text"
                  onChange={onChange}
                  value={value}
                ></Input>
              )}
            />
          </Grid>
        )}
        <Grid theme={theme} item xs={3}>
          {selectedCountry?.country_code && (
            <Controller
              name={"InvoicingAddress.contact_number"}
              control={control}
              rules={{
                validate: {
                  minLength: (value) => {
                    if (applyInvoiceValidation && selectedCountry?.country_code)
                      return (
                        parseInt(value) >
                          selectedCountry?.country_code.length ||
                        ValidationMessages.CONTACT_NUMBER_REQUIRED
                      );
                    else if (applyInvoiceValidation && value)
                      return (
                        value.length > 1 ||
                        ValidationMessages.CONTACT_NUMBER_REQUIRED
                      );
                  },
                },
              }}
              render={({ field }) => (
                <PhoneInput
                  theme={theme}
                  type={"text"}
                  {...field}
                  label={"Contact Number"}
                  fullWidth
                  phoneCountryCode={selectedCountry?.country_code}
                  error={errors?.InvoicingAddress?.contact_number?.message}
                />
              )}
            />
          )}
        </Grid>
        <Grid theme={theme} item>
          {invoiceingAddressState && googleAPIKey && (
            <Button
              theme={theme}
              secondary
              text={"Verify Address"}
              onClick={verifyInvoiceAddress}
            />
          )}
        </Grid>
      </Grid>
    </AddressLayoutWrap>
  );
};
export default InvoiceAddress;

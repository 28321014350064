import { FC, useState, useEffect } from "react";
import { useTheme } from "@mui/material";
import { LayoutWithSideNav } from "../layout";
import {
  IGridProps,
  Icon,
  Input,
  IBoxProps,
  Grid,
  DuoTab,
  Box,
  Button,
  Typography,
  Dialog,
} from "@ntpkunity/controls";
import {
  DuoTabWrap,
  PageHeader,
  ConfirmationDialog,
  ThemeBuilder,
  DrawerWrap,
} from "../shared/components";
import Logo from "shared/assets/images/index-logo.svg";
import { IAddress, ICountry, ISelectItemList } from "Interface";
import { useForm } from "react-hook-form";
import {
  UseMutation_UpdateLenderProfile,
  UseMutation_AddLenderProfile,
  UseQuery_GetAll_CURRENCIES,
  UseQuery_GetAll_USA_States,
  UseQuery_getLenderIntegrations,
  UseQuery_GetAll_Countries,
  UseQuery_GetAll_Radius,
  UseQuery_getLenderByTenantId,
} from "services";
import { useSetupsStore, useStoreContext } from "Stores/SetupStore/Store";
import { LenderProfile, LenderAddress, Language } from "modules";
import { usePrompt } from "utilities/usePrompt";
import { LenderModel } from "models";
import {
  AddressType,
  QueryKeys,
  ToastMessages,
  DialogMessages,
  ValidationMessages,
} from "Enums";
import { useQueryClient } from "react-query";
import { useGetVerifyAddress } from "services/integration-api.service";
import VerifyAddress from "../components/AddAddresses/verifyaddress";
import { useNavigate } from "react-router-dom";
import { validate } from "json-schema";
import { logout } from "@ntpkunity/controls-ums";
import { setStyleSheet, setFavicon, setLogo } from "favicon";

const Lender: FC = () => {
  const theme = useTheme();
  const [state, dispatch] = useSetupsStore();
  const { openDrawerSt } = state;
  const [leavePagePopupState, setLeavePageState] = useState<boolean>(false);
  const [googleAPIKey, setGoogleAPIKey] = useState<string>(null);
  const [isAddressValidationActive, setIsAddressValidationActive] =
    useState<boolean>(false);
  const [failurePopupState, setFailurePopupState] = useState<boolean>(false);
  const [lenderFieldState, setLenderFieldState] = useState<boolean>(false);
  const [legalAddressState, setLegalAddressState] = useState<boolean>(false);
  const [verifiedAddress, setyVerifiedAddress] = useState<IAddress>();
  const [verifiedAddressName, setVerifiedAddressName] = useState<string>("");
  const [mailingAddressState, setMailingAddressState] =
    useState<boolean>(false);
  const [invoiceingAddressState, setInvoiceingAddressState] =
    useState<boolean>(false);
  const [officeAddressState, setOfficeAddressState] = useState<boolean>(false);
  const [garagingAddressState, setGaragingAddressState] =
    useState<boolean>(false);
  const [applyInvoiceValidation, setApplyInvoiceValidation] =
    useState<boolean>(false);
  const [applyOfficeAddressValidation, setApplyOfficeAddressValidation] =
    useState<boolean>(false);
  const [applyGaragingAddressValidation, setApplyGaragingAddressValidation] =
    useState<boolean>(false);
  const [defaultCurrency, setDefaultCurrency] =
    useState<Array<ISelectItemList>>();
  const [lenderCountries, setLenderCountries] = useState<Array<ICountry>>([]);
  const [tenantId, setTenantId] = useState<number>(null);
  const form = useForm();
  const {
    control,
    handleSubmit,
    formState,
    getValues,
    setValue,
    reset,
    watch,
    trigger,
    setError,
  } = form;
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [navigationState, setNavigationState] = useState<any>();

  UseQuery_getLenderByTenantId(tenantId);
  UseQuery_GetAll_CURRENCIES();
  UseQuery_GetAll_USA_States();
  UseQuery_GetAll_Countries();
  UseQuery_GetAll_Radius();
  UseQuery_getLenderIntegrations();
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();
  const { mutate: getAddress } = useGetVerifyAddress();
  const lenderProfile: any = queryClient.getQueryData(
    QueryKeys.GET_LENDER_PROFILE
  );

  const setThemeDefaultValues: any = () => {
    setValue("theme.favicon", defaultThemeValues?.favicon);
    setValue("theme.logo", defaultThemeValues?.logo);
    setValue("theme.primary_color", defaultThemeValues?.primary_color);
    setValue("theme.secondary_color", defaultThemeValues?.secondary_color);
    setFavicon(defaultThemeValues.favicon?.location);
    setLogo(defaultThemeValues.logo?.location);
  };
  // const ThemeBuilderData: any = queryClient.getQueryData([
  //   QueryKeys.GET_THEME_BUILDER,
  //   lenderProfile?.email,
  // ]);
  const addressStates: any = queryClient.getQueryData(
    QueryKeys.GET_ALL_USA_STATES
  );
  const lenderIntegrations: any = queryClient.getQueryData(
    QueryKeys.GET_ALL_LENDER_INTEGRATIONS
  );
  const allCountries: any = queryClient.getQueryData(
    QueryKeys.GET_ALL_COUNTRIES
  );
  const [selectedDefaultCurrencyId, setSelectedDefaultCurrencyId] =
    useState<number>(lenderProfile?.default_currency);
  useEffect(() => {
    if (localStorage.getItem("settings") != null) {
      var access_token_local = JSON.parse(localStorage.getItem("settings"));
      setTenantId(access_token_local.tenant_id);
    }
  }, []);
  useEffect(() => {
    if (lenderIntegrations) {
      setGoogleAPIKey(null);
      lenderIntegrations.map((integration) => {
        if (
          integration.integration_type === "Address Validation" &&
          integration.provider_name === "Google"
        ) {
          setIsAddressValidationActive(integration.is_active);
        }
        if (
          integration.integration_type === "Address Validation" &&
          integration.provider_name === "Google" &&
          integration.is_active === true
        ) {
          integration.lender_integration_user_inputs?.map((user_inputs) => {
            if (user_inputs.user_input_name === "api_secret_key") {
              setGoogleAPIKey(user_inputs.user_input_value);
              return true;
            } else {
              setGoogleAPIKey(null);
            }
          });
        }
      });
    }
  }, [lenderIntegrations]);

  const defaultThemeValues = {
    primary_color: "3952F5",
    secondary_color: "D8DAE5",
    favicon: {
      location:
        "https://unitydmsbucket.s3.us-east-1.amazonaws.com/images/favicon.ico",
    },
    logo: { location: Logo },
  };

  useEffect(() => {
    if (
      formState.dirtyFields?.MailingAddress ||
      formState.dirtyFields?.OfficeAddress ||
      formState.dirtyFields?.GaragingAddress ||
      formState.dirtyFields?.InvoicingAddress ||
      formState.dirtyFields?.theme
    ) {
      setLenderFieldState(true);
    }
    // if (formState.dirtyFields?.MailingAddress){
    //  setMailingAddressState(true)
    // }
  }, [formState]);

  function setFormValue(name: string, address: IAddress) {
    setValue(
      name,
      {
        id: address?.id ? address?.id : undefined,
        address_line_1: address?.address_line_1 ? address?.address_line_1 : "",
        address_line_2: address?.address_line_2 ? address?.address_line_2 : "",
        city: address?.city ? address?.city : "",
        contact_number: address?.contact_number ? address?.contact_number : "",
        county: address?.county ? address?.county : "",
        zip_code: address?.zip_code ? address?.zip_code : "",
        state_id: address?.state_id ? address?.state_id : "",
        state_name: address?.state_name ? address?.state_name : "",
        field5: address?.field5 ? address?.field5 : "",
        field6: address?.field6 ? address?.field6 : "",
        verified: address?.verified ? address?.verified : false,
        address_type: address?.address_type ? address?.address_type : "",
      },
      { shouldValidate: false, shouldDirty: false }
    );
  }
  useEffect(() => {
    setSelectedDefaultCurrencyId(lenderProfile?.default_currency);
    let lender: LenderModel = new LenderModel();
    lender.lender_profile = lenderProfile;
    lender.lender_addresses = lenderProfile?.addresses;
    lender.theme = lenderProfile?.theme;
    if (lenderProfile?.theme) {
      setValue("theme.favicon", lenderProfile?.theme?.favicon);
      setValue("theme.logo", lenderProfile?.theme?.logo);
      setValue("theme", lenderProfile?.theme);
    } else {
      setThemeDefaultValues();
    }
    setFormValue(
      "MailingAddress",
      lender.lender_addresses?.find(
        (x) => x.address_type == AddressType.MAILING
      )
    );
    setFormValue(
      "OfficeAddress",
      lender.lender_addresses?.find((x) => x.address_type == AddressType.OFFICE)
    );
    setFormValue(
      "InvoicingAddress",
      lender.lender_addresses?.find(
        (x) => x.address_type == AddressType.INVOICE
      )
    );
    setFormValue(
      "GaragingAddress",
      lender.lender_addresses?.find(
        (x) => x.address_type == AddressType.GARAGING
      )
    );

    let val = lender?.lender_profile?.preferred_currency_ids
      ?.split(",")
      ?.map(Number);

    setValue("LenderProfile", lender?.lender_profile, {
      shouldDirty: false,
      shouldTouch: false,
    });
    setValue("LenderProfile.preferred_currency_ids", val);

    setValue(
      "LenderProfile.default_currency",
      lender?.lender_profile?.default_currency
    );
    setValue("MailingAddress.address_type", AddressType.MAILING);
    setValue("InvoicingAddress.address_type", AddressType.INVOICE);
    setValue("OfficeAddress.address_type", AddressType.OFFICE);
    setValue("GaragingAddress.address_type", AddressType.GARAGING);

    if (lenderProfile?.theme == null) {
      setThemeDefaultValues();
    } else {
      setValue("theme", lenderProfile?.theme);
    }
    if (lender?.lender_profile?.existing_customer_check === null) {
      setValue("LenderProfile.existing_customer_check", "Internal");
    }
  }, [lenderProfile]);
  const handleButtonClick = async (stylesheetlink: any) => {
    const s3FileLink = stylesheetlink;
    try {
      const response = await fetch(s3FileLink);
      const cssContent = await response.text();
      const primaryColorRegex = /--primary:\s*#?([a-fA-F0-9]+)\b/;
      const secondaryColorRegex = /--secondary:\s*#?([a-fA-F0-9]+)\b/;

      // Extract primary and secondary color values from the CSS string
      const primaryColorMatch = cssContent.match(primaryColorRegex);
      const secondaryColorMatch = cssContent.match(secondaryColorRegex);
      // const matches = cssContent.match(colorRegex);
      const primaryColor = primaryColorMatch
        ? primaryColorMatch[1].trim()
        : null;
      const secondaryColor = secondaryColorMatch
        ? secondaryColorMatch[1].trim()
        : null;

      setValue("theme.primary_color", primaryColor);
      setValue("theme.secondary_color", secondaryColor);
      return { primaryColor: primaryColor, secondaryColor: secondaryColor };
    } catch (error: any) {
      console.error("Error:", error.message);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (lenderProfile?.theme) {
        try {
          const colors = await handleButtonClick(
            lenderProfile?.theme?.stylesheet
          );
          if (colors) {
            setValue("theme.primary_color", colors.primaryColor);
            setValue("theme.secondary_color", colors.secondaryColor);
          }
        } catch (error) {
          console.error("Error fetching colors:", error.message);
        }
      } else {
        setThemeDefaultValues();
      }
    };

    fetchData();
  }, [lenderProfile?.theme]);
  async function convertCssToBase64(
    primaryColor: string,
    secondaryColor: string
  ) {
    try {
      const response = await fetch(
        "https://unitydmsbucket.s3.us-east-1.amazonaws.com/theme-builder.css"
      );
      const cssContent = await response.text();
      const modifiedCss = modifyCssContent(
        cssContent,
        primaryColor,
        secondaryColor
      );

      const blob = new Blob([modifiedCss], { type: "text/css" });

      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          if (event.target && event.target.result) {
            const base64Css = btoa(event.target.result as string);
            resolve(base64Css);
          } else {
            reject(new Error("Error converting CSS to base64."));
          }
        };
        reader.readAsBinaryString(blob);
      });
    } catch (error: any) {
      throw new Error("Error fetching or updating CSS file: " + error.message);
    }
  }

  function modifyCssContent(
    cssContent: any,
    primaryColor: string,
    secondaryColor: string
  ) {
    const modifiedCss = cssContent
      .replace(/--primary:\s*(.*?);/, `--primary: #${primaryColor};`)
      .replace(/--secondary:\s*(.*?);/, `--secondary: #${secondaryColor};`);

    return modifiedCss;
  }
  const { mutate: UpdateLenderProfile, isLoading: updateLoader } =
    UseMutation_UpdateLenderProfile();
  const { mutate: addLenderProfile } = UseMutation_AddLenderProfile();
  async function onSave(data) {
    let errorStates = false;
    if (data.theme) {
      data.theme.tenant_id = JSON.parse(
        localStorage.getItem("settings")
      )?.tenant_id;
      const base64Css = await convertCssToBase64(
        data.theme.primary_color,
        data.theme.secondary_color
      );
      data.theme.stylesheet = base64Css;

      if (data.theme.favicon_url) {
        data.theme.favicon = {
          url: data.theme.favicon_url.url,
          key: data.theme.favicon_url.key,
        };
        delete data.theme.favicon_url;
      }
      if (data.theme.logo_url) {
        data.theme.logo = {
          url: data.theme.logo_url.url,
          key: data.theme.logo_url.key,
        };
        delete data.theme.logo_url;
      }
    }
    if (
      !data.MailingAddress.address_line_1 ||
      !data.MailingAddress.city ||
      data.MailingAddress.country_id
    ) {
      if (!data.MailingAddress.address_line_1) {
        setError("MailingAddress.address_line_1", {
          message: ValidationMessages.ADDRESS_LINE_1,
        });
        errorStates = true;
      }
      if (!data.MailingAddress.city) {
        setError("MailingAddress.city", {
          message: ValidationMessages.CITY_REQUIRED,
        });
        errorStates = true;
      }
      if (data.MailingAddress.country_id) {
        const countryCode = lenderCountries.find(
          (x) => x.id == data.MailingAddress.country_id.id
        ).country_code;
        if (
          (countryCode == "US" || countryCode == "CA") &&
          (!data.MailingAddress.state_id || !data.MailingAddress.zip_code)
        ) {
          if (!data.MailingAddress.state_id) {
            setError("MailingAddress.state_id", {
              message: ValidationMessages.STATE_REQUIRED,
            });
            errorStates = true;
          }
          if (!data.MailingAddress.zip_code) {
            setError("MailingAddress.zip_code", {
              message: ValidationMessages.ZIP_CODE_REQUIRED,
            });
            errorStates = true;
          }
        }
      }
    }
    if (data.theme) {
      if (
        !data.theme.primary_color ||
        !data.theme.secondary_color ||
        !data.theme.logo ||
        !data.theme.favicon
      ) {
        if (!data.theme.logo) {
          setError("theme.logo", {
            message: "Logo is Required",
          });
          errorStates = true;
        }
        if (!data.theme.favicon) {
          setError("theme.favicon", {
            message: "Favicon is Required",
          });
          errorStates = true;
        }
        if (!data.theme.favicon) {
          setError("theme.favicon", {
            message: "Favicon is Required",
          });
          errorStates = true;
        }
        if (!data.theme.primary_color) {
          setError("theme.primary_color", {
            message: "Primary Color is Required",
          });
          errorStates = true;
        }
        if (!data.theme.secondary_color) {
          setError("theme.secondary_color", {
            message: "Secondary Color is Required",
          });
          errorStates = true;
        }
      }
    }
    if (data.theme == null) {
      setThemeDefaultValues();
    }
    if (errorStates) {
      return;
    }
    if (
      googleAPIKey &&
      (mailingAddressState ||
        (applyInvoiceValidation && invoiceingAddressState) ||
        (applyOfficeAddressValidation && officeAddressState))
    ) {
      actions.setToast({
        toastMessage: ToastMessages.VERIFY_ADDRESS,
        toastState: true,
        variant: "error",
      });
    } else {
      let addrArr = [];
      let lender: LenderModel = new LenderModel();
      lender.lender_profile = data?.LenderProfile;
      lender.lender_profile.preferred_currency_ids =
        lender.lender_profile?.preferred_currency_ids?.toString();
      lender.lender_profile.minimum_financed_amount =
        +lender.lender_profile.minimum_financed_amount;
      lender.theme = data?.theme;
      lender.theme.id = lender.theme?.id ?? 0;
      lender.theme.user_email = lender.lender_profile?.email;

      if (
        data?.MailingAddress?.address_line_1 != undefined &&
        data?.MailingAddress?.address_line_1 != ""
      ) {
        data.MailingAddress.lender_id = lender?.lender_profile?.id;
        data.MailingAddress.address_type = AddressType.MAILING;
        data.MailingAddress.verified = data.MailingAddress.verified
          ? data.MailingAddress.verified
          : false;
        data.MailingAddress.country_id = data.MailingAddress.country_id?.id;
        data.MailingAddress.state_id = data.MailingAddress.state_id
          ? data.MailingAddress.state_id
          : null;
        if (!data.MailingAddress.state_name) {
          data.MailingAddress.state_name = data.MailingAddress.state_id
            ? addressStates?.find((x) => x.id == data.MailingAddress.state_id)
                ?.name
            : "";
        }
        addrArr?.push(data.MailingAddress);
      }
      if (
        data?.GaragingAddress?.address_line_1 != undefined &&
        data?.GaragingAddress?.address_line_1 != ""
      ) {
        data.GaragingAddress.lender_id = lender?.lender_profile?.id;
        data.GaragingAddress.address_type = AddressType.GARAGING;
        data.GaragingAddress.verified = data.GaragingAddress.verified
          ? data.GaragingAddress.verified
          : false;
        data.GaragingAddress.country_id = data.GaragingAddress?.country_id?.id;
        data.GaragingAddress.state_id = data.GaragingAddress.state_id
          ? data.GaragingAddress.state_id
          : null;
        if (!data.GaragingAddress.state_name) {
          data.GaragingAddress.state_name = data.GaragingAddress.state_id
            ? addressStates?.find((x) => x.id == data.GaragingAddress.state_id)
                ?.name
            : "";
        }
        addrArr.push(data.GaragingAddress);
      }
      if (
        data?.InvoicingAddress?.address_line_1 != undefined &&
        data?.InvoicingAddress?.address_line_1 != ""
      ) {
        data.InvoicingAddress.lender_id = lender?.lender_profile?.id;
        data.InvoicingAddress.address_type = AddressType.INVOICE;
        data.InvoicingAddress.verified = data.InvoicingAddress.verified
          ? data.InvoicingAddress.verified
          : false;
        data.InvoicingAddress.country_id =
          data.InvoicingAddress?.country_id?.id;
        data.InvoicingAddress.state_id = data.InvoicingAddress.state_id
          ? data.InvoicingAddress.state_id
          : null;
        if (!data.InvoicingAddress.state_name) {
          data.InvoicingAddress.state_name = data.InvoicingAddress.state_id
            ? addressStates?.find((x) => x.id == data.InvoicingAddress.state_id)
                ?.name
            : "";
        }
        addrArr.push(data.InvoicingAddress);
      }
      if (
        data?.OfficeAddress?.address_line_1 != undefined &&
        data?.OfficeAddress?.address_line_1 != ""
      ) {
        data.OfficeAddress.lender_id = lender?.lender_profile?.id;
        data.OfficeAddress.address_type = AddressType.OFFICE;
        data.OfficeAddress.verified = data.OfficeAddress.verified
          ? data.OfficeAddress.verified
          : false;
        data.OfficeAddress.country_id = data.OfficeAddress?.country_id?.id;
        data.OfficeAddress.state_id = data.OfficeAddress.state_id
          ? data.OfficeAddress.state_id
          : null;
        if (!data.OfficeAddress.state_name) {
          data.OfficeAddress.state_name = data.OfficeAddress.state_id
            ? addressStates?.find((x) => x.id == data.OfficeAddress.state_id)
                ?.name
            : "";
        }
        addrArr.push(data.OfficeAddress);
      }
      if (addrArr?.length > 0) {
        lender.lender_addresses = addrArr;
      }
      if (lender?.lender_addresses?.length > 0) {
        lender.lender_profile.id > 0
          ? UpdateLenderProfile(lender, {
              onSuccess: (response: any) => {
                if (response.theme) {
                  setStyleSheet(response?.data?.theme?.stylesheet);
                  setFavicon(response?.data.theme?.favicon?.location);
                  setLogo(response?.data.theme?.logo?.location);
                }
                if (response.theme == null) {
                  setFavicon(defaultThemeValues.favicon?.location);
                  setLogo(defaultThemeValues.logo?.location);
                }
                queryClient.invalidateQueries([
                  QueryKeys.GET_THEME_BUILDER,
                  response?.data?.theme.user_email,
                ]);
              },
            })
          : addLenderProfile(lender);
        delete formState.dirtyFields?.MailingAddress;
        delete formState.dirtyFields?.OfficeAddress;
        delete formState.dirtyFields?.GaragingAddress;
        delete formState.dirtyFields?.InvoicingAddress;
        delete formState.dirtyFields?.theme;
        setLenderFieldState(false);
      }
    }
  }
  const resetMailingAddress = () => {
    setValue("MailingAddress.address_line_1", "");
    setValue("MailingAddress.address_line_2", "");
    setValue("MailingAddress.city", "");
    setValue("MailingAddress.contact_number", "");
    setValue("MailingAddress.county", "");
    setValue("MailingAddress.zip_code", "");
    setValue("MailingAddress.state_id", "");
    setValue("MailingAddress.verified", false);
    setMailingAddressState(false);
  };

  const tabs = [
    {
      title: "Profile",
      content: (
        <LenderProfile
          control={control}
          setValue={setValue}
          errors={formState.errors}
          setLenderFieldState={setLenderFieldState}
          defaultCurrency={defaultCurrency}
          setDefaultCurrency={setDefaultCurrency}
          selectedDefaultCurrencyId={selectedDefaultCurrencyId}
          setSelectedDefaultCurrencyId={setSelectedDefaultCurrencyId}
          getValues={getValues}
          setLenderCountries={setLenderCountries}
          resetMailingAddress={() => resetMailingAddress()}
        />
      ),
    },
    {
      title: (
        <Box
          className={
            Object.keys(formState.errors).includes("MailingAddress")
              ? "error"
              : ""
          }
          theme={theme}
        >
          Address
        </Box>
      ),

      content: (
        <LenderAddress
          resetMailingAddress={() => resetMailingAddress()}
          control={control}
          errors={formState.errors}
          setValue={setValue}
          getValues={getValues}
          verifyAddress={(type) => verifyAddress(type)}
          mailingAddressState={mailingAddressState}
          setMailingAddressState={setMailingAddressState}
          invoiceingAddressState={invoiceingAddressState}
          setInvoiceingAddressState={setInvoiceingAddressState}
          officeAddressState={officeAddressState}
          setOfficeAddressState={setOfficeAddressState}
          garagingAddressState={garagingAddressState}
          setGaragingAddressState={setGaragingAddressState}
          applyInvoiceValidation={applyInvoiceValidation}
          applyOfficeAddressValidation={applyOfficeAddressValidation}
          setApplyInvoiceValidation={setApplyInvoiceValidation}
          setApplyOfficeAddressValidation={setApplyOfficeAddressValidation}
          applyGaragingAddressValidation={applyGaragingAddressValidation}
          setApplyGaragingAddressValidation={setApplyGaragingAddressValidation}
          googleAPIKey={googleAPIKey}
          isAddressValidationActive={isAddressValidationActive}
          lenderCountries={lenderCountries}
          setFormValue={setFormValue}
        />
      ),
    },
    {
      title: (
        <Box
          className={
            Object.keys(formState.errors).includes("theme") ? "error" : ""
          }
          theme={theme}
        >
          Personalisation
        </Box>
      ),
      content: (
        <ThemeBuilder form={form} theme={theme} value={defaultThemeValues} />
      ),
    },
    {
      title: (
        <Box
          className={
            Object.keys(formState.errors).includes("theme") ? "error" : ""
          }
          theme={theme}
        >
          Language
        </Box>
      ),
      content: <Language />,
    },
  ];

  const onClose = () => {
    setFailurePopupState(false);
  };

  const showErrorPopUp = () => {
    setFailurePopupState(true);
  };
  const verifyAddress = (type) => {
    let address: IAddress = null;
    switch (type) {
      case AddressType.MAILING:
        setVerifiedAddressName("MailingAddress");
        address = getValues("MailingAddress");
        break;
      case AddressType.INVOICE:
        setVerifiedAddressName("InvoicingAddress");
        address = getValues("InvoicingAddress");
        break;
      case AddressType.OFFICE:
        setVerifiedAddressName("OfficeAddress");
        address = getValues("OfficeAddress");
        break;
      case AddressType.GARAGING:
        setVerifiedAddressName("GaragingAddress");
        address = getValues("GaragingAddress");
        break;
      default:
        break;
    }
    let data = [
      address.address_line_1,
      address.address_line_2,
      address.city,
      address.state_name,
      address?.zip_code,
    ]
      .filter(Boolean)
      .join(", ");

    getAddress(
      { address: data, googleAPIKey },
      {
        onSuccess(response: any) {
          if (response.results.length > 0) {
            address.verified = true;
            let formattedAddressComponents =
              response.results[0].formatted_address.split(",");
            address.address_line_1 = formattedAddressComponents[0];
            address.address_line_2 = "";
            address.city = "";
            address.state_name = "";
            address.county = "";
            address.zip_code = "";

            for (
              let ii = 0;
              ii < response.results[0].address_components.length;
              ii++
            ) {
              let types =
                response.results[0].address_components[ii].types.join(",");
              switch (types) {
                case "locality,political":
                  address.city =
                    response.results[0].address_components[ii].long_name;
                  break;
                case "administrative_area_level_1,political":
                  address.state_name =
                    response.results[0].address_components[ii].long_name;
                  break;
                case "administrative_area_level_2,political":
                  if (
                    lenderCountries?.find(
                      (x) => x.id == address?.country_id?.id
                    )?.address_template?.field4_visible
                  )
                    address.county =
                      response.results[0].address_components[ii].long_name;
                  break;
                case "postal_code":
                  address.zip_code =
                    response.results[0].address_components[ii].long_name !== ""
                      ? response.results[0].address_components[ii].long_name
                      : undefined;
                  break;
              }
            }
          }
          // if (!(response.results[0].address_components[1] !== null) || !(response.results[0].address_components[2] !== null)) {
          //   setLegalAddressState(true)
          //   return
          // }

          setyVerifiedAddress(address);
          setLegalAddressState(false);
          setOpen(true);
        },
      }
    );
  };
  const cancelDialog = (): void => {
    setOpen(false);
  };
  const confirmDialog = (): void => {
    setValue(verifiedAddressName, {
      id: verifiedAddress?.id ? verifiedAddress?.id : undefined,
      address_line_1: verifiedAddress?.address_line_1
        ? verifiedAddress?.address_line_1
        : "",
      city: verifiedAddress?.city ? verifiedAddress?.city : "",
      county: verifiedAddress?.county ? verifiedAddress?.county : "",
      zip_code: verifiedAddress?.zip_code ? verifiedAddress?.zip_code : "",
      address_line_2: verifiedAddress?.address_line_2
        ? verifiedAddress?.address_line_2
        : "",
      contact_number: verifiedAddress?.contact_number
        ? verifiedAddress?.contact_number
        : "",
      verified: true,
      address_type: verifiedAddress?.address_type,
      country_id: verifiedAddress?.country_id,
      state_id: addressStates?.find((obj) => {
        return obj.name === verifiedAddress?.state_name;
      })?.id,
      state_name: verifiedAddress?.state_name
        ? verifiedAddress?.state_name
        : "",
      field5: verifiedAddress?.field5 ? verifiedAddress?.field5 : "",
      field6: verifiedAddress?.field6 ? verifiedAddress?.field6 : "",
    });

    if (
      verifiedAddress?.address_line_1 === "" ||
      verifiedAddress?.city === "" ||
      verifiedAddress?.state_name === "" ||
      verifiedAddress?.zip_code === ""
    ) {
      setValue(verifiedAddressName + ".verified", false);
      verifiedAddress.verified = false;
    }

    trigger(verifiedAddressName);

    setAddressState(verifiedAddress);
    setOpen(false);
    setLenderFieldState(true);
  };
  const setAddressState = (verifiedAddress: IAddress): void => {
    if (AddressType.MAILING === verifiedAddress.address_type) {
      verifiedAddress.verified
        ? setMailingAddressState(false)
        : setMailingAddressState(true);
    } else if (AddressType.OFFICE === verifiedAddress.address_type) {
      verifiedAddress.verified
        ? setOfficeAddressState(false)
        : setOfficeAddressState(true);
    } else if (AddressType.INVOICE === verifiedAddress.address_type) {
      verifiedAddress.verified
        ? setInvoiceingAddressState(false)
        : setInvoiceingAddressState(true);
    } else if (AddressType.GARAGING === verifiedAddress.address_type) {
      verifiedAddress.verified
        ? setGaragingAddressState(false)
        : setGaragingAddressState(true);
    }
  };
  useEffect(() => {
    if (lenderProfile?.countries) {
      let arr: Array<ICountry> = [];
      for (let i = 0; i < lenderProfile?.countries.length; i++) {
        if (allCountries)
          arr.push(
            allCountries?.find((x) => x.id == lenderProfile?.countries[i])
          );
      }
      setLenderCountries(arr);
      setValue("LenderProfile.countries", lenderProfile?.countries);
    }
  }, [allCountries, lenderProfile]);
  const onSaveConfirm = (e: any) => {
    handleSubmit(onSave)();
    setLeavePageState(false);
    setLenderFieldState(false);
  };
  const onCancelConfirm = (e: any) => {
    navigate(".." + navigationState.location.pathname);
    if (navigationState.location.pathname == "/market-place/signup") {
      logout();
      localStorage.clear();
    }
    localStorage.setItem("DirtyState", "false");
  };
  usePrompt(
    "Leave screen?",
    lenderFieldState,
    (dirty) => {
      setLeavePageState(dirty);
    },
    (tx) => {
      setLenderFieldState(false);
      reset(getValues());
      setNavigationState(tx);
    }
  );
  return (
    <>
      <ConfirmationDialog
        openPopUp={leavePagePopupState}
        onConfirm={onSaveConfirm}
        onCancel={onCancelConfirm}
        setPopUpState={setLeavePageState}
        confirmationTitle={DialogMessages.saveTitle}
        confirmationText={DialogMessages.saveText}
        primaryBtnText={DialogMessages.saveBtnText}
        icon={<Icon name="DialogSaveIcon" />}
      />
      <DrawerWrap open={openDrawerSt}>
        <LayoutWithSideNav theme={theme}>
          <Grid theme={theme} container rowSpacing={2}>
            <Grid theme={theme} item xs={12} />
            <PageHeader
              theme={theme}
              className="main-page-header border-bottom"
            >
              <Grid theme={theme} container spacing={2}>
                <Grid theme={theme} item xs={12} sm={12} lg={7} md={6}>
                  <Typography
                    variant="h3"
                    component="h3"
                    sx={{ fontSize: 36 }}
                    rowGap={5}
                    mt={1}
                    data-testid="heading"
                    theme={theme}
                  >
                    Lender Profile
                  </Typography>
                </Grid>
                <Grid
                  theme={theme}
                  item
                  xs={12}
                  lg={5}
                  sm={12}
                  md={6}
                  textAlign="right"
                >
                  {form.formState.isDirty && lenderFieldState ? (
                    <Button
                      theme={theme}
                      onClick={handleSubmit(onSave)}
                      primary
                      text="Save Changes"
                    />
                  ) : null}
                </Grid>
              </Grid>
            </PageHeader>

            <Grid theme={theme} item xs={12} my={-2} sx={{ mb: 5 }}>
              {/* <DuoTabWrap theme={theme} className="tab-form"> */}
              <DuoTab
                theme={theme}
                varient={"underline"}
                items={tabs}
                defaultTabIndex={0}
              />
              {/* </DuoTabWrap> */}
            </Grid>
          </Grid>
          <Grid theme={theme} item xs={12} />
          {failurePopupState && (
            <Dialog
              size="xs"
              variant="confirmation"
              open={failurePopupState}
              noFooter={false}
              onCloseDialog={onClose}
              theme={theme}
              customFooter={
                <Button
                  primary
                  text={"Close"}
                  fullWidth={true}
                  style={{ paddingRight: "82px", paddingLeft: "82px" }}
                  onClick={onClose}
                />
              }
            >
              <Box theme={theme} className="dialog-icon">
                <Icon name="DialogAlertIcon" />
              </Box>
              <Box theme={theme}>
                <Typography
                  theme={theme}
                  mb={2}
                  className="content-title"
                  variant="h2"
                  component="h2"
                >
                  An Error Ocurred
                </Typography>
                <Typography
                  theme={theme}
                  className="text-muted"
                  variant="body2"
                  component="span"
                >
                  Something went wrong
                </Typography>
              </Box>
            </Dialog>
          )}

          <Dialog
            variant={undefined}
            size="sm"
            title="Verify Address"
            open={open}
            onCloseDialog={cancelDialog}
            customFooter={
              <>
                <Grid theme={theme} container rowSpacing={3} columnSpacing={3}>
                  <Grid theme={theme} item xs={3} sm={3}>
                    <Button text="Cancel" secondary onClick={cancelDialog} />
                  </Grid>
                  <Grid theme={theme} item xs={9} sm={9}>
                    <Button
                      theme={theme}
                      primary
                      fullWidth
                      text="Confirm"
                      onClick={confirmDialog}
                    />
                  </Grid>
                </Grid>
              </>
            }
            theme={theme}
            disablePortal
            children={
              <VerifyAddress
                control={control}
                verifyAddress={verifiedAddress}
                showCounty={
                  lenderCountries?.find(
                    (x) => x?.id == verifiedAddress?.country_id?.id
                  )?.address_template?.field4_visible
                }
              />
            }
          />
        </LayoutWithSideNav>
      </DrawerWrap>
    </>
  );
};

export default Lender;

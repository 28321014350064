import { FC } from "react";
import { useTheme } from "@mui/material";
import { LayoutWithSideNav } from "layout";
import { IGridProps, Grid, Typography, DuoTab } from "@ntpkunity/controls";
import { PageHeader, DuoTabWrap } from "shared/components";

import {
  RvCharts,
  InterestCharts,
  SubsidyCharts,
  CommissionCharts,
  DownPaymentChart,
  BaseRateChart,
} from "modules";
import { useSetupsStore } from "Stores/SetupStore/Store";
import {
  UseQuery_GetAllAssetConditions,
  UseQuery_GetAllAssetUsages,
  UseQuery_getAllCreditRatings,
  UseQuery_getAllFinancedAmount,
  UseQuery_GetAllTerms,
  UseQuery_GetAll_CURRENCIES,
} from "services";

import { styled } from "@mui/material/styles";
import { SetupContext } from "context";
import {
  IAssetCondition,
  IAssetUsage,
  ICreditRating,
  ICurrencies,
  IFinancedAmount,
  ILenderProfile,
  ITerms,
} from "Interface";
import { QueryKeys } from "Enums";
import { useQueryClient } from "react-query";

const drawerWidth = 424;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: 0,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginRight: 0,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

const Charts: FC = () => {
  UseQuery_GetAllAssetConditions();
  UseQuery_GetAllTerms();
  UseQuery_getAllCreditRatings();
  UseQuery_getAllFinancedAmount();
  UseQuery_GetAll_CURRENCIES;
  UseQuery_GetAllAssetUsages();
  const queryClient = useQueryClient();
  const assetConditions: Array<IAssetCondition> = queryClient.getQueryData(
    QueryKeys.Get_All_Asset_Conditions
  );
  const assetUsages: Array<IAssetUsage> = queryClient.getQueryData(
    QueryKeys.GET_ALL_ASSET_USAGES
  );
  const terms: Array<ITerms> = queryClient.getQueryData(
    QueryKeys.GET_ALL_TERMS
  );
  const AllFinancedAmounts: Array<IFinancedAmount> = queryClient.getQueryData(
    QueryKeys.GET_ALL_FINANCED_AMOUNTS
  );
  const creditRatings: Array<ICreditRating> = queryClient.getQueryData(
    QueryKeys.GET_ALL_CREDIT_RATINGS
  );
  const currencies: Array<ICurrencies> = queryClient.getQueryData(
    QueryKeys.GET_ALL_CURRENCIES
  );
  const lenderProfile: ILenderProfile = queryClient.getQueryData(
    QueryKeys.GET_LENDER_PROFILE
  );
  const theme = useTheme();
  const [state, dispatch] = useSetupsStore();
  const { openDrawerSt } = state;
  const tabs = [
    {
      title: "Base Rate",
      content: <BaseRateChart />,
    },
    {
      title: "Base Rate Mapping",
      content: <></>,
      disabled: true,
    },
    {
      title: "Margin Rate",
      content: (
        <SetupContext.Provider
          value={{
            assetConditions,
            terms,
            creditRatings,
            currencies,
            lenderProfile,
            AllFinancedAmounts,
            assetUsages,
          }}
        >
          <InterestCharts />
        </SetupContext.Provider>
      ),
    },
    {
      title: "RV/Balloon",
      content: (
        <SetupContext.Provider
          value={{
            assetConditions,
            terms,
            creditRatings,
            currencies,
            lenderProfile,
            AllFinancedAmounts,
            assetUsages,
          }}
        >
          <RvCharts />
        </SetupContext.Provider>
      ),
    },
    {
      title: "Down Payment",
      content: (
        <SetupContext.Provider
          value={{
            assetConditions,
            terms,
            creditRatings,
            currencies,
            lenderProfile,
            AllFinancedAmounts,
            assetUsages,
          }}
        >
          <DownPaymentChart />
        </SetupContext.Provider>
      ),
    },
    {
      title: "Commission",
      content: (
        <SetupContext.Provider
          value={{
            assetConditions,
            terms,
            creditRatings,
            currencies,
            lenderProfile,
            AllFinancedAmounts,
            assetUsages,
          }}
        >
          <CommissionCharts />
        </SetupContext.Provider>
      ),
    },
    {
      title: "Subsidy",
      content: (
        <SetupContext.Provider
          value={{
            assetConditions,
            terms,
            creditRatings,
            currencies,
            lenderProfile,
            AllFinancedAmounts,
            assetUsages,
          }}
        >
          <SubsidyCharts />
        </SetupContext.Provider>
      ),
    },
  ];

  return (
    <Main open={openDrawerSt}>
      <LayoutWithSideNav theme={theme}>
        <Grid theme={theme} container rowSpacing={2}>
          <Grid theme={theme} item xs={12} />
          <PageHeader theme={theme} className="main-page-header border-bottom">
            <Grid theme={theme} container spacing={2}>
              <Grid theme={theme} item xs={12} sm={12} lg={7} md={6}>
                <Typography
                  variant="h3"
                  component="h3"
                  sx={{ fontSize: 36 }}
                  rowGap={5}
                  mt={1}
                  data-testid="heading"
                  theme={theme}
                >
                  Charts
                </Typography>
              </Grid>
            </Grid>
          </PageHeader>

          <Grid theme={theme} item xs={12} my={-2}>
            <DuoTabWrap>
              <DuoTab
                theme={theme}
                varient={"underline"}
                items={tabs}
                defaultTabIndex={0}
              />
            </DuoTabWrap>
          </Grid>
        </Grid>
      </LayoutWithSideNav>
    </Main>
  );
};

export default Charts;

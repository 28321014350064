import { ConfigurationAPI } from "services";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QueryKeys, ToastMessages } from "Enums";
import { useStoreContext } from "Stores/SetupStore/Store";
import { IThemeBuilder } from "Interface";

export const UseMutation_AddThemeBuilder = (): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  return useMutation<IThemeBuilder>(
    (themeBuilder) => {
      return ConfigurationAPI.post("/theme-builder", themeBuilder);
    },
    {
      onSuccess: async (response: any) => {
        actions.setToast({
          toastMessage: ToastMessages.Record_Added_Success,
          toastState: true,
        });
        return response.data;
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage:
            error.response.data.detail.message ??
            ToastMessages.Internal_Server_Error,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};

export const UseMutation_UpdateThemeBuilder = (): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  return useMutation<IThemeBuilder>(
    (themeBuilder: any) => {
      return ConfigurationAPI.patch(
        `/theme-builder/${themeBuilder.user_email}`,
        themeBuilder
      );
    },
    {
      onSuccess: async (response: any) => {
        // queryClient.invalidateQueries(QueryKeys.GET_THEME_BUILDER);
        actions.setToast({
          toastMessage: ToastMessages.Record_Updated_Success,
          toastState: true,
        });
        return response.data;
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage:
            error.response.data.detail.message ??
            ToastMessages.Internal_Server_Error,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};

export const useMutation_ThemeBuilderByEmail: any = () => {
  const queryClient = useQueryClient();
  return useMutation<{ user_email: string }, any>(
    (body: any) => {
      return ConfigurationAPI.get(
        `/theme-builder?user_email=${body.user_email}`
      );
    },
    {
      onSuccess: async (response: any) => {
        queryClient.invalidateQueries([
          QueryKeys.GET_THEME_BUILDER,
          response.user_email,
        ]);
        return response.data;
      },
    }
  );
};
export const getThemeBuilderByEmailFromServer = (user_email: string) => {
  return ConfigurationAPI.get(`/theme-builder?user_email=${user_email}`).then(
    (res) => {
      return res.data;
    }
  );
};
export const UseQuery_getThemeBuilderbyEmail = (user_email: string) => {
  return useQuery<IThemeBuilder>(
    [QueryKeys.GET_THEME_BUILDER, user_email],
    () => {
      return getThemeBuilderByEmailFromServer(user_email).then((data) => {
        return data;
      });
    },
    { keepPreviousData: true, enabled: !!user_email }
  );
};

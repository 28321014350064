import React, { FC, useState, useEffect } from "react";
import { useTheme, TablePagination } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import {
  Icon,
  Menu,
  DataTable,
  Button,
  Input,
  Box,
  Switch,
} from "@ntpkunity/controls";
import { ConfirmationDialog } from "shared/components";
import ChevronDown from "../../shared/assets/images/chevron-down";

import {
  UseMutation_DeleteFeeName,
  UseMutation_UpdateFeeName,
  UseQuery_getAllFeeName_filter,
} from "services";
import { styled } from "@mui/material/styles";
import { ActionOptions, DialogMessages, QueryKeys } from "Enums";
import { IFeeNameFilter, IFeeName } from "Interface";

const tableOptions: any = [
  {
    optionText: (
      <>
        <Icon className="menu-icon" name="EditIcon" />
        {ActionOptions.EDIT}
      </>
    ),
    optionkey: ActionOptions.EDIT,
    optionValue: {},
  },
  {
    optionText: (
      <>
        <Icon className="menu-icon" name="DeleteIcon" />
        {ActionOptions.DELETE}
      </>
    ),
    optionkey: ActionOptions.DELETE,
    optionValue: {},
  },
];

const FeeNameTable: FC<{ onEdit: (data: IFeeName) => unknown }> = ({
  onEdit,
}) => {
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [columnFilters, setColumnFilters] = useState(
    `page_number=${pageNumber}&page_size=${pageSize}`
  );

  const { data } = UseQuery_getAllFeeName_filter(columnFilters);

  const [nameFilter, setNameFilter] = useState("");
  const [codeFilter, setCodeFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const setQueryString = (pgNo, pgSize) => {
    let query_string: string = `page_number=${pgNo}&page_size=${pgSize}`;
    if (nameFilter.trimStart() != null && nameFilter.trimStart() != "") {
      query_string = query_string.concat(`&name=${nameFilter}`);
    }
    if (codeFilter.trimStart() != null && codeFilter.trimStart() != "") {
      query_string = query_string.concat(`&code=${codeFilter}`);
    }
    if (statusFilter.trimStart() != null && statusFilter.trimStart() != "") {
      if (
        "enabled".startsWith(
          statusFilter.toLowerCase().slice(0, Math.max("enabled".length - 1, 1))
        )
      ) {
        query_string = query_string.concat(`&is_active=${true}`);
      } else if (
        "disabled".startsWith(
          statusFilter
            .toLowerCase()
            .slice(0, Math.max("disabled".length - 1, 1))
        )
      ) {
        query_string = query_string.concat(`&is_active=${false}`);
      } else {
        query_string = query_string.concat(`&is_active=None`);
      }
    }
    return query_string;
  };
  const [confirmationPopupState, setConfirmationPopupState] =
    useState<boolean>(false);

  const feeNameFilter: IFeeNameFilter = data;

  const [actionData, setActionData] = useState<IFeeName>(undefined);
  const { mutate: deleteFeeName } = UseMutation_DeleteFeeName();
  const { mutate: UpdateFeeName } = UseMutation_UpdateFeeName(undefined);

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPageNumber(newPage);
    setColumnFilters(setQueryString(newPage, pageSize));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(+event.target.value);
    setPageNumber(0);
    setColumnFilters(setQueryString(0, +event.target.value));
  };

  const handleFilter = () => {
    setPageNumber(0);
    setColumnFilters(setQueryString(0, pageSize));
  };

  const handleSelection = (event, key, value) => {
    switch (key) {
      case ActionOptions.EDIT:
        onEdit(value);
        return;
      case ActionOptions.DELETE:
        setActionData(value);
        setConfirmationPopupState(true);
        return;
    }
  };

  const onDeleteConfirm = (e: any) => {
    deleteFeeName(actionData?.id, {
      onSuccess: (data, variables, context) => {
        setActionData(undefined);
      },
    });
  };
  44;

  const handleEnableChange = (data: IFeeName, event) => {
    let updatedData = {
      ...data,
      is_active: event.target.checked,
    };
    UpdateFeeName(updatedData);
  };

  const theme = useTheme();

  return (
    <>
      <DataTable
        theme={theme}
        variant={"basic"}
        theadChildren={
          <>
            <TableRow>
              {/* <TableCell className="checkbox-cell">
              <Checkbox label=""></Checkbox>
            </TableCell> */}
              <TableCell>Code</TableCell>
              <TableCell>Fee Name</TableCell>
              <TableCell>Status</TableCell>
              <TableCell className="action-cell"></TableCell>
            </TableRow>
            <TableRow className="filters-row">
              {/* <TableCell className="checkbox-cell"></TableCell> */}
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setCodeFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={codeFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setNameFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={nameFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>

              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setStatusFilter(value);
                    }}
                    value={statusFilter?.trimStart()}
                    onBlur={() => {
                      handleFilter();
                    }}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell className="action-cell"></TableCell>
            </TableRow>
          </>
        }
        tbodyChildren={
          feeNameFilter?.result &&
          feeNameFilter.result.map((feeName, index) => (
            <TableRow key={index} className="child-tr">
              <TableCell>{feeName.code}</TableCell>
              <TableCell>{feeName.name}</TableCell>
              <TableCell>
                <Switch
                  theme={theme}
                  varient={"basic"}
                  switchEnabled={feeName.is_active}
                  onChange={(event) => handleEnableChange(feeName, event)}
                  label={feeName.is_active ? "Enabled" : "Disabled"}
                />
              </TableCell>
              <TableCell className="action-cell fixed-cell">
                <Menu
                  theme={theme}
                  disablePortal
                  options={tableOptions.map((option) => {
                    return {
                      optionText: option.optionText,
                      optionkey: option.optionkey,
                      optionValue: feeName,
                    };
                  })}
                  handleOptionClick={handleSelection}
                  render={(onMenuSelection) => (
                    <Button
                      defaultBtn
                      iconText={<Icon name="MoreIcon" />}
                      onClick={onMenuSelection}
                    ></Button>
                  )}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                />
              </TableCell>
            </TableRow>
          ))
        }
      ></DataTable>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15, 20]}
        component="div"
        count={feeNameFilter ? feeNameFilter?.total_results : -1}
        rowsPerPage={pageSize}
        page={pageNumber}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        SelectProps={{
          IconComponent: ChevronDown,
        }}
      />
      {confirmationPopupState && (
        <ConfirmationDialog
          openPopUp={confirmationPopupState}
          onConfirm={onDeleteConfirm}
          setPopUpState={setConfirmationPopupState}
          confirmationTitle={DialogMessages.confirmationTitle}
          confirmationText={DialogMessages.confirmationText}
          primaryBtnText={DialogMessages.deleteBtnText}
          icon={<Icon name="DialogDeleteIcon" />}
        />
      )}
    </>
  );
};

export default FeeNameTable;

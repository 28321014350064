import { useContext, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useQueryClient } from "react-query";
import { ActionOptions, DialogMessages, QueryKeys } from "Enums";
import { Theme, TextField, ToggleButton } from "@mui/material";
import {
  Button,
  Icon,
  ISideBarLayoutProps,
  IDrawerProps,
  IBoxProps,
  List,
  Autocomplete,
  Menu,
  Grid,
  Typography,
  Avatar,
  Box,
  Drawer,
  Switch as CustomizedSwitches,
  PaginatedHeader as CustomPaginationHeader,
  unityTheme,
  Checkbox,
} from "@ntpkunity/controls";
import {
  ConfirmationDialog,
  Divider,
  AutoSearch,
} from "../../shared/components";
import { useNavigate, useLocation } from "react-router-dom";
import { APP_ROUTES } from "shared/config/links";
import clsx from "clsx";
import LogoIcon from "shared/assets/images/logo-icon.svg";
import Logo from "shared/assets/images/index-logo.svg";
// import Logo from "shared/assets/images/BMW-Logo.svg";
import UnPin from "shared/assets/images/un-pin.svg";
import Pin from "shared/assets/images/pin.svg";
import {
  UseQuery_GetActiveDealers,
  UseQuery_GetAll_CURRENCIES,
  UseQuery_getLenderByTenantId,
} from "services";
import { logout } from "@ntpkunity/controls-ums";
import { useForm } from "react-hook-form";
import { DealerContext, ThemeContext } from "context";
import { useGetDealerByEmail } from "services/dealer-management.service";
const drawerWidth = 320;
const chevronDown = `url('data:image/svg+xml;utf8,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 9L12 15L18 9" stroke="${encodeURIComponent(
  "#12131A"
)}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" /></svg>')`;

type ICustomizedBox = IBoxProps & { open?: boolean };

const CustomizedContainer = styled(Box)<ICustomizedBox>(({ theme, open }) => ({
  width: "calc(100% - 80px)",
  zIndex: theme.zIndex.drawer + 1,
  minHeight: "100vh",
  backgroundColor:
    theme.palette.mode === "dark"
      ? [theme.palette.common.black]
      : [theme.palette.common.white],
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  padding: "0 40px",
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  header: {
    borderBottom: "1px solid" + theme.palette.grey[100],
    padding: "22px 0",
    backgroundColor:
      theme.palette.mode === "dark"
        ? [theme.palette.common.black]
        : [theme.palette.common.white],
    position: "sticky",
    top: 0,
    zIndex: 1300,
    ".MuiGrid-container": {
      alignItems: "center",
    },
    ".tenant-title": {
      color: theme.palette.grey[900],
    },
    ".tenant-name": {
      fontWeight: theme.typography.fontWeightMedium,
      padding: "0 4px",
      position: "relative",
      color: theme.palette.grey[900],
    },
    ".header-nav-right": {
      ".header-link-item": {
        padding: "0 0 0 12px",
        position: "relative",
        cursor: "pointer",
        textDecoration: "none",
        "&:last-child": {
          paddingRight: 0,
        },
      },
    },
    ".status-icon": {
      backgroundColor: theme.palette.primary.main,
      display: "block",
      width: 8,
      height: 8,
      borderRadius: "50%",
      border: "2px solid" + theme.palette.common.white,
      position: "absolute",
      right: 0,
      bottom: 0,
    },
    ".toggle": {
      cursor: "pointer",
      float: "none",
      display: "inline-block",
      height: "auto",
      position: "relative",
      top: 0,
      ".toggle-btn": {
        float: "left",
        cursor: "pointer",
      },
      ".ch-up": {
        webkitTransform: "scaleY(-1)",
        transform: "scaleY(-1)",
      },
    },
    ".auto-search": {
      ".MuiTypography-root": {
        display: "inline-block",
        marginTop: 3,
      },
    },
    ".breadcrumbs": {
      ".breadcrumbs-item": {
        "&:after": {
          padding: "0 8px",
          content: '"/"',
        },
        "&:last-child": {
          fontWeight: theme.customVariables.fontWeightSemiBold,
          "&:after": {
            display: "none",
          },
        },
      },
    },
  },
}));

const openedMixin = (theme: Theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  "&.u-side-nav": {
    ".aside-content-wrap": {
      padding: "12px 20px 12px 20px",
    },
    ".u-list-item": {
      ".u-list-item-text": {
        opacity: 1,
      },
    },
  },
});

const closedMixin = (theme: Theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(80px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(80px)`,
  },
  "&.u-side-nav": {
    ".logo-wrap": {
      ".logo-area": {
        padding: 0,
        ".logo": {
          display: "flex",
          verticalAlign: "middle",
          width: "100%",
          textAlign: "cener",
          img: {
            margin: "auto",
            maxWidth: 50,
          },
        },
        ".custom-checkbox-wrap": {
          display: "none",
        },
      },
    },
    ".aside-content-wrap": {
      padding: "12px",
    },
    ".u-drawer-pin": {
      opacity: 0,
      visibility: "hidden",
    },
    ".u-list-item": {
      justifyContent: "center",
      ".u-list-item-icon": {
        marginRight: 0,
        "&.collapse-icon": {
          display: "none",
        },
      },
      ".u-list-item-text": {
        opacity: 0,
      },
      "&:focus": {
        backgroundColor: "#F2F2F2 !important",
        ".u-list-item-icon": {
          svg: {
            path: {
              stroke: theme.palette.primary.main + "!important",
            },
          },
        },
      },
      "&:active": {
        backgroundColor: "#F2F2F2 !important",
        ".u-list-item-icon": {
          svg: {
            path: {
              stroke: theme.palette.primary.main + "!important",
            },
          },
        },
      },
      "&.active": {
        backgroundColor: "#F2F2F2 !important",
        ".u-list-item-icon": {
          svg: {
            path: {
              stroke: theme.palette.primary.main + "!important",
            },
          },
        },
      },
    },
    ".nav-group-title": {
      textAlign: "center",
    },
    ".side-nav-actions": {
      ".toggle-nav-btn": {
        width: 40,
        height: 40,
        transform: "rotate(0deg)",
      },
      ".MuiFormGroup-root": {
        display: "none",
      },
    },
    ".u-nested-list": {
      display: "none",
    },
  },
});

const CustomizedDrawer = styled(Drawer)<IDrawerProps>(
  ({ theme, open }): any => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
    ".MuiDrawer-paper": {
      backgroundColor:
        theme.palette.mode === "light"
          ? [unityTheme.palette.common.white]
          : unityTheme.palette.grey[900],
      borderColor:
        theme.palette.mode === "light"
          ? [unityTheme.palette.divider]
          : unityTheme.palette.grey[600],
    },
  })
);

const LayoutWrap = styled(Box)<IBoxProps>(({ theme }): any => ({
  display: "flex",
  ".u-aside-wrap": {
    zIndex: 2048,
    display: "flex",
    ".side-nav-area-wrap": {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      overflowX: "hidden",
      overflowY: "auto",
      "@supports (-moz-appearance:none)": {
        scrollbarWidth: "thin",
      },
      "&::-webkit-scrollbar": {
        width: 7,
        height: 7,
      },
      "&::-webkit-scrollbar-track": {
        padding: "0 1px",
        backgroundColor:
          theme.palette.mode === "light" ? "#f0f0f0" : theme.palette.grey[700],
      },
      "&::-webkit-scrollbar-thumb": {
        transition: "all 0.3s",
        backgroundColor:
          theme.palette.mode === "light" ? "#cdcdcd" : theme.palette.grey[500],
      },
      "&::-webkit-scrollbar-thumb:hover": {
        backgroundColor:
          theme.palette.mode === "light" ? "#a6a6a6" : theme.palette.grey[600],
      },
    },
    ".aside-content-wrap": {
      "&.content-top": {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
      },
      "&.action-content": {
        padding: "0px 20px 12px 20px",
        marginTop: "-12px",
      },
      ".MuiDivider-root": {
        margin: "12px 0",
      },
    },
    ".logo-wrap": {
      borderBottom: "1px solid" + theme.palette.divider,
      padding: 12,
      ".logo-area": {
        width: "100%",
        display: "flex",
        paddingLeft: 18,
        height: 51,
        ".logo": {
          cursor: "pointer",
          display: "flex",
          float: "left",
          height: 50,
          width: "75%",
          img: {
            maxHeight: 50,
          },
        },
        ".u-drawer-pin": {
          width: 24,
          height: 24,
          padding: 0,
          float: "right",
          marginRight: 24,
          backgroundColor: "transparent !important",
          "&.pinned": {
            svg: {
              path: {
                fill: theme.palette.primary.main,
              },
            },
          },
          ".MuiTouchRipple-root": {
            display: "none",
          },
        },
        ".custom-checkbox-wrap": {
          position: "absolute",
          right: 16,
          marginTop: 15,
          ".u-custom-control-label": {
            ".custom-checkbox": {
              marginBottom: 0,
              backgroundImage: `url(${UnPin})`,
              width: 24,
              height: 24,
              backgroundPosition: "center",
              backgroundSize: "97%",
              backgroundRepeat: "no-repeat",
              svg: {
                display: "none",
                marginTop: 1,
                marginLeft: 1,
              },
              "&.Mui-checked": {
                backgroundImage: "none",
                svg: {
                  display: "inline-block",
                  path: {
                    fill: theme.palette.primary.main,
                  },
                },
              },
              "&:hover": {
                "&:after": {
                  display: "none",
                },
              },
            },
          },
        },
      },
      ".MuiDivider-root": {
        marginTop: 50,
      },
    },
    ".nav-group-title": {
      fontSize: 10,
      textTransform: "uppercase",
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.palette.grey[600],
      lineHeight: theme.typography.body1.lineHeight,
      display: "block",
    },
    ".side-nav-actions": {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "4px 0",
      height: "60px",
      ".toggle-nav-btn": {
        padding: 0,
        width: 40,
        height: 40,
        borderRadius: theme.shape.borderRadius,
        transition: "all 0.3s ease-in-ou 0s",
        transform: "rotate(180deg)",
        "&:hover": {
          backgroundColor: "#F2F2F2",
        },
        "&.btn.with-icon": {
          padding: 8,
          "&:hover": {
            svg: {
              path: {
                stroke: theme.palette.grey[900],
              },
            },
          },
        },
        ".MuiTouchRipple-root": {
          display: "none",
        },
      },
      ".MuiFormGroup-root": {
        label: {
          marginLeft: 0,
          marginRight: 0,
          position: "absolute",
          bottom: "23px",
        },
      },
    },
  },
}));

const CustomSideBarLayout: React.FC<ISideBarLayoutProps> = ({
  children,
  theme,
  ...props
}) => {
  UseQuery_GetAll_CURRENCIES();
  const queryClient = useQueryClient();
  const {
    formState: { isDirty },
  } = useForm();
  const { selectedTheme, toggleTheme } = useContext(ThemeContext);
  const { dealer_code, updateDealerCode } = useContext(DealerContext);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(false);
  const [showDealer, setShowDealer] = useState(false);
  const [showBreadcrumbsTitle, setShowBreadcrumbsTitle] = useState(false);
  const [leavePagePopupState, setLeavePageState] = useState<boolean>(false);
  const [dealerEmail, setDealerEmail] = useState<string>(null);
  let navigate = useNavigate();
  const location = useLocation();
  UseQuery_GetActiveDealers();
  const [tenantId, setTenantId] = useState<number>(null);
  const [userNameinitials, setUserNameInitials] = useState<string>("");
  const [userRole, setUserRole] = useState<any>();
  const { mutate: getDealerByEmail } = useGetDealerByEmail();
  const dealerData: any = queryClient.getQueryData(
    QueryKeys.GET_ACTIVE_DEALERS
  );
  const settings = JSON.parse(localStorage.getItem("settings"));
  const [pinStatus, setPinStatus] = useState(
    JSON.parse(localStorage.getItem("pin_state"))
  );
  const getInitials = function (string) {
    var names = string.split(" "),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  useEffect(() => {
    if (localStorage.getItem("settings") != null) {
      var access_token_local = JSON.parse(localStorage.getItem("settings"));
      setUserNameInitials(getInitials(access_token_local?.user_name));
      localStorage.setItem("api-key", access_token_local?.access_token);
      setTenantId(access_token_local?.tenant_id);
      setUserRole(access_token_local?.role);
      setDealerEmail(access_token_local?.email);
    }

    location.pathname.search("dealer-configurations") == -1 &&
    location.pathname.search("inventory-management") == -1 &&
    location.pathname.search("dealer-options") == -1 &&
    location.pathname.search("financial-insurance") == -1
      ? setShowDealer(false)
      : setShowDealer(true);
    location.pathname.search("submit-feedback") == -1 &&
    location.pathname.search("dealer-management") == -1 &&
    location.pathname.search("user-management") == -1
      ? setShowBreadcrumbsTitle(false)
      : setShowBreadcrumbsTitle(true);
  }, []);
  const lenderProfile: any = queryClient.getQueryData(
    QueryKeys.GET_LENDER_PROFILE
  );
  const userEmail =
    settings?.role.name === "Admin"
      ? lenderProfile?.email
      : settings?.role?.name === "Index Dealer Admin User"
      ? dealerEmail
      : "";
  const ThemeBuilderData: any = queryClient.getQueryData([
    QueryKeys.GET_THEME_BUILDER,
    userEmail,
  ]);

  useEffect(() => {
    if (
      tenantId &&
      dealerEmail &&
      userRole?.name === "Index Dealer Admin User"
    ) {
      getDealerByEmail(
        {
          data: `tenant_id=${tenantId}&email=${dealerEmail}`,
        },
        {
          onSuccess(response: any) {
            updateDealerCode(response?.dealer_code);
          },
        }
      );
    }
  }, [tenantId, dealerEmail, userRole]);

  const handleSelection = (event, key, value) => {
    switch (key) {
      case ActionOptions.USER_PROFILE:
        navigate(`${APP_ROUTES.User_Profile}`);
        return;
      case ActionOptions.LOGOUT:
        navigate("../market-place/signup");
        if (JSON.parse(localStorage.getItem("DirtyState")) == false) {
          logout();
          localStorage.clear();
          return;
        } else {
          return;
        }
      case ActionOptions.CHANGE_PASSWORD:
        navigate(`${APP_ROUTES.Change_Password}`);
        return;
      default:
        return;
    }
  };

  const url = window.location.href
    .split("/")
    .at(4)
    ?.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
      return g1.toUpperCase() + g2.toLowerCase();
    });

  const MenuOptions: any = [
    {
      optionText: "Profile",
      optionkey: "MyProfile",
      optionValue: "MyProfile",
    },
    {
      optionText: "Logout",
      optionkey: "Logout",
      optionValue: "Logout",
    },
  ];

  const handleDrawer = () => {
    setOpen(true);
  };

  const handleDrawerPin = (e: any) => {
    let pinState: boolean = JSON.parse(localStorage.getItem("pin_state"));
    setOpen(true);
    localStorage.setItem("pin_state", pinState === true ? "false" : "true");
    setPinStatus(!pinState);
  };
  useEffect(() => {
    if (pinStatus) {
      setOpen(true);
    } else {
      localStorage.removeItem("lender_sub_menu");
      localStorage.removeItem("dealer_sub_menu");
      localStorage.removeItem("docu_sub_menu");
    }
  }, [localStorage.getItem("pin_state")]);

  useEffect(() => {
    window.addEventListener("beforeunload", () =>
      localStorage.setItem("pin_state", "false")
    );
    return () => {
      window.removeEventListener("beforeunload", () => {});
    };
  }, []);
  const userManagmentMenu = () => {
    if (userRole?.is_admin) {
      return {
        icon: <Icon name="UserManagementIcon" />,
        label: "User Management",
        toolTipItem: open ? undefined : "User Management",
        hideLabel: false,
        hideIcon: false,
        onClick: () => {
          navigate(`${APP_ROUTES.Manage_Users}`);
        },
        listItemButtonSx: undefined,
        listItemIconSx: undefined,
        listItemTextSx: undefined,
      };
    } else
      return {
        label: "",
        hideLabel: true,
        selected: false,
        hideIcon: false,
      };
  };
  return (
    <>
      <ConfirmationDialog
        openPopUp={leavePagePopupState}
        onConfirm={logout}
        setPopUpState={setLeavePageState}
        confirmationTitle={DialogMessages.saveTitle}
        confirmationText={DialogMessages.saveText}
        primaryBtnText={DialogMessages.saveBtnText}
        icon={<Icon name="DialogSaveIcon" />}
      />
      <LayoutWrap theme={theme} component="div">
        <Box theme={theme} component="aside" className="u-aside-wrap">
          <CustomizedDrawer
            theme={theme}
            variant="permanent"
            open={open || JSON.parse(localStorage.getItem("pin_state"))}
            className="u-side-nav collapse"
          >
            <div className="logo-wrap">
              <div className="logo-area">
                <Box theme={theme} className="logo" onClick={handleDrawer}>
                  {open || JSON.parse(localStorage.getItem("pin_state")) ? (
                    <img
                      src={
                        ThemeBuilderData
                          ? ThemeBuilderData?.logo.location
                          : Logo
                      }
                      alt="Logo"
                      id="unity_logo"
                    />
                  ) : (
                    <img
                      src={
                        ThemeBuilderData
                          ? ThemeBuilderData?.logo.location
                          : LogoIcon
                      }
                      alt="Logo Icon"
                      id="unity_logo"
                    />
                  )}
                </Box>
                <Checkbox
                  label=""
                  checkedIcon={<Icon name="PinUnpin" />}
                  theme={theme}
                  onChange={handleDrawerPin}
                  checkBoxChecked={pinStatus}
                />
              </div>
            </div>
            <div className="side-nav-area-wrap">
              <div className="aside-content-wrap content-top">
                <List
                  theme={theme}
                  options={
                    userRole?.name == "DealerAdmin" ||
                    userRole?.name === "Index Dealer Admin User"
                      ? [
                          {
                            icon: <Icon name="DealerConfigIcon" />,
                            label: "Dealer Configurations",
                            toolTipItem: open
                              ? undefined
                              : "Dealer Configurations",
                            hideLabel: false,
                            hideIcon: false,
                            expanded:
                              JSON.parse(localStorage.getItem("pin_state")) &&
                              JSON.parse(
                                localStorage.getItem("dealer_sub_menu")
                              ),
                            onClick: () => {
                              if (
                                JSON.parse(localStorage.getItem("pin_state"))
                              ) {
                                const dealerMenuState = JSON.parse(
                                  localStorage.getItem("dealer_sub_menu")
                                );
                                localStorage.setItem(
                                  "dealer_sub_menu",
                                  dealerMenuState ? "false" : "true"
                                );
                              } else {
                                localStorage.setItem(
                                  "dealer_sub_menu",
                                  "false"
                                );
                              }
                              setOpen(true);
                            },
                            listItemButtonSx: undefined,
                            listItemIconSx: undefined,
                            listItemTextSx: undefined,
                            subOptions: [
                              {
                                label: "Dealer Profile ",
                                onClick: () => {
                                  navigate(
                                    `${APP_ROUTES.Dealer_Configurations}`
                                  );
                                },
                              },
                              {
                                label: "Dealer Options",
                                onClick: () => {
                                  navigate(`${APP_ROUTES.Dealer_Options}`);
                                },
                              },

                              {
                                label: "F&I Products",
                                onClick: () => {
                                  navigate(`${APP_ROUTES.Financial_Insurance}`);
                                },
                              },
                            ],
                          },
                          {
                            icon: <Icon name="InvenroryManagementIcon" />,
                            label: "Inventory Management",
                            toolTipItem: open
                              ? undefined
                              : "Inventory Management",
                            hideLabel: false,
                            hideIcon: false,
                            onClick: () => {
                              navigate(`${APP_ROUTES.Inventory_Management}`);
                            },
                            listItemButtonSx: undefined,
                            listItemIconSx: undefined,
                            listItemTextSx: undefined,
                          },
                        ]
                      : [
                          {
                            icon: <Icon name="LenderConfigurations" />,
                            label: "Lender Configurations",
                            toolTipItem: open
                              ? undefined
                              : "Lender Configurations",
                            hideLabel: false,
                            hideIcon: false,
                            expanded:
                              JSON.parse(localStorage.getItem("pin_state")) &&
                              JSON.parse(
                                localStorage.getItem("lender_sub_menu")
                              ),
                            onClick: () => {
                              if (
                                JSON.parse(localStorage.getItem("pin_state"))
                              ) {
                                const lenderMenuState = JSON.parse(
                                  localStorage.getItem("lender_sub_menu")
                                );
                                localStorage.setItem(
                                  "lender_sub_menu",
                                  lenderMenuState ? "false" : "true"
                                );
                              } else {
                                localStorage.setItem(
                                  "lender_sub_menu",
                                  "false"
                                );
                              }

                              setOpen(true);
                            },
                            listItemButtonSx: undefined,
                            listItemIconSx: undefined,
                            listItemTextSx: undefined,
                            subOptions: [
                              {
                                label: "Lender Profile ",
                                onClick: () => {
                                  navigate(APP_ROUTES.LENDER_PROFILE);
                                },
                              },
                              {
                                label: "Asset Setups",
                                onClick: () => {
                                  navigate(APP_ROUTES.Asset_Setups);
                                },
                              },
                              {
                                label: "Order Setups",
                                onClick: () => {
                                  navigate(APP_ROUTES.Order_Setups);
                                },
                              },
                              {
                                label: "General Setups",
                                onClick: () => {
                                  navigate(APP_ROUTES.General_Setups);
                                },
                              },
                              {
                                label: "Programs",
                                onClick: () => {
                                  navigate(APP_ROUTES.Programs);
                                },
                              },
                              {
                                label: "Charts",
                                onClick: () => {
                                  navigate(APP_ROUTES.Charts);
                                },
                              },
                            ],
                          },
                          {
                            icon: <Icon name="DealershipManagement" />,
                            label: "Dealer Management",
                            toolTipItem: open ? undefined : "Dealer Management",
                            hideLabel: false,
                            hideIcon: false,
                            onClick: () => {
                              navigate(`${APP_ROUTES.Dealer_Management}`);
                            },
                            listItemButtonSx: undefined,
                            listItemIconSx: undefined,
                            listItemTextSx: undefined,
                          },
                          {
                            icon: <Icon name="DealerConfigIcon" />,
                            label: "Dealer Configurations",
                            toolTipItem: open
                              ? undefined
                              : "Dealer Configurations",
                            hideLabel: false,
                            hideIcon: false,
                            expanded:
                              JSON.parse(localStorage.getItem("pin_state")) &&
                              JSON.parse(
                                localStorage.getItem("dealer_sub_menu")
                              ),
                            onClick: () => {
                              if (
                                JSON.parse(localStorage.getItem("pin_state"))
                              ) {
                                const dealerMenuState = JSON.parse(
                                  localStorage.getItem("dealer_sub_menu")
                                );
                                localStorage.setItem(
                                  "dealer_sub_menu",
                                  dealerMenuState ? "false" : "true"
                                );
                              } else {
                                localStorage.setItem(
                                  "dealer_sub_menu",
                                  "false"
                                );
                              }
                              setOpen(true);
                            },
                            listItemButtonSx: undefined,
                            listItemIconSx: undefined,
                            listItemTextSx: undefined,
                            subOptions: [
                              {
                                label: "Dealer Profile ",
                                onClick: () => {
                                  navigate(
                                    `${APP_ROUTES.Dealer_Configurations}`
                                  );
                                },
                              },
                              {
                                label: "Dealer Options",
                                onClick: () => {
                                  navigate(`${APP_ROUTES.Dealer_Options}`);
                                },
                              },

                              {
                                label: "F&I Products",
                                onClick: () => {
                                  navigate(`${APP_ROUTES.Financial_Insurance}`);
                                },
                              },
                            ],
                          },
                          {
                            icon: <Icon name="InvenroryManagementIcon" />,
                            label: "Inventory Management",
                            toolTipItem: open
                              ? undefined
                              : "Inventory Management",
                            hideLabel: false,
                            hideIcon: false,
                            onClick: () => {
                              navigate(`${APP_ROUTES.Inventory_Management}`);
                            },
                            listItemButtonSx: undefined,
                            listItemIconSx: undefined,
                            listItemTextSx: undefined,
                          },
                          userManagmentMenu(),
                        ]
                  }
                />
              </div>
              <div className="aside-content-wrap">
                <Divider />
                <Typography
                  theme={theme}
                  variant="caption"
                  component="small"
                  className="nav-group-title"
                >
                  Support
                </Typography>
                <List
                  theme={theme}
                  options={
                    userRole?.name == "DealerAdmin" ||
                    userRole?.name === "Index Dealer Admin User"
                      ? [
                          {
                            icon: <Icon name="OrdersIcon" />,
                            label: "Documentation",
                            toolTipItem: open ? undefined : "Documentation",
                            hideLabel: false,
                            hideIcon: false,
                            expanded:
                              JSON.parse(localStorage.getItem("pin_state")) &&
                              JSON.parse(localStorage.getItem("docu_sub_menu")),
                            onClick: () => {
                              if (
                                JSON.parse(localStorage.getItem("pin_state"))
                              ) {
                                const docuMenuState = JSON.parse(
                                  localStorage.getItem("docu_sub_menu")
                                );
                                localStorage.setItem(
                                  "docu_sub_menu",
                                  docuMenuState ? "false" : "true"
                                );
                              } else {
                                localStorage.setItem("docu_sub_menu", "false");
                              }
                              setOpen(true);
                            },
                            listItemButtonSx: undefined,
                            listItemIconSx: undefined,
                            listItemTextSx: undefined,
                            subOptions: [
                              {
                                label: "User Guide",
                                onClick: () => {
                                  window.open(
                                    process.env.INDEX_USER_MANUAL_BASE_URL,
                                    "_blank"
                                  );
                                },
                              },
                            ],
                          },
                        ]
                      : [
                          {
                            icon: <Icon name="OrdersIcon" />,
                            label: "Documentation",
                            toolTipItem: open ? undefined : "Documentation",
                            hideLabel: false,
                            hideIcon: false,
                            expanded:
                              JSON.parse(localStorage.getItem("pin_state")) &&
                              JSON.parse(localStorage.getItem("docu_sub_menu")),
                            onClick: () => {
                              if (
                                JSON.parse(localStorage.getItem("pin_state"))
                              ) {
                                const docuMenuState = JSON.parse(
                                  localStorage.getItem("docu_sub_menu")
                                );
                                localStorage.setItem(
                                  "docu_sub_menu",
                                  docuMenuState ? "false" : "true"
                                );
                              } else {
                                localStorage.setItem("docu_sub_menu", "false");
                              }
                              setOpen(true);
                            },
                            listItemButtonSx: undefined,
                            listItemIconSx: undefined,
                            listItemTextSx: undefined,
                            subOptions: [
                              {
                                label: "User Guide",
                                onClick: () => {
                                  window.open(
                                    process.env.INDEX_USER_MANUAL_BASE_URL,
                                    "_blank"
                                  );
                                },
                              },
                              {
                                label: "API Documentation",
                                onClick: () => {
                                  window.open(
                                    process.env.INDEX_DOCS_BASE_URL,
                                    "_blank"
                                  );
                                },
                              },
                            ],
                          },
                          {
                            icon: <Icon name="SubmitFeedbackIcon" />,
                            label: "Submit Feedback",
                            hideLabel: false,
                            hideIcon: false,
                            toolTipItem: open ? undefined : "Submit Feedback",
                            onClick: () => {
                              navigate(`${APP_ROUTES.Feedback}`);
                            },
                            listItemButtonSx: undefined,
                            listItemIconSx: undefined,
                            listItemTextSx: undefined,
                          },
                        ]
                  }
                />
              </div>
            </div>
            <div className="aside-content-wrap action-content">
              <Divider />
              <div className="side-nav-actions">
                <CustomizedSwitches
                  switchEnabled={selectedTheme === "dark"}
                  onChange={() => {
                    toggleTheme();
                  }}
                  theme={theme}
                />
                <Button
                  theme={theme}
                  className="toggle-nav-btn"
                  onClick={() => {
                    localStorage.setItem("pin_state", "false");
                    setOpen(!open);
                    setPinStatus(false);
                  }}
                  iconText={<Icon name="ChevronRight" />}
                />
              </div>
            </div>
          </CustomizedDrawer>
        </Box>
        <CustomizedContainer
          theme={theme}
          open={open || JSON.parse(localStorage.getItem("pin_state"))}
          component="main"
        >
          <Box theme={theme} component="header" sx={{ display: "flex" }}>
            <Grid theme={theme} direction="row" container>
              <Grid
                theme={theme}
                item
                xs={6}
                md={6}
                sx={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                }}
                className="breadcrumbs"
              >
                {showDealer ? (
                  <>
                    {dealerData && (
                      <>
                        <AutoSearch theme={theme} className="auto-search">
                          <Typography
                            variant="body2"
                            component="span"
                            sx={{ mr: 1 }}
                            theme={theme}
                          >
                            Dealer:
                          </Typography>
                          <ToggleButton
                            value="check"
                            selected={selected}
                            onChange={() => {
                              setSelected(!selected);
                            }}
                          >
                            {
                              dealerData?.find(
                                (x) => x.dealer_code == dealer_code
                              )?.dealer_name
                            }
                            <Icon name="ChevronDown" />
                          </ToggleButton>
                          {selected && (
                            <Box theme={theme} className="list-wrap">
                              <Autocomplete
                                theme={theme}
                                id="select_dealers"
                                open={selected}
                                disablePortal={true}
                                capitalizeLabel={true}
                                items={
                                  userRole?.name == "DealerAdmin" ||
                                  userRole?.name === "Index Dealer Admin User"
                                    ? dealerData
                                        ?.filter(
                                          (x) => x.dealer_code === dealer_code
                                        )
                                        .map((option) => ({
                                          label: option.dealer_name,
                                          id: option.dealer_code,
                                        }))
                                    : dealerData?.map((option) => ({
                                        label: option.dealer_name,
                                        id: option.dealer_code,
                                      }))
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder="Search"
                                    className={clsx({
                                      "u-form-group": true,
                                    })}
                                    variant="outlined"
                                    fullWidth
                                  />
                                )}
                                renderOption={(props, option, { selected }) => (
                                  <li {...props} key={option.id}>
                                    <span>{option.label}</span>
                                  </li>
                                )}
                                getOptionLabel={(option: any) => option.label}
                                onInputChange={(_e: any, value) => {}}
                                onChange={(_e: any, value: any) => {
                                  updateDealerCode(value);
                                  setSelected(!selected);
                                }}
                              />
                            </Box>
                          )}
                        </AutoSearch>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <Typography
                      theme={theme}
                      component="span"
                      variant="body2"
                      className={`tenant-title ${
                        !showBreadcrumbsTitle && `breadcrumbs-item`
                      }`}
                      children={
                        !showBreadcrumbsTitle && "Lender Configurations"
                      }
                    />
                    <Typography
                      theme={theme}
                      component="span"
                      variant="body2"
                      className="tenant-title breadcrumbs-item"
                      children={url ? url.replace(/-/g, " ") : "Lender Profile"}
                    />
                  </>
                )}
              </Grid>

              <Grid
                theme={theme}
                item
                display="flex"
                justifyContent="right"
                alignItems={"center"}
                xs={6}
                md={6}
                className="header-nav-right"
              >
                <Menu
                  theme={theme}
                  open={false}
                  disablePortal
                  options={MenuOptions}
                  render={(cb) => (
                    <Box
                      theme={theme}
                      className="header-link-item user-link"
                      onClick={cb}
                    >
                      <Avatar theme={theme}>{userNameinitials}</Avatar>
                      <span className="status-icon" />
                    </Box>
                  )}
                  handleOptionClick={handleSelection}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                />
              </Grid>
              <Divider />
            </Grid>
          </Box>
          {children}
          {/* <Box component="footer">footer</Box> */}
        </CustomizedContainer>
      </LayoutWrap>
    </>
  );
};

export default CustomSideBarLayout;

import { PaletteMode, createTheme } from "@mui/material";
import { unityTheme, unityDarkTheme } from "@ntpkunity/controls";

export const CustomColors = {
  primary: "#3952F5", // Index Primary
  gradient: "linear-gradient(100deg, #FC4778 0%, #FC4778 0.01%, #3952F5 100%)", // Index Gradient
  // primary: "#0653b6", // BMW Primary
  // gradient: "#0653b6", // BMW Gradient
  inputLableTextColor: "rgba(0,0,0,0.87)",
  inputBorderColor: "rgba(0,0,0,0.20)",
  inputPlaceholder: "rgba(0,0,0,0.87)",
  darkInputPlaceholder: "rgba(255,255,255,0.20)",
  darkInputBorder: "rgba(255,255,255,0.20)",
  darkInputDisableColor: "rgba(255, 255, 255, 0.40)",
  darkInputDisableBorder: "rgba(255,255,255,0.20)",
  darkPaperBg: "#252833",
  darkPaperBorder: "#515466",
};

export const IndexTheme = createTheme({
  ...unityTheme,
  palette: {
    ...unityTheme.palette,
    primary: { main: CustomColors.primary },
  },
});

export const DarkTheme = createTheme({
  ...unityDarkTheme,
  palette: {
    ...unityDarkTheme.palette,
    mode: "dark",
    primary: { main: CustomColors.primary },
  },
});

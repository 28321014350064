import { FC, useContext, useEffect, useMemo, useState } from "react";
import { useTheme } from "@mui/material";
import { LayoutWithSideNav } from "layout";
import {
  Box,
  Icon,
  PersistentDrawer,
  Typography,
  Grid,
  Button,
} from "@ntpkunity/controls";
import { logout } from "@ntpkunity/controls-ums";
import { DrawerWrap, PageHeader, ConfirmationDialog } from "shared/components";
import { OptionsTable, NewOption } from "modules/DealerOptions";
import { useForm } from "react-hook-form";
import { usePrompt } from "utilities/usePrompt";
import { DialogMessages } from "Enums";
import { useNavigate } from "react-router-dom";
import { IImage } from "Interface";
import { DealerContext } from "context";
interface IFormInputs {
  id?: number;
  offered_by: string;
  description: string;
  product_name: string;
  category_id: number;
  specfic_category: string;
  vehicle_type: string;
  part_no: string;
  supplier: string;
  installation_mode: string;
  price: number;
  price_inclusive: boolean;
  rv_adder: number;
  compatible_models: any[];
  is_active: boolean;
  image?: string[];
  is_price_inclusive?: boolean;
}
const AddOns: FC = () => {
  const theme = useTheme();
  const [popUpState, setPopUpState] = useState(false);
  const form: {
    handleSubmit;
    control;
    setValue;
    reset;
    getValues;
    resetField;
    watch;
    setError;
    clearErrors;
    formState: { errors; isDirty };
  } = useForm<IFormInputs>();
  const navigate = useNavigate();
  const { dealer_code } = useContext(DealerContext);
  const [navigationState, setNavigationState] = useState<any>();
  const [formData, setFormData] = useState<IFormInputs>();
  const [formDefaultData, setFormDefaultData] = useState<IFormInputs>();
  const [isEditMode, setIsEditMode] = useState(false);
  const [images, setImages] = useState<Array<IImage>>([]);
  const [compatibleModels, setcompatibleModels] = useState<any[]>([]);
  const [productId, setProductId] = useState(null);
  const [compatibleTrimModels, setCompatibleTrimModels] = useState<any[]>([]);
  const [leavePagePopupState, setLeavePageState] = useState<boolean>(false);
  const handleshow = () => {
    setFormDefaultData({} as IFormInputs);
    form?.reset({} as IFormInputs);
    setPopUpState(true);
    setIsEditMode(false);
    setImages([]);
    setcompatibleModels(undefined);
    setProductId(null);
  };
  const onSubmit = (formValues: IFormInputs): void => {
    setFormData(formValues);
    let children = document.querySelectorAll(".drawer-header .pinned");
    if (children.length == 0) {
      setPopUpState(false);
    } else {
      setPopUpState(true);
    }
    setFormDefaultData({} as IFormInputs);
    form.reset({} as IFormInputs);
    setIsEditMode(false);
    setImages([]);
    setcompatibleModels(undefined);
    setProductId(null);
  };

  const defaultValues: IFormInputs = useMemo(() => {
    const updatedFormDefaultValues = formDefaultData;
    setCompatibleTrimModels(
      formDefaultData?.compatible_models?.map((trim) => trim.model_id)
    );
    if (updatedFormDefaultValues) {
      updatedFormDefaultValues.compatible_models = formDefaultData
        ? formDefaultData.compatible_models?.reduce<
            { text: string; value: any }[]
          >((previous, model: any) => {
            return [...previous, { text: model.trim_name, value: model.id }];
          }, [])
        : [];
      setFormDefaultData(updatedFormDefaultValues);
    }
    return formDefaultData;
  }, [formDefaultData]) as unknown as IFormInputs;

  const onEdit = (formData: IFormInputs) => {
    setFormDefaultData(formData);
    setIsEditMode(true);
    setPopUpState(true);
  };

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues]);

  useEffect(() => {
    if (!popUpState) {
      setIsEditMode(false);
    }
  }, [popUpState]);

  const onSaveConfirm = (e: any) => {
    form.handleSubmit(onSubmit)();
    setLeavePageState(false);
    form.reset({} as IFormInputs);
  };
  const onCancelConfirm = (e: any) => {
    navigate(".." + navigationState.location.pathname);
    if (navigationState.location.pathname == "/market-place/signup") {
      logout();
      localStorage.clear();
    }
    localStorage.setItem("DirtyState", "false");
  };
  usePrompt(
    "Leave screen?",
    form.formState.isDirty,
    (dirty) => {
      setLeavePageState(dirty);
    },
    (tx) => {
      form.reset(form.getValues());
      setNavigationState(tx);
    }
  );
  return (
    <>
      <ConfirmationDialog
        openPopUp={leavePagePopupState}
        onConfirm={onSaveConfirm}
        onCancel={onCancelConfirm}
        setPopUpState={setLeavePageState}
        confirmationTitle={DialogMessages.saveTitle}
        confirmationText={DialogMessages.saveText}
        primaryBtnText={DialogMessages.saveBtnText}
        icon={<Icon name="DialogSaveIcon" />}
      />
      <DrawerWrap open={popUpState}>
        <LayoutWithSideNav theme={theme}>
          <PageHeader
            className="main-page-header"
            theme={theme}
            container
            item
            spacing={2}
          >
            <Grid theme={theme} item xs={12} sm={6} lg={7} md={6}>
              <Typography
                variant="h2"
                component="h2"
                theme={theme}
                data-testid="heading"
              >
                Dealer Options
              </Typography>
            </Grid>
          </PageHeader>

          <PersistentDrawer
            title={isEditMode ? "Edit Option" : "Add New Option"}
            openPopUp={popUpState}
            setPopUpState={setPopUpState}
            theme={theme}
            enabledPin={true}
            children={
              <>
                <form onSubmit={(e) => e.preventDefault()}>
                  <NewOption
                    form={form}
                    formData={formData}
                    isEditMode={isEditMode}
                    defaultFormValues={defaultValues}
                    dealer_code={dealer_code}
                    setcompatibleModels={setcompatibleModels}
                    compatibleModels={compatibleModels}
                    setProductId={setProductId}
                    productId={productId}
                    compatibleTrimModels={compatibleTrimModels}
                    setImages={setImages}
                    images={images}
                    setCompatibleTrimModels={setCompatibleTrimModels}
                  ></NewOption>
                </form>
              </>
            }
            customFooter={
              <Button
                theme={theme}
                id="saveOption"
                primary
                type="submit"
                text="Save"
                fullWidth
                onClick={form.handleSubmit(onSubmit)}
              />
            }
          />
          <OptionsTable
            onEdit={(data) => onEdit(data)}
            dealer_code={dealer_code}
            isEditMode={isEditMode}
          ></OptionsTable>
          <Box theme={theme} sx={{ mt: 3 }}>
            <Button
              theme={theme}
              secondary
              fullWidth={true}
              onClick={handleshow}
              text="Add New Option"
              data-testid="tid_addNewOption"
              startIcon={<Icon name="AddIcon" />}
            />
          </Box>
        </LayoutWithSideNav>
      </DrawerWrap>
    </>
  );
};

export default AddOns;

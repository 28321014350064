import { FC, useEffect } from "react";
import { Controller } from "react-hook-form";
import { useTheme, SelectChangeEvent } from "@mui/material";
import { Select, IBoxProps } from "@ntpkunity/controls";
import { Grid, Input, Box, TextBox } from "shared/components";
import { styled } from "@mui/material/styles";
import {
  useGetAllowedMileage,
  useGetContractTerms,
} from "services/dealer-configurations.service";

const TabWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(() => ({
  "&.tab-wrap": {
    width: "100%",
    display: "inline-block",

    hr: {
      opacity: 0.4,
    },

    ".title-icon": {
      width: "100%",
      display: "inline-block",
      "span, Icon": {
        float: "left",
      },
      span: {
        marginRight: 10,
      },
    },
    ".buttons-row": {
      display: "inline-block",
      textAlign: "center",
      width: "100%",

      Button: {
        margin: "0 20px 20px 0",

        "&:last-child": {
          marginRight: 0,
        },
      },
    },
  },
}));

const Preferences: FC<{
  form: any;
}> = ({ form }) => {
  const theme = useTheme();
  const { mutate: getContractTerms, data: contractTermsData } =
    useGetContractTerms();
  const { mutate: getAllowedMileage, data: allowedMileageData } =
    useGetAllowedMileage();
  const {
    control,
    formState: { errors },
    watch,
  } = form;
  const mileageId = watch("preferences.default_mileage_id");
  const termId = watch("preferences.default_term_id");
  useEffect(() => {
    getContractTerms();
    getAllowedMileage();
  }, []);

  return (
    <TabWrap className="tab-wrap">
      <Grid
        container
        rowSpacing={3}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{ mb: 2.7 }}
      >
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Controller
            name="preferences.default_mileage_id"
            control={control}
            defaultValue={""}
            render={({ field }) => (
              <Select
                theme={theme}
                label={"Default Mileage"}
                items={allowedMileageData
                  ?.filter((x) => x.is_active || x.id == mileageId)
                  ?.map((mileage: any) => {
                    return {
                      text: mileage.value,
                      value: mileage.id,
                    };
                  })}
                {...field}
                value={field.value ?? ""}
                onChange={field.onChange}
                sxProps={""}
                selectError={errors?.preferences?.default_mileage_id?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Controller
            name="preferences.default_term_id"
            control={control}
            defaultValue={""}
            render={({ field }) => (
              <Select
                theme={theme}
                label={"Default Term"}
                items={contractTermsData
                  ?.filter((x) => x.is_active || x.id == termId)
                  ?.map((term: any) => {
                    return {
                      text: term.term,
                      value: term.id,
                    };
                  })}
                sxProps={""}
                {...field}
                value={field.value ?? ""}
                onChange={field.onChange}
                selectError={errors?.preferences?.default_term_id?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Controller
            name="preferences.quotation_expiry_days"
            control={control}
            rules={{
              maxLength: {
                value: 3,
                message: "Length should be less than 4",
              },
              min: {
                value: 0,
                message: "Quotation Expiry Days is invalid",
              },
              max: {
                value: 999,
                message: "Quotation Expiry Days is invalid",
              },
            }}
            render={({ field }) => (
              <Input
                theme={theme}
                fullWidth
                placeholder={"Type here.."}
                label={"Quotation Expiry Days (Optional)"}
                type="number"
                {...field}
                value={field.value ?? ""}
                error={errors?.preferences?.quotation_expiry_days?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Controller
            name="preferences.application_expiry_days"
            control={control}
            rules={{
              maxLength: {
                value: 3,
                message: "Length should be less than 4",
              },
              min: {
                value: 0,
                message: "Application Expiry Days is invalid",
              },
              max: {
                value: 999,
                message: "Application Expiry Days is invalid",
              },
            }}
            render={({ field }) => (
              <Input
                theme={theme}
                fullWidth
                placeholder={"Type here..."}
                label={"Application Expiry Days (Optional)"}
                type="number"
                {...field}
                value={field.value ?? ""}
                error={errors?.preferences?.application_expiry_days?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Controller
            name="preferences.availability_check"
            control={control}
            defaultValue={""}
            render={({ field }) => (
              <Select
                theme={theme}
                label={"Asset Availability Check"}
                items={[
                  { text: "Automatic", value: "Automatic" },
                  { text: "Manual", value: "Manual" },
                ]}
                {...field}
                value={field.value ?? ""}
                onChange={field.onChange}
                sxProps={""}
                selectError={errors?.preferences?.availability_check?.message}
              />
            )}
          />
        </Grid>
      </Grid>
    </TabWrap>
  );
};
export default Preferences;

import axios from "axios";
import { logout, handleRefreshToken } from "@ntpkunity/controls-ums";
import { ACCESS_TOKEN_KEY } from "shared/config/constants";
import { LocalStorage } from "shared/helper/browserStorage";

const lenderId = localStorage.getItem("lender_id");
// const token = localStorage.getItem('api-key');
const _localStorage = new LocalStorage();

export const ConfigurationAPI = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: false,
  headers: {
    "Content-type": "application/json",
    // Authorization: `Bearer ${token}`
  },
});
ConfigurationAPI.interceptors.request.use(function (config) {
  // Do something before request is sent
  let lenderId = localStorage.getItem("lender_id");
  const token = _localStorage.getItemByKey(ACCESS_TOKEN_KEY);

  config.headers["lender_id"] = lenderId;
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

export default ConfigurationAPI;

const token = _localStorage.getItemByKey(ACCESS_TOKEN_KEY);

export const IntegrationAPI = axios.create({
  baseURL: process.env.INTEGRATION_API_GATEWAY,
  withCredentials: false,
  headers: {
    "Content-type": "application/json",
    Authorization: `Bearer ${token}`,
    lender_id: lenderId,
  },
});
export const UmsAPI = axios.create({
  baseURL: process.env.UMS_API_GATEWAY,
  withCredentials: false,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
});

ConfigurationAPI.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    await handleRefreshToken(error, ConfigurationAPI, logout);
  }
);

IntegrationAPI.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    await handleRefreshToken(error, IntegrationAPI, logout);
  }
);

UmsAPI.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    await handleRefreshToken(error, UmsAPI, logout);
  }
);

import { FC, useState } from "react";
import { useTheme, TablePagination } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Icon, Menu, DataTable, Button, Box, Input } from "@ntpkunity/controls";
import { ConfirmationDialog } from "shared/components";
import ChevronDown from "../../../shared/assets/images/chevron-down";
import {
  UseMutation_UpdateDocumentType,
  UseQuery_getAllChartsFilter,
  UseMutation_DeleteBaseRateChart,
} from "services";
import { ActionOptions, ChartTypes, DialogMessages } from "Enums";
import { IBaseChart, IBaseChartFilter, IDocumentTypes } from "Interface";
import { useQueryClient } from "react-query";

const tableOptions: any = [
  {
    optionText: (
      <>
        <Icon className="menu-icon" name="EditIcon" />
        {ActionOptions.EDIT}
      </>
    ),
    optionkey: ActionOptions.EDIT,
    optionValue: {},
  },
  {
    optionText: (
      <>
        <Icon className="menu-icon" name="DeleteIcon" />
        {ActionOptions.DELETE}
      </>
    ),
    optionkey: ActionOptions.DELETE,
    optionValue: {},
  },
];

const BaseRateTable: FC<{ onEdit: (data: IBaseChart) => unknown }> = ({
  onEdit,
}) => {
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [columnFilters, setColumnFilters] = useState(
    `page_number=${pageNumber}&page_size=${pageSize}&chart_type_id=${ChartTypes.BaseRateChart}`
  );
  const { data } = UseQuery_getAllChartsFilter(columnFilters);

  const [baseRateFilter, setBaseRateFilter] = useState<number>(null);
  const [effectiveDateFilter, setEffectiveDateFilter] = useState("");
  const [tenureFilter, setTenureDateFilter] = useState("");
  const [confirmationPopupState, setConfirmationPopupState] =
    useState<boolean>(false);
  const setQueryString = (pgNo, pgSize) => {
    let query_string: string = `page_number=${pgNo}&page_size=${pgSize}&chart_type_id=${ChartTypes.BaseRateChart}`;
    if (baseRateFilter) {
      query_string = query_string.concat(`&base_rate=${+baseRateFilter}`);
    }
    if (
      effectiveDateFilter.trimStart() != null &&
      effectiveDateFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(
        `&effecive_date=${effectiveDateFilter}`
      );
    }
    if (tenureFilter.trimStart() != null && tenureFilter.trimStart() != "") {
      query_string = query_string.concat(`&tenure=${tenureFilter}`);
    }
    return query_string;
  };
  const baseRateCharts: IBaseChartFilter = data;
  const [actionData, setActionData] = useState<IBaseChart>(undefined);

  const { mutate: deleteBaseRateChart } = UseMutation_DeleteBaseRateChart();
  const handleChangePage = (_event: unknown, newPage: number) => {
    setPageNumber(newPage);
    setColumnFilters(setQueryString(newPage, pageSize));
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(+event.target.value);
    setPageNumber(0);
    setColumnFilters(setQueryString(0, +event.target.value));
  };

  const handleFilter = () => {
    setPageNumber(0);
    setColumnFilters(setQueryString(0, pageSize));
  };
  const handleSelection = (event, key, value) => {
    switch (key) {
      case ActionOptions.EDIT:
        onEdit(value);
        return;
      case ActionOptions.DELETE:
        setActionData(value);
        setConfirmationPopupState(true);
        return;
    }
  };

  const onDeleteConfirm = (e: any) => {
    deleteBaseRateChart(actionData?.id, {
      onSuccess: () => {
        setActionData(undefined);
      },
    });
  };

  const theme = useTheme();
  function checkIfDateIsPassed(date: Date): boolean {
    var getDate = new Date(date);
    var currentDate = new Date();
    getDate.setHours(0, 0, 0, 0); //ignore time
    currentDate.setHours(0, 0, 0, 0);
    return currentDate > getDate ? true : false;
  }
  return (
    <>
      <DataTable
        theme={theme}
        variant="basic"
        theadChildren={
          <>
            <TableRow>
              <TableCell id="listTenure">Tenure</TableCell>
              <TableCell id="listHeaderCode">Base Rate</TableCell>
              <TableCell id="listHeaderDescription">Effective Date</TableCell>
              <TableCell className="action-cell fixed-cell" />
            </TableRow>
            <TableRow className="filters-row">
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    startAdornment={undefined}
                    endAdornment={undefined}
                    id={"tenureFilter"}
                    onChange={(value) => {
                      setTenureDateFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={tenureFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    startAdornment={undefined}
                    endAdornment={undefined}
                    id={"listingCode"}
                    onChange={(value) => {
                      setBaseRateFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={baseRateFilter}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    startAdornment={undefined}
                    endAdornment={undefined}
                    id={"listingDescription"}
                    onChange={(value) => {
                      setEffectiveDateFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={effectiveDateFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>

              <TableCell className="action-cell fixed-cell" />
            </TableRow>
          </>
        }
        tbodyChildren={
          baseRateCharts &&
          baseRateCharts?.result?.map((item, index) => (
            <TableRow key={index} className="child-tr">
              <TableCell id={"listTenure" + item.id}>
                {item?.tenure ? item.tenure + " days" : ""}
              </TableCell>
              <TableCell id={"listDataCode" + item.id}>
                {`${item?.base_rate.toFixed(4)}%`}
              </TableCell>
              <TableCell id={"listDataDescription" + item.effective_date}>
                {item.effective_date?.toString().slice(5, 7)}/
                {item.effective_date?.toString().slice(8, 10)}/
                {item.effective_date?.toString().slice(0, 4)}
              </TableCell>

              <TableCell className="action-cell fixed-cell">
                {checkIfDateIsPassed(item?.effective_date as Date) ? (
                  <></>
                ) : (
                  <>
                    <Menu
                      theme={theme}
                      disablePortal
                      options={tableOptions.map((option) => {
                        return {
                          optionText: option.optionText,
                          optionkey: option.optionkey,
                          optionValue: item,
                        };
                      })}
                      handleOptionClick={handleSelection}
                      render={(onMenuSelection) => (
                        <Button
                          id={"listDataEllipses" + item.id}
                          defaultBtn
                          iconText={<Icon name="MoreIcon" />}
                          onClick={onMenuSelection}
                        ></Button>
                      )}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    />
                  </>
                )}
              </TableCell>
            </TableRow>
          ))
        }
      />
      <TablePagination
        rowsPerPageOptions={[5, 10, 15, 20]}
        component="div"
        count={baseRateCharts ? baseRateCharts?.total_results : -1}
        rowsPerPage={pageSize}
        page={pageNumber}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        SelectProps={{
          IconComponent: ChevronDown,
        }}
      />
      {confirmationPopupState && (
        <ConfirmationDialog
          openPopUp={confirmationPopupState}
          onConfirm={onDeleteConfirm}
          setPopUpState={setConfirmationPopupState}
          confirmationTitle={DialogMessages.confirmationTitle}
          confirmationText={DialogMessages.confirmationText}
          primaryBtnText={DialogMessages.deleteBtnText}
          icon={<Icon name="DialogDeleteIcon" />}
        />
      )}
    </>
  );
};

export default BaseRateTable;

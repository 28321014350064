import { FC, useContext, useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import {
  Select,
  Typography,
  Button,
  ConfirmationDialog,
} from "shared/components";
import { INewFinancedAmountPopUp } from "./AddNewFinancedAmount.type";
import { Controller, useForm } from "react-hook-form";
import {
  UseMutation_AddFinancedAmount,
  UseMutation_UpdateFinancedAmount,
} from "services";
import { IFinancedAmount } from "Interface";
import {
  ActionOptions,
  QueryKeys,
  ValidationMessages,
  DialogMessages,
} from "Enums";
import { useSetupsStore } from "Stores/SetupStore/Store/SetupsStoreProvider";
import { useQueryClient } from "react-query";
import { Grid, PersistentDrawer, Input, Icon } from "@ntpkunity/controls";
import { usePrompt } from "utilities/usePrompt";
import { useNavigate } from "react-router-dom";
import { logout } from "@ntpkunity/controls-ums";
import { SetupContext } from "context";

const NewFinancedAmount: FC<INewFinancedAmountPopUp> = ({
  openPopUp,
  setPopUpState,
  title,
  actionType,
  financedAmountData,
  enabledPin,
}) => {
  const [state] = useSetupsStore();
  const theme = useTheme();
  const queryClient = useQueryClient();
  const lenderProfile: any = queryClient.getQueryData(
    QueryKeys.GET_LENDER_PROFILE
  );
  const [leavePagePopupState, setLeavePageState] = useState<boolean>(false);
  const [amountRange, setAmountRange] = useState<number[]>([1, 100000]);

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    setError,
    clearErrors,
    formState: { errors, isDirty },
    watch,
  } = useForm<IFinancedAmount>();
  const currencies: any = queryClient.getQueryData(
    QueryKeys.GET_ALL_CURRENCIES
  );
  const watchAmounts = watch(["financed_amount_from", "financed_amount_to"]);
  //   const watchMileageUnit = watch(["mileage_unit"]);
  const navigate = useNavigate();
  const [navigationState, setNavigationState] = useState<any>();
  const { mutate: AddFinancedAmount } = UseMutation_AddFinancedAmount();
  const { mutate: EditFinancedAmount } = UseMutation_UpdateFinancedAmount(
    getValues("id")
  );
  const [defaultCurrency, setDefaultCurrency] = useState<string>("");

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      let AmountFrom, AmountTo;
      AmountFrom =
        getValues("financed_amount_from") >= 0
          ? getValues("financed_amount_from")
          : 0;
      AmountTo =
        getValues("financed_amount_to") >= 0
          ? getValues("financed_amount_to")
          : 0;
      setAmountRange([+AmountFrom, +AmountTo]);
    });
    return () => subscription.unsubscribe();
  }, [watchAmounts]);

  useEffect(() => {
    if (actionType !== ActionOptions.ADD) {
      setValue("id", financedAmountData.id);
    }
    setValue(
      "financed_amount_from",
      financedAmountData
        ? financedAmountData.financed_amount_from
        : amountRange[0]
    );
    setValue(
      "financed_amount_to",
      financedAmountData
        ? financedAmountData.financed_amount_to
        : amountRange[1]
    );
    setValue(
      "is_active",
      financedAmountData ? financedAmountData.is_active : true
    );

    setAmountRange([
      getValues("financed_amount_from"),
      getValues("financed_amount_to"),
    ]);

    if (actionType === ActionOptions.ADD) {
      reset();
    }
  }, [financedAmountData]);

  useEffect(() => {
    const filteredCurrency = currencies?.filter(
      (currency: any) => currency.id === lenderProfile?.default_currency
    );

    setDefaultCurrency(
      filteredCurrency && filteredCurrency.length > 0
        ? filteredCurrency[0].symbol
        : ""
    );
  }, [lenderProfile]);

  function onSubmit(data) {
    data.lender_id = lenderProfile?.id;
    if (data.financed_amount_from >= 0 && data.financed_amount_from <= 100000) {
      clearErrors("financed_amount_from");
    } else {
      setError("financed_amount_from", {
        message: "Value should be in between 1 - 100000",
      });
    }
    if (data.financed_amount_to >= 0 && data.financed_amount_to <= 100000) {
      clearErrors("financed_amount_to");
    } else {
      setError("financed_amount_to", {
        message: "Value should be in between 1 - 100000",
      });
    }
    if (
      actionType === ActionOptions.ADD &&
      data.financed_amount_to >= data.financed_amount_from &&
      data.financed_amount_to <= 100000 &&
      data.financed_amount_from >= 0 &&
      data.financed_amount_from <= 100000
    ) {
      AddFinancedAmount(data, {
        onSuccess: (response) => {
          reset();
          onClose();
        },
        onError: (error) => {},
      });
    }
    if (
      actionType === ActionOptions.EDIT &&
      data.financed_amount_to >= data.financed_amount_from &&
      data.financed_amount_to <= 100000 &&
      data.financed_amount_from >= 0 &&
      data.financed_amount_from <= 100000
    ) {
      EditFinancedAmount(data, {
        onSuccess: (response) => {
          reset();
          onClose();
        },
        onError: (error) => {},
      });
      onClose();
    }
    setAmountRange([1, 100000]);
  }

  const onSliderChange = (value: Array<number>) => {
    setValue("financed_amount_from", value[0]);
    setValue("financed_amount_to", value[1]);
    setAmountRange(value);
  };

  const onClose = () => {
    let children = document.querySelectorAll(".drawer-header .pinned");
    if (children.length == 0) {
      setPopUpState(false);
    }
  };
  const onCancel = () => {
    onClose();
  };
  const onAmountFromChange = (e) => {
    if (e > 100000) {
      setError("financed_amount_from", {
        message: "Value should be in between 1 - 100000",
      });
      setValue("financed_amount_from", parseInt(e));
    } else {
      setValue("financed_amount_from", parseInt(e));
      clearErrors("financed_amount_from");
    }
  };

  const onAmountToChange = (e) => {
    if (e > 100000) {
      setError("financed_amount_to", {
        message: "Value should be in between 1 - 100000",
      });
      setValue("financed_amount_to", parseInt(e));
    } else {
      setValue("financed_amount_to", parseInt(e));
      clearErrors("financed_amount_to");
    }
  };
  const onSaveConfirm = (e: any) => {
    handleSubmit(onSubmit)();
    setLeavePageState(false);
  };
  const onCancelConfirm = (e: any) => {
    navigate(".." + navigationState.location.pathname);
    if (navigationState.location.pathname == "/market-place/signup") {
      logout();
      localStorage.clear();
    }
    localStorage.setItem("DirtyState", "false");
  };
  usePrompt(
    "Leave screen?",
    isDirty,
    (dirty) => {
      setLeavePageState(dirty);
    },
    (tx) => {
      reset(getValues());
      setNavigationState(tx);
    }
  );

  return (
    <>
      <ConfirmationDialog
        openPopUp={leavePagePopupState}
        onConfirm={onSaveConfirm}
        onCancel={onCancelConfirm}
        setPopUpState={setLeavePageState}
        confirmationTitle={DialogMessages.saveTitle}
        confirmationText={DialogMessages.saveText}
        primaryBtnText={DialogMessages.saveBtnText}
        icon={<Icon name="DialogSaveIcon" />}
      />
      <PersistentDrawer
        title={title}
        openPopUp={openPopUp}
        setPopUpState={setPopUpState}
        theme={theme}
        enabledPin={true}
        children={
          <>
            <Grid theme={theme} container item columnSpacing={2} rowSpacing={3}>
              <Grid theme={theme} item xs={12}>
                <Controller
                  name="financed_amount_from"
                  control={control}
                  defaultValue={0}
                  rules={{
                    required: ValidationMessages.FINANCED_AMOUNT_FROM_REQUIRED,
                    max: {
                      value: getValues("financed_amount_to"),
                      message:
                        ValidationMessages.FINANCED_AMOUNT_FROM_LESS_THAN_FINANCED_AMOUNT_TO,
                    },
                    min: {
                      value: 1,
                      message:
                        "Financed Amount From should be greater than zero",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={"1"}
                      label={"Financed Amount From"}
                      type="text"
                      // adornmentOnFocus
                      startAdornment={
                        <span className="adornment-text">
                          {defaultCurrency}
                        </span>
                      }
                      onChange={(e) => {
                        onAmountFromChange(e);
                      }}
                      min={1}
                      value={value}
                      maskNumeric
                      maskDecimalScale={0}
                      masking
                      error={errors?.financed_amount_from?.message}
                    />
                  )}
                />
              </Grid>
              <Grid theme={theme} item xs={12}>
                <Controller
                  name="financed_amount_to"
                  defaultValue={0}
                  control={control}
                  rules={{
                    required: ValidationMessages.FINANCED_AMOUNT_TO_REQUIRED,
                    min: {
                      value: getValues("financed_amount_from"),
                      message:
                        ValidationMessages.FINANCED_AMOUNT_TO_GREATER_THAN_FINANCED_AMOUNT_FROM,
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      fullWidth={true}
                      theme={theme}
                      placeholder={"0"}
                      label={"Financed Amount To"}
                      type="text"
                      id="financed_amount_to"
                      startAdornment={
                        <span className="adornment-text">
                          {defaultCurrency}
                        </span>
                      }
                      onChange={(e) => {
                        onAmountToChange(e);
                      }}
                      value={value}
                      maskNumeric
                      maskDecimalScale={0}
                      masking
                      error={errors?.financed_amount_to?.message}
                    />
                  )}
                />
              </Grid>
              <Grid theme={theme} item xs={12}>
                <Controller
                  name="is_active"
                  control={control}
                  defaultValue={true}
                  render={({ field: { value, onChange } }) => (
                    <Select
                      theme={theme}
                      label={"Status"}
                      placeholder={"Select"}
                      items={[
                        { text: "Enabled", value: true },
                        { text: "Disabled", value: false },
                      ]}
                      value={value}
                      onChange={onChange}
                    ></Select>
                  )}
                />
              </Grid>
            </Grid>
          </>
        }
        customFooter={
          <Button
            primary
            text="Save"
            fullWidth
            onClick={handleSubmit(onSubmit)}
          />
        }
      />
    </>
  );
};

export default NewFinancedAmount;

import React, { FC, useEffect, useState } from "react";
import { TablePagination, useTheme } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Icon, Menu, DataTable, Button, Box, Input } from "@ntpkunity/controls";
import { PaginationWrap, DataTableWrap, Switch } from "shared/components";
import ChevronDown from "../../shared/assets/images/chevron-down";
import { ActionOptions, ActionTypes, QueryKeys, ToastMessages } from "Enums";
import { useQueryClient } from "react-query";
import {
  useUpdateLenderIntegration,
  UseQuery_getAllLenderIntegration_filter,
  useUpdateLenderIntegrationParentStatus,
  useMutation_CheckDealerRegistrationWithPen,
  useMutation_CheckDealerRegistrationWithStripe,
} from "services";
import { useStoreContext } from "Stores/SetupStore/Store";

const tableOptions: any = [
  {
    optionText: (
      <>
        <Icon className="menu-icon" name="EditIcon" />
        {ActionOptions.EDIT}
      </>
    ),
    optionkey: ActionOptions.EDIT,
    optionValue: {},
  },
  {
    optionText: (
      <>
        <Icon className="menu-icon" name="DeleteIcon" />
        {ActionOptions.DELETE}
      </>
    ),
    optionkey: ActionOptions.DELETE,
    optionValue: {},
  },
];

const TbodyRow: FC<{
  onEdit: (data) => unknown;
  onDelete: (data) => unknown;
  integrationsRow: any;
  setParentIntegrationState: any;
}> = ({ onEdit, onDelete, integrationsRow, setParentIntegrationState }) => {
  const [open, setOpen] = useState(false);
  const { mutate: updateLenderIntegrationStatus } =
    useUpdateLenderIntegrationParentStatus();
  const handleSelection = (event, key, value) => {
    switch (key) {
      case ActionOptions.EDIT:
        setParentIntegrationState(true);
        onEdit(value);
        return;
    }
  };
  const theme = useTheme();
  return (
    <>
      <TableRow key={`parent${integrationsRow[1].id}`} className="child-tr">
        <TableCell>
          {open ? (
            <Icon
              name="ChevronDown"
              className="toggle-btn ch-right"
              onClick={() => setOpen(!open)}
            />
          ) : (
            <Icon
              name="ChevronDown"
              className="toggle-btn"
              onClick={() => setOpen(!open)}
            />
          )}
          {integrationsRow[0]}
        </TableCell>
        <TableCell></TableCell>
        <TableCell>
          <Switch
            id={"ParenttableToggle" + integrationsRow[1][0]?.id}
            theme={theme}
            varient={"basic"}
            label={
              integrationsRow[1][0]?.is_parent_active ? "Enabled" : "Disabled"
            }
            switchEnabled={integrationsRow[1][0]?.is_parent_active}
            onChange={(event) => {
              updateLenderIntegrationStatus({
                ...integrationsRow[1][0],
                is_parent_active: event.target.checked,
              });
            }}
          />
        </TableCell>
        <TableCell className="action-cell fixed-cell">
          <Menu
            id={"ellipses" + integrationsRow[1][0]?.id}
            theme={theme}
            disablePortal
            options={[
              {
                optionText: (
                  <>
                    <Icon className="menu-icon" name="EditIcon" />
                    {ActionOptions.ADD_PROVIDER}
                  </>
                ),
                optionkey: ActionOptions.EDIT,
                optionValue: {},
              },
            ]}
            handleOptionClick={(e, key, value) =>
              handleSelection(e, key, integrationsRow[1][0])
            }
            render={(onMenuSelection) => (
              <Button
                defaultBtn
                iconText={<Icon name="MoreIcon" />}
                onClick={onMenuSelection}
              ></Button>
            )}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          />
        </TableCell>
      </TableRow>
      {open ? (
        <Results
          onEdit={onEdit}
          onDelete={onDelete}
          integrationsRow={integrationsRow[1]}
          setParentIntegrationState={setParentIntegrationState}
        />
      ) : null}
    </>
  );
};
const Results: FC<{
  onEdit: (data) => unknown;
  onDelete: (data) => unknown;
  integrationsRow: any;
  setParentIntegrationState?: any;
}> = ({ onEdit, onDelete, integrationsRow, setParentIntegrationState }) => {
  const { actions } = useStoreContext();
  const { mutate: updateLenderIntegration } = useUpdateLenderIntegration();
  const { mutate: checkPenRegistration } =
    useMutation_CheckDealerRegistrationWithPen();
  const { mutate: checkStirpeRegistration } =
    useMutation_CheckDealerRegistrationWithStripe();
  const queryClient = useQueryClient();

  const integrationsData: any = queryClient.getQueryData(
    QueryKeys.GET_ALL_LENDER_INTEGRATIONS_FILTER
  );
  const [tableData, setTableData] = useState(integrationsData);
  const [popUpState, setPopUpState] = useState(false);
  const handleshow = () => {
    setPopUpState(true);
  };
  useEffect(() => {}, [popUpState]);
  const handleSelection = (event, key, value) => {
    switch (key) {
      case ActionOptions.EDIT:
        onEdit(value);
        setParentIntegrationState(false);
        return;
      case ActionOptions.DELETE:
        if (
          value.integration_type === "F&I Products" &&
          value.provider_name === "Provider Exchange Network"
        ) {
          checkPenRegistration(
            {},
            {
              onSuccess: (response) => {
                if (response.data == 0) {
                  onDelete(value);
                } else {
                  actions.setToast({
                    toastMessage: ToastMessages.RECORD_ALREADY_IN_USE,
                    toastState: true,
                    variant: "error",
                  });
                }
              },
            }
          );
        } else if (
          value.integration_type === "Payment Processing" &&
          value.provider_name === "Stripe Connect"
        ) {
          checkStirpeRegistration(
            {},
            {
              onSuccess: (response) => {
                if (response.data == 0) {
                  onDelete(value);
                } else {
                  actions.setToast({
                    toastMessage: ToastMessages.RECORD_ALREADY_IN_USE,
                    toastState: true,
                    variant: "error",
                  });
                }
              },
            }
          );
        } else {
          onDelete(value);
        }
        return;
    }
  };

  const theme = useTheme();

  return (
    <>
      {integrationsRow?.map((integrationRow, index) => {
        return (
          <React.Fragment key={`child${integrationsRow.id}`}>
            <TableRow key={`childData${index}`} className="child-tr">
              {/* <TableCell>
                    <Box theme={theme} className="group-title" sx={{ ml: 6 }}>
                        {integrationRow.provider_name}
                    </Box>
                    </TableCell> */}
              <TableCell className="indent-cell" colSpan={2}>
                <Box
                  theme={theme}
                  display="flex"
                  className="group-title"
                  sx={{ ml: 6 }}
                >
                  {integrationRow.provider_name}
                  {/* <Tooltip theme={theme} title="Register with PEN"> */}
                  {/* <IconButton sx={{ p:0, ml: 1}}>
                                <Icon name="GreenCheckIcon" />
                            </IconButton> */}
                  {/* </Tooltip> */}
                </Box>
              </TableCell>
              <TableCell>
                <Switch
                  id={"tableToggle" + integrationRow.id}
                  theme={theme}
                  varient={"basic"}
                  label={integrationRow.is_active ? "Enabled" : "Disabled"}
                  switchEnabled={integrationRow.is_active}
                  onChange={(event) => {
                    updateLenderIntegration({
                      ...integrationRow,
                      is_active: event.target.checked,
                    });
                  }}
                />
              </TableCell>
              <TableCell className="action-cell fixed-cell">
                <Menu
                  id={"ellipses" + integrationRow.id}
                  theme={theme}
                  disablePortal
                  options={tableOptions.map((option) => {
                    return {
                      optionText: option.optionText,
                      optionkey: option.optionkey,
                      optionValue: null,
                    };
                  })}
                  handleOptionClick={(e, key, value) =>
                    handleSelection(e, key, integrationRow)
                  }
                  render={(onMenuSelection) => (
                    <Button
                      defaultBtn
                      iconText={<Icon name="MoreIcon" />}
                      onClick={onMenuSelection}
                    ></Button>
                  )}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                />
              </TableCell>
            </TableRow>
          </React.Fragment>
        );
      })}
    </>
  );
};

const IntergrationTable: FC<{
  onEdit: (data) => unknown;
  onDelete: (data) => unknown;
  setParentIntegrationState: any;
}> = ({ onEdit, onDelete, setParentIntegrationState }) => {
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [columnFilters, setColumnFilters] = useState(
    `page_number=${pageNumber}&page_size=${pageSize}`
  );

  const { data } = UseQuery_getAllLenderIntegration_filter(columnFilters);
  const [codeFilter, setCodeFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const integrationsData: any = data;
  const [tableData, setTableData] = useState(integrationsData);
  const [popUpState, setPopUpState] = useState(false);
  const setQueryString = (pgNo, pgSize) => {
    let query_string: string = `page_number=${pgNo}&page_size=${pgSize}`;
    if (codeFilter.trimStart() != null && codeFilter.trimStart() != "") {
      query_string = query_string.concat(`&integration=${codeFilter}`);
    }
    if (statusFilter.trimStart() != null && statusFilter.trimStart() != "") {
      if (
        "enabled".startsWith(
          statusFilter.toLowerCase().slice(0, Math.max("enabled".length - 1, 1))
        )
      ) {
        query_string = query_string.concat(`&is_active=${true}`);
      } else if (
        "disabled".startsWith(
          statusFilter
            .toLowerCase()
            .slice(0, Math.max("disabled".length - 1, 1))
        )
      ) {
        query_string = query_string.concat(`&is_active=${false}`);
      } else {
        query_string = query_string.concat(`&is_active=None`);
      }
    }
    return query_string;
  };
  useEffect(() => {
    const result = integrationsData?.result?.reduce(function (r, a) {
      r[a.integration_type] = r[a.integration_type] || [];
      r[a.integration_type].push(a);
      return r;
    }, Object.create(null));
    setTableData(result);
  }, [integrationsData]);

  const theme = useTheme();
  const handleChangePage = (_event: unknown, newPage: number) => {
    setPageNumber(newPage);
    setColumnFilters(setQueryString(newPage, pageSize));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(+event.target.value);
    setPageNumber(0);
    setColumnFilters(setQueryString(0, +event.target.value));
  };
  const handleFilter = () => {
    setPageNumber(0);
    setColumnFilters(setQueryString(0, pageSize));
  };

  return (
    <>
      <DataTableWrap className="table-tabs">
        <Box theme={theme} className="scroll">
          <Box className="scroll-hide spr-border" theme={theme} />
        </Box>
        <DataTable
          theme={theme}
          variant="basic"
          theadChildren={
            <>
              <TableRow>
                <TableCell colSpan={2}>Integrations</TableCell>
                <TableCell className="indent-cell">Status</TableCell>
                <TableCell className="action-cell fixed-cell" />
              </TableRow>
              <TableRow className="filters-row">
                <TableCell colSpan={2}>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={"Search..."}
                      type="text"
                      onChange={(value) => {
                        setCodeFilter(value);
                      }}
                      onBlur={() => {
                        handleFilter();
                      }}
                      value={codeFilter?.trimStart()}
                    />
                    <Button
                      defaultBtn
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={"Search..."}
                      type="text"
                      onChange={(value) => {
                        setStatusFilter(value);
                      }}
                      value={statusFilter?.trimStart()}
                      onBlur={() => {
                        handleFilter();
                      }}
                    />
                    <Button
                      defaultBtn
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell className="action-cell fixed-cell" />
              </TableRow>
            </>
          }
          tbodyChildren={
            <>
              {tableData &&
                Object.entries(tableData)?.map((integrationsRow, index) => {
                  return (
                    <React.Fragment key={index}>
                      <TbodyRow
                        onEdit={(data) => onEdit(data)}
                        onDelete={(data) => onDelete(data)}
                        integrationsRow={integrationsRow}
                        setParentIntegrationState={setParentIntegrationState}
                      />
                    </React.Fragment>
                  );
                })}
            </>
          }
        />
        <PaginationWrap>
          {integrationsData && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 15, 20]}
              component="div"
              count={integrationsData ? integrationsData?.totalResults : -1}
              rowsPerPage={pageSize}
              page={pageNumber}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              SelectProps={{
                IconComponent: ChevronDown,
              }}
            />
          )}
        </PaginationWrap>
      </DataTableWrap>
    </>
  );
};

export default IntergrationTable;

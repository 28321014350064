import { INewDownPaymentChartsPopUp } from "./AddNewDownPayment.type";
import { FC, useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTheme } from "@mui/material";
import {
  PersistentDrawer,
  Autocomplete,
  Icon,
  MultiSelect,
  Box,
  Button,
  Input,
  Select,
  Grid,
} from "@ntpkunity/controls";
import { ConfirmationDialog, MultiSelectWrap } from "shared/components";
import {
  IAssetMake,
  IAssetModel,
  IAssetTrim,
  IChartCriteria,
  ICharts,
  ICriteria,
  ISelectItemList,
} from "Interface";
import {
  useMutation_AddNewDownPaymentCharts,
  useMutation_AddNewDownPaymentCriteria,
  useMutation_UpdateDownPaymentCharts,
  UseQuery_GetAllAssetMakes,
  UseQuery_GetAllAssetModels,
  UseQuery_GetAllAssetTrims,
  UseMutation_AddCharts,
  UseMutation_UpdateCharts,
  UseMutation_CheckDuplicateChartName,
} from "services";
import { useSetupsStore, useStoreContext } from "Stores/SetupStore/Store";
import { logout } from "@ntpkunity/controls-ums";
import {
  ActionOptions,
  DialogMessages,
  QueryKeys,
  ToastMessages,
  ValidationMessages,
} from "Enums";
import { setOpenDrawer } from "Stores/SetupStore/Actions";
import { useQueryClient } from "react-query";
import { usePrompt } from "utilities/usePrompt";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SetupContext } from "context";
const schema = Yup.object().shape({
  chart: Yup.object().shape({
    chart_name: Yup.string().required("Chart name is required"),
    is_active: Yup.boolean().required("Chart status is required"),
  }),
  criteria: Yup.lazy((values) =>
    Yup.object().shape(
      {
        default_down_payment: Yup.number().when(
          [
            // "asset_classification",
            "asset_make_name",
            "asset_model_name",
            "asset_trim_name",
            "asset_condition",
            "terms",
            "credit_rating",
            "asset_usages",
            "minimum_down_payment",
            "maximum_down_payment",
          ],
          {
            is: (
              // assetClassification,
              assetMakeName,
              assetModelName,
              assetTrimName,
              assetCondition,
              term,
              creditRating,
              assetUsage,
              minimumDownPayment,
              maximumDownPayment
            ) => {
              return (
                // assetClassification ||
                assetMakeName ||
                assetModelName ||
                assetTrimName ||
                assetCondition ||
                term ||
                creditRating ||
                assetUsage ||
                minimumDownPayment ||
                maximumDownPayment
              );
            },
            then: Yup.number()
              .typeError("Default Down Payment is required")
              .required("Default Down Payment is required")
              .min(0, "Default Down Payment must be greater than or equal to 0")
              .test({
                name: "default_down_payment",
                message:
                  "Default Down Payment must be between Min. and Max. Down Payment",
                test: function (value) {
                  const minDownPayment = this.resolve(
                    Yup.ref("minimum_down_payment")
                  );
                  const maxDownPayment = this.resolve(
                    Yup.ref("maximum_down_payment")
                  );
                  if (value >= minDownPayment && value <= maxDownPayment) {
                    return true;
                  }
                  return false;
                },
              }),
          }
        ),
        minimum_down_payment: Yup.number().when(
          [
            // "asset_classification",
            "asset_make_name",
            "asset_model_name",
            "asset_trim_name",
            "asset_condition",
            "terms",
            "credit_rating",
            "asset_usages",
            "default_down_payment",
            "maximum_down_payment",
          ],
          {
            is: (
              // assetClassification,
              assetMakeName,
              assetModelName,
              assetTrimName,
              assetCondition,
              term,
              creditRating,
              assetUsage,
              defaultDownPayment,
              maximumDownPayment
            ) => {
              return (
                // assetClassification ||
                assetMakeName ||
                assetModelName ||
                assetTrimName ||
                assetCondition ||
                term ||
                creditRating ||
                assetUsage ||
                defaultDownPayment ||
                maximumDownPayment
              );
            },
            then: Yup.number()
              .typeError("Min. Down Payment is required")
              .required("Min. Down Payment is required")
              .min(0, "Min. Down Payment must be greater than or equal to 0")
              .test({
                name: "minimum_down_payment",
                message:
                  "Minimum Down Payment must be between 0 and Default Down Payment",
                test: function (value) {
                  const defaultDownPayment = this.resolve(
                    Yup.ref("default_down_payment")
                  );
                  if (value >= 0 && value <= defaultDownPayment) {
                    return true;
                  }
                  return false;
                },
              }),
          }
        ),
        maximum_down_payment: Yup.number().when(
          [
            // "asset_classification",
            "asset_make_name",
            "asset_model_name",
            "asset_trim_name",
            "asset_condition",
            "terms",
            "credit_rating",
            "asset_usages",
            "default_down_payment",
            "minimum_down_payment",
          ],
          {
            is: (
              // assetClassification,
              assetMakeName,
              assetModelName,
              assetTrimName,
              assetCondition,
              term,
              creditRating,
              assetUsage,
              defaultDownPayment,
              minimumDownPayment
            ) => {
              return (
                // assetClassification ||
                assetMakeName ||
                assetModelName ||
                assetTrimName ||
                assetCondition ||
                term ||
                creditRating ||
                assetUsage ||
                defaultDownPayment ||
                minimumDownPayment
              );
            },
            then: Yup.number()
              .typeError("Max. Down Payment is required")
              .required("Max. Down Payment is required")
              .min(0, "Max. Down Payment must be greater than or equal to 0")
              .test({
                name: "maximum_down_payment",
                message:
                  "Max. Down Payment must be between Min. and Default Down Payment",
                test: function (value) {
                  const defaultDownPayment = this.resolve(
                    Yup.ref("default_down_payment")
                  );
                  const minDownPayment = this.resolve(
                    Yup.ref("minimum_down_payment")
                  );
                  if (value >= minDownPayment && value >= defaultDownPayment) {
                    return true;
                  }
                  return false;
                },
              }),
          }
        ),
      },
      [
        ["default_down_payment", "minimum_down_payment"],
        ["default_down_payment", "maximum_down_payment"],
        ["maximum_down_payment", "minimum_down_payment"],
        ["maximum_down_payment", "default_down_payment"],
        ["minimum_down_payment", "default_down_payment"],
        ["minimum_down_payment", "maximum_down_payment"],
      ]
    )
  ),
});
const AddNewDownPaymentCharts: FC<INewDownPaymentChartsPopUp> = ({
  openPopUp,
  setPopUpState,
  title,
  actionType,
  downPaymentChartData,
}) => {
  const { mutate: CheckNameDuplicate } = UseMutation_CheckDuplicateChartName();
  const [state, dispatch] = useSetupsStore();

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    getValues,
    resetField,
    trigger,
    watch,
    setError,
    clearErrors,
    formState: { errors, isValid, isDirty, isValidating },
  } = useForm<IChartCriteria>({
    mode: "all",
    resolver: yupResolver(schema),
  });
  const { actions } = useStoreContext();
  const theme = useTheme();
  const {
    assetConditions,
    terms,
    creditRatings,
    lenderProfile,
    AllFinancedAmounts,
    assetUsages,
  } = useContext(SetupContext);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [navigationState, setNavigationState] = useState<any>();
  const [leavePagePopupState, setLeavePageState] = useState<boolean>(false);
  const onSubmit = (data: IChartCriteria) => {
    data.criteria.lender_id = lenderProfile?.id;
    data.chart.lender_id = lenderProfile?.id;
    data.criteria.asset_make_name = assetMakeId;
    data.criteria.asset_model_name = assetModelId;
    data.criteria.asset_trim_name = assetTrimId;
    data.criteria.terms = assetTerm;
    data.criteria.credit_rating = assetCreditScore;
    data.criteria.asset_condition = assetCondition;
    data.criteria.asset_usages = assetUsage;
    data.criteria.financed_amount = financedAmounts;
    data.chart.chart_type_id = 3;
    if (actionType == ActionOptions.ADD_NEW_CHART) {
      if (
        !(data?.criteria.default_down_payment >= 0) ||
        data?.criteria.default_down_payment == null
      ) {
        if (
          chartId == null &&
          data?.criteria?.asset_make_name == null &&
          data?.criteria?.asset_model_name == null &&
          data?.criteria?.asset_trim_name == null &&
          data?.criteria?.credit_rating.length == 0 &&
          data?.criteria?.asset_usages.length == 0 &&
          data?.criteria?.terms.length == 0 &&
          data?.criteria?.financed_amount.length == 0 &&
          data?.criteria?.asset_condition.length == 0
        ) {
          AddChart(data.chart, {
            onSuccess: (dataa, variables, context) => {
              setChartId(dataa.data.id);
              setValue("chart.chart_name", dataa.data.chart_name);
              setIsChartAdded(true);
              setChartEnable(true);
              onClose();
            },
            onError: (error) => {
              reset(undefined);
              setValue("chart.chart_name", data.chart.chart_name);
              if (error?.response?.data?.detail?.code === 400) {
                setError("chart.chart_name", {
                  message: "Chart Name should be unique",
                });
                return;
              } else {
                actions.setToast({
                  toastMessage:
                    error.response.data.detail.message ??
                    ToastMessages.Internal_Server_Error,
                  toastState: true,
                  variant: "error",
                });
              }
            },
          });
        } else if (
          chartId == null &&
          data?.criteria?.asset_make_name != null &&
          data?.criteria?.asset_model_name != null &&
          data?.criteria?.asset_trim_name != null &&
          data?.criteria?.credit_rating.length != 0 &&
          data?.criteria?.asset_usages.length != 0 &&
          data?.criteria?.terms.length != 0 &&
          data?.criteria?.asset_condition.length != 0 &&
          data?.criteria?.financed_amount.length != 0
        ) {
          if (
            data?.criteria?.minimum_down_payment != null &&
            data.criteria?.maximum_down_payment != null
          ) {
            addNewChartWithCriteria(data);
            setChartEnable(true);
          }
        }
      } else if (data?.criteria?.default_down_payment >= 0) {
        if (chartId != null) {
          data.criteria.chart_id = chartId;
          addCriteria(data.criteria);
          setIsChartAdded(true);
          setChartEnable(true);
        } else {
          addNewChartWithCriteria(data);
          setIsChartAdded(true);
          setChartEnable(true);
        }
      }
    } else if (actionType == ActionOptions.RENAME_CHART) {
      data.chart.id = downPaymentChartData.chart.id;
      updateChart(data.chart);
    } else if (actionType == ActionOptions.ADD_CRITERIA) {
      if (
        data?.criteria.down_payment_type != null &&
        data?.criteria?.default_down_payment >= 0 &&
        data?.criteria?.maximum_down_payment >=
          data?.criteria?.minimum_down_payment &&
        data?.criteria?.minimum_down_payment >= 0
      ) {
        data.criteria.chart_id = downPaymentChartData.chart.id;
        addCriteria(data.criteria);
      }
      setChartEnable(true);
    }
    //edit criteria
    else if (actionType == ActionOptions.EDIT) {
      data.criteria.chart_id = downPaymentChartData?.chart.id;
      data.criteria.id = downPaymentChartData?.criteria.id;
      updateCriteria(data.criteria);
    }
  };
  const onClose = () => {
    let children = document.querySelectorAll(".drawer-header .pinned");
    if (children.length == 0) {
      setPopUpState(false);
      dispatch(setOpenDrawer(false));
    }
  };

  const { mutate: AddDownPaymentChart } = useMutation_AddNewDownPaymentCharts();
  const { mutate: AddChart } = UseMutation_AddCharts();
  const { mutate: UpdateChart } = UseMutation_UpdateCharts();
  const { mutate: AddNewDownPaymentCriteria } =
    useMutation_AddNewDownPaymentCriteria();
  const { mutate: UpdateDownPaymentCriteria } =
    useMutation_UpdateDownPaymentCharts(undefined);

  UseQuery_GetAllAssetMakes();
  UseQuery_GetAllAssetModels();
  UseQuery_GetAllAssetTrims();

  const assetMakes: Array<IAssetMake> = queryClient.getQueryData(
    QueryKeys.GET_ALL_ASSET_MAKES
  );
  const assetModels: Array<IAssetModel> = queryClient.getQueryData(
    QueryKeys.GET_ALL_ASSET_MODELS
  );
  const assetTrims: Array<IAssetTrim> = queryClient.getQueryData(
    QueryKeys.GET_ALL_ASSET_TRIMS
  );
  const [assetMakeId, setAssetMakeId] = useState(null);
  const [assetModelId, setAssetModelId] = useState(null);
  const [assetTrimId, setAssetTrimId] = useState(null);
  const [assetMake, setAssetMake] = useState(null);
  const [assetModel, setAssetModel] = useState(null);
  var [assetTerm, setAssetTerm] = useState([]);
  const [assetCondition, setAssetCondition] = useState([]);
  const [assetUsage, setAssetUsage] = useState([]);
  const [assetCreditScore, setAssetCreditScore] = useState([]);
  const [chartId, setChartId] = useState(null);
  const [chartEnable, setChartEnable] = useState<boolean>(false);
  const [criteriaEnable, setCriteriaEnable] = useState<boolean>(false);
  const [isChartAdded, setIsChartAdded] = useState<boolean>(false);
  const [financedAmounts, setFinancedAmounts] = useState([]);
  const [chartNameValidation, setChartNameValidation] =
    useState<boolean>(false);
  useEffect(() => {
    if (actionType === ActionOptions.RENAME_CHART && downPaymentChartData) {
      setCriteriaEnable(true);
      setChartEnable(false);
      setValue("chart.chart_name", downPaymentChartData.chart?.chart_name);
      setValue("chart.is_active", downPaymentChartData.chart?.is_active);
    } else if (
      actionType === ActionOptions.ADD_CRITERIA &&
      downPaymentChartData
    ) {
      setChartEnable(true);
      setCriteriaEnable(false);
      setValue("chart.chart_name", downPaymentChartData.chart?.chart_name);
      setValue("chart.is_active", downPaymentChartData.chart?.is_active);
      setValue("criteria.asset_condition", []);
      setValue("criteria.terms", []);
      setValue("criteria.credit_rating", []);
    } else if (actionType === ActionOptions.ADD_NEW_CHART && !isChartAdded) {
      setChartEnable(false);
      setCriteriaEnable(false);
      reset(undefined);
    }
    //edit criteria
    else if (actionType === ActionOptions.EDIT && downPaymentChartData) {
      setChartEnable(true);
      setCriteriaEnable(false);
      setValue("chart.chart_name", downPaymentChartData?.chart?.chart_name);
      setValue("chart.is_active", downPaymentChartData?.chart?.is_active);
      setValue("criteria.id", downPaymentChartData?.criteria?.id);
      setAssetMakeId(downPaymentChartData?.criteria?.asset_make_name);
      setAssetModelId(downPaymentChartData?.criteria?.asset_model_name);
      setAssetTrimId(downPaymentChartData?.criteria?.asset_trim_name);
      if (downPaymentChartData?.criteria?.terms) {
        setAssetTerm(downPaymentChartData?.criteria?.terms);
      }
      if (downPaymentChartData?.criteria?.asset_condition) {
        setAssetCondition(downPaymentChartData?.criteria?.asset_condition);
      }
      if (downPaymentChartData?.criteria?.credit_rating) {
        setAssetCreditScore(downPaymentChartData?.criteria?.credit_rating);
      }
      if (downPaymentChartData?.criteria?.financed_amount) {
        setFinancedAmounts(downPaymentChartData?.criteria?.financed_amount);
      }
      // setValue(
      //   "criteria.asset_classification",
      //   downPaymentChartData?.criteria?.asset_classification
      // );
      if (downPaymentChartData?.criteria?.asset_usages) {
        setAssetUsage(downPaymentChartData?.criteria?.asset_usages);
      }
      setValue(
        "criteria.asset_condition",
        downPaymentChartData?.criteria?.asset_condition
      );
      setValue(
        "criteria.default_down_payment",
        downPaymentChartData?.criteria?.default_down_payment
      );
      setValue(
        "criteria.minimum_down_payment",
        downPaymentChartData?.criteria?.minimum_down_payment
      );
      setValue(
        "criteria.maximum_down_payment",
        downPaymentChartData?.criteria?.maximum_down_payment
      );
    }
  }, [downPaymentChartData, criteriaEnable]);
  const [assetmakesSelection, setAssetmakesSelection] = useState<
    Array<ISelectItemList>
  >(
    assetMakes
      ?.filter((x) => x.is_active)
      .map((make) => ({
        value: make.id,
        text: make.name,
      }))
  );

  useEffect(() => {
    setAssetmakesSelection(
      assetMakes
        ?.filter((x) => x.is_active)
        .map((makes) => ({
          value: makes.name,
          text: makes.name,
        }))
    );
  }, [assetMakes, assetMakeId]);

  const [assetmodelsSelection, setAssetmodelsSelection] = useState<
    Array<ISelectItemList>
  >([]);

  const [assetTrimSelection, setAssettrimSelection] = useState<
    Array<ISelectItemList>
  >([]);

  const [creditRatingsSelection, setCreditRatingState] =
    useState<Array<ISelectItemList>>();
  const [financedAmountsSelection, setFinancedAmountsSelectionState] =
    useState<Array<ISelectItemList>>();
  useEffect(() => {
    const ratingsArray = creditRatings
      ?.filter((x) => x.is_active)
      ?.map((creditRating) => ({
        value: creditRating.description,
        text: creditRating.description,
      }));
    assetCreditScore?.forEach((desc) => {
      const selectedRating = creditRatings?.find(
        (x) => x.description == desc && !x.is_active
      );
      selectedRating &&
        ratingsArray.push({
          value: selectedRating?.description,
          text: selectedRating?.description,
        });
    });
    setCreditRatingState(ratingsArray);
  }, [creditRatings, assetCreditScore]);

  const [termsSelection, setTermsSelectionState] =
    useState<Array<ISelectItemList>>();
  const [assetConditionSelection, setAssetConditionSelectionState] =
    useState<Array<ISelectItemList>>();
  const [assetUsageSelection, setAssetUsageSelectionState] =
    useState<Array<ISelectItemList>>();

  useEffect(() => {
    const termsArray = terms
      ?.filter((x) => x.is_active)
      ?.map((term) => ({
        value: `${term.terms_from} - ${term.terms_to}`,
        text: `${term.terms_from} - ${term.terms_to}`,
      }));
    assetTerm?.forEach((id) => {
      const selectedTerms = terms?.find((x) => x.id == id && !x.is_active);
      selectedTerms &&
        termsArray.push({
          value: `${selectedTerms.terms_from} - ${selectedTerms.terms_to}`,
          text: `${selectedTerms.terms_from} - ${selectedTerms.terms_to}`,
        });
    });
    setTermsSelectionState(termsArray);
  }, [terms, assetTerm]);

  useEffect(() => {
    const assetConditionArray = assetConditions
      ?.filter((x) => x.is_active)
      ?.map((asset) => ({
        value: `${asset.description}`,
        text: `${asset.description}`,
      }));
    assetCondition.forEach((desc) => {
      const selectedAssetConditions = assetConditions?.find(
        (x) => x.description == desc && !x.is_active
      );
      selectedAssetConditions &&
        assetConditionArray.push({
          value: selectedAssetConditions?.description,
          text: selectedAssetConditions?.description,
        });
    });
    setAssetConditionSelectionState(assetConditionArray);
  }, [assetConditions, assetCondition]);

  useEffect(() => {
    const assetUsageArray = assetUsages
      ?.filter((x) => x.is_active)
      ?.map((asset) => ({
        value: `${asset.description}`,
        text: `${asset.description}`,
      }));
    assetUsage.forEach((desc) => {
      const selectedAssetUsages = assetUsages?.find(
        (x) => x.description == desc && !x.is_active
      );
      selectedAssetUsages &&
        assetUsageArray.push({
          value: selectedAssetUsages?.description,
          text: selectedAssetUsages?.description,
        });
    });
    setAssetUsageSelectionState(assetUsageArray);
  }, [assetUsages, assetUsage]);

  useEffect(() => {
    const financedAmountArray = AllFinancedAmounts?.filter(
      (x) => x.is_active
    )?.map((financed) => ({
      value: `${financed.financed_amount_from} - ${financed.financed_amount_to}`,
      text: `${financed.financed_amount_from.toLocaleString()} - ${financed.financed_amount_to.toLocaleString()}`,
    }));
    financedAmounts?.forEach((id) => {
      const selectedFinancedAmounts = AllFinancedAmounts?.find(
        (x) => x.id == id && !x.is_active
      );
      selectedFinancedAmounts &&
        financedAmountArray.push({
          value: `${selectedFinancedAmounts.financed_amount_from} - ${selectedFinancedAmounts.financed_amount_to}`,
          text: `${selectedFinancedAmounts.financed_amount_from.toLocaleString()} - ${selectedFinancedAmounts.financed_amount_to.toLocaleString()}`,
        });
    });
    setFinancedAmountsSelectionState(financedAmountArray);
  }, [AllFinancedAmounts, financedAmounts]);
  useEffect(() => {
    setAssetMake(assetMakes?.filter((x) => x.name == assetMakeId)[0]?.id);
    setAssetmodelsSelection(
      assetModels
        ?.filter(
          (models) =>
            assetMake > 0 && models.make_id === assetMake && models.is_active
        )
        ?.map((model) => ({
          value: model.model_name,
          text: model.model_name,
        }))
    );
  }, [assetModels, assetMakes, assetMakeId, assetModelId, assetMake]);

  useEffect(() => {
    setAssetModel(
      assetModels?.filter((x) => x.model_name == assetModelId)[0]?.id
    );
    setAssettrimSelection(
      assetTrims
        ?.filter(
          (trims) =>
            assetModel > 0 && trims.model_id === assetModel && trims.is_active
        )
        ?.map((trim) => ({
          value: trim.trim_name,
          text: trim.trim_name,
        }))
    );
  }, [
    assetTrims,
    assetModels,
    assetMakes,
    assetMakeId,
    assetModelId,
    assetTrimId,
    assetModel,
  ]);

  const onMakeSelection = (value) => {
    setAssetMakeId(value?.id);
    setAssetModelId(null);
    setAssetTrimId(null);
  };

  const onModelSelection = (value) => {
    setAssetModelId(value?.id);
    setAssetTrimId(null);
  };

  const onTrimSelection = (value) => {
    setAssetTrimId(value?.id);
  };

  const onTermsSelection = (e) => {
    setAssetTerm(
      typeof e.target.value === "string"
        ? e.target.value.split(",")
        : e.target.value
    );
  };

  const onCreditScoreSelection = (e) => {
    setAssetCreditScore(
      typeof e.target.value === "string"
        ? e.target.value.split(",")
        : e.target.value
    );
  };

  const onConditionSelection = (e) => {
    setAssetCondition(
      typeof e.target.value === "string"
        ? e.target.value.split(",")
        : e.target.value
    );
  };
  const onUsageSelection = (e) => {
    setAssetUsage(
      typeof e.target.value === "string"
        ? e.target.value.split(",")
        : e.target.value
    );
  };

  const onFinancedAmountSelection = (e) => {
    setFinancedAmounts(
      typeof e.target.value === "string"
        ? e.target.value.split(",")
        : e.target.value
    );
  };

  const addNewChartWithCriteria = (data: IChartCriteria) => {
    data.chart.lender_id = lenderProfile?.id;
    if (
      data?.criteria?.down_payment_type != null &&
      data?.criteria?.default_down_payment >= 0 &&
      data?.criteria?.default_down_payment <=
        data?.criteria?.maximum_down_payment &&
      data?.criteria?.default_down_payment >=
        data?.criteria?.minimum_down_payment &&
      data?.criteria?.maximum_down_payment >=
        data?.criteria?.minimum_down_payment &&
      data?.criteria?.minimum_down_payment >= 0
    ) {
      AddDownPaymentChart(data, {
        onSuccess: (data, variables, context) => {
          setChartId(data.data.id);
        },
      });
      onClose();
    } else {
      schema.validate(data);
    }
  };
  const resetFormFields = () => {
    resetField("criteria.default_down_payment");
    resetField("criteria.minimum_down_payment");
    resetField("criteria.maximum_down_payment");
    setAssetMakeId(undefined);
    setAssetModelId(null);
    setAssetTrimId(null);
    setAssetCreditScore([]);
    setAssetTerm([]);
    setAssetCondition([]);
    setAssetUsage([]);
    setFinancedAmounts([]);
  };
  const addCriteria = (data: ICriteria) => {
    AddNewDownPaymentCriteria(data);
    resetFormFields();
    onClose();
  };

  const updateCriteria = (data: ICriteria) => {
    UpdateDownPaymentCriteria(data);
    onClose();
  };

  const updateChart = (data: ICharts) => {
    UpdateChart(data);
    reset();
    onClose();
  };
  const setMarginValue = (val) => {
    if (val <= 100 && val >= 0) {
      setValue("criteria.default_down_payment", parseFloat(val));
    }
  };
  const setMinMargin = (val) => {
    if (val <= 100 && val >= 0) {
      setValue("criteria.minimum_down_payment", parseFloat(val));
    }
  };
  const setMaxMargin = (val) => {
    if (val <= 100 && val >= 0) {
      setValue("criteria.maximum_down_payment", parseFloat(val));
    } else {
      const num = val.substr(0, 2);
      setValue("criteria.maximum_down_payment", num);
    }
  };
  const onSaveConfirm = (e: any) => {
    handleSubmit(onSubmit)();
    setLeavePageState(false);
  };

  const onCancelConfirm = (e: any) => {
    navigate(".." + navigationState.location.pathname);
    if (navigationState.location.pathname == "/market-place/signup") {
      logout();
      localStorage.clear();
    }
    localStorage.setItem("DirtyState", "false");
  };
  usePrompt(
    "Leave screen?",
    isDirty,
    (dirty) => {
      setLeavePageState(dirty);
    },
    (tx) => {
      reset(getValues());
      setNavigationState(tx);
    }
  );
  return (
    <>
      <ConfirmationDialog
        openPopUp={leavePagePopupState}
        onConfirm={onSaveConfirm}
        onCancel={onCancelConfirm}
        setPopUpState={setLeavePageState}
        confirmationTitle={DialogMessages.saveTitle}
        confirmationText={DialogMessages.saveText}
        primaryBtnText={DialogMessages.saveBtnText}
        icon={<Icon name="DialogSaveIcon" />}
      />
      <PersistentDrawer
        title={title}
        openPopUp={openPopUp}
        setPopUpState={setPopUpState}
        theme={theme}
        onClose={onClose}
        enabledPin={true}
        children={
          <>
            <Controller
              name="chart.chart_name"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: ValidationMessages.CHART_NAME_REQUIRED,
                },
                validate: {
                  checkUniqueName: () => {
                    if (chartNameValidation) {
                      return ValidationMessages.NAME_UNIQUE;
                    }
                    return true;
                  },
                },
              }}
              render={({ field: { onChange, value } }) => (
                <Input
                  id="drawerChartName"
                  fullWidth={true}
                  theme={theme}
                  label={"Chart Name"}
                  placeholder={"Type Here"}
                  value={value?.trimStart()}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  // onBlur={(e) => checkDownPaymentChartName(e.target.value)}
                  disabled={chartEnable}
                  error={errors?.chart?.chart_name?.message}
                  type="text"
                />
              )}
            />

            <Controller
              name="chart.is_active"
              control={control}
              defaultValue={true}
              render={({ field: { value, onChange } }) => (
                <Select
                  id="drawerStatus"
                  theme={theme}
                  label={"Status"}
                  items={[
                    { text: "Enabled", value: true },
                    { text: "Disabled", value: false },
                  ]}
                  value={value}
                  onChange={onChange}
                  disabled={chartEnable}
                ></Select>
              )}
            />
            <Box theme={theme} className="divider-with-text">
              <Box theme={theme} className="divider-text">
                BUSINESS CRITERIA
              </Box>
            </Box>
            {/* <Controller
              name="criteria.asset_classification"
              control={control}
              defaultValue={""}
              render={({ field: { value, onChange } }) => (
                <Select
                  id="drawerAssetClassification"
                  theme={theme}
                  label={"Asset Classification"}
                  items={[{ text: "Vehicles", value: "Vehicles" }]}
                  value={value}
                  onChange={onChange}
                  disabled={criteriaEnable}
                ></Select>
              )}
            /> */}
            <Controller
              name="criteria.asset_make_name"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  disablePortal
                  {...field}
                  id="drawerMake"
                  theme={theme}
                  disabled={criteriaEnable}
                  capitalizeLabel={true}
                  items={
                    assetmakesSelection
                      ? assetmakesSelection?.map((option: any) => {
                          return { label: option.text, id: option.value };
                        })
                      : []
                  }
                  onChange={(_e: any, value: any) => {
                    onMakeSelection(value);
                    field.onChange(value);
                  }}
                  label={assetMakeId ? "Asset Make" : "Asset Make"}
                  value={
                    assetMakeId
                      ? {
                          label: assetMakeId,
                          id: assetMakeId,
                        }
                      : { label: "", id: "" }
                  }
                  renderOption={(props, option, { selected }) => (
                    <li {...props} key={option.id}>
                      <span>{option.label}</span>
                    </li>
                  )}
                />
              )}
            />
            <Controller
              name="criteria.asset_model_name"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  disablePortal
                  id="drawerModel"
                  {...field}
                  theme={theme}
                  disabled={criteriaEnable}
                  capitalizeLabel={true}
                  items={
                    assetmodelsSelection
                      ? assetmodelsSelection?.map((option: any) => {
                          return { label: option.text, id: option.text };
                        })
                      : []
                  }
                  onChange={(_e: any, value: any) => {
                    onModelSelection(value);
                    field.onChange(value);
                  }}
                  label={assetModelId ? "Asset Model" : "Asset Model"}
                  value={
                    assetModelId
                      ? {
                          label: assetModelId,
                          id: assetModelId,
                        }
                      : { label: "", id: "" }
                  }
                  renderOption={(props, option, { selected }) => (
                    <li {...props} key={option.id}>
                      <span>{option.label}</span>
                    </li>
                  )}
                />
              )}
            />
            <Controller
              name="criteria.asset_trim_name"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  disablePortal
                  id="drawerTrim"
                  {...field}
                  theme={theme}
                  disabled={criteriaEnable}
                  capitalizeLabel={true}
                  items={
                    assetTrimSelection
                      ? assetTrimSelection?.map((option: any) => {
                          return { label: option.text, id: option.text };
                        })
                      : []
                  }
                  onChange={(_e: any, value: any) => {
                    onTrimSelection(value);
                    field.onChange(value);
                  }}
                  label={assetTrimId ? "Asset Trim" : "Asset Trim"}
                  value={
                    assetTrimId
                      ? {
                          label: assetTrimId,
                          id: assetTrimId,
                        }
                      : { label: "", id: "" }
                  }
                  renderOption={(props, option, { selected }) => (
                    <li {...props} key={option.id}>
                      <span>{option.label}</span>
                    </li>
                  )}
                />
              )}
            />
            <MultiSelectWrap>
              <Controller
                name="criteria.asset_condition"
                control={control}
                render={({ field: { value } }) => (
                  <MultiSelect
                    theme={theme}
                    id="DrawerAssetCondition"
                    sxProps={{ m: 0, width: "100%" }}
                    label={"Asset Condition"}
                    value={assetCondition || []}
                    items={assetConditionSelection}
                    onChange={onConditionSelection}
                    disabled={criteriaEnable}
                  />
                )}
              />
            </MultiSelectWrap>
            <Grid
              theme={theme}
              container
              rowSpacing={3}
              columnSpacing={2}
              sx={{ mb: 3 }}
            >
              <Grid theme={theme} item xs={6}>
                <MultiSelectWrap>
                  <Controller
                    name="criteria.terms"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <MultiSelect
                        theme={theme}
                        id="drawerTerms"
                        label={"Terms"}
                        value={assetTerm || []}
                        items={termsSelection}
                        onChange={onTermsSelection}
                        disabled={criteriaEnable}
                        sxProps={{ m: 0, width: "100%" }}
                      />
                    )}
                  />
                </MultiSelectWrap>
              </Grid>
              <Grid theme={theme} item xs={6}>
                <MultiSelectWrap>
                  <Controller
                    name="criteria.credit_rating"
                    control={control}
                    render={({ field }) => (
                      <MultiSelect
                        id="drawerCreditRating"
                        theme={theme}
                        label={"Credit Score"}
                        items={creditRatingsSelection}
                        value={assetCreditScore || []}
                        onChange={onCreditScoreSelection}
                        disabled={criteriaEnable}
                        sxProps={{ m: 0, width: "100%" }}
                      />
                    )}
                  />
                </MultiSelectWrap>
              </Grid>
            </Grid>
            <MultiSelectWrap>
              <Controller
                name="criteria.financed_amount"
                control={control}
                render={({ field: { value } }) => (
                  <MultiSelect
                    theme={theme}
                    id="DrawerFinancedAmountRange"
                    sxProps={{ m: 0, width: "100%" }}
                    label={"Financed Amount Range"}
                    value={financedAmounts || []}
                    items={financedAmountsSelection}
                    onChange={onFinancedAmountSelection}
                    disabled={criteriaEnable}
                  />
                )}
              />
            </MultiSelectWrap>
            <MultiSelectWrap>
              <Controller
                name="criteria.asset_usages"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <MultiSelect
                    theme={theme}
                    id="DrawerAssetUsage"
                    sxProps={{ m: 0, width: "100%" }}
                    label={"Asset Usage"}
                    value={assetUsage || []}
                    items={assetUsageSelection}
                    onChange={onUsageSelection}
                    disabled={criteriaEnable}
                  />
                )}
              />
            </MultiSelectWrap>
            <Box theme={theme} className="divider-with-text">
              <Box theme={theme} className="divider-text">
                OUTPUT PARAMETERS
              </Box>
            </Box>
            <Controller
              name="criteria.down_payment_type"
              control={control}
              defaultValue={"Rate"}
              render={({ field }) => (
                <Select
                  id="drawerMarginType"
                  theme={theme}
                  label={"Down Payment Type"}
                  items={[{ text: "Rate", value: "Rate" }]}
                  {...field}
                  disabled={criteriaEnable}
                  selectError={
                    errors?.criteria?.down_payment_type?.message as never
                  }
                ></Select>
              )}
            />
            <Controller
              name="criteria.default_down_payment"
              control={control}
              render={({ field: { value } }) => (
                <Input
                  fullWidth
                  theme={theme}
                  id="drawerMargin"
                  label={"Default Down Payment"}
                  type="text"
                  value={value}
                  onChange={(val) => {
                    if (parseFloat(val) <= 100) setMarginValue(val);
                    else setMarginValue(val.substr(0, 3));
                  }}
                  disabled={criteriaEnable}
                  adornmentOnFocus
                  endAdornment={
                    <>
                      <span className="MuiInputAdornment-root">%</span>
                    </>
                  }
                  masking
                  maskDecimalScale={4}
                  maskNumeric
                  error={
                    errors?.criteria?.default_down_payment?.message as never
                  }
                  // scale={4}
                  // numeric
                />
              )}
            />
            <Grid theme={theme} container rowSpacing={3} columnSpacing={2}>
              <Grid theme={theme} item xs={6}>
                <Controller
                  name="criteria.minimum_down_payment"
                  control={control}
                  render={({ field }) => (
                    <Input
                      fullWidth
                      theme={theme}
                      id="drawerMinMargin"
                      type="text"
                      {...field}
                      onChange={(val) => {
                        if (parseFloat(val) <= 100) setMinMargin(val);
                        else setMinMargin(val.substr(0, 3));
                      }}
                      label={"Min. Down Payment"}
                      adornmentOnFocus
                      endAdornment={
                        <>
                          <span className="MuiInputAdornment-root">%</span>
                        </>
                      }
                      masking
                      maskDecimalScale={4}
                      maskNumeric
                      // scale={4}
                      // numeric
                      disabled={criteriaEnable}
                      error={
                        errors?.criteria?.minimum_down_payment?.message as never
                      }
                    />
                  )}
                />
              </Grid>
              <Grid theme={theme} item xs={6}>
                <Controller
                  name="criteria.maximum_down_payment"
                  control={control}
                  render={({ field }) => (
                    <Input
                      fullWidth
                      theme={theme}
                      id="drawerMaxMargin"
                      type="text"
                      {...field}
                      onChange={(val) => {
                        if (parseFloat(val) <= 100) setMaxMargin(val);
                        else setMaxMargin(val.substr(0, 3));
                      }}
                      label={"Max. Down Payment"}
                      disabled={criteriaEnable}
                      adornmentOnFocus
                      endAdornment={
                        <>
                          <span className="MuiInputAdornment-root">%</span>
                        </>
                      }
                      masking
                      maskDecimalScale={4}
                      maskNumeric
                      // scale={4}
                      // numeric
                      error={
                        errors?.criteria?.maximum_down_payment?.message as never
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
          </>
        }
        customFooter={
          <Button
            theme={theme}
            id="saveCriteria"
            primary
            text={"Save"}
            fullWidth={true}
            onClick={handleSubmit(onSubmit)}
          />
        }
      />
    </>
  );
};
export default AddNewDownPaymentCharts;

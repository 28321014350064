import { ConfigurationAPI } from "services";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QueryKeys, ToastMessages } from "Enums";
import { useStoreContext } from "Stores/SetupStore/Store";
import { IAssetCondition } from "Interface";

export const getAllAssetConditionsFilterFromServer = (
  columnFilters: string
) => {
  return ConfigurationAPI.get(
    `/asset-condition-test/filter?`.concat(columnFilters)
  ).then((response) => {
    return response.data;
  });
};

export const UseQuery_Asset_Conditions_filter = (columnFilters: string) => {
  return useQuery(
    [QueryKeys.GET_ALL_ASSET_CONDITIONS_FILTER, columnFilters],
    async () => {
      return getAllAssetConditionsFilterFromServer(columnFilters).then(
        (data) => {
          return data;
        }
      );
    },
    { keepPreviousData: true }
  );
};

export const getActiveAssetConditionsFromServer = () => {
  return ConfigurationAPI.get("/asset-condition/active").then((res) => {
    return res.data;
  });
};

export const getAllAssetConditionsFromServer = () => {
  return ConfigurationAPI.get("/asset-conditions").then((res) => {
    return res.data;
  });
};

export const UseQuery_GetActiveAssetConditions = () => {
  return useQuery(QueryKeys.GET_ALL_ASSET_CONDITIONS_ACTIVE, async () => {
    return getActiveAssetConditionsFromServer().then((data) => {
      return data;
    });
  });
};

export const UseQuery_GetAllAssetConditions = () => {
  return useQuery(QueryKeys.Get_All_Asset_Conditions, async () => {
    return getAllAssetConditionsFromServer().then((data) => {
      return data;
    });
  });
};

export const UseMutation_AddAssetCondition = (): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  return useMutation<IAssetCondition>(
    (assetCondition) => {
      return ConfigurationAPI.post("/asset-conditions", assetCondition);
    },
    {
      onSuccess: async (response: any) => {
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_ASSET_CONDITIONS_FILTER
        );
        queryClient.invalidateQueries(QueryKeys.Get_All_Asset_Conditions);
        actions.setToast({
          toastMessage: ToastMessages.Record_Added_Success,
          toastState: true,
        });
      },
    }
  );
};

export const UseMutation_UpdateAssetCondition = (ratingId: number): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  return useMutation<IAssetCondition>(
    (assetCondition: any) => {
      return ConfigurationAPI.patch(
        `/asset-conditions/${assetCondition.id}`,
        assetCondition
      );
    },
    {
      onSuccess: async (response: any) => {
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_ASSET_CONDITIONS_FILTER
        );
        queryClient.invalidateQueries(QueryKeys.Get_All_Asset_Conditions);
        actions.setToast({
          toastMessage: ToastMessages.Record_Updated_Success,
          toastState: true,
        });
      },
    }
  );
};

export const UseMutation_DeleteAssetCondition = (): any => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();
  return useMutation<IAssetCondition>(
    (assetConditionId) => {
      return ConfigurationAPI.delete(`/asset-conditions/${assetConditionId}`);
    },
    {
      onSuccess: async (response: any) => {
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_ASSET_CONDITIONS_FILTER
        );
        queryClient.invalidateQueries(QueryKeys.Get_All_Asset_Conditions);
        actions.setToast({
          toastMessage: ToastMessages.Record_Deleted_Success,
          toastState: true,
        });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage:
            error.response.data.detail.message ??
            ToastMessages.Internal_Server_Error,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};

import { FC, useState, useEffect } from "react";
import { useTheme } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useQueryClient } from "react-query";
import { Box, Button, Input, DataTableWrap } from "shared/components";

import { Icon, DataTable, Checkbox } from "@ntpkunity/controls";
import { DealersProps } from "./dealers.type";
import { Controller } from "react-hook-form";
import { UseQuery_GetActiveDealers, useGetActiveDealersFilter } from "services";
import { IDealer } from "Interface";
import { ActionOptions, QueryKeys } from "Enums";

const Dealers: FC<DealersProps> = ({
  control,
  dealerIds,
  setDealerIds,
  actionType,
  setValue,
}) => {
  const theme = useTheme();
  const { mutate: getDealersFilter } = useGetActiveDealersFilter();
  const [checkParent, setCheckParent] = useState<boolean>(false);
  const [nameFilter, setNameFilter] = useState("");
  const [codeFilter, setCodeFilter] = useState("");

  UseQuery_GetActiveDealers();

  const queryClient = useQueryClient();
  const dealersData: IDealer[] = queryClient.getQueryData(
    QueryKeys.GET_ACTIVE_DEALERS
  );
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const handleFilter = () => {
    const params = {
      name: nameFilter,
      code: codeFilter,
    };
    getDealersFilter(params);
  };
  useEffect(() => {
    if (actionType == ActionOptions.VIEW) {
      setIsDisabled(true);
    }
  }, [actionType]);

  const onCheckAllChange = (e: any) => {
    setValue("dealerGroup", "undefined", { shouldDirty: true });
    let arr: Array<number> = [];
    if (e.target.checked === true) {
      dealersData?.map((x) => {
        arr.push(x.id);
      });
      setDealerIds(arr);
    } else {
      setDealerIds([]);
    }
  };
  const onCheckChange2 = (e: any) => {
    setValue("dealerGroup", "undefined", { shouldDirty: true });
    if (e.target.checked === true) {
      setDealerIds([...dealerIds, parseInt(e.target.value)]);
    } else {
      setDealerIds(dealerIds?.filter((x) => x !== parseInt(e.target.value)));
    }
  };
  return (
    <>
      <Box theme={theme} sx={{ mt: "24px" }}>
        <Controller
          name="dealerGroup"
          control={control}
          render={({ field: { onChange, value } }) => <> </>}
        />
        <DataTableWrap className="table-tabs">
          <Box className="scroll">
            <Box
              className="scroll-hide spr-border sh-fixed-cell"
              theme={theme}
            />
          </Box>
          <DataTable
            theme={theme}
            theadChildren={
              <>
                <TableRow>
                  <TableCell className="checkbox-cell fixed-cell">
                    <Checkbox
                      theme={theme}
                      checkBoxChecked={
                        dealerIds?.length === dealersData?.length &&
                        dealerIds?.length != 0
                      }
                      checkBoxDisabled={isDisabled}
                      // disabled={isDisabled}
                      onChange={onCheckAllChange}
                      label={""}
                    />
                  </TableCell>
                  <TableCell>Dealer Name</TableCell>
                  <TableCell>Dealer Code</TableCell>
                </TableRow>
                <TableRow className="filters-row">
                  <TableCell className="checkbox-cell"></TableCell>
                  <TableCell>
                    <Box theme={theme} className="table-filter">
                      <Input
                        id="dealerNameSearchId"
                        name="dealerNameSearch"
                        theme={theme}
                        fullWidth={true}
                        placeholder={"Search..."}
                        type="text"
                        value={nameFilter}
                        onChange={(value) => {
                          setNameFilter(value);
                        }}
                        onBlur={() => {
                          handleFilter();
                        }}
                      />
                      <Button
                        defaultBtn
                        id="dealerNameFilter"
                        iconText={<Icon name="IconFilter" />}
                      ></Button>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box theme={theme} className="table-filter">
                      <Input
                        id="dealerCodeSearchId"
                        theme={theme}
                        fullWidth={true}
                        placeholder={"Search..."}
                        type="text"
                        value={codeFilter}
                        onChange={(value) => {
                          setCodeFilter(value);
                        }}
                        onBlur={() => {
                          handleFilter();
                        }}
                      ></Input>
                      <Button
                        defaultBtn
                        id="dealerFilter"
                        iconText={<Icon name="IconFilter" />}
                      ></Button>
                    </Box>
                  </TableCell>
                </TableRow>
              </>
            }
            tbodyChildren={
              dealersData &&
              dealersData.map((dealer, index) => (
                <TableRow key={index} className="child-tr">
                  <TableCell className="checkbox-cell fixed-cell">
                    <Checkbox
                      id={"checkBox" + dealer.dealer_code}
                      checkBoxChecked={
                        dealerIds.find((x) => x === dealer.id) ? true : false
                      }
                      key={index}
                      theme={theme}
                      checkBoxDisabled={isDisabled}
                      checkBoxValue={dealer.id}
                      // disabled={isDisabled}
                      onChange={onCheckChange2}
                      label={""}
                    />
                  </TableCell>
                  <TableCell id={"dealerNameData" + dealer.dealer_code}>
                    {dealer.dealer_name}
                  </TableCell>
                  <TableCell id={"dealerCode" + dealer.dealer_code}>
                    {dealer.dealer_code}
                  </TableCell>
                </TableRow>
              ))
            }
          />
        </DataTableWrap>
      </Box>
    </>
  );
};
export default Dealers;

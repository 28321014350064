import { FC, useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import { Box, Button } from "@ntpkunity/controls";
import { DataTableWrap } from "shared/components";
import { ActionOptions, QueryKeys } from "Enums";
import { IBaseChart } from "Interface";
import BaseRateTable from "./base-rate-table";
import { setOpenDrawer } from "Stores/SetupStore/Actions";
import { useSetupsStore } from "Stores/SetupStore/Store";
import { AddNewBaseRateChart } from "components";
import { useQueryClient } from "react-query";
import { UseQuery_getAllCharts } from "services";
const BaseRateChart: FC = () => {
  UseQuery_getAllCharts();

  const queryClient = useQueryClient();
  const [baseRateChartState, setBaseRateChartState] = useState<boolean>(false);
  const [actionTitle, setActionTitle] = useState<string>("Add New Chart");
  const [actionType, setActionType] = useState<ActionOptions>(
    ActionOptions.ADD
  );
  const data = queryClient.getQueryData(QueryKeys.GET_ALL_CHARTS);
  const [actionData, setActionData] = useState<IBaseChart>(undefined);

  const [state, dispatch] = useSetupsStore();
  useEffect(() => {
    if (baseRateChartState === false) {
      dispatch(setOpenDrawer(false));
    }
  }, [baseRateChartState]);

  const handleSelection = (event, key, value) => {
    switch (key) {
      case ActionOptions.ADD:
        setActionType(ActionOptions.ADD_NEW_CHART);
        setActionTitle("Add New Base Rate");
        // setActionData(value);
        setBaseRateChartState(true);
        dispatch(setOpenDrawer(true));
      default:
        return;
    }
  };
  const onEdit = (formData: IBaseChart) => {
    setActionType(ActionOptions.EDIT);
    setActionTitle("Edit Base Rate");
    setActionData(formData);
    setBaseRateChartState(true);
    dispatch(setOpenDrawer(true));
  };
  const theme = useTheme();
  return (
    <>
      <DataTableWrap className="table-tabs-pagination-button">
        <Box theme={theme} className="scroll">
          <Box className="scroll-hide sh-fixed-cell spr-border" theme={theme} />
        </Box>
        <BaseRateTable onEdit={(data) => onEdit(data)} />
        <Button
          theme={theme}
          secondary
          fullWidth={true}
          text={"Add New Base Rate"}
          onClick={(e) => handleSelection(e, ActionOptions.ADD, undefined)}
        />
        {baseRateChartState && (
          <AddNewBaseRateChart
            openPopUp={baseRateChartState}
            setPopUpState={setBaseRateChartState}
            title={actionTitle}
            actionType={actionType}
            baseRateChart={actionData}
          />
        )}
      </DataTableWrap>
    </>
  );
};
export default BaseRateChart;
